import { TableModuleMetadata } from "../../types";

export default function getMetadataSqlV1() : TableModuleMetadata {
  return {
    module: 'SqlV1',
    description: 'Query Graffe tables using SQL (select * from any_table)', 
    displayName: 'SQL (in app)',
    attributions: [
      { label: 'DuckDB 0.7', url: 'https://duckdb.org/', spotlight: true },
    ],
    requiresInsider: true,
  }
}
import runtime from "../runtime.js";

function handleError(err: Error | string) {
  // If development mode, throw a popup
  if(typeof window !== 'undefined') {
    const error = document.createElement('div');

    Object.assign(error.style, {
      backgroundColor: '#400101',
      fontWeight: 'bold',
      color: 'red',
      zIndex: '99999',
      border: '2px solid #FFF',
      padding: '12px',
      position: 'absolute',
      minHeight: '200px',
      left: '4px',
      top: '4px',
      right: '4px',
    });

    error.innerHTML = `
    <pre style="cursor: pointer;" onclick="document.body.removeChild(this.parentNode);">Error (see your dev console for trace)</pre>
    <pre style="border-top: 2px solid #fff; padding-top: 8px">${err && (err.stack && err.stack.toString() || err.message || (err.toString && err.toString())) || err}</pre>
    `;
    
    document.body.append(error);
  }
}

// When called, it captured unhandled exceptions and triggers the above view
export const showErrors = () => window.addEventListener('error', event => {
  handleError(event.error || event.message);
});
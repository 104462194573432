import CryptoJS from 'crypto-js';
import stableStringify from 'fast-json-stable-stringify';
import { IdentityAccessScope, IdentityAccessToken } from 'graffe-shared/src/identity/types';
import { CollectionId } from "graffe-shared/src/types/types";
import store from "../idb/store";
import { appJson, credentialsMode } from "../lib/auth";
import { throwOnBadResponse } from "../lib/fetch";
import { logError } from '../lib/logger';
import { state } from "../state";

interface LocalToken {
  collection: CollectionId,
  token: string,
  tokenHash: string,
  expires: number,
  scopes: IdentityAccessScope[],
}

// It should only refresh token when: we don't have a valid local token, when teams have changed or when a token was rejected.
// Universe tokens are created on the client, but hashed and enriched in the id service.
export async function getUniverseToken() : Promise<string> {
  // If we still have a valid local token, we go down that path to avoid a roundtrip
  const lastTokenHash = await (await store()).getKey(`latest-token`);
  if(lastTokenHash) {
    const lastTokenRaw = await (await store()).getKey(`token-${lastTokenHash}`);
    const localToken = parseLocalToken(lastTokenRaw);
    if(!isExpired(localToken.expires) && scopesAreValid(localToken.scopes) && state.user.v.id === localToken.collection) {
      return localToken.token;
    }
  }

  // Here we need to refresh the token
  return await refreshUniverseToken();
}

function parseLocalToken(text: string) : LocalToken {
  try {
    return JSON.parse(text);
  } catch (err) {
    return {
      expires: Date.now() - 1000, // Expire it, to force upgrade
      collection: '',
      token: text,
      tokenHash: '',
      scopes: [],
    }
  }
}

function isExpired(expires: number) : boolean {
  // Tokens have a few hours lifetime, we add 2 minutes to account a bit for time differences with devices in the wild.
  return expires <= Date.now() + 120000;
}

// Validate if scopes have not changed
function scopesAreValid(scopes: IdentityAccessScope[]) : boolean {
  const left = stableStringify(scopes);
  const right = stableStringify(tokenScopes());
  return left === right;
}

function tokenScopes() : IdentityAccessScope[] {
  const scopes = [
    // My collection
    {
      collection: state.user.v.id, 
      scope: ['read', 'write']
    },
    // Add all my teams with my permissions to the token
    ...((state.teams.v||[]).map(team => {
      let scope = ['read'];
      if(team.role === 'owner' || team.role === 'member') {
        // TODO: lower permission levels (service will reject)
        scope = ['read', 'write'];
      }
      return {
        collection: team.team.id,
        scope,
      }
    })),
  ];

  return scopes;
}

async function refreshUniverseToken() : Promise<string> {
  // TODO: would be cool to hashproof the token content, if validation is then still possible.
  const rand = CryptoJS.lib.WordArray.random(32);
  const tokenHash = CryptoJS.algo.SHA256.create().update(rand).finalize().toString(CryptoJS.enc.Hex);
  const token = rand.toString(CryptoJS.enc.Hex);
  await (await store()).putKey(`token-${tokenHash}`, token);

  // Create scopes
  const scopes = tokenScopes();

  const req = await fetch(`${state.idService.v}/v1/session/accessTokens`, {
    method: 'POST',
    headers: appJson, 
    ...credentialsMode,
    body: JSON.stringify({
      tokenHash,
      scopes,
    }),
  });
  await throwOnBadResponse(req);

  const { expires, scopes: idScopes } = await req.json() as IdentityAccessToken;

  // Store it locally
  const localToken: LocalToken = {
    collection: state.user.v?.id,
    expires,
    scopes: idScopes,
    token,
    tokenHash,
  }
  await (await store()).putKey(`token-${tokenHash}`, JSON.stringify(localToken));

  // Swap it live
  await (await store()).putKey(`latest-token`, tokenHash);

  // Schedule janitor to clean
  setTimeout(() => removeExpiredTokens(), 1000);

  // Return the authorization token for the ongoing request
  return token
}

// Self-contained function
async function removeExpiredTokens() {
  try {
    const rows = await (await store()).getKeyValRows();
    const expired = rows
      .filter(r => r.key.substring(0, 6) === 'token-')
      .filter(r => {
        const lt = parseLocalToken(r.val);
        return isExpired(lt.expires);
      });
    for(let row of expired) {
      await (await store()).delKey(row.key);
    }
  } catch (err) {
    logError('univerToken.janitor', err);
  }
}
import { Signal, signal } from "ufti";
import { CollectionId } from "../types/types.js";

// What kind of access actions are applied on a collection for an object/reference.
// export type AclAction = 'read' | 'edit' | 'comment' | 'annotate' | 'acl' | 'owner';
export type AclAction = 'read' | 'owner';

export type AclRuleEffect = 'allow' | 'deny';

export type AclRulePrincipal = CollectionId | '*';

export interface AclRuleData {
  // How the rule behaves. We can later implement that deny overrules allow.
  effect: AclRuleEffect;

  // '*' indicates anonymous. Which is used by "open" objects (if you figure out the URL, you can access it).
  principal: AclRulePrincipal[];

  // Action
  action: AclAction[];
}

export class AclRule {
  effect: Signal<AclRuleEffect> = signal();

  principal: Signal<AclRulePrincipal[]> = signal();
  
  action: Signal<AclAction[]> = signal();

  static fromData(d: AclRuleData) : AclRule {
    const r = new AclRule();
    r.effect.v = d.effect;
    r.principal.v = d.principal;
    r.action.v = d.action;
    return r;
  }
}

export function validateIfAllRead(rules: AclRuleData[]) : boolean {
  const action = 'read';

  // First check if DENY rules exist anywhere, they overrule.
  if(rules.find(r => r.effect === 'deny' && r.principal.indexOf('*') >= 0 && r.action.indexOf(action) >= 0)) {
    return false;
  }

  // Check if we find an ALLOW rule
  if(rules.find(r => r.effect === 'allow' && r.principal.indexOf('*') >= 0 && r.action.indexOf(action) >= 0)) {
    return true;
  }

  return false;
}
import { unexpected } from 'graffe-shared/src/lib/devflow';
import { GuidanceError } from 'graffe-shared/src/lib/error';
import { PureCallback } from 'ufti/src/types';
import { setImmediate } from './thread';

// export async function parseError(req: Response) : Promise<string> {
//   // First try to parse as JSON
//   if(req.status !== 200) {
//     try {
//       const blob = await req.json();
//       if(blob?.error?.message) {
//         return blob?.error?.message;
//       }
//     } catch (err) {
//       return req.statusText;
//     }
//   }
// }

// Defensive and best-effort error response handler for universe and ID-service responses.
// Convert the response to an error that is bubbelable and logs nicely.
// We restore the universe errors back into their original internal type
export async function throwOnBadResponse(res: Response, opts?: { onError: PureCallback }) : Promise<void> {
  if(!res.ok) {
    let err: Error = null;
    let raw, rawText;
    try {
      raw = await res.blob();
      rawText = await raw.text();
    } catch (err) {
      console.error(err); // Network level issue
      err = new Error(res.statusText);
    }

    if(err == null && rawText != null) {
      // First try to parse as JSON
      try {
        const jres = JSON.parse(rawText);
        if(jres?.error?.guidance != null) {
          err = new GuidanceError(jres.error.status, jres.error.message, jres.error.guidance);
        } else if(jres?.error?.message != null) {
          err = new Error(jres?.error?.message);
        } else {
          unexpected();
        }
      } catch (jsonErr) {
        // Not json content
      }

      // Then try handling as text
      if(err == null) {
        err = new Error(rawText);
      }
    }

    // If we didn't figure out anything, we fallback to statusText
    if(err == null) {
      err = new Error(res.statusText);
    }

    // Safely handle any caller error handler
    if(opts?.onError != null) {
      setImmediate(opts.onError);
    }

    throw err;
  }
}
import { Schema } from "@cfworker/json-schema";
import { JSONValue } from "../types/json.js";
import { ObjectTitle } from "./objects.js";
import { TableData } from "./table.js";

// Css string, eg: 100%, 400px, ...
type CssString = string;

export const enum ViewModuleId {
  // --  Reference loader

  GraffeViewV1 = "GraffeViewV1", 

  // --  Data driven implementations

  DataGridV1 = "DataGridV1", 
  ChartV1 = "ChartV1", 
  MarkdownV1 = "MarkdownV1", 

  // -- Static implementaions
  
  DashTitleV1 = "DashTitleV1", 
  InfoV1 = "InfoV1", 
  TextV1 = "TextV1", 

  // -- Alpha
  GeomapV1 = "GeomapV1", 
  DebugV1 = "DebugV1", 
};

export type TypeViewModuleId = keyof typeof ViewModuleId;

export interface UICoordinates {
  x: number;
  y: number;

  // List rendering order
  order?: number;
}

export const UICoordinatesSchema: Schema = {
  $id: "/schemas/UICoordinates",

  type: "object",
  properties: {
    x: { type: "number" },
    y: { type: "number" },
    order: { type: "number" },
  },
  required: ["x", "y"],
};

export const enum SideValue {
  Top = 0,
  Right = 1,
  Bottom = 2,
  Left = 3,
}

export interface UIDimensions {
  w: number;
  h: number;

  // l*: List rendering prefix
  
  // List rendering height
  lh?: number;
}

// export interface UIMargin {
//   // The top spacing in the render card, implemented as padding.
//   top: number;
  
//   // The right spacing in the render card, implemented as padding.
//   right: number;

//   // The bottom spacing in the render card, implemented as padding.
//   bottom: number;

//   // The left spacing in the render card, implemented as padding.
//   left: number;
// }

// export interface StyleSpacing {
//   top?: number;
//   right?: number;
//   bottom?: number;
//   left?: number;
// }

// export interface StyleTitle {
//   // Spacing implemented as padding to extend the hitradius
//   spacing?: StyleSpacing;

//   // Adjust title position
//   pos?: UICoordinates;
// }

// // Fully optional layout, appearance and rendering config of an object.
// // This is *NOT* directly mapped to CSS. It's a custom style definition.
// // Eg: things like spacing depends on the object how it's implemented. For an object the spacing can be implemented as paddings, for a title as it might be padding or a placeholder object to extend click behavior.
// export interface StyleObject {
//   // The spacing in the render card, implemented as padding.
//   spacing?: StyleSpacing;

//   // Applies whenever the title is truthy
//   title?: StyleTitle;
// }

export const UIDimensionsSchema: Schema = {
  $id: "/schemas/UIDimensions",

  type: "object",
  properties: {
    w: { type: "number" },
    h: { type: "number" },
    lh: { type: "number" },
  },
  required: ["w", "h"],
};

export interface ViewData {
  module: ViewModuleId;
  pos: UICoordinates;
  dim: UIDimensions;
  // style?: StyleObject;

  // DEPRECATED
  // margin?: UIMargin;
  
  depId?: string;
  title?: ObjectTitle;
  accessible?: boolean;
  
  // Dynamic props the view can specify
  props?: JSONValue;

  // Table definitions for this view
  tables?: TableData[];

  // Can be set to a version number of the last migration done.
  migration?: number;
}

export const ViewDataSchema: Schema = {
  $id: "/schemas/ViewData",

  type: "object",
  properties: {
    type: { type: "string" },
    pos: { $ref: "/schemas/UICoordinates" },
    dim: { $ref: "/schemas/UIDimensions" },
    title: { type: "string" },
    accessible: { type: "boolean" },
    props: { $ref: "/schemas/JSONValue" },
    tables: {
      type: "array",
      items: { $ref: "/schemas/TableData" },
    },
  },

  required: ["type", "pos", "dim"],
};
import { ViewModuleId } from "graffe-shared/src/models/view";
import { Signal, signal } from "ufti";
import { ObjectInstance } from "../models/data";
import Table from "../nodes/table";
import { View } from "../nodes/view";

// Instance Ctx contains the actual data object.
// This allows building generic components which can work on changes, but also inline objects and other.
// Objects should only require objectCtx if they are working on a universe object.
//
// TODO: remove the generatedView etc from the object after implementing this and move all usages.
export class InstanceCtx {
  instance: Signal<ObjectInstance> = signal();

  // Optional view which can be set which holds a representation of this instance if its not renderable.
  //
  // Generated view for non-renderable objects, so they can be visualized in generic contexts.
  // All components showing views, should implement support to fallback to the generatedView.
  // Only available for objects which have no visual renderer.
  generatedView: Signal<View> = signal();

  constructor(instance: ObjectInstance) {
    this.instance.v = instance;
  }

  // There is a double rendering problem here. The generated view should be a separate ctx, since this can be used and exist in parallel contexts.
  // Long search for a bug to figure that one out...
  ensureView(clientWidth, clientHeight, fullWidth?: boolean) {
    if(this.instance.v instanceof Table && !this.generatedView.v) {
      this.generatedView.v = createTableDataGridView(this.instance.v, clientWidth, clientHeight, fullWidth)
    }
  }

  getView() : View {
    return this.generatedView.v ?? this.instance.v;
  }
}

export function calcGoodTableWidth(table: Table, containerWidth: number) : number {
  let w = containerWidth;
  if(table.fields.v != null) {
    w = table.fields.v.length * 128 + 26 + 2; // 26px for the rowNumbers
    if(w > containerWidth) {
      w = containerWidth;
    }
  }

  return w;
}

function createTableDataGridView(table: Table, containerWidth: number, containerHeight: number, fullWidth?: boolean) : View {
  let w = containerWidth;
  
  if(!fullWidth) {
    containerWidth = (containerWidth ?? (window.innerWidth-32));
    w = calcGoodTableWidth(table, containerWidth);
    // if(table.fields.v != null) {
    //   w = table.fields.v.length * 128 + 26 + 2; // 30px for the rowNumbers
    //   if(w > (containerWidth)) {
    //     w = containerWidth;
    //   } 
    // }
  }

  const view = View.fromData({ 
    module: ViewModuleId.DataGridV1,
    pos: { x: 0, y: 0 },
    dim: { h: 328, w },
    props: {
      rowNumbers: true,
    }
  });

  // Tables are hooked up to the original
  view.tables = signal([table]);

  return view;
}
import { primitiveValue } from 'graffe-shared/src/universe/utils';
import { uniqueId } from 'lodash-es';
import { findCtx } from 'ufti/src/plugin/ctx';
import { Signal, onEnter, signal, sub } from "ufti";
import { toSignal } from './extUfti';
import { OutlineIcon } from "./icons";
import { tm } from './tailwind';

// After all the fields mess, this file contains composable fields which are all reactive and have clean design.
//
// Rules:
// -  nicely typed
// -  sig = primary value signal and is reactive to its value.
// -  class applies to outer wrapper.
// -  no default values, they need to be set on the sig to ensure stable behavior across time.
// -  optional values at the end

interface InputCheckboxProps extends Partial<HTMLInputElement> {
  sig: Signal<boolean>,
  readOnly: boolean | Signal<boolean>,
}

export function InputCheckbox({ 
  sig, 
  class: klass,
  readOnly,
  ...other
} : InputCheckboxProps) : HTMLInputElement {
  const el: HTMLInputElement = <input
    type="checkbox" 
    class={`cursor-pointer ${klass||''}`} 
    onchange={(e: Event) => {
      sig.v = e.target.checked;
    }}
    {...other}
  />;

  sub([sig, signal(1)], () => {
    if(el.checked !== (!!sig.v)) {
      el.checked = !!sig.v;
    }
  }, el);

  sub([toSignal(readOnly), signal(1)], () => {
    const val = !!primitiveValue(readOnly);
    el.readOnly = val;
    el.disabled = val;
  }, el);

  handleDisabledCtx(el);

  return el;
}

interface CustomInputCheckboxProps extends InputCheckboxProps {
  inputClass?: string,
}

// This is a custom labelled checkbox whic
export function CustomInputCheckbox({ 
  id,
  inputClass,
  ...other
} : CustomInputCheckboxProps) : HTMLDivElement {
  if(!id) {
    id = uniqueId('chk-');
  }
  
  return <div class="relative h-[18px]">
    <InputCheckbox 
      id={id}
      class={tm(`appearance-none m-[1px] rounded-sm w-4 h-4 outline outline-1 outline-zinc-600 bg-white checked:bg-indigo-600 checked:outline-indigo-600 disabled:bg-zinc-100 disabled:outline-zinc-300`, inputClass)}
      {...other} />
    
    <label for={id} class="pointer-events-none absolute left-[2px] top-[2px]">
      <OutlineIcon icon="check" class="w-[14px] h-[14px] text-white" />
    </label>
  </div>;
}

export class DisabledCtx {
  disabled: Signal<boolean> = signal(false);
}

// Toggle disabled if the field is in a disabled ctx.
export function handleDisabledCtx(el: HTMLInputElement | HTMLSelectElement | HTMLButtonElement | HTMLTextAreaElement | HTMLFieldSetElement | HTMLOptGroupElement | HTMLOptionElement) {
  onEnter(el, () => {
    const disabledCtx = findCtx(el, DisabledCtx);

    if(disabledCtx) {
      sub(disabledCtx.disabled, () => {
        el.disabled = !!disabledCtx.disabled.v
      }, el);
    }
  });
}
import { onEnter, onExit } from '../render.js';
import { Signal, signal, sub } from '../signal.js';

// State which controls the document title
export const head: {
  meta: Signal<Record<string, Record<string, string>>>,
  title: {
    main: Signal<string>;
    separator: Signal<string>;
    suffix: Signal<string>;
  }
} = {
  title: {
    main: signal(''),
    separator:  signal(' - '),
    suffix: signal(''),
  },
  meta: signal({}),
};

function setTitle(exitEl: Element, main: string) {
  let before: string;
  onEnter(exitEl, () => {
    before = head.title.main.v;
    head.title.main.v = main;

    onExit(exitEl, () => head.title.main.v = before);
  }, exitEl);
}

// Runtime effect which sets a relevant tile.
sub([head.title.main, head.title.suffix, head.title.separator], () => {
  document.title = `${head.title.main.v||''}${head.title.main.v && head.title.suffix.v ? head.title.separator.v : ''}${head.title.suffix.v || ''}`;
});

function strEscape(str: string) : string {
  return str
    .replace(/\"/g, '\\"')
    .replace(/\\/g, '\\\\');
}

// Set meta keys
sub(head.meta, () => {
  for(let [key, val] of Object.entries(head.meta.v)) {
    const nameKey = Object.keys(val).filter(k => k !== 'content')[0];
    let el = document.querySelector(`meta[${nameKey}="${strEscape(val[nameKey])}"]`);
    let add = false;
    if(!el) {
      el = document.createElement('meta');
      add = true;
    }
    el.setAttribute(nameKey, strEscape(val[nameKey]));
    if(val.content) el.setAttribute('content', val.content);
    if(add) document.head.appendChild(el);
  }
});
import { Thing } from 'graffe-shared/src/models/nodes';
import { CollectionId, CommitHash, RefHash } from 'graffe-shared/src/types/types';
import { Commit } from 'graffe-shared/src/universe/commit';
import { getStorageModel } from 'graffe-shared/src/universe/utils';
import { Signal, signal } from 'ufti';
import { canWrite } from '../models/nodes';
import { CommitStoreRow, ConnectionRowStatus, KeyVal } from './types';
import { Connection } from '../connections/types';

function warn() {
  console.warn('Embedded floow does not support storing this');
}

// Embed store is used in an embedded context, it doesn't require migrations and we want to guarantee that the user session is not available there.
export class EmbedStore {
  private storeCommits: Record<string, CommitStoreRow> = {};
  private lastRefHash: Record<string, string> = {};
  private storeKV: Record<string,KeyVal> = {};
  
  // Signal which gets fired when change actions are written.
  // Entries are written as complete as possible
  public changes: Signal<{ 
    action: 'change' | 'delete' | 'clear', 
    thing?: Thing, 
    refHash?: RefHash, 
    change?: Commit
  }> = signal();

  // -- CHANGES
  async putChange(change: Commit) {
    warn();
  }

  async getChangeRowForRefHash(thing: Thing, refHash: RefHash) : Promise<ChangeStoreRow> {
    warn();
  }

  async getChangesAsCommits() : Promise<Commit[]> {
    warn();
    return [];
  }

  async getChangeCommitForRefHash(thing: Thing, refHash: RefHash) : Promise<Commit> {
    warn();
  }

  async getChangeRows() : Promise<ChangeStoreRow[]> {
    return [];
  }

  async delChangeForRefHash(thing: Thing, refHash: RefHash) : Promise<void> {
    warn();
  }

  // -- COMMITS

  async getHeadRow(thing: Thing, refHash: string) : Promise<CommitStoreRow> {
    return this.storeCommits[this.lastRefHash[`${thing}:${refHash}`]];
  }

  async getHead(thing: Thing, refHash: string) : Promise<Commit> {
    const row = await this.getHeadRow(thing, refHash);
    if(row) return Commit.fromData(row.commit);
  }

  async getCommitRow(hash: CommitHash) : Promise<CommitStoreRow> {
    return this.lastRefHash[hash];
  }

  async getCommit(hash: CommitHash) : Promise<CommitStoreRow> {
    const row = await this.getCommitRow(hash);
    if(row) return Commit.fromData(row.commit);
  }

  async cacheCommit(commit: Commit) : Promise<void> {
    if(canWrite(commit)) {
      return this.writeCommit(commit);
    }
  }

  // WARN: this function works under the assumption the commits has a sparse set with minimally the parent row loaded.
  //       it requires the store to be synced up or might make mistakes. 
  //       The remote sync can correct any mistakes normally.
  private async writeCommit(commit: Commit) {
    // Get current commit row
    const existing = await this.storeCommits[commit.hash];
    if(existing) return;

    // Check if this commit is the new head
    const headRow = await this.getHeadRow(commit.ext.thing, commit.ext.refHash);
    const head = (headRow && Commit.fromData(headRow.commit) || null);
    let isNewHead = false;
    if(!head || commit.commit.parents?.indexOf(head.hash) >= 0) {
      isNewHead = true;
    }

    // Storage model
    const cd = getStorageModel(commit);

    // Complete row
    const row: CommitStoreRow = {
      commit: cd,
      isHead: (isNewHead ? commit.ext.refHash : null),
      modified: Date.now(),
    };

    // If it already exists, we ignore it
    this.storeCommits[commit.hash] = row;

    // Mark the parent commit off head
    if(isNewHead && head) {
      this.storeCommits[commit.hash] = {
        ...headRow,
        isNewHead: null,
        modified: Date.now(),
      }
    }
  }

  // WARN: this function works under the assumption the commits has a sparse set with minimally the parent row loaded.
  //       it requires the store to be synced up or might make mistakes. 
  //       The remote sync can correct any mistakes normally.
  async putCommit(commit: Commit) {
    await this.writeCommit(commit, false);

    console.log('stored commit');

    // Mark the row to be synced
    await this.syncQueueCommit(commit.hash);
  }

  async delCommit(hash: CommitHash) : Promise<void> {
    return this.db.delete(Tables.commits, hash);
  }

  async getCommitRows() : Promise<CommitStoreRow[]> {
    const rows = await this.db.getAll(Tables.commits);

    return rows as CommitStoreRow[];
  }

  async syncQueueCommit(hash: CommitHash) : Promise<IDBValidKey> {
    warn();
  }

  async getSyncQueue() : Promise<syncLogEntry[]> {
    warn();
    return [];
  }

  async delFromSyncQueue(id: number) : Promise<void> {
    warn();
  }

  // -- KEYV

  async getKey(key: string) : Promise<KeyVal> {
    const row = await this.storeKV[key];
    if(row?.key) {
      return row.val;
    }
    return null
  }

  async putKey(key: string, val: string) : Promise<void> {
    this.storeKV[key] = { key, val, time: Date.now() };
  }

  async getKeyValRows() : Promise<KeyVal[]> {
    return Object.values(this.storeKV);
  }

  async delKey(key: string) {
    delete this.storeKV[key];
  }

  async clearTables() {
    this.storeKV = {};
    this.commits = {};
  }

  // -- Stub connection calls

  async putConnection(c: Connection, status: ConnectionRowStatus = 'modified') {
    warn();
  }

  async markConnectionSynced(conn: Connection) {
    warn();
  }

  async getConnection(refHash: RefHash) : Promise<Connection> {
    return null;
  }

  // Get local connections which are not removed
  async getConnections(collection: CollectionId) : Promise<Connection[]> {
    return [];
  }

  // Returns a list of connections which either have been modified or removed
  async getWalConnections() : Promise<{ status: ConnectionRowStatus, conn: Connection }[]> {
    return [];
  }

  // Remove from the local table
  async delConnection(conn: Connection) {
    warn();
  }
}

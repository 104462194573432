import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import dayjs from "dayjs";
import { createReporter } from 'edogawa';
import { ProtectedUserProfile } from "graffe-shared/src/types/types";
import { appJson } from "../lib/auth";
import { isProduction } from "../lib/dev";
import state from './state'; // Reporting bootstraps with a small state to capture all errors and avoid side-effects

export function initBundledCrashReporting() {
  if(!state.errors.v) return;
  if(!process.env.GRF_REPORTS_POST_URL) throw new Error('required: GRF_REPORTS_POST_URL');
  if(!process.env.GRF_REPORTS_WRITE_TOKEN) throw new Error('required: GRF_REPORTS_WRITE_TOKEN');

  // Custom crash reporter which captures unknown errors
  createReporter({ 
    endpoint: process.env.GRF_REPORTS_POST_URL,
    restToken: process.env.GRF_REPORTS_WRITE_TOKEN,
    additionalInfo: {
      thread: 'ui'
    }
  });
}

export function initExternalCrashReporting() {
  if(state.external.v !== 'sentry') return;
  if(!process.env.GRF_SENTRY_URL) return console.warn('missing: GRF_SENTRY_URL'); // Optional (disabled in dev)

  Sentry.init({
    dsn: process.env.GRF_SENTRY_URL,
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function jsonMemory() {
  const mem = {};
  if(performance?.memory?.jsHeapSizeLimit != null) {
    Object.assign(mem, {
      usedJSHeapSize: performance?.memory?.usedJSHeapSize,
      totalJSHeapSize: performance?.memory?.totalJSHeapSize,
      jsHeapSizeLimit: performance?.memory?.jsHeapSizeLimit,
    })
  }
  return mem;
}

export async function notifyImportantError(err: string, user: ProtectedUserProfile) {
  if(!process.env.GRF_USAGE_URL) throw new Error('required: GRF_USAGE_URL');
  if(!process.env.GRF_REPORTS_WRITE_TOKEN) throw new Error('required: GRF_REPORTS_WRITE_TOKEN');

  try {
    const { origin, pathname, search } = window.location;
    const req = await fetch(process.env.GRF_USAGE_URL, {
      method: 'POST',
      headers: {
        ...appJson,
        authorization: process.env.GRF_REPORTS_WRITE_TOKEN,
      },
      body: JSON.stringify({
        uid: crypto.randomUUID(),
        err,
        route: {
          origin,
          path: pathname,
          query: search,
        },
        ts: Date.now(),
        ltime: dayjs().format(),
        session: {
          id: user?.id,
          slug: user?.slug,
        },
        ua: navigator.userAgent,
      }),
    })
  } catch (err) {
    console.warn('usage', err);
  }
}

function cleanSearchParams(search: string) : string {
  const urlParams = new URLSearchParams(search);
  const params = ['ref', 'source', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  const result = [];

  for (const param of params) {
    if (urlParams.has(param)) {
      result.push(`${param}=${urlParams.get(param)}`);
    }
  }

  return result.join('&');
}

async function makeSeed(seed: string) : string {
  const encoder = new TextEncoder();
  const data = encoder.encode(seed);
  const hash = await window.crypto.subtle.digest('SHA-256', data);

  return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
}

export async function notifyRouteChange(route: Route, match: any, user: ProtectedUserProfile, time: number) {
  if(!state.usage.v) return;
  if(!process.env.GRF_USAGE_URL) throw new Error('required: GRF_USAGE_URL');
  if(!isProduction()) return; 

  try {
    // TODO: should use route and match
    const { origin, pathname, search } = window.location;
    const cleanSearch = cleanSearchParams(search);
    const mem = jsonMemory();

    const data = JSON.stringify({
      path: pathname,
      ...(cleanSearch?.length > 0 && { search: cleanSearch }),
      time,
      ltime: dayjs(time).format(),
      // Anonimize these
      screen: {
        height: window.innerHeight,
        width: window.innerWidth,
        pixels: window.devicePixelRatio,
      },
      ...(
        user != null && { session: {
          id: user?.id,
          slug: user?.slug,
        }}
      ),
      mem,
    });

    // Report the usage
    const req = await fetch(process.env.GRF_USAGE_URL, {
      method: 'POST',
      headers: {
        ...appJson,
      },
      body: JSON.stringify({
        nonce: crypto.randomUUID(),
        ref: document.referrer,
        seed: makeSeed(
          [
            mem?.jsHeapSizeLimit,
            window.devicePixelRatio, 
            window.doNotTrack, 
            window.screen.colorDepth, 
            navigator.language || navigator.userLanguage
          ].filter(d => d != null).join('|')
        ),
        data,
      }),
    });
    if(!req.ok) {
      console.error(req.statusText);
      console.error(await req.text())
    }
  } catch (err) {
    console.warn('usage', err);
  }
}

// export async function reportFeedback(feedback: any, user: UserProfile) {
//   if(!process.env.GRF_FEEDBACK_URL) throw new Error('required: GRF_FEEDBACK_URL');
//   if(!process.env.GRF_REPORTS_WRITE_TOKEN) throw new Error('required: GRF_REPORTS_WRITE_TOKEN');

//   try {
//     const { contact, message, shot, ctx } = feedback;
//     const { origin, pathname, search } = window.location;
//     const mem = jsonMemory();
//     const req = await fetch(process.env.GRF_FEEDBACK_URL, {
//       method: 'POST',
//       headers: {
//         ...appJson,
//         authorization: process.env.GRF_REPORTS_WRITE_TOKEN,
//       },
//       body: JSON.stringify({
//         uid: randomId(32),
//         contact,
//         message,
//         session: {
//           id: session.id.v,
//           slug: session.slug.v,
//         },
//         ltime: dayjs().format(),
//         shot,
//         ctx,
//         route: {
//           origin,
//           path: pathname,
//           query: search,
//         },
//         mem
//       }),
//     })
//   } catch (err) {
//     console.warn('usage', err);
//   }
// }

import rison from "rison";
import { unexpected } from "graffe-shared/src/lib/devflow";
import { reportSuccess, reportWarning } from "../types/notifications";
import { UrlStateData } from "../types/urlState";
import { isProduction } from "./dev";

export function toFullRef(slug: string, ref: string) : string {
  return `${slug}/${ref}`;
}

export function removeAtSign(s: string) : string {
  if(s[0] === '@') {
    return s.substring(1);
  }
  return s;
}

export function studioUrlForRef(fullRef: string) : string {
  return `/studio?s=${rison.encode_object({ 
    ref: fullRef,
  } as UrlStateData)}`;
}

export async function copyLink(fullRef: string) {
  // Keep this line for license.
  if(location.origin !== 'https://graffe.app' && location.origin.substring(0, 16) !== 'http://localhost' && location.origin.substring(0, 15) !== 'http://192.168.') {
    unexpected();
  }
  if(!navigator.clipboard && !isProduction()) {
    reportWarning('This only works on https or localhost. It will not error.');
  }
  await navigator.clipboard.writeText(`${location.origin}/@${fullRef}`);
  reportSuccess('Link copied');
}

import { onEnter, onExit } from "ufti";

// Warn: this ignores content from dialog elements!
export function whenClickedOutside(el: HTMLElement, doThis: (e: PointerEvent) => void) {
  const outsideClickWatcher = (e: PointerEvent) => {
    if(e.target !== el && !el.contains(e.target) && !e.target.closest('dialog')) {
      doThis(e);
    }
  }

  addRelativeEventListener(el, document.body, 'click', outsideClickWatcher);
}

export function addRelativeEventListener<T>(el: HTMLElement, eventEl: HTMLElement, event: string, handler: (e: T) => void) {
  onEnter(el, () => {
    // We do this to ensure any event origin handler callbacks have fired.
    window.requestAnimationFrame(() => {
      eventEl.addEventListener(event, handler);

      onExit(el, () => {
        eventEl.removeEventListener(event, handler);
      });
    });
  });
}
export function isEE() : boolean {
  return process.env.GRF_APP === 'ee';
}

export function isCE() : boolean {
  return process.env.GRF_APP == null || process.env.GRF_APP === 'ce';
}

export function isGraffeSite() : boolean {
  return process.env.GRF_APP === 'graffe.app';
}

export function hasRemoteRepo() : boolean {
  return process.env.SL_LOCAL == null;
}

import { TeamProtected, TeamPublic } from "graffe-shared/src/identity/types";
import { CollectionId } from "graffe-shared/src/types/types";
import { Signal, signal } from "ufti";

export class TeamCtx {
  public teamId: CollectionId;
  public slug: Signal<string> = signal();

  public team: TeamPublic | TeamProtected;

  constructor(teamId: CollectionId) {
    this.teamId = teamId;
  }
}
import { getTimeTzOffset } from "graffe-shared/src/lib/time";
import { CollectionId, CollectionRef, CollectionSlug, RefHash } from "graffe-shared/src/types/types";
import { toRefHash } from 'graffe-shared/src/universe/utils';
import { Connection, UnsealedConnection } from "../connections/types";
import store from "../idb/store";
import { appJson, credentialsMode } from "../lib/auth";
import { isProduction } from "../lib/dev";
import { throwOnBadResponse } from "../lib/fetch";
import { logError } from "../lib/logger";
import { state } from "../state";
import { getUniverseToken } from "./universeTokens";

// Fetch = fetch from a remote API
// Get = get from a local store
// Resolve = do both and keep latest result

export async function searchIdServiceForConnections(collection: CollectionId) : Promise<Connection[]> {
  const req = await fetch(`${state.idService.v}/v1/connections?collection=${collection}`, {
    headers: appJson,
    ...credentialsMode,
  });
  await throwOnBadResponse(req);
  const res = await req.json();

  return res.items.map(Connection.fromData) as Connection[];
}

export async function fetchConnection(refHash: RefHash) : Promise<Connection> {
  const req = await fetch(`${state.idService.v}/v1/connections/${refHash}`, {
    headers: appJson, 
    ...credentialsMode,
  });
  if(req.status === 404) {
    return null;
  }
  await throwOnBadResponse(req);
  const res = await req.json();
  
  return Connection.fromData(res);
}

// Fetch from remote and local and merge sets
export async function resolveAllConnections(collection: CollectionId) : Promise<UnsealedConnection[]> {
  // We try fetching from remote, if it fails, the flow still continues.
  let remote = [] as Connection[];
  try {
    remote = await searchIdServiceForConnections(collection);
  } catch(err) {
    logError(err);
  }

  const results: Connection[] = [];

  // Loop over any remote connections, use them if they are latest and cache them otherwise.
  for(let r of remote) {
    const l = await (await store()).getConnection(r.ext.refHash);
    if(!l || l.ext.time[0] < r.ext.time[0]) {
      await (await store()).putConnection(r, 'synced');
      results.push(r);
    }
  }

  // Keep the last by remote and local
  const local = await (await store()).getConnections(collection);
  for(let l of local) {
    if(!results.find(r => r.ext.refHash === l.ext.refHash)) {
      results.push(l);
    }
  }
  
  return Promise.all(results.map(r => r.unseal()));
}

export async function resolveConnection(refHash: RefHash) : Promise<UnsealedConnection> {
  let r;
  try {
    r = await fetchConnection(refHash);
  } catch(err) {
    logError(err);
  }
  const l = await (await store()).getConnection(refHash);
  
  let res: Connection;
  if(l && (!r || r.ext.time[0] < l.ext.time[0])) {
    res = l;
  } else {
    res = r;
  }
  if(res) {
    return res.unseal();
  }
}

export async function removeConnection(refHash: RefHash) {
  const conn = await resolveConnection(refHash);
  if(conn) {
    // Remove action counts as a local update
    conn.ext.time.v = getTimeTzOffset();
    const sealed = await conn.seal();
    await (await store()).putConnection(sealed, 'removed');
  }
}

export async function getAuthReqProps() : Promise<{ [key: string]: string }> {
  return {
    ...appJson,
    ...(state.user.v != null ? { authorization: await getUniverseToken() } : {}),
  }
}

export async function resolveCollectionInfo(slug: CollectionSlug) : Promise<{ id: string, slug: string, name: string, relation: 'user' | 'team' }[]> {
  const req = await fetch(`${state.idService.v}/v1/collections?slug=${slug}`);
  await throwOnBadResponse(req);

  return (await req.json())?.items;
}

export async function findCachedCollectionId(slug: CollectionSlug) {
  if(slug === state.user.v?.slug) {
    return state.user.v?.id;
  }
  for(let team of state.teams.v) {
    if(team.team.slug === slug) {
      return team.team.id;
    }
  }
}

export function getGraffeCollectionId() : Promise<CollectionId> | CollectionId {
  if(isProduction()) {
    return 'MTD7V2B74VCS3NWPXJ23M37XKM';
  }

  return resolveCollectionId('graffe');
}

export async function resolveCollectionId(slug: CollectionSlug) : Promise<CollectionId> {
  const items = await resolveCollectionInfo(slug);
  if(items?.length !== 1) {
    throw new Error('Invalid collection id');
  }
  return items[0].id;
}

export async function resolveCollectionSlugById(colId: CollectionId) : Promise<CollectionSlug> {
  if(colId === state.user.v.id) {
    return state.user.v.slug;
  }

  // Other id's we look up
  const req = await fetch(`${state.idService.v}/v1/collections?id=${colId}`);
  await throwOnBadResponse(req);
  const res = await req.json();
  if(res?.items?.length > 0) {
    return res.items[0].slug;
  }
}

// export async function resolveCollectionSlugById(collectionId: CollectionId) : Promise<CollectionSlug> {
//   if(collectionId === state.user.v.id) {
//     return state.user.v.slug;
//   }
//   // Other id's we look up
//   const req = await fetch(`${state.idService.v}/v1/collections?id=${collectionId}`);
//   if(req.status !== 200) {
//     throw new Error(await parseError(req));
//   }
//   const res = await req.json();
//   if(res?.items?.length !== 1) {
//     throw new Error('Invalid collection id');
//   }
//   return res.items[0].slug;
// }

export async function fullRefToRefHashProps(fullRef: FullRef) : Promise<{
  colId: CollectionId,
  colRef: CollectionRef,
  slug: string,
}> {
  const isAbsolute = fullRef[0] === '/';
  const parts = fullRef.split('/');
  let collection: string, colRef: string;
  if(isAbsolute) {
    parts.shift();
  }
  
  // First resolve the collection
  collection = parts.shift();
  colRef = parts.join('/');

  if(!collection){
    throw new Error('invalid collection: '+collection);
  }

  let colId: CollectionId;
  if(collection === state.user.v?.slug) {
    colId = state.user.v?.id;
  } else {
    colId = await resolveCollectionId(collection);
  }

  return {
    colId,
    colRef,
    slug: collection,
  }
}

// TODO IMPROVE match on colId and align and cleanup.
export async function fullRefToRefHash(fullRef: FullRef) : Promise<RefHash> {
  const { colId, colRef } = await fullRefToRefHashProps(fullRef);
  return toRefHash(colRef, colId);
}
import { TableModuleId } from 'graffe-shared/src/models/table';
import getMetadataFileV1 from './modules/FileV1/metadata';
import getMetadataHttpV1 from './modules/HttpV1/metadata';
import getMetadataPubGoogleSheetV1 from './modules/PubGoogleSheetV1/metadata';
import getMetadataSqlV1 from './modules/SqlV1/metadata';
import getMetadataStravaV1 from './modules/StravaV1/metadata';
import { TableModuleMetadata } from './types';

export const tableModuleUIs: Record<TableModuleId, (() => Promise<any>)> = {
  HttpV1: _ => import('./modules/HttpV1/HttpV1UI'),
  FileV1: _ => import('./modules/FileV1/FileV1UI'),
  SqlV1: _ => import('./modules/SqlV1/SqlV1UI'),
  PubGoogleSheetV1: _ => import('./modules/PubGoogleSheetV1/PubGoogleSheetV1UI'),
  StravaV1: _ => import('./modules/StravaV1/StravaV1UI'),
}

export const basicTableModuleLibrary: TableModuleMetadata[] = [
  getMetadataSqlV1(),
  getMetadataFileV1(),
  getMetadataPubGoogleSheetV1(),
  getMetadataStravaV1(),
  getMetadataHttpV1(),
];
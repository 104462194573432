import { isProduction } from "../lib/dev";
import { state } from "../state";

export type NotificationStatus = 'info' | 'success' | 'warning' | 'failed';

export interface Notification {
  id: string;
  status: NotificationStatus;
  message: string;
  time: number;
  bag: any[];
}

// Message which bubbles up to the toast system
let counter = 0;
function report(status: NotificationStatus, message: string, ...bag: any[]) {
  // console.log(status, message, bag);
  // Create a notification
  const n: Notification = { id: ++counter, status, message, time: Date.now(), bag };
  
  // Add to the notification signal
  const curr: Notification[] = state.notifications.v || [];
  state.notifications.v = [...curr, n];

  // Only keep last 20 errors  
  if(state.notifications.v.length > 20) {
    state.notifications.v = state.notifications.v.slice(state.notifications.v.length-20);
  }
}

export function reportSuccess(msg: string, ...bag: any[]) {
  report("success", msg, ...bag);
}

export function reportFailed(msg: string, ...bag: any[]) {
  console.error(msg, ...bag);
  report("failed", msg, ...bag);
}

export function reportInfo(msg: string, ...bag: any[]) {
  console.log(msg, ...bag);
  report("info", msg, ...bag);
}

export function reportDebug(msg: string, ...bag: any[]) {
  console.debug(msg, ...bag);
  if(!isProduction()) {
    report("info", msg, ...bag);
  }
}

export function reportWarning(msg: string, ...bag: any[]) {
  console.warn(msg, ...bag);
  report("warning", msg, ...bag);
}

// Can be sent from anywhere in the system and will only show to the user if they are in edit mode of the object
export function reportDevHint(msg: string, ...bag: any[]) {
  report('info', msg, ...bag);
}

export function reportDataMigration(msg: string, ...bag: any[]) {
  console.warn('migrating:', msg, ...bag);
  report("info", `Migration object, commit to apply migration. ${msg}`, ...bag);
}
import { PureCallback } from "./types.js";

// Fast linked list which only supports push/shift/length
interface LinkedListItem<T> { 
  item: T;
  next?: LinkedListItem<T>;
}

class LinkedList<T> {
  private first: LinkedListItem<T>;
  private last: LinkedListItem<T>;
  public length: number = 0;

  push(item: T) {
    const node = { item };
    if(this.last) {
      this.last = this.last.next = node;
    } else {
      this.last = this.first = node;
    }
    this.length++;
  }

  shift() : T {
    const node = this.first;
    if(node != null) {
      this.length--;
      this.first = node.next || null;
      if(this.length <= 0) {
        this.last = null;
      }
      return node.item;
    }
  }

  // TODO: big improvement would be to add backwards linking and search from the end instead of the front.
  has(item: T) : boolean {
    let node = this.first;
    for(let i = 0; i < this.length; i++) {
      if(node.item === item) return true;
      node = node.next;
    }
    return false;
  }
}

// Next tick handling
const nextTickActions = new LinkedList<PureCallback>();
let nextTickScheduled = false;
let inActionCycle = false;
function processNextTickQueue() {
  inActionCycle = true;
  const breaker = setTimeout(() => inActionCycle = false, 16);
  try {
    while(inActionCycle && nextTickActions.length > 0) { 
      const nextAction = nextTickActions.shift();
      nextAction();
    }    
  } catch (err) {
    throw err; 
  } finally { // Errors don't stop execution
    clearTimeout(breaker);
    inActionCycle = false;
    nextTickScheduled = false; // might belong at start to avoid long-running tasks

    // Continue (in next animation frame) if there is more work
    if(nextTickActions.length > 0) {
      scheduleNextTick();
    }
  }
}

const hasWindow = typeof window !== 'undefined';
function scheduleNextTick() {
  if(nextTickScheduled) return;
  nextTickScheduled = true;
  if(hasWindow) {
    // If a window exists, execute in the next UI tick
    window.requestAnimationFrame(processNextTickQueue);
  } else {
    // If window doesn't exist, we execute instantly
    processNextTickQueue();
  }
}

/**
 * Schedule actions with minimal jank
 * 
 * @param nextAction The action to schedule
 */
export function updateInNextTick(nextAction: PureCallback) {
  // Skip actions which already exist (batch calls)
  if(nextTickActions.has(nextAction)) return;
  
  // Push on the execution stack, which will execute as fast as possible if a cycle is executing
  nextTickActions.push(nextAction);
  
  // If we're not inActionCycle, request processing on the next animation frame
  if(!inActionCycle) {
    scheduleNextTick();
  }
}
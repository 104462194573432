import {
  signal,
  Signal,
  isSignal,
  sub,
  firstValue,
  computed,
  regSurface,
  batch,
  SyntheticObject,
} from './signal.js';

import { 
  append,
  prepend,
  clear,
  setChildren,
  onEnter,
  onExit,
  remove,
  replace 
} from './render.js';

import { 
  createElement
} from './createElement.js';

import runtime from './runtime.js';

export default {
  // API for JSX transform classic runtime, such that `ufti.h` can be resolved.
  h: createElement,
  createElement,
}

export { 
  // -- signal.ts
  signal,
  Signal,
  isSignal,
  sub,
  computed,
  regSurface,
  firstValue,
  batch,
  SyntheticObject,

  // -- render.ts
  append,
  prepend,
  clear,
  setChildren,
  onEnter,
  onExit,
  remove,
  replace,

  // -- createElement.ts
  createElement,

  // -- runtime.ts
  runtime,

  // // -- Sub
  // Sub,
};
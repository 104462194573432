import { DataType, Thing } from 'graffe-shared/src/models/nodes';
import { UnsealedCommit, UnsealedCommitData } from 'graffe-shared/src/universe/commit';
import { getStorageModel } from "graffe-shared/src/universe/utils";
import { ValidationError } from 'graffe-shared/src/validator/commit';
import { signal, Signal } from 'ufti';
import { PureCallback } from 'ufti/src/types';
import { AppRoleValue } from '../components/lib/appRole';
import store from "../idb/store";
import { applyCommitProps, Change } from '../models/change';
import { state } from '../state';
import { reportFailed, reportInfo, reportSuccess } from '../types/notifications';
import { resolveHeadCommitByRefHash } from '../universe/gateway';
import { enableCtxAutoSaving, unsealedCommitsEq } from './helpers';
import { RefHash } from '../../../graffe-shared/src/types/types';

// Like Object ctx, but for Access things.
// Is a bit different, since Access policies are latest only and they don't need to be passed down to workers etc.
//
// We avoid usage intent here and "hope" we can handle everything with ctx appRole and owner permissions to figure out mutability.
//
export class AccessCtx {
  // Snapshot of the last commit done on this access policy.
  // For fast stable diffing of changes.
  initData: Signal<UnsealedCommitData> = signal<UnsealedCommitData>();

  // The live open change, signalling.
  // Gets debounced saved periodically.
  open: Signal<Change> = signal<Change>();

  // Savepoint state of the auto-saved commit (to avoid re-writes)
  // Initialized with the last stored change on load
  lastSave: Signal<UnsealedCommitData> = signal<UnsealedCommitData>();

  // If set, autosaving is active and can be cancelled
  autoSaveCancel?: PureCallback;

  static create(appRole: AppRoleValue, lastCommit?: UnsealedCommit, lastSave?: UnsealedCommit) : Promise<AccessCtx> {
    const ctx = new AccessCtx();
    
    if(!lastCommit && !lastSave) throw new Error('invalid object');

    if(lastCommit) {
      ctx.initData.v = getStorageModel(lastCommit);
    }
    if(lastSave) {
      ctx.lastSave.v = getStorageModel(lastSave);
    }

    // Load the change from either last save point or last version.
    const change = Change.fromUnsealedCommit(lastSave ?? lastCommit);

    // Enable auto saving in editor
    if(appRole === 'editor') {
      // TODO: I expect autoSaveCancel to be automatically garbage collected... might not be. Keep an eye on.
      ctx.autoSaveCancel = enableCtxAutoSaving(ctx);
    }

    // Trigger the context, it's now "open for business".
    ctx.open.v = change;

    return ctx;
  }

  isModified() : boolean {
    return this.lastSave.v != null;
  }

  // Remove the unsaved change for this ctx from the stage
  async dropOpenChange() : Promise<void> {
    await (await store()).delChangeForRefHash(Thing.Access, this.lastSave.v.ext.refHash);
    this.lastSave.v = null;
    console.log('deleted last save');
  }
}

// Commit access ctx
export async function commitCtxAccess(ctx: AccessCtx) {
  console.log('commitCtxAccess', ctx.open.v?.ext.ref.v);

  // Create a stable copy to work with
  const change = Change.fromUnsealedCommit(ctx.open.v.toUnsealedCommit());

  if((await change.calcContentHash()) === change.commit.content.v) {
    await ctx.dropOpenChange();
    return reportInfo('Nothing changed');
  }

  // Create a commit
  try {
    const parents = [];
    
    // If the open change had a previous version, we tag it.
    if(ctx.open.v.hash.v != null) {
      parents.push(ctx.open.v.hash.v);
    } else {
      // This is a new object, we verify if there aren't any previous commits to continue on.
      // If it's not a Tombstone, we error.
      // We resolve the oldest head on this refHash to mark as parent if it already existed before.
      const oldHead = await resolveHeadCommitByRefHash(Thing.Access, ctx.open.v?.ext.refHash.v, true);
      if(oldHead) {
        if(oldHead.ext.dataType !== DataType.Tombstone) {
          throw new Error(`Invalid parent commit for ${ctx.open.v?.fullRef()} found old head: ${oldHead.hash}`);
        }
        parents.push(oldHead.hash);
      }
    }

    // Create the commit and queue it.
    const commit = await change.finalize(state.user.v.id, parents, null);
    await (await store()).putCommit(commit);

    // Re-initialize the context with the new commit
    const unsealed = await commit.unseal();
    const commitData = getStorageModel(unsealed);

    // If we have a change which was loaded from commit, we update the commit hash to keep it in the same state, but evolved, without changing behavior.
    ctx.initData.v = commitData;
    ctx.open.v.hash.v = commit.hash;
    applyCommitProps(ctx.open.v.commit, commit.commit);

    // Delete the auto save that matches the latest commit, this value might have changed in meantime, but that's OK.
    if(ctx.lastSave.v) {
      const { hash: hash1, commit: commit1, ...lastSaveWithoutHash } = ctx.lastSave.v;
      const { hash: hash2, commit: commit2,  ...committedChangeWithoutHash } = change.toUnsealedCommit();
      if(unsealedCommitsEq(lastSaveWithoutHash, committedChangeWithoutHash)) {
        await ctx.dropOpenChange();
      }
    }

    // Notify commit
    reportSuccess('Access policy committed');
  } catch(err) {
    if(err instanceof ValidationError) {
      for(let err of err.res.errors) reportFailed(err.error, err);
      return;
    }

    throw err;
  }
}

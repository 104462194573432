import stableStringify from 'fast-json-stable-stringify';
import { unexpected } from 'graffe-shared/src/lib/devflow';
import { UnsealedCommitData } from 'graffe-shared/src/universe/commit';
import { getStorageModel } from "graffe-shared/src/universe/utils";
import { debounce } from 'lodash-es';
import { PureCallback } from "ufti/src/types";
import store from "../idb/store";
import { logError } from '../lib/logger';
import { Change } from '../models/change';
import { canWrite } from '../models/nodes';
import { AccessCtx } from './accessCtx';
import { ObjectCtx } from './objectCtx';

export function unsealedCommitsEq(a: UnsealedCommitData, b: UnsealedCommitData) : boolean {
  const left = getStorageModel(a);
  const right = getStorageModel(b);

  return stableStringify(left) === stableStringify(right);
}

export function saveCtxAsChange(ctx: ObjectCtx | AccessCtx) {
  // console.debug('saveCtxAsChange', ctx.open.v?.ext.thing.v, ctx.open.v?.ext.ref.v);
  if(!ctx.open.v) {
    unexpected();
  }
 
  // TODO: refactor and think about - should not be possible to commit changes to shared objects
  if(!canWrite(ctx)) {
    return;
  }

  // Stupid but since we want to develop efficiently with parcel, better to be safe
  if(!(ctx instanceof AccessCtx || ctx instanceof ObjectCtx)) {
    unexpected();
  }

  // Don't watch commit-loaded objects
  if(ctx instanceof ObjectCtx && ctx.usageCtx.usage.intent !== 'mutable') {
    console.debug('not watching for changes on a commit-based load');
    return;
  }

  const serializedChange = ctx.open.v.toUnsealedCommit();

  // Compare against last commit version, if identical, we remove the open change.
  if(ctx.open.v?.hash.v != null && ctx.lastSave.v != null) {
    if(!ctx.initData.v) {
      unexpected();
    }
    if(unsealedCommitsEq(ctx.initData.v, serializedChange)) {
      console.debug('dropping change since its equal to last commit');
      (async () => {
        try {
          await ctx.dropOpenChange();
        } catch (err) {
          logError(err);
        }
      })();

      return
    }
  }

  // Compare serialization and if different, continue to saving
  const lastStoredVersion = ctx.lastSave.v || ctx.initData.v;
  if(unsealedCommitsEq(lastStoredVersion, serializedChange)) {
    console.debug('skipping update: identical to last stored version');
    return;
  }

  // Here we know we can make a new change and replace the old change
  const change = Change.fromUnsealedCommit(serializedChange);
  const fallback = ctx.lastSave.v;

  // Set it already in advance
  ctx.lastSave.v = getStorageModel(Change.getDiffModel(change));

  // Send to disk
  (async () => {
    try {
      const sealed = await change.seal();
      await (await store()).putChange(sealed);
    } catch(err) {
      ctx.lastSave.v = fallback;
      logError(err);
    }
  })();
};

export function enableCtxAutoSaving(ctx: ObjectCtx | AccessCtx) : PureCallback {
  const saveOnce = () => saveCtxAsChange(ctx);
  const saveOnceDebounced = debounce(saveOnce, 100);

  // Watch the whole signal tree for changes and save them when they happen.
  const cancel = ctx.open.regSurface(() => saveOnceDebounced());

  // Cancel debounced function
  return () => {
    cancel();
    saveOnceDebounced.flush();
    saveOnceDebounced.cancel();
  }
}

import { todo } from "graffe-shared/src/lib/devflow";
import { DataType } from "graffe-shared/src/models/nodes";
import { PrimitiveValue } from "graffe-shared/src/types/types";
import { Dash } from "../nodes/dash";
import { ObjectPolicy } from "../nodes/objectPolicy";
import Table from "../nodes/table";
import { View } from "../nodes/view";

export type ObjectInstance = Dash | Table | View;

export type DataInstance = ObjectInstance | ObjectPolicy;

const initializers: Record<DataType, (data: PrimitiveValue) => DataInstance> = {
  [DataType.DashV1]: (data) => {
    return Dash.fromData(data);
  },

  [DataType.ViewV1]: (data) => {
    return View.fromData(data);
  },

  [DataType.TableV1]: (data) => {
    return Table.fromData(data);
  },
  
  [DataType.ObjectPolicy]: (data) => {
    return ObjectPolicy.fromData(data);
  },
}

export function getDataInstance(type: DataType, data: object) : DataInstance {
  if(!initializers[type]) {
    todo('Implement change parsing for data type: '+type);
  }

  return initializers[type](data);
}

import { staticTiers } from 'graffe-shared/src/identity/tiers';
import { setChildren, signal, sub } from "ufti";
import { addCtx } from "ufti/src/plugin/ctx";
import { showSignInModal } from '../../../components/session/SignIn';
import { UIButton } from '../../../components/ui/buttons';
import { onEntered } from "../../../lib/extUfti";
import { SelectCustom } from "../../../lib/fields";
import { wrapAsync } from "../../../lib/logger";
import { setPostAccountRoute } from '../../../lib/redirectManager';
import { state } from "../../../state";
import RenderTiers from "./RenderTiers";
import { SponseeCtx, SponsorCtx } from "./lib";

export default function SessionSponsorTiers() {
  const team = signal();
  let teamTiersDiv;

  const el = <div class="flex flex-col gap-2">
    <div class="flex gap-4 items-center">
      <h3 class="text-lg font-semibold">Sponsor as</h3>
      
      <SponsorAccountDropdown team={team} />
    </div>

    <h4 class="text-base font-semibold">Select a tier</h4>

    <div class="text-xs text-zinc-600">The more you sponsor, the higher you rank.</div>
    
    {teamTiersDiv = <div />}
  </div>;

  onEntered(el, SponseeCtx, sponseeCtx => {
    sub([team, signal(1)], wrapAsync(async () => {
      const tiersEl = <RenderTiers />;
      const sponsorCtx = addCtx(tiersEl, new SponsorCtx(sponseeCtx));
      sponsorCtx.id.v = team.v;
      sponsorCtx.tiers.v = staticTiers;

      setChildren(teamTiersDiv, tiersEl);

      await sponsorCtx.reload();
    }), el);
  });

  return el;
}

function SponsorAccountDropdown({ team }) {
  const el = <div />;

  onEntered(el, () => {
    sub([state.user, signal(1)], () => {
      // Initialize the selector when the session is available
      if(state.user.v?.id && team.v == null) {
        team.v = state.user.v?.id;
      }

      // Load selector
      setChildren(el, 
        state.user.v?.id ? (
          <SelectCustom divClass="bg-white" sig={team} opts={signal(
            [
              {
                label: '@'+state.user.v?.slug + ' (personal)',
                value: state.user.v?.id,
              },
              ...state.teams.v?.map(t => ({
                label: '@'+t.team.slug,
                value: t.team.id,
              }))
            ]
          )} />
        ) : (
          <UIButton role="sec" onclick={wrapAsync(async () => {
            setPostAccountRoute(window.location.pathname);
            await showSignInModal();
          })}>Sign in to sponsor</UIButton>
        ));
    }, el)
  })

  return el;
}
import { Schema } from "@cfworker/json-schema";
import { DataFieldData } from "./dataField.js";
import { JSONValue } from "../types/json.js";

export const enum TableModuleId {
  // -- The reference

  GraffeTableV1 = "GraffeTableV1", 

  // --  The implementations

  ConnectionV1 = "ConnectionV1", 
  FileV1 = "FileV1", 
  HttpV1 = "HttpV1", 
  SqlV1 = "SqlV1", 
  StravaV1 = "StravaV1", 
  PubGoogleSheetV1 = "PubGoogleSheetV1", 
  RawJsonV1 = "RawJsonV1", 
};

export type TypeTableModuleId = keyof typeof TableModuleId;

export const TableModuleTypeSchema: Schema = {
  $id: "/schemas/TableModuleId",

  enum: [
    "GraffeTableV1",

    "ConnectionV1",
    "HttpV1",
    "PubGoogleSheetV1",
    "RawJsonV1",
    "SqlV1",
    "StravaV1",
    "FileV1",
  ],
  type: "string",
};

export interface TableData {
  // // Title of the object
  // title?: string;

  // Large description of the object
  info?: string;

  // // Alias name of the relation, how it's exposed in the query context.
  // alias?: string;

  // Indicate which module manages this table. This can be ConnectionV1 / ViewNodeTableV1 / ... 
  // Should be fully versioned to guarantee backwards compatibility.
  module: TableModuleId;
  
  // Table module props
  props?: JSONValue;

  // Any dependency tables to resolve
  deps?: TableData[];

  // Module output parsing configuration. Not always possible.
  parse?: {
    // If null, the parser doesn't execute
    parser?: 'json' | 'csv',
    props?: JSONValue, 
  }

  // Parse output transform configuration. Not always possible.
  transform?: {
    // If null, the transform doesn't execute.
    runtime?: 'js',
    script?: string,
  }
  
  // Output data fields this table provides
  fields?: DataFieldData[];

  // Can be set to a version number of the last migration done.
  migration?: number;
}

export const TableDataSchema: Schema = {
  $id: "/schemas/TableData",
  type: "object",
  properties: {
    alias: {
      type: "string",
    },
    deps: {
      items: { $ref: "/schemas/TableData" },
      type: "array",
    },
    fields: {
      items: { $ref: "/schemas/DataFieldData", },
      type: "array",
    },
    module: { $ref: "/schemas/TableModuleId" },
    props: { $ref: "/schemas/JSONValue" },
  },
  required: ["module"],
};

import { Signal, signal } from "ufti";
import { AppRoleValue } from "../components/lib/appRole";

// TODO: find a new name for this "intent", going with Usage for now... let sink in and rename further...

export type UsageIntentValue = 'immutable' | 'mutable';

// Object seed represents the loading intent and how the object will exist in Graffe and its workspaces. In Viewer we load immutable always. In editor, we mostly load mutable, with optional immutable loads for different versions.
//
// If you want to edit the latest version, you open by refHash.
// If you want to view a specific version, you open by commitHash.
//
// Objects in Graffe are only instantiated once by their intent, such that a change to an object reflects everywhere.
export interface UsageIntent {
  // How the change is opened.
  // When it's immutable, the commitHash should be provided and refHash is ignored.
  // When it's mutable, the refHash should be provided and commitHash is ignored.
  intent: UsageIntentValue;
  
  // Full reference to the object, when loading, without the @-prefix.
  // This loads strings like roel/some-dash/some-table and resolves the collection ID from the slug.
  //
  // When this is missing, it indicates a detached load.
  refHash?: string;

  // The most recent known load commit version is always passed, except for changes which have not yet been committed.
  //
  // When this is missing, then we've loaded from a fresh change.
  commitHash?: string;

  // We pass down the app role to points where we don't have access to the elements.
  //
  // TODO: this doesn't belong here. We should resolve from respective points the appRoleCtx.
  appRole: AppRoleValue;
}

export class UsageCtx {
  usage: UsageIntent;

  constructor(usage: UsageIntent) {
    this.usage = usage;
  }
}

import dayjs from "dayjs";
import { append, onEnter, onExit, remove } from 'ufti';
import { Link, NavLink } from "ufti/src/plugin/router";
import { GraffeLogoIcon } from "../../lib/assets";
import { whenClickedOutside } from "../../lib/body";
import { devClasses } from "../../lib/dev";
import { CustomIcon, SolidIcon } from "../../lib/icons";
import VersionTag from "../lib/VersionTag";

function Hamburger() {
  return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 8C5 7.44772 5.44772 7 6 7H22C22.5523 7 23 7.44772 23 8C23 8.55228 22.5523 9 22 9H6C5.44772 9 5 8.55228 5 8ZM5 14C5 13.4477 5.44772 13 6 13H22C22.5523 13 23 13.4477 23 14C23 14.5523 22.5523 15 22 15H6C5.44772 15 5 14.5523 5 14ZM5 20C5 19.4477 5.44772 19 6 19H22C22.5523 19 23 19.4477 23 20C23 20.5523 22.5523 21 22 21H6C5.44772 21 5 20.5523 5 20Z" fill="#3F3F46"/>
  </svg>;
}

function CloseIcon() {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#3F3F46"/>
  </svg>;  
}

export default function LogoMenu() : HTMLDivElement {
  // Indicator to show that we are localhost
  let iconDiv: HTMLDivElement;

  const el: HTMLDivElement = <Link 
    // onclick={() => append(document.body, <OverlayMenu />)}
    alt="Go to your home"
    title="Go to your home"
    href="/"
    class="ml-3 flex gap-2 items-center group">
    {/* <div class="group-hover:bg-zinc-100 rounded-sm"><Hamburger /></div> */}
    <GraffeLogoIcon class={`${devClasses('rounded-full outline outline-4 outline-offset-1 outline-orange-400')}`} />
  </Link>;

  return el;
}

function OverlayMenu() : HTMLDivElement {
  let dropdownMenu: HTMLDivElement;
  let contentFilterDiv: HTMLDivElement;
 
  const el = <div class={`gOverlayMenu fixed top-0 left-0 bottom-0 w-[248] bg-white text-zinc-600 min-w-[248] shadow-md z-[9999] animate-in slide-in-from-left-64 duration-150`}>
    <div 
      onclick={() => close()}
      class="h-[44px] ml-3.5 flex gap-2.5 items-center group hover:cursor-pointer">
        <div class="group-hover:bg-zinc-100 rounded-sm"><CloseIcon /></div>
        <GraffeLogoIcon />
    </div>

    <div class="px-2 pb-3 mt-[22px]">

      <div class="text-sm font-medium text-zinc-600">
        <NavLink href="/studio" onclick={() => close()} class="flex items-center hover:cursor-pointer hover:bg-zinc-100 px-2 py-1.5" activeClasses="bg-zinc-100 text-zinc-800">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1.5">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4226 3.5774C16.0972 3.25196 15.5695 3.25196 15.2441 3.5774L8.33333 10.4881L8.33333 11.6667H9.51184L16.4226 4.75591C16.748 4.43048 16.748 3.90284 16.4226 3.5774ZM14.0656 2.39889C15.0419 1.42258 16.6248 1.42258 17.6011 2.39889C18.5774 3.3752 18.5774 4.95811 17.6011 5.93442L10.4463 13.0892C10.29 13.2455 10.078 13.3333 9.85702 13.3333H7.5C7.03976 13.3333 6.66667 12.9602 6.66667 12.5L6.66667 10.143C6.66667 9.92195 6.75446 9.70999 6.91074 9.55371L14.0656 2.39889ZM5 4.99999C4.53976 4.99999 4.16667 5.37309 4.16667 5.83333V15C4.16667 15.4602 4.53976 15.8333 5 15.8333H14.1667C14.6269 15.8333 15 15.4602 15 15V10.8333C15 10.3731 15.3731 9.99999 15.8333 9.99999C16.2936 9.99999 16.6667 10.3731 16.6667 10.8333V15C16.6667 16.3807 15.5474 17.5 14.1667 17.5H5C3.61929 17.5 2.5 16.3807 2.5 15V5.83333C2.5 4.45261 3.61929 3.33333 5 3.33333H9.16667C9.6269 3.33333 10 3.70642 10 4.16666C10 4.6269 9.6269 4.99999 9.16667 4.99999H5Z" fill="#52525B"/>
          </svg>
          Studio
        </NavLink>

        <NavLink 
          href="/pricing" 
          onclick={() => close()} 
          class="px-2 py-1.5 flex items-center hover:bg-zinc-100 hover:text-zinc-800" 
          activeClasses="bg-zinc-100 text-zinc-800">Pricing</NavLink>
        
        <NavLink 
          href="/about" 
          onclick={() => close()} 
          class="px-2 py-1.5 flex items-center hover:bg-zinc-100 hover:text-zinc-800" 
          activeClasses="bg-zinc-100 text-zinc-800">About</NavLink>
      </div>

      <div class="flex flex-row text-xxs font-normal px-2 mt-12">
          <a class="mr-5" href="https://twitter.com/graffeapp" target="_blank">
            <CustomIcon icon="twitter" class="inline w-4 h-4 text-zinc-400 hover:text-zinc-600" />
          </a>
          <a class="" href="mailto:info@graffe.app" target="_blank">
            <SolidIcon icon="envelope" class="inline h-4 w-4 hover:cursor-pointer text-zinc-400 hover:text-zinc-600" />
          </a>
      </div>

      <div class="mt-2">
        {/* TODO: other links terms/privacy/... */}
      </div>

      <div class="text-zinc-400 font-normal text-xxs mt-2 px-2 flex flex-col gap-1">
        <VersionTag />

        <span>
          Copyright &#169;{dayjs().year()} - All rights reserved by <Link href="/" class="underline underline-offset-[3px]">graffe.app</Link>
        </span>
      </div>
    </div>
  </div>;

  // Blur the application
  onEnter(el, () => {
    setTimeout(() => {
      const app = document.body.querySelector('.gApp');
      app?.classList.add('blur-[2px]', 'grayscale');

      onExit(el, () => app?.classList.remove('blur-[2px]', 'grayscale'));
    }, 151);
  });

  whenClickedOutside(el, () => close());
  
  function close() {
    remove(el);
  }

  return el;
}

// {/* <OutlineIcon class="w-4 h-4 ml-1 inline" icon="chevronDown" /> */}

// x-2 pb-3">
//       {/* <div class="my-3 text-xs font-normal">
//         <Action class="py-1.5 px-2 block">Graffe Universe Explorer</Action>
//         <Action class="py-1.5 px-2 block">Getting started</Action>
//         <Link href="/changelog" class="py-1.5 px-2 block">Changelog</Link>
//       </div>

//       <hr class="my-3 mx-4 border-zinc-200 border-t-[1px]" /> */}

//       {/* <div class="flex flex-row text-xxs font-normal px-2">
//         <Link class="hover:underline">Terms</Link>
//         <Link class="hover:underline ml-3 mr-auto">Privacy</Link>
//       </div> */}

//       <div class="flex flex-row text-xxs font-normal px-2 mt-3">
//         {/* <FeedbackButton class="hover:underline mr-auto">
//           <OutlineIcon icon="chatBubbleLeftEllipsis" class="w-3 h-4 mr-1 inline" />
//           Send feedback
//         </FeedbackButton> */}
//           <a class="mr-5" href="https://twitter.com/graffeapp" target="_blank"><CustomIcon icon="twitter" class="inline w-4 h-4 text-zinc-400 hover:text-zinc-600" /></a>
//           <a class="" href="mailto:info@graffe.app" target="_blank"><SolidIcon icon="envelope" class="inline h-4 w-4 hover:cursor-pointer text-zinc-400 hover:text-zinc-600" /></a>
//       </div>

//       <div class="mt-2">
//         {/* TODO: other links terms/privacy/... */}
//       </div>

//       <div class="text-zinc-400 font-normal text-xxs mt-2 px-2 flex flex-col gap-1">
//         <VersionTag />

//         <span>
//           Copyright &#169;{dayjs().year()} - All rights reserved by <Link href="/" class="underline underline-offset-[3px]">graffe.app</Link>
//         </span>
//       </div>
//       {/* </div>}
//     </div>

//     {contentFilterDiv = <div onclick={() => close()} class="fixed left-[248] top-0 right-0 bottom-0 bg-zinc-800 opacity-20 z-[9999]"/>} */}
//   </div>;
import { setChildren } from "ufti";
import { Link } from "ufti/src/plugin/router";

const items = [
  { slug: 'roel', ref: 'climatedata.imf.org/climate-change/annual-surface-temp-change', title: 'Annual estimates of mean surface temperature change are measured with respect to a baseline climatology. From the IMF.', avatar: 'https://gravatar.com/avatar/c8425c7e9862a06f00edcfd50e307d34?s=96&d=identicon' },
  
  { slug: 'roel', ref: 'opendata.infrabel.be/datasets/ruwe-gegevens-van-stiptheid/day-1/data-sql', title: 'The raw data about arrival and departure of Belgian passenger trains (domestic and international) at their stopping points over a day and by train number. The relations and lines are also given.', avatar: 'https://gravatar.com/avatar/c8425c7e9862a06f00edcfd50e307d34?s=96&d=identicon' },

  { slug: 'roel', ref: 'strava/data/activities-sql', title: 'Table that wraps your Strava activities and makes them filterable. parses some fields and sorts by default on time. Great table to use for building dashboards on top of the strava API.', avatar: 'https://gravatar.com/avatar/c8425c7e9862a06f00edcfd50e307d34?s=96&d=identicon' },
]

export default function TrendingTables({ class: klass }) {
  let itemsDiv;
  const el = <div class={klass||''}>
    <div class="font-semibold">Trending Tables</div>

    <div class="text-xs text-zinc-500 mt-1.5">Shared tables worth to explore.</div>

    {itemsDiv = <div class="mt-4 flex flex-col gap-2 text-xs"></div>}
  </div>;

  setChildren(itemsDiv, items.map(i => <Item item={i} />));

  return el;
}

function Item({ item } : { item: { slug: string, ref: string, title: string, avatar?: string } }) {
  const el = <div class="flex gap-2 items-start">
    <div class="mr-2">
      <div class="rounded-full overflow-hidden">
        <div class="w-8 h-8">
          <img class="w-full h-auto" src={item.avatar} />
        </div>
      </div>
    </div>

    <div class="flex gap-1 flex-col">
      <Link href={`/@${item.slug}/${item.ref}`} class="hover:underline font-semibold">
        <div>@{item.slug}/{item.ref}</div>
      </Link>

      <div class="text-zinc-500">{item.title}</div>
    </div>
  </div>;

  return el;
}
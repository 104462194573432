// TODO: move to ufti

import { isSignal, onEnter, onExit, setChildren, signal, Signal, sub } from 'ufti';
import { findCtx } from 'ufti/src/plugin/ctx';
import { PureCallback } from 'ufti/src/types';

type IOPair<I, O> = { o: O, i: I };

// Create a signal which gets updated values of executing each handler against a list of input signals
export function subListSignal<I,O>(list$: Signal<I[]>, eachHandler: ((item: I) => O), exitEl?: Element) : Signal<IOPair<I, O>[]> {
  const output = signal<IOPair<I, O>[]>([]);

  const cancel = sub(list$, list => {
    if(list == null) return;
    
    // When the sigs change, we re-create the watchList and reuse previous watches.
    // Handler is only called when the signal is added.
    output.v = list.map((iv, i) => {
      let watch = output.v.find(w => w.i === iv);
      if(!watch) {
        watch = { o: eachHandler(iv), i: iv };
      }
      return watch;
    });
  }, exitEl);

  // Cancel the parent subscription
  output.onEnd(() => cancel());

  return output;
}

// Creates a signal which can be subscribed, which internally registers a surface watch.
export function surfaceSignal<T>(sig: Signal<T>, exitEl?: HTMLElement) : [Signal<Signal<any>>, PureCallback] {
  const out = signal();
  const cancellation = sig.regSurface(changedSig => out.v = changedSig);
  if(exitEl) {
    onExit(exitEl, cancellation);
  }
  return [out, cancellation];
}

export function syncedEl<T>(sig: Signal<T>, el: HTMLElement): HTMLElement {
  onEnter(el, () => {
    sub(sig, () => {
      setChildren(el, sig.v);
    }, el);
  });
  
  return el;
}

// export function onEnterWithCtxs<T>(el: Element, classes?: { new(): T }[], handler: (...ctxs: T[]) => void) : PureCallback {
//   return onEnter(el, () => {
//     const resolved = classes?.map(klass => findCtx(el, klass));
//     handler(...resolved);
//   });
// }

type Constructor<T = any> = new (...args: any[]) => T;

export function onEntered<T extends Constructor[]>(
  el: HTMLElement, 
  ...args: [
    ...classes: T, 
    handler: (
      ...instances: { [K in keyof T]: T[K] extends Constructor<infer I> ? I : never
    }) => void
  ]): PureCallback {
    const classes = args.slice(0, -1) as T;
    const handler = args[args.length - 1] as (...instances: { [K in keyof T]: T[K] extends Constructor<infer I> ? I : never }) => void;
  
    return onEnter(el, () => {
      const instances = classes.map(Cls => findCtx(el, Cls)) as { [K in keyof T]: T[K] extends Constructor<infer I> ? I : never };
      handler(...instances);
    });
}

export function SubDiv({ sig, render, class: klass } : { sig: Signal<T> | Signal<T>[], render: () => Element, class?: string }) : HTMLDivElement {
  const el = <div class={klass}></div>;

  sub(sig, () => setChildren(el, render() ?? []), el);

  return el;
}

export function toSignal<T>(d: any) : Signal<T> {
  if(isSignal(d)) {
    return d;
  }
  return signal(d);
}

// // Effect render always replaces the object it previously created
// // 
// export function Replace({ content, exitEl } : { content: () => HTMLElement, exitEl?: HTMLElement }) : HTMLElement {
//   // Initial outlet
//   let prev;
  
//   effect(() => {
//     const child = content();
//     if(child == null) throw new Error('no child');
//     if(prev != null) { // Replace it
//       replace(prev, child);
//     }
//     prev = child;
//   }, exitEl);

//   if(!prev) {
//     throw new Error('no element');
//   }

//   return prev;
// }

// // Component creator where the render function is called automatically when entered.
// // The render function can be a promise, but ideally is not.
// export function make(
//   sig: Signal<T> | Array<Signal<T>>,
//   el: HTMLElement | SVGElement,
//   renderFunc: (el: HTMLDivElement) => HTMLElement | SVGElement | Promise<HTMLElement | SVGElement>
// ) : HTMLDivElement {
//   if(!el) {
//     el = <div />;
//   }
//   const sigs = (Array.isArray(sig) ? sig : [sig]);
//   onEnter(el, () => {
//     sub([...sig, signal(1)], () => render(), el);
//   });

//   // Render function which supports promises
//   function render() {
//     const content = renderFunc(el);
//     if(isPromise(content)) {
//       content
//         .then(d => setChildren(el, d))
//         .catch(err => {
//           console.error(err);
//           throw err;
//         });
//     } else {
//      setChildren(el, content); 
//     }
//   }

//   return el;
// }

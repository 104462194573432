import { isIFramedLoad } from '../lib/browser';
import { notifyImportantError } from '../reporting/reporting';
import { reportFailed } from '../types/notifications';
import { BrowserStore } from './browserStore';
import { EmbedStore } from './embedStore';

// Get browser repo
let _store: Promise<BrowserStore | EmbedStore>;
export default async function store() : Promise<BrowserStore | EmbedStore> {
  if(!_store) {
    // Embedded gets a volatile implementation (which also doesn't load the credentials)
    if(isIFramedLoad()) {
      _store = new EmbedStore();
    } else {
      _store = BrowserStore.createAndInit();
    }
  }

  // Error reporter for the failed store
  (async () => {
    const to = setTimeout(() => {
      reportFailed('Your browser might have a bug. Please try to restart your browser (or your device).');

      notifyImportantError('IDB setup timeout reached'); // ensure we get informed about this

      throw new Error('idb setup timeout reached'); // should report via sentry
    }, 4000);
    const loaded = await _store;
    clearTimeout(to);
  })();
  
  return await _store;
}

import { showSignInModal } from "../../../components/session/SignIn";
import { Action } from "../../../lib/button";

// TODO: rotate "a different kind of data platform"

export default function Hero({ class: klass }) {
  const el = <div class={`rounded-[24px] bg-white p-[5px] h-[560px] lg:h-[500px] ${klass||''}`}>
    <div class="relative rounded-[24px] bg-[#F1F1F4] overflow-hidden h-full">
      <div class="pt-6 pl-8 lg:pt-[43px] lg:pl-[55px]">
        <h1 class=" text-zinc-800 font-display font-[700] text-[32px] leading-[44px] md:text-[40px] md:leading-[52px] xl:text-[52px] xl:leading-[64px] align-middle max-w-[266px] sm:max-w-none">
          Create & share data in graffe —<br />
          your trustworthy data universe<br />
          built to last
        </h1>

        <Action onclick={() => showSignInModal()} class="inline-block mt-6 lg:mt-8 py-[10px] px-5 bg-zinc-900 text-white text-base font-semibold rounded hover:bg-zinc-800">Get started</Action>
      </div>

      <div class="absolute right-[-105px] bottom-[-96px] lg:right-[-5px] lg:bottom-[-5px]">
        <img src="/_assets/graffe-app/about/purple-balls.png" class="max-h-[300px] sm:max-h-[378px] w-auto density-2" />
      </div>
    </div>
  </div>; 

  return el;
}
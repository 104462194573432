// Filter context can be added to any element to make it data filter aware.

import { uniq } from 'lodash-es';
import { signal, Signal } from 'ufti';
import { getCtxsOn } from "ufti/src/plugin/ctx";
import { reportDebug } from "../types/notifications";
import { FieldValueFilterData, ValueFilter } from "./filters";

// All child elements will react to this.
type majorFilterLevels = 'app' | 'adhoc';
export class FilterCtx {
  // debug id
  public id: string;

  public readonly level?: majorFilterLevels;

  // Configured filters on this context.
  public filters: Signal<ValueFilter[]> = signal([]);

  // Discovered filters at this level.
  // Table modules bubble up discovered filters. Once discovered, they can be applied.
  // Not always filters can be discovered, let's handle later when we support dynamic SQL filters which get pushed down to the DB.
  // However, for clarity we can maybe choose to avoid this and then.
  public discovered: Signal<string[]> = signal([]);

  constructor(level?: majorFilterLevels) {
    this.id = level;
    // console.log(`creating filter: ${level||'-'}`);
    this.level = level;
  }

  // Add a filter
  addFilter(filter: ValueFilter) {
    this.filters.v = [...this.filters.v, filter];
  }

  discoverFilter(key: string) {
    this.discovered.v = uniq([...this.discovered.v, key]).sort();
  }

  getNextDiscoveredUnfilteredValue(allDiscovered: string[]) : string {
    for(let val of allDiscovered) {
      if(!this.filters.v?.find(f => f.field.v === val)) {
        return val;
      }
    }
  }
}

// This resolves all parent filters, including the element user is on.
export function getFilterCtxs(el: Element) : FilterCtx[] {
  const ctxs: FilterCtx[] = [];

  // Find ctx on element
  let node = el;
  while(node != null) {
    const foundCtxs = getCtxsOn(node, FilterCtx);
    if(foundCtxs) {
      for(let ctx of foundCtxs) {
        ctxs.push(ctx);
      }
    }
    node = node.parentElement; // Step up
  }

  return ctxs;
}

// Walk over all found filter contexts and reduce a filter set which can be used by views and tables.
export function reduceFilters(el: Element) : FieldValueFilterData {
  const ctxs = getFilterCtxs(el);

  return reduceFilterCtxs(ctxs);
}

export function reduceFilterCtxs(ctxs: FilterCtx[]) {
  const fvFilters: FieldValueFilterData = {};

  // Loop over found contexts, the list we receive is down to up order.
  for(let ctx of ctxs) {
    for(let filter of ctx.filters.v) {
      if(fvFilters[filter.field.v] != null) {
        reportDebug('Filter override on: '+filter.field.v);
      }
      fvFilters[filter.field.v] = filter;
    }
  }

  // Here fvFilters has the highest override per field.
  return fvFilters;
}

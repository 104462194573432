import { FilterCondition, ValueFilterData } from 'graffe-shared/src/models/filters';
import { JSONValue } from 'graffe-shared/src/types/types';
import { getUrlModel } from "graffe-shared/src/universe/utils";
import { signal, Signal } from 'ufti';
import { reportDataMigration } from '../types/notifications';

// // Filters exist alongside dashboards, views, ... at different contextual levels.
// //
// // We want to avoid requiring endless repetitions of table definitions to filter out values which could be captured by one well-done table implementation.
// // When filters are "passable" and "composable", then tables can be made generic and reusable.
// // Labeled and tagged filters also allow for easy aggregational caching at multiple aggregation levels. This is good for all steps in parsing data.
// // Generic filters can then be used to enter/exit dashboards with complex filter sets and then drilling is there as well.
// // When these levels exist, a global time filter becomes possible.
// //
// // Filters by default override parent filters, but can behave like a default value, or lock a filter.
// //
// // Example: A dashboard might filter on a single country, but one of it's views has an additional filter on gender=F. Within that view, if a complex app, while there might also be a view which compares against a reference country, which ignores the filter.
// //
// // The behaviors we want is composition, overrideability, object hierarchical.
// // Journey filters should come from clean URL patterns by going back URLs. URLs are great, let's not re-invent the wheel.
// //
// // Filters are therefore crawled and collected from system, rendercontexttree and view context trees.
// //
// // Dimensions should behave like an explode pattern on top of this.
// //
// // The view render is the point where filters are collected, the views and it's tables can decide what filters are applicable to them.
// // We pass down the filter definitions (if not private) to the tables.
// //
// // Find all parent filter contexts of an element, bottom-up.
// // Until the parent returns null (top level system filters are also a FilterCtx - so one filter ctx is guaranteed).
// export function getFilterCtxs(el: Element) : FilterCtx[] {
//   const ctxs: FilterCtx[] = [];

//   // Find ctx on element
//   const elCtx = getCtxOn(el, FilterCtx);
//   if(elCtx) ctxs.push(elCtx);

//   // Collect parent contexts
//   let res = getCtxEl(el, FilterCtx);
//   while(res != null) {
//     const { ctx, el: parent } = res;
//     ctxs.push(ctx);
//     res = getCtxEl(parent, FilterCtx);
//   }

//   return ctxs;
// }

// // Walk over all found filter contexts and reduce a filter set which can be used by views and tables.
// export function reduceFilters(el: Element) : FieldValueFilterData {
//   const ctxs = getFilterCtxs(el);

//   return reduceFilterCtxs(ctxs);
// }

// export function reduceFilterCtxs(ctxs: FilterCtx[]) : FieldValueFilterData {
//   // Loop over found contexts in reverse
//   const fvFilters: FieldValueFilterData = {};
//   for(let i = ctxs.length-1; i >= 0; i--) {
//     const ctx = ctxs[i];
//     for(let filter of ctx.filters.v) {
//       if(fvFilters[filter.field.v] != null) {
//         reportInfo('Filter override on: '+filter.field.v);
//       }
//       fvFilters[filter.field.v] = filter;
//     }
//   }

//   // Here fvFilters has the latest override per field.
//   return fvFilters;
// }

// // Filter context can be added to any element to make it filter enabled
// // This can be a static field, or a dynamic implementation
// export class FilterCtx {
//   // Object-level saved filters
//   public oFilters: Signal<ValueFilter[]> = signal([]);

//   // Ad-hoc filters
//   public ahFilters: Signal<ValueFilter[]> = signal([]);

//   // Computed signal with all filters, ordered by override importance.
//   public filters: Signal<ValueFilter[]>;

//   // Add a filter
//   addAdhocFilter(filter: ValueFilter) {
//     this.ahFilters.v = [...this.ahFilters.v, filter];
//   }

//   // Add a filter
//   addObjectFilter(filter: ValueFilter) {
//     this.oFilters.v = [...this.oFilters.v, filter];
//   }

//   // Create a FilterCtx with a computed filters signal
//   static createFilterCtx(el: Element) : FilterCtx {
//     const ctx = new FilterCtx();
//     ctx.filters = computed(
//       [ctx.oFilters, ctx.ahFilters], 
//       () => [...ctx.oFilters.v, ...ctx.ahFilters.v], 
//       el,
//     );

//     return ctx;
//   }
// }

export type FieldValueFilterData = Record<string, ValueFilter>;

// A filter definition with multiple fields
export class ValueFilter {
  // Fields this filter is active on
  field: Signal<string> = signal('');

  // Optional condition
  condition: Signal<FilterCondition> = signal('eq');

  // Optional values
  values: Signal<string[]> = signal([]);

  static fromData(data: ValueFilterData) : ValueFilter {
    // -- MIGRATION
    if(!data.field && data.fields) {
      data.field = data.fields[0];
      data.values = data.selections;
      reportDataMigration('Filter: '+data.field);
    }
    // -- 
    const { field, values, condition } = data;
    const filter = new ValueFilter();
    filter.field.v = field;
    filter.values.v = values;
    filter.condition.v = condition;

    return filter;
  }

  static fromUrlModel(data: JSONValue) : ValueFilter {
    const { f, v, c } = data;
    const filter = new ValueFilter();
    filter.field.v = f;
    filter.values.v = v;
    filter.condition.v = c;

    return filter;
  }

  toUrlModel() : JSONValue {
    return {
      f: getUrlModel(this.field),
      v: getUrlModel(this.values),
      c: getUrlModel(this.condition),
    }
  }
}

// export function applyUrlFilters(el: Element) : FilterCtx[] {
//   // Collect all filter contexts, bottom-up
//   const ctxs = getFilterCtxs(el);

//   const urlFilters = urlState.filters.v;
  
//   // Walk over the contexts and diff the filters
//   for(let i = 0; i < ctxs.length; i++) {
//     // Create new set of filters
//     const urlFilter = urlFilters?.find(d => d.l === i);
//     const newFilters = (urlFilter ? urlFilter.v.map(d => ValueFilter.fromUrlModel(d)) : []);

//     // If changed, apply
//     if(stableStringify(primitiveValue(ctxs[i].ahFilters.v)) !== stableStringify(primitiveValue(newFilters))) {
//       ctxs[i].ahFilters.v = newFilters;
//     }
//   }

//   return ctxs;
// }

// // Initialize and sync filters from the URL on top of the overrides of the dashboard.
// // This is expected to be called after the object has loaded.
// //
// // Sync is done by watching the signals.
// export function syncFiltersToUrl(el: Element) {
//   // Sync once the URL filters
//   const ctxs = applyUrlFilters(el);

//   // Subscribe to all filter contexts for changes, bottom-up
//   for(let i = 0; i < ctxs.length; i++) {
//     sub(ctxs[i].ahFilters, filters => {
//       // Current filters
//       let urlFilters = urlState.filters.v ?? [];

//       // Update the location with the new state
//       let idx = urlFilters.findIndex(f => f.l === i);
//       if(idx < 0) idx = urlFilters.length;
//       urlFilters[idx] = { 
//         l: i, 
//         v: filters.map(f => f.toUrlModel()),
//       };

//       // Remove empty filters
//       urlFilters = urlFilters.filter(d => d.v.length > 0);
      
//       urlState.filters.v = urlFilters;
//     }, el);
//   }
// }
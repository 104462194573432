import CryptoJS from 'crypto-js';
import stableStringify from 'fast-json-stable-stringify';
import { wordArrToBase32 } from 'graffe-shared/src/lib/encoding';
import { EncryptionType } from "graffe-shared/src/models/encryption";
import { PrimitiveValue, RefHash, RefSeed, SealedData } from "graffe-shared/src/types/types";
import { CollectionMetadata, CollectionMetadataSignals, TimestampWithTzInfo } from 'graffe-shared/src/universe/types';
import { clone, getStorageModel, removeNullKeys, seal, unseal } from 'graffe-shared/src/universe/utils';
import { Signal, signal } from 'ufti';
import { makeComputedRefHash, makeComputedRefSeed } from '../models/change';
import { state } from "../state";

// Connections are discoverable reffed sealed bags of config to interact with a remote.
// Tables can then use the connection and implement data fetching.

// Should be JSON-seriazable, where null===undefined.
export interface ConnectionExternalizedProps  {
  // Encryption (copied over from the commit)
  enc: EncryptionType;

  // The salted seed hash, used for lookups and identification. Can be validated and calculated.
  // Can be generated by doing sha256(refSeed, collectionId)
  refHash: RefHash;

  // Base hash for validating refHash ownership
  // Clients generate this hash by doing sha256('connection:strava', collectionId). This reduces dictionary attack surface.
  refSeed?: RefSeed;

  // Optional ref
  ref: string;

  // Collection metadata at time of commit
  collection: CollectionMetadata;

  // Update time
  time: TimestampWithTzInfo;
}

// Signal version of ConnectionExternalizedProps
export interface ConnectionExternalizedPropsSignals  {
  // Encryption (copied over from the commit)
  enc: Signal<EncryptionType>;

  // The salted seed hash, used for lookups and identification. Can be validated and calculated.
  // Can be generated by doing sha256(refSeed, collectionId)
  refHash: Signal<RefHash>;

  // Optional collectionId salted hash of the ref.
  // Required when the ref field is null or length 0
  // When the ref is provided, it's uniqueness properties are rolled into the refHash
  refSeed: Signal<RefSeed>;

  // The reference of this connection (optionally empty for encrypted connections)
  // When empty, refSeed is required and props.ref should be set client-side.
  ref: Signal<string>;

  // Collection metadata at time of commit
  collection: CollectionMetadataSignals;

  // Update time
  time: Signal<TimestampWithTzInfo>;
}

export interface ConnectionData {
  ext: ConnectionExternalizedProps;
  data: SealedData;
}

// Sealed connection
export class Connection implements ConnectionData {
  public ext: ConnectionExternalizedProps;
  public data: SealedData;

  getStorageModel() : ConnectionData {
    const { ext, data } = this;

    return getStorageModel({ ext, data });
  }

  static fromData(props: ConnectionData) : Connection {
    const { ext, data } = props;
    const sealed = new Connection();
    sealed.ext = clone(ext);
    sealed.data = data;

    return sealed;
  }

  async unseal() : Promise<UnsealedConnection> {
    const { p: props, d: data } = await unseal(
      state.keyService.v,
      this.ext.enc,
      this.data,
    );

    return UnsealedConnection.fromData(
      this.ext,
      props,
      data,
    );
  }

  contentHash() : ContentHash {
    // Hasher
    const sha256 = CryptoJS.algo.SHA256.create(); 

    // First write the content, so we have controls to model later on
    sha256.update(CryptoJS.enc.Utf8.parse(this.data));

    // Then add the content
    const serializedExt = stableStringify(removeNullKeys(this.ext));
    sha256.update(CryptoJS.enc.Utf8.parse(serializedExt));

    // Finalize the content hash
    const hashBuf = sha256.finalize();
    const hash = wordArrToBase32(hashBuf);

    return hash;
  }
}

export interface ConnectionInternalProps {
  // The reference string used to generate the hash
  ref: string;
}

export interface ConnectionInternalPropsSignals {
  // The reference string used to generate the hash
  ref: Signal<string>;
}

// Unsealed connection with accessible properties.
export class UnsealedConnection {
  public ext: ConnectionExternalizedPropsSignals;

  public props: ConnectionInternalPropsSignals;

  // The connection properties
  public data: PrimitiveValue;

  static fromData(
    ext: ConnectionExternalizedProps, 
    props: ConnectionInternalProps, 
    data: PrimitiveValue,
  ) : UnsealedConnection {
    const c = new UnsealedConnection();
    
    const e = clone(ext);
    c.ext = {
      collection: {
        id: signal(e.collection.id),
        slug: signal(e.collection.slug),
      },
      enc: signal(e.enc),
      ref: signal(e.ref),
      // refHash: signal(e.refHash),
      // refSeed: signal(e.refSeed),
      time: signal(e.time),
    }

    const p = clone(props);
    c.props = {
      ref: signal(p?.ref),
    }

    c.data = clone(data);

    // Hook up the refSeed and the refHash computed signals.
    c.ext.refSeed = makeComputedRefSeed(c.ext.collection.id, c.props.ref);

    // If the ref is public, we calculate inline the refSeed without setting it (to keep object stable).
    c.ext.refHash = makeComputedRefHash(c.ext.collection.id, c.ext.refSeed, c.ext.ref);

    return c;
  }

  async seal() : Promise<Connection> {
    const { ext, props, data } = this;
    const unsealedData = getStorageModel({ p: props, d: data });
    const sealed = await seal(state.keyService.v, this.ext.enc.v, unsealedData);
    
    return Connection.fromData({
      ext: getStorageModel(ext),
      data: sealed,
    });
  }

  // async save() {
  //   if(!this.props.ref.v.trim()) {
  //     throw new Error('Module or ID invalid');
  //   }

  //   // Seal
  //   const sealedConn = await this.seal();

  //   // Store locally (putConnection will trigger sync)
  //   await (await store()).putConnection(sealedConn);
  // }
}

import { ObjectPolicyData } from 'graffe-shared/src/models/objectPolicy';
import { AclRule } from 'graffe-shared/src/universe/sharing';
import { signal, Signal } from 'ufti';

// An object access policy works directly on an object and defines who has access to the object.
//
// Once an object has an access policy, it's listed to the accounts that have permission to it.
// If an object doesn't have an access policy, it's part of the parent object in the path structure.
//
// For any object, the most specific access policy is in use for that object, taking into account the wstructure of the path.
//
// Path Policies can be added by owners to configure more restrictions, which might override.
//
// Eg:
// -  /@team/path/dashboard/view-1234: no policy => Object can be resolved through parent policy
// -  /@team/path/dashboardd/view-1234: no policy => Object cannot be resolved (different parent)
//
// For encrypted dashboards, the dashboard (or view) should expose the refHashes which are part of it and should be restricted to objects defined.
//
// When no rules, the owner is inferred
export class ObjectPolicy {
  // When true, shows in portal
  discover: Signal<boolean> = signal();

  // When empty, it's an owner policy
  rules: Signal<AclRule[]> = signal();

  static fromData(data: ObjectPolicyData) : ObjectPolicy {
    const a = new ObjectPolicy();
    
    a.discover.v = !!data.discover;
    a.rules.v = data.rules?.map(d => AclRule.fromData(d)) || [];

    return a;
  }
}

export function isPubliclyAccessible(rules: AclRule[]) : boolean {
  // TODO: this doesn't support filtering out previous deny rules
  return rules.find(r => r.action.v?.indexOf('read') > -1 && r.principal.v?.indexOf('*') > -1 && r.effect.v === 'allow');
}
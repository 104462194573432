import { DashData } from 'graffe-shared/src/models/dash';
import { signal, Signal } from 'ufti';
import { randomId } from '../../../graffe-shared/src/lib/encoding';
import { UICoordinates, UIDimensions, ViewModuleId } from '../../../graffe-shared/src/models/view';
import { JSONObject } from '../../../graffe-shared/src/types/json';
import { CommitHash } from '../../../graffe-shared/src/types/types';
import { getStorageModel } from '../../../graffe-shared/src/universe/utils';
import { ObjectInstance } from '../models/data';
import { View } from "./view";
import { ObjectTitle } from 'graffe-shared/src/models/objects';
import { ObjectTitleHelpers, ObjectTitleSignals } from '../models/objectTitle';

// export const dashDefaultStyle: StyleObject = {
//   spacing: {
//     // top: 16,
//     // right: 16,
//     // bottom: 16,
//     // left: 16,
//   },
//   title: {
//     spacing: {
//   //     left: 16,
//   //     bottom: 8,
//     }
//   },
// }

export class Dash {
  title: ObjectTitleSignals;
  views: Signal<View[]>;
  dim: Signal<UIDimensions>;
  info: Signal<string>;

  // Can be set to a version number of the last migration done.
  migration: Signal<number>;

  static fromData(data: DashData) : Dash {
    const d = new Dash();

    // Silent-upgrade the title
    // TODO: notify migration with the new system!
    if(data.title != null && typeof data.title === 'string') {
      data.title = {
        logic: 'v1',
        text: data.title,
        dim: { h: 20 },
        visible: data.title.length > 0,
      }
    }

    d.title = ObjectTitleHelpers.fromData(data.title);
    d.views = signal((data.views||[]).map(v => View.fromData(v)));
    d.dim = signal(data.dim);
    d.info = signal(data.info);
    d.migration = signal(data.migration);
    d.ensureCorrectObject();

    return d;
  }

  // TODO: migrations should be applied automatically.
  //
  // // Migration controls should be exposed to the object
  // private migrateTitle() {
  //   if(this.title.v?.length > 0 && !this.views.v?.find(v => v.module.v === 'DashTitleV1')) {
  //     reportInfo('Migrating title');

  //     // Shift down all views
  //     for(let view of this.views.v) {
  //       view.pos.v = {
  //         ...view.pos.v,
  //         y: view.pos.v.y + 34, // 28 line height + 1.5 padding
  //       }
  //     }

  //     // Add title object
  //     this.addInlineView('DashTitleV1', { x: 16, y: 0 });
  //   }
  // }

  private ensureCorrectObject() {
    // Seed random ID if missing
    for(let v of this.views.v) {
      if(v.depId.v == null) {
        v.depId.v = randomId(8);
      }
    }
  }

  // Guarantee that we serialize with the depIds in place
  getStorageModel() : DashData {
    this.ensureCorrectObject();
    const { margin, ...others } = this;

    return getStorageModel({ ...others });
  }

  getDeps() : Signal<ObjectInstance[]> {
    return this.views;
  }

  addView(commitHash: CommitHash, fullRef: string, sourceView: View, coords?: UICoordinates) {
    const pos = { x: 0, y: 0 };
    if(coords) {
      pos.x = coords.x;
      pos.y = coords.y;
    }

    this.views.v = [
      ...this.views.v,
      View.fromData({
        module: ViewModuleId.GraffeViewV1,
        props: {
          ref: fullRef,
          commit: commitHash,
        },
        pos,
        dim: { ...sourceView.dim.v },
        title: getStorageModel(sourceView.title),
        // style: { ...sourceView.style.v||{} },
      }),
    ];
  }

  // Inline views don't get a position, we delegate this to the elements or to the wrapper who calls this.
  addInlineView(module: ViewModuleId, opts?: { props?: JSONObject, dim?: UIDimensions, pos?: UICoordinates }) {
    this.views.v = [
      ...this.views.v,
      View.fromData({
        module,
        pos: opts?.pos ?? null,
        // dim: (opts?.dim === undefined ? { w: 150, h: 100 } : opts?.dim),
        dim: opts?.dim ?? null,
        props: opts?.props ?? null,
        // Inline views are incentivized to set their size on first initialization. 
        // If dimensions are not passed, the renderer falls backs to a default size (see top level view renderer).
      }),
    ];
  }
}

import { reportFailed } from "../types/notifications";
import { isProduction } from "./dev";

// Never failing error logger hookpoint
export function logError(...bag: any[]) {
  console.error(...bag);
  
  // Turn the bag into a meaningful string
  let str: string;
  try {
    str = bag.map(b => b.toString()).join(" - ");
  } catch(err) {
    str = `Unknown error, see console for details`;
  }
  if(str.indexOf('Error: ') < 0) str = `Error: ${str}`;
  reportFailed(`${str}`);

  // Throw the error, errors are always thrown.
  const err = bag.find(e => e instanceof Error);
  if(err){
    throw err;
  } else {
    throw new Error(bag[0].toString());
  }
}

// Execute a promise based handler function and return it's results, but report errors and hook to devflow within
// Proxies input & output unchanged and errors optionally optimized.
export function wrapAsync<A extends any[], R>(handler: (...args: A) => Promise<R> | R): (...args: A) => Promise<R> {
  return async (...args: A): Promise<R> => {
    try {
      return await handler(...args);
    } catch (err) {
      if(!isProduction()) {
        debugger
      }
      logError(err);
    }
  }
}

// Execute a sync handler function and return it's results, but report errors and hook to devflow within.
// Proxies input & output unchanged and errors optionally optimized.
export function wrapSync<A extends any[], R>(handler: (...args: A) => R): (...args: A) => R {
  return (...args: A): R => {
    try {
      return handler(...args);
    } catch (err) {
      if(!isProduction()) {
        debugger
      }
      logError(err);
    }
  }
}




// // -- Custom error wrappers

// type AnyFunction = (...args: any[]) => any;

// export function wrapAsync<T extends AnyFunction>(handler: T) : ReturnType<T> {
//   return async (...args: Parameters<T>) => {
//     try {
//       return await handler(...args);
//     } catch (err) {
//       if(!isProduction()) {
//         debugger
//       }
//       logError(err);
//     }
//   }
// }

// export function wrapSync<T extends AnyFunction>(handler: T) : ReturnType<T> {
//   return (...args: Parameters<T>) => {
//     try {
//       return handler(...args);
//     } catch (err) {
//       if(!isProduction()) {
//         debugger
//       }
//       logError(err);
//     }
//   }
// }
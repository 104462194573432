import { Schema } from "@cfworker/json-schema";

export type JSONValue =
    | JSONSimpleValue
    | JSONObject
    | JSONArray;

export const JSONValueSchema: Schema = {
  $id: "/schemas/JSONValue",

  anyOf: [
    { $ref: "/schemas/JSONObject", },
    {
      items: {
        $ref: "/schemas/JSONValue",
      },
      type: "array",
    },
    { $ref: "/schemas/JSONSimpleValue", },
  ],
};
    
export type JSONObject = { [x: string]: JSONValue };

export const JSONObjectSchema: Schema = {
  $id: "/schemas/JSONObject",

  type: "object",
  additionalProperties: { $ref: "/schemas/JSONValue", },
};
    
export type JSONArray = Array<JSONValue>;

export const JSONArraySchema: Schema = {
  $id: "/schemas/JSONArray",

  type: "array",
  items: {
    $ref: "/schemas/JSONValue",
  },
};
    
export type JSONSimpleValue = string | number | boolean;

export const JSONSimpleValueSchema: Schema = {
  $id: "/schemas/JSONSimpleValue",

  type: ["string", "number", "boolean"],
};

export type JSONSimpleObject = { [x: string]: JSONSimpleValue };

export const JSONSimpleObjectSchema: Schema = {
  $id: "/schemas/JSONSimpleObject",

  type: "object",
  additionalProperties: {
    $ref: "/schemas/JSONSimpleValue",
  },
};
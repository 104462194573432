import { signal } from 'ufti';

const reportingState = {
  // This is the embedded error handling which is part of the bundle.
  errors: signal(true),

  // This enabled the embedded privacy-safe usage reporting which is part of the bundle
  usage: signal(true),

  // This is the more advanced error handling, loaded externally. Opt-in.
  external: signal<'sentry' | false>('sentry'),
}

export default reportingState;
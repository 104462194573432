import { ViewModuleMetadata } from "../types";

export default function getMetadataChartV1() : ViewModuleMetadata {
  return {
    module: 'ChartV1', 
    description: 'Make bar/line/area/... charts', 
    displayName: 'Chart',
    attributions: [
      { label: 'Chart.js 4.0', url: 'https://www.chartjs.org/', spotlight: true }
    ],
  }
}
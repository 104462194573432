import { setChildren, signal, sub } from 'ufti';
import { last } from "../lib/array";
import { toggleClass } from '../lib/ui';
import { state } from "../state";
import { Notification } from "../types/notifications";

type AlertStyle = 'alert-info' | 'alert-success' | 'alert-error' | 'alert-warning'; // Type required to hint tailwind compile
function UINotification({ item } : { item: Notification }) {
  const type = (item.status === 'failed' ? 'error' : item.status);
  const alert: AlertStyle = `alert-${type}`;
  return <div class={`alert ${alert} px-3 py-1 text-right overflow-hidden`}>
    <span>{item.message}</span>
  </div>;
}

let shownUntil = 0;
export default function Toaster() {
  const el: HTMLDivElement = <div class="fixed bottom-0 right-0 px-2 py-1 w-96 z-[9999] hidden"></div>;

  const visible = signal<Notification[]>([]);

  // Remove timed-out toasts
  function cleanVisible() {
    visible.v = visible.v.filter(d => d.time > Date.now()-5999); // -1ms yeah this will fail lol
  }

  // Update visible
  sub(state.notifications, msgs => {
    const newMsgs = msgs.filter(m => m.id > shownUntil);
    if(newMsgs.length == 0) return;
    shownUntil = last(newMsgs).id;

    // TODO: remove duplicates
    const keepers = newMsgs.filter(n => {
      for(let e of visible.v) {
        if(e.type === n.type && e.message === n.message) return false;
      }
      return true;
    });
    if(keepers.length == 0) return;

    visible.v = [...visible.v, ...newMsgs];

    setTimeout(() => cleanVisible(), 6000);
  }, el)

  // Update the UI toasts
  sub(visible, items => {
    setChildren(
      el, 
      items.map(n => <UINotification item={n} />),
    );

    // Get on top of the modals without always being there
    // TODO:... can we organise this differently?
    toggleClass(!items?.length, el, 'hidden');
  });

  return el;
}
import { setChildren, sub } from 'ufti';
import { ProfileIcon } from '../../lib/assets';
import { state } from "../../state";

export default function Avatar({ class: klass } : { class: string }) {
  const el: HTMLDivElement = <div></div>;

  sub(state.user, () => {
    if(!state.user.v) return;

    if(state.user.v?.avatar) {
      return setChildren(el,
        <div class={`${klass||''}`}>
          <img class="rounded-full" src={state.user.v.avatar} />
        </div>
      );
    }
    
    // Fallback
    setChildren(el, <ProfileIcon class="inline" />);
  }, el);

  return el;
}
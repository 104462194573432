import { sortBy } from 'lodash-es';
import { setChildren } from "ufti";
import { Link } from "ufti/src/plugin/router";
import { resolveCollectionId } from '../../../id/helpers';
import { onEntered } from '../../../lib/extUfti';
import { resolveSponsorshipsFor } from '../sponsor/lib';
import { wrapAsync } from '../../../lib/logger';

export default function SponsorList() {
  const el = <div class="flex flex-col gap-6 lg:gap-8" />;

  onEntered(el, wrapAsync(async () => {
    const graffeId = await resolveCollectionId('graffe');
    const sponsorships = await resolveSponsorshipsFor(graffeId);
    const sorted = sortBy(sponsorships, s => s.amount).reverse();

    // Cut into tiers
    const basic = sorted.filter(d => d.amount >= 900 && d.amount < 1500);
    const advanced = sorted.filter(d => d.amount >= 1500 && d.amount < 2500);
    const advocate = sorted.filter(d => d.amount >= 2500 && d.amount < 5000);
    const bronze = sorted.filter(d => d.amount >= 5000 && d.amount < 25000);
    const silver = sorted.filter(d => d.amount >= 25000 && d.amount < 50000);
    const gold = sorted.filter(d => d.amount > 50000);

    // Special rendering by tier, more exposure per sponsor level
    setChildren(el, <>
      <div>
        <h3 class="text-lg">Gold Sponsors</h3>

        <div class="mt-2 grid grid-cols-12">
          {gold.map(s => <Link href={`/@${s.sponsorSlug}`} class="hover:underline">
            <div class="rounded-full overflow-hidden w-10 h-10 mx-auto">
              <img src={s.sponsorAvatar} class="h-full w-auto" title={`@${s.sponsorSlug}`} />
            </div>
          </Link>)}
        </div>

        {gold.length == 0 && <div class="text-zinc-500 italic">No Gold sponsors yet, be the first and stand out.</div>}
      </div>

      <div>
        <h3 class="text-lg">Silver Sponsors</h3>

        <div class="mt-2 grid grid-cols-12">
          {silver.map(s => <Link href={`/@${s.sponsorSlug}`} class="hover:underline">
            <div class="rounded-full overflow-hidden w-10 h-10 mx-auto">
              <img src={s.sponsorAvatar} class="h-full w-auto" title={`@${s.sponsorSlug}`} />
            </div>
          </Link>)}
        </div>

        {silver.length == 0 && <div class="text-zinc-500 italic">No Silver sponsors yet, be the first and stand out.</div>}
      </div>

      <div>
        <h3 class="text-lg">Bronze Sponsors</h3>

        <div class="mt-2 grid grid-cols-12">
          {bronze.map(s => <Link href={`/@${s.sponsorSlug}`} class="hover:underline">
            <div class="rounded-full overflow-hidden w-10 h-10 mx-auto">
              <img src={s.sponsorAvatar} class="h-full w-auto" title={`@${s.sponsorSlug}`} />
            </div>
          </Link>)}
        </div>

        {bronze.length == 0 && <div class="text-zinc-500 italic">No Bronze sponsors yet, be the first and stand out.</div>}
      </div>

      <div>
        <h3 class="text-lg">Graffe Advocates</h3>

        <div class="mt-2 grid grid-cols-12">
          {advocate.map(s => <Link href={`/@${s.sponsorSlug}`} class="hover:underline">
            <div class="rounded-full overflow-hidden w-10 h-10 mx-auto">
              <img src={s.sponsorAvatar} class="h-full w-auto" title={`@${s.sponsorSlug}`} />
            </div>
          </Link>)}
        </div>

        {advocate.length == 0 && <div class="text-zinc-500 italic">Be the first Graffe Advocate sponsor.</div>}
      </div>

      <div>
        <h3 class="text-lg">Advanced Insiders</h3>

        <div class="mt-2 grid grid-cols-12">
          {advanced.map(s => <Link href={`/@${s.sponsorSlug}`} class="hover:underline">
            <div class="rounded-full overflow-hidden w-10 h-10 mx-auto">
              <img src={s.sponsorAvatar} class="h-full w-auto" title={`@${s.sponsorSlug}`} />
            </div>
          </Link>)}
        </div>

        {advanced.length == 0 && <div class="text-zinc-500 italic">Be the first Advance Insider sponsor.</div>}
      </div>

      <div>
        <h3 class="text-lg">Basic Supporters</h3>

        <div class="mt-2 grid grid-cols-12">
          {basic.map(s => <Link href={`/@${s.sponsorSlug}`} class="hover:underline">
            <div class="rounded-full overflow-hidden w-10 h-10 mx-auto">
              <img src={s.sponsorAvatar} class="h-full w-auto" title={`@${s.sponsorSlug}`} />
            </div>
          </Link>)}
        </div>

        {basic.length == 0 && <div class="text-zinc-500 italic">Be the first Basic Supporter sponsor.</div>}
      </div>
    </>)
  }));

  return el;
}

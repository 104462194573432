import { onEnter } from "ufti";
import { head } from "ufti/src/plugin/head";
import { keepHeightScreen } from "../../lib/ui";
import { state } from "../../state";
import { topBarHeight } from "../TopBar/TopBar";
import { UILinkButton } from "../ui/buttons";
import TrendingDashboards from "./components/TrendingDashboards";
import GraffeChanges from "./components/GraffeChanges";
import TrendingDataArtists from "./components/TrendingDataArtists";
import TrendingTables from "./components/TrendingTables";

// Page for users which are not authorized
export default function HomeSession() {
  const el = <div class="flex flex-col md:flex-row overflow-y-auto">

    {/* Personal menu */}
    <div class="w-full md:max-w-[200px] lg:max-w-[340px] flex flex-col gap-8 lg:overflow-y-auto">

      {/* Session user ctx */}
      <div class="mx-2 flex mt-6 items-center gap-4">
        <div class="flex-1 flex gap-1.5 items-center">
          
          {/* Logo */}
          <div class="rounded-full border-[1px] bg-zinc-200 border-zinc-200 overflow-hidden">
            <div class="w-10 h-10">
              <img class="w-full h-auto" src={state.user.v?.avatar} />
            </div>
          </div>

          {/* Slug */}
          <div class="font-semibold flex-1">{state.user.v?.slug}'s overview</div>
        </div>

        <UILinkButton class="hidden md:block w-fit h-fit" href="/studio">Studio</UILinkButton>
      </div>

      {/* <div>
        <TopObjects />
      </div>

      <div>
        <PendingChanges />
      </div>

      <div>
        <RecentActivity />
      </div>

      <div>
        <YourTeams />
      </div> */}
    </div>
    
    {/* Feed area */}
    <div class="md:flex-1 flex gap-2 flex-col text-center px-2 mb-6 md:mb-0">
      {/* Tabs for Following / Objects / Dependencies / Your Activity */}
      {/* <ActivityFeed /> */}
      <div class="mt-8 italic text-xs text-zinc-500">Functionality of this area is "under construction".</div>
      <div class="italic text-xs text-zinc-500">Use the Studio for building dashboards, charts and tables.</div>
    </div>

    {/* Non-personal components */}
    <div class="w-full md:max-w-[200px] lg:max-w-[340px] flex flex-col gap-6 lg:overflow-y-auto">
      <TrendingDashboards class="mt-6 mx-2" />
      <TrendingTables class="mx-2" />
      <TrendingDataArtists class="mx-2" />

      <GraffeChanges class="mx-2 max-h-[240px] lg:max-h-[300px]" />
    </div>

  </div>;
  // <div>
  //   TODO: add objects which you haven't committed yet
  //   TODO: show objects you can upgrade and sync
  //   TODO: starred objects
  //   TODO: graffe news section with updates and releases.
  //   TODO: demo dashboards
  //   TODO: highlighted objects
  //   TODO: charts using graffe on your graffe connection (personal activity)
  // </div>

  onEnter(el, () => {
    state.topBar.visible.v = true;
    head.title.main.v = 'Your overview';

    keepHeightScreen(el, inner => inner - topBarHeight);
  });

  return el;
}
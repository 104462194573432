import { Signal, signal } from "ufti";

export interface LocalFile { 
  integrity: string,
  file: File, 
}

// This ctx is loaded system-wide so keep it ultra lightweight.

// Local files represents a set of locally loaded files with their browser File instance.
// These get cleared upon refreshing/reloading the page.
//
// They allow to work/experiment with files instantly without needing to upload them.
//
// TODO: this one should follow GraffeFiles pattern: indexed by integrity to allow multiple versions.
export class LocalFilesCtx {
  // Files, indexed by their sha256-b32 integrity hash.
  public files: Signal<Map<string, LocalFile>> = signal(new Map());
}

export function unsupported(msg: string = 'not implemented') {
  debugger;
  throw new Error('unsupported: '+msg);
}

export function unexpected(msg: string = 'unexpected') {
  debugger;
  throw new Error('unexpected: '+msg);
}

export function todo(msg: string = 'unimplemented') {
  console.warn(`todo: ${msg}`);
  // throw new Error('TODO: '+msg);
}

export function unhandled(msg: string = 'something unhandled') {
  debugger;
  throw new Error('unhandled: '+msg);
}

export function implement(msg: string = 'something unhandled') {
  debugger;
  throw new Error('implementation missing: '+msg);
}

export function required(msg: string = 'required something missing') {
  throw new Error('required: '+msg);
}
// Helper module to play nice with @babel/plugin-transform-react-jsx >= 7.9.0
//
// Usage .babelrc:
// {
//   "plugins": [
//     ["@babel/plugin-transform-react-jsx", { 
//       "runtime": "automatic",
//       "importSource": "ufti"
//     }]
//   ],
// }
import { createElement } from "./createElement";
import { UftiFragment as Fragment } from "./types";

function jsxTransform(tagNameNodeOrComponent, config, maybeKey) {
  // TODO: throw error if children is not of the correct type, eg: a component created with children arg gets this swallowed!!
  let { children, ...otherProps } = config;
  if(children != null && !Array.isArray(children)) {
    children = [children];
  }
  if(children == null) {
    children = [];
  }

  return createElement(tagNameNodeOrComponent, otherProps, ...children);
}

const jsx = jsxTransform;
// jsxs allows taking advantage of static children
const jsxs = jsxTransform;
const jsxDEV = jsxTransform;

export { jsx, jsxs, jsxDEV, Fragment };

// Volatile application state which is reflected in the search param s= of the url
// We strive to have an exact representation of the view looking at in the URL such copied URLs always
// 
// The idea is that this state is loaded with the application and managed at the application-level.
//
// No complex objects are stored in the URL state, so the state exists as signals which are nulled when not used.
//
// Any state set here according to the rules is reflected automatically to the URL as the signals are set, making the URL state well typed and a breeze to work with.
//
// We try to achieve a true one-way data flow for this: changes on the URL are modified and then the system reacts to these changes. 
//
// This also means that ad-hoc filter interaction needs to be sent to the URL. Important to take this into the design of the filter detail component.
// 
// The idea is that components link up with the urlState directly and use it as "source of truth" and be reactive to them. 

import stableStringify from 'fast-json-stable-stringify';
import { getUrlModel, removeNullKeys } from 'graffe-shared/src/universe/utils';
import rison from "rison";
import { Signal, signal, sub } from 'ufti';
import { router } from "ufti/src/plugin/router";
import { getSearchParams } from "../lib/browser";
import { debounce } from 'lodash-es';

interface UrlValueFilter {
  f: string;
  c: string;
  v: string[];
}

interface UrlSelected {
  // Either RefHash or CommitHash
  hash: string;

  // Optional dependency to focus within the selected object
  dep?: string;
}

// interface UrlFilterSet {
//   l: number;
//   v: UrlValueFilter[];
// }

// interface UrlToolbar2 {
//   area: string;
// }

// interface UrlInspect {
//   open: boolean;
// }

export interface UrlStateData {
  ref?: string;
  commit?: string;
  selected?: UrlSelected;
  filters?: UrlValueFilter[];
  subState?: any;
  // toolbar2?: UrlToolbar2;
  // inspect?: UrlInspect;
}

interface UrlState {
  ref: Signal<string>;
  commit: Signal<string>;
  selected: Signal<UrlSelected>;
  filters: Signal<UrlValueFilter[]>;
  subState: Signal<any>;
  // toolbar2: Signal<UrlToolbar2>;
  // inspect: Signal<UrlInspect>;
}

// All possible s= url state in the application
const urlState: UrlState = {
  ref: signal<string>(null),
  commit: signal<string>(null),
  selected: signal<UrlSelected>(null),
  filters: signal<UrlValueFilter>(null),
  subState: signal<any>(null),
  // toolbar2: signal<UrlToolbar2>(null),
  // inspect: signal<UrlInspect>(null),
};

function setIfDiff(sig: Signal<T>, val: T) {
  if(sig.v == null && val == null) return;
  const prev = stableStringify(sig.v);
  const curr = stableStringify(val);
  if(prev !== curr) {
    sig.v = val;
  }
}

function setStateIfDiff(s: UrlStateData) {
  setIfDiff(urlState.ref, s.ref);
  setIfDiff(urlState.commit, s.commit);
  setIfDiff(urlState.selected, s.selected);
  setIfDiff(urlState.filters, s.filters);
  setIfDiff(urlState.subState, s.subState);
  // setIfDiff(urlState.toolbar2, s.toolbar2);
  // setIfDiff(urlState.inspect, s.inspect);
}

function reflectState() {
  const strState = getSearchParams().get('s');
  const s = (strState != null ? rison.decode_object(strState) : {});

  // One-way sync of URL values to signals which also resets fields to their default values if they are missing in the URL.
  setStateIfDiff(s);
}

// On load, we load the current state instantly, so the state starts correctly initialized and all flows work well.
reflectState();

// If the route changes, we align the signals with it
sub(router.search, search => {
  reflectState();
}, null, { blocking: true });

function onUrlStateChange() {
  console.log('executing');
  const params = getSearchParams();
  const stableStartUrl = toStableParamUrl(params);

  // See if we need to update
  const prev = params.get('s');
  const prevState = (prev ? rison.decode_object(prev) : null);

  // When the old and updated state are not equal...
  const updated = removeNullKeys(getUrlModel(urlState));
  if(stableStringify(prevState) !== stableStringify(updated)) {
    // Create an updated url and set the new URL
    params.delete('s');

    const parts = [];
    if(updated != null) {
      const encoded = rison.encode_object(updated);
      parts.push(`s=${encoded}`);
    }
    parts.push(params.toString()); // Needs to be last to be stable

    const updatedUrl = partsToUrl(parts);
    if(updatedUrl !== stableStartUrl) {
      // TODO LATER: remove the rewriting URL creation does which makes the URLs sometimes unnecessary dirty. (pushState does an additional URL encoding - I don't think we can circumvent). This requires working with the params directly and construct url from there without toString usage. Trick is to have a stable parser which can be used for comparison and just use the map functions on params.
      // history.replaceState(null, '', updatedUrl);
      history.pushState(null, '', updatedUrl);
    }
  }
}
const debouncedUrlStateChange = debounce(onUrlStateChange, 4);

// If any of the signals change, we align the route with it.
sub(Object.values(urlState), () => {
  console.log('called');
  debouncedUrlStateChange();
}, null, { blocking: true });

export default urlState;

// -- Processing state

function partsToUrl(parts: string[]) : string {
  // Make a full URL which can be navigated to
  const queryStr = parts.filter(d => d && d.length > 0).join('&');
  const sign = (queryStr.length > 0 ? '?' : '');
  const url = `${location.pathname}${sign}${queryStr}`;
  return url;
}

// Create a stable start string that matches with our updatedUrl structure (because link annotations from GA might make this unstable)
// Stable param URL which puts the url state at the front
function toStableParamUrl(params: URLSearchParams) : string {
  const clone = new URLSearchParams(params);
  // Pull out the state
  const stateProps = clone.get('s');
  clone.delete('s');

  // Make the stable URL structure
  const parts = [];
  if(stateProps) parts.push(`s=${stateProps}`); // Filters first
  parts.push(clone.toString()); // Then the rest

  return partsToUrl(parts);
}

export function toggleSubState(prop: string) {
  const nextVal = (urlState.subState.v?.[prop] == 1 ? null : 1);
  urlState.subState.v = { ...urlState.subState.v||{}, [prop]: nextVal };
}
import { KeyService } from 'graffe-shared/src/keyService';
import { JSONValue } from 'graffe-shared/src/types/json';
import { HttpOrigin, ProtectedUserProfile, TeamRole } from 'graffe-shared/src/types/types';
import { UnsealedCommit } from 'graffe-shared/src/universe/commit';
import { signal } from 'ufti';
import { Space } from './components/Space/Space';
import { RouterCtx } from './components/lib/RouterCtx';
import { isProduction } from './lib/dev';
import { NodeProvider } from './nodeCtxs/nodeProvider';
import { ObjectCtx } from './nodeCtxs/objectCtx';
import { UsageIntent } from './nodeCtxs/usageIntent';
import reporting from './reporting/state';
import { LocalFilesCtx } from './tables/modules/FileV1/FileProvider/LocalFilesCtx';
import { Notification } from './types/notifications';

function getIdService() : string {
  // Support both localhost and IP address usage of the page (for mobile testing)
  if(!isProduction()) {
    if(window.location.origin.endsWith(':4000')) {
      return window.location.origin.replace(':4000', ':4001');
    }
  }
  
  return process.env.GRF_ID || 'https://id.graffe.app';
}

export const state = {
  // The ID service active on the session
  idService: signal<HttpOrigin>(getIdService()),

  // The authenticated user.
  // When empty, the user is logged out.
  user: signal<ProtectedUserProfile>(),

  // Teams the authenticated user is part of
  teams: signal<TeamRole[]>([]),

  // Onboarding props
  onboarding: signal<{ gauth?: string }>(),

  // Encryption key service
  keyService: signal(new KeyService()),

  // User UI notifications
  notifications: signal<Notification[]>([]),

  // Topbar controls
  topBar: {
    logo: {
      visible: signal<boolean>(true),
    },
    visible: signal<boolean>(false),
    leftOutlet: signal<HTMLDivElement>(null),
    centerOutlet: signal<HTMLDivElement|HTMLDivElement[]>(null), // HTML Outlet content which can be set
    controlsOutletTop: signal<HTMLDivElement[]>([]), // HTML Outlet content which can be set
  },

  // TODO: we should remove all ctx and space here and restructure with composable contexts.

  // Active Space
  space: signal<Space>(),

  // Active object/access/... ctxs.
  nodeProvider: signal<NodeProvider>(),

  // Ctx which exposes locally defined files.
  // Can often be abused to inject files.
  localFiles: signal<LocalFilesCtx>(),

  // Set on initialization of the router component
  routerCtx: signal<RouterCtx>(),

  // Universe gateway
  universe: {
    gateway: signal<HttpOrigin>(),
    
    actions: {
      flushWal: signal<boolean>(), // Signal to flush wal, devs can replace with a disabled signal.
    },
  },

  // Footer controls
  footer: {
    visible: signal<boolean>(true),
  },

  // Crash reporting state
  reporting,

  // User settings
  // TODO: these are inactive
  settings: signal<Record<string, JSONValue>>({}),

  // A signal where a title string or signal can be set, and whatever this signal provides, the title will become.
  // REFACTOR
  titleSig: signal(null), 

  // Dev flags
  dev: {
    mem: signal(false),
    copyJson: signal(false),
    wsUsageIntent: signal(false),
  },

  // Editor flow
  editor: {
    commit: {
      sent: signal<number>(0),
    }
  },

  // -- DEPRECATED

  // Feedback information
  feedback: {
    ctx: signal<WeakRef<ObjectCtx>>(),
  },

  // Portal 
  portal: {
    // User query done in the portal selector
    search: signal<string>(''),

    // // Narrow the scope of the portal to a certain change
    // // Setting scope clears the navigation stack.
    // scope: signal<WeakRef<ObjectCtx>>(),

    // Stack overrides the user pushes with actions within the drawer.
    // All levels remain mounted and are hidden, except the last entry.
    navStack: signal<HTMLDivElement[]>([]),

    // Active space which holds whatever is loaded in an editor
    space: signal<WeakRef<Space>>(),

    // Actions that can be done
    actions: {
      // Portal add actions are emitted here
      addObject: signal<UsageIntent>(),

      // Set this to any object you want to preview.
      preview: signal<WeakRef<UnsealedCommit>>(),
    },
  },
}

import { setChildren } from "ufti";
import { Link } from "ufti/src/plugin/router";

const items = [
  { slug: 'roel', avatar: 'https://gravatar.com/avatar/c8425c7e9862a06f00edcfd50e307d34?s=96&d=identicon' },
 
]

export default function TrendingDataArtists({ class: klass }) {
  let itemsDiv;
  const el = <div class={klass||''}>
    <div class="font-semibold">Trending Data Artists</div>

    <div class="text-xs text-zinc-500 mt-1.5">Artists that are building on Graffe.</div>

    {itemsDiv = <div class="mt-4 flex flex-col gap-2 text-xs"></div>}
  </div>;

  setChildren(itemsDiv, items.map(i => <Item item={i} />));

  return el;
}

function Item({ item } : { item: { slug: string, ref: string, title: string, avatar?: string } }) {
  const el = <div class="flex gap-2 items-center">
    <div class="mr-2">
      <div class="rounded-full overflow-hidden">
        <div class="w-8 h-8">
          <img class="w-full h-auto" src={item.avatar} />
        </div>
      </div>
    </div>

    <div class="flex gap-1 flex-col">
      <Link href={`/@${item.slug}`} class="hover:underline font-semibold">
        <div>Explore @{item.slug}'s profile</div>
      </Link>
    </div>
  </div>;

  return el;
}
import { $ } from './objects.js';
import { PureCallback, UftiFragment } from './types.js';

type CallbackType = 'enter' | 'exit';

// action counter which vale is set to block repeated traversing
let actionCtr = 1;

function assertObject(o: any) {
  if(typeof o !== 'object') throw new Error('not an object');
}

function onEvent(type: CallbackType, obj: object, handler: PureCallback) : PureCallback {
  assertObject(obj);
  if(!$.has(obj)) $.set(obj, {});
  
  if($.get(obj).evt == null) $.get(obj).evt = {};
  if($.get(obj).evt[type] == null) $.get(obj).evt[type] = [];
  
  // TODO: maybe warn when repeated additions if debug mode.
  // TODO: sets change will fix this automatically.
  $.get(obj).evt[type].push(handler);

  // Call to remove handler
  return () => {
    if($.get(obj).evt[type] == null) return;
    const idx = $.get(obj).evt[type].indexOf(handler);
    if(idx >= 0) $.get(obj).evt[type].splice(idx, 1);
  };
}

// --- Dev friendly exports

// Execute a callback after entering the dom
export function onEnter(elem: Element, handler: PureCallback) : PureCallback {
  assertObject(elem);

  // If already entered and the enter handler is already burned, fire the handler immediately
  if(elem.isConnected && $.get(elem)?.enter > 0) {
    handler();
    return () => {};
  }

  // Wait for the event to fire it
  return onEvent('enter', elem, handler);
}

// Execute a callback when the element left the dom
export function onExit(elem: object, handler: PureCallback) : PureCallback {
  assertObject(elem);

  // If already unmounted, fire the handler instantly and return a mock handler
  if($.get(elem)?.exit > 0) {
    handler();
    return () => {};
  }

  return onEvent('exit', elem, handler);
}

function traverse(action: CallbackType, elem: Element) {
  if(typeof elem !== 'object') return;

  // Enter events are only done if the object is connected to the dom
  if(action === 'enter' && !elem.isConnected) return;

  // Protect against repeated calls.
  if($.get(elem)?.[action] === actionCtr) {
    // Mark the action burned, so we don't call this twice. 
    // This also means all it's children have it applied, if any.
    return;
  } else if (!$.has(elem)) {
    // Initialize it so that the inline enter/exits can fire.
    $.set(elem, {});
  }
  $.get(elem)[action] = actionCtr;

  // Loop over children before firing my own callbacks.
  // Nodes enter/exit bottom->up, in child->parent order.
  if(elem.nodeType === 1 || elem.nodeType === 9) {
    for(let i = 0; i < elem.children.length; i++) {
      traverse(action, elem.children.item(i));
    }
  }

  // Then apply to self
  if($.get(elem) && $.get(elem).evt && $.get(elem).evt[action] != null) {
    // Burn the callbacks first, since we don't need them anymore
    const handlers = $.get(elem).evt[action];
    delete $.get(elem).evt[action];

    // Call the callbacks
    for(let handler of handlers) {
      handler();
    }
  }
}

// Returns the accumulator reference
function toElements(accumulator: Element[], content: any[] | any) : Element[] {
  if(content && content.type === UftiFragment) {
    content = content.v;
  }
  if(Array.isArray(content)) {
    for(let c of content) {
      toElements(accumulator, c);
    }
  } else {
    accumulator.push(content);
  }

  return accumulator;
}

function addToObject(isPrepend: boolean, parent: Element, content: any[] | any) : Element {
  actionCtr++;
  if(parent.nodeType !== 1 && parent.nodeType !== 9) {
    throw new Error('invalid parent');
  }

  const elements = toElements([], content);

  // Append/Prepend
  (isPrepend && parent.prepend || parent.append).bind(parent)(...elements);

  // Only when the parent is connected, we fire off the mounting
  for(let el of elements) {
    traverse('enter', el);  
  }

  return parent;
}

// Mount helper which appends elements or dom-nodes and returns the parent
export function append(parent: Element, content: any[] | any) : Element {
  return addToObject(false, parent, content);
}

// Mount helper which prepends elements or dom-nodes and returns the parent
export function prepend(parent: Element, content: any[] | any) : Element {
  return addToObject(true, parent, content);
}

// Empty nodes their children
export function clear(...parents: Element[]) {
  actionCtr++;
  for(let parent of parents) {
    // Exit all exiting nodes
    for(let i = 0; i < parent.children.length; i++) {
      traverse('exit', parent.children.item(i) as Element);
    }
    parent.innerHTML = '';
  }
}

// Remove an element from the DOM
export function remove(childToRemove: Element) {
  actionCtr++;
  if(!childToRemove.isConnected) return;
  traverse('exit', childToRemove);
  childToRemove.parentNode.removeChild(childToRemove);
}

// Replace an existing element. 
// This is just a helper to remove/add into dom. The original object exits and it's reference dies.
// Fragments are not supported here.
// Not the biggest fan of this... feels fishy... not sure.
export function replace(node: Element, content: Element) : Element {
  if(node === content) return;
  if(Array.isArray(content) && content.length > 1) {
    throw new Error('unsupported: multiple elements');
    // unsupported();
  }
  actionCtr++;
  traverse('exit', node);
  if(typeof content !== 'string') {
    content = content[0] ?? content; // Don't like this
  }
  if(typeof content === 'string') {
    content = document.createTextNode(content);
  }
  node.parentNode.replaceChild(content, node);
  traverse('enter', content);
  
  return content;
}

// Replace children with detach support
export function setChildren(parent: Element, contents: any[] | any, opts?: { detach?: boolean }) {
  actionCtr++;
  if(parent == null || contents == null) {
    throw new Error('invalid elements');
  }
  const elements = toElements([], contents);
  
  // fire the onExit callbacks on the nodes that are leaving the dom
  for(let i = 0; i < parent.children.length; i++) {
    const node = parent.children.item(i);
    if(elements.find(e => e === node)) continue;
    if(!opts?.detach) {
      traverse('exit', node);
    }
  }

  // Capture the new nodes to fire their enter handlers
  const newNodes = elements.filter(e => !e.isConnected);
  
  // Set all the nodes (replaces also those which have previously entered)
  parent.replaceChildren(...elements);
  
  // Fire the onEnter callbacks
  for(let node of newNodes) {
    traverse('enter', node);
  }
}

import { onEnter } from "ufti";
import { head } from "ufti/src/plugin/head";
import { Link } from "ufti/src/plugin/router";
import { noIndexRobots } from "../lib/seo";

export default function AccountRequired() {
  const el = <div class="w-screen h-screen justify-center items-center text-zinc-800 font-sm flex flex-col gap-2">
    <div class="font-semibold">For now, this page requires to be signed in.</div>
    <div class="mt-4 text-zinc-600 text-xs">For signing in, go to <Link href="/">https://graffe.app</Link>...</div>
  </div>;

  onEnter(el, () => {
    head.meta.v = {
      ...head.meta.v,
      robots: noIndexRobots,
    }
  });

  return el;
}

export enum EncryptionType {
  // No client-side encryption has been implemented yet, sole allowed value for now is none.
  none = "none",

  // Uses keyService for encryption/decryption
  // key = "key",
}

// export interface KeyIV {
//   key: CryptoJS.lib.WordArray;
//   iv: CryptoJS.lib.WordArray;
// }

// export interface KeyService {
//   resolveKeyIVFor(guardedObject: any) : Promise<KeyIV>;
// }
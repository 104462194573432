import { staticTiers } from 'graffe-shared/src/identity/tiers';
import { SponsorshipRow, SponsorTier } from 'graffe-shared/src/identity/types';
import { InsiderSponsorship } from 'graffe-shared/src/types/types';
import { computed, signal, Signal } from 'ufti';
import { resolveCollectionId } from '../../../id/helpers';
import { appJson, credentialsMode } from '../../../lib/auth';
import { throwOnBadResponse } from '../../../lib/fetch';
import { state } from '../../../state';

export class SponseeCtx {
  // User/Team ID who is getting sponsored
  id: Signal<string> = signal();

  slug: Signal<string> = signal();
}

export class SponsorCtx {
  sponsee: SponseeCtx;

  // User/Team ID who is sponsoring
  id: Signal<string> = signal();

  // Amount in full currency.
  amount: Signal<number> = signal();
  
  tiers: Signal<SponsorTier[]> = signal([]);

  // The last amount known to the ID service, set on load in the parent page.
  activeAmount: Signal<number> = signal();

  // Active tier if it's exactly this amount, otherwise null.
  activeTier: Signal<SponsorTier>;

  constructor(sponseeCtx: SponseeCtx) {
    this.sponsee = sponseeCtx;

    this.activeTier = computed([this.activeAmount, this.tiers], () => {
      if(!this.activeAmount.v) {
        return;
      }

      return [...this.tiers.v].sort(d => d.amount).reverse().find(d => d.amount === this.activeAmount.v);
    }, null, { blocking: true });
  }

  async reload() {
    // Load active sponsorship for this account
    const active = await resolveSponsorshipFor(this.sponsee.id.v, this.id.v);
    if(active != null) {
      this.activeAmount.v = active.amount/100;
    }
  }
}

export async function resolveSponsorshipFor(sponsee: string, sponsor: string) : Promise<{ amount: number, sponsorEntity: string }> {
  const req = await fetch(`${state.idService.v}/v1/sponsorships?sponsee=${sponsee}&sponsor=${sponsor}`, {
    ...credentialsMode,
    headers: appJson,
  });
  await throwOnBadResponse(req);
  const res = await req.json();

  return res?.items?.[0];
}

export async function resolveSponsorshipsFor(sponsee: string) : Promise<SponsorshipRow[]> {
  const req = await fetch(`${state.idService.v}/v1/sponsorships?sponsee=${sponsee}`, {
    ...credentialsMode,
    headers: appJson,
  });
  await throwOnBadResponse(req);
  const { items } = await req.json();

  return items;
}

export async function resolveInsiderSponsorshipFor(sponsor: string) : Promise<InsiderSponsorship> {
  const graffeId = await resolveCollectionId('graffe');
  const sponsorship = await resolveSponsorshipFor(graffeId, sponsor);
  if(sponsorship) {
    const activatedTier = [...staticTiers].sort(d => d.amount).reverse().find(d => d.amount*100 === sponsorship.amount);

    return {
      amount: sponsorship.amount,
      activatedTier,
    }
  }
}
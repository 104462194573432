import { onEnter, onExit } from 'ufti';
import { getSetting, SettingProps } from "../models/settings";
import { state } from "../state";

export default function DebugBar() {
  const btomb = 1024 * 1024;

  const el = <div
    class="fixed bottom-1.5 left-[150px] h-[18px] px-1 border-0 text-zinc-800 text-xxs z-50 hidden max-w-[100px] select-none"
    title="Memory (heap): Used | Free | Max" />;

  let updater;
  onEnter(el, () => {
    if(!state.user.v?.id) return; // INVITE stuff
    if(!state.dev.mem.v) return;
    // if(!getSetting(SettingProps.BEAST_MODE)) return;
    el.classList.remove('hidden');

    updater = setInterval(() => {
      if(typeof performance === 'undefined') return;
      const m = performance.memory;
      if(m) {
        el.textContent = `${Math.round(m.usedJSHeapSize / btomb)} | ${Math.round((m.totalJSHeapSize - m.usedJSHeapSize) / btomb)} | ${Math.round(m.jsHeapSizeLimit / btomb)}`
      }
    }, 500);
  });

  onExit(el, () => {
    if(updater) clearInterval(updater);
  });
  
  return el;
}



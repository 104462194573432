import { JSONValue } from "graffe-shared/src/types/json";
import { Signal, signal } from "ufti";
import { Route } from "ufti/src/plugin/router";

export class RouterCtx {
  public route: Signal<Route> = signal();
  public match: Signal<Record<string, string>> = signal();
  
  public prevRoute: Signal<Route> = signal();
  public prevMatch: Signal<Record<string, string>> = signal();
}
export function GraffeLogoIcon(props) {
  return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="16" fill="#3F3F46"/>
    <path d="M15.9136 23.6541C14.9617 23.6541 14.1467 23.5211 13.4687 23.2551C12.7906 22.9891 12.2582 22.625 11.8713 22.1628C11.4845 21.7006 11.252 21.1773 11.1737 20.593L14.1924 20.423C14.2489 20.6279 14.3532 20.8067 14.5053 20.9593C14.6618 21.1119 14.8639 21.2296 15.1116 21.3125C15.3637 21.3953 15.6593 21.4368 15.9983 21.4368C16.5329 21.4368 16.9741 21.306 17.3218 21.0443C17.6739 20.7871 17.8499 20.3358 17.8499 19.6904V17.9244H17.7391C17.613 18.234 17.4218 18.5109 17.1653 18.7551C16.9089 18.9993 16.5894 19.1911 16.2069 19.3307C15.8288 19.4702 15.3941 19.54 14.903 19.54C14.1728 19.54 13.5034 19.3699 12.8949 19.0298C12.2908 18.6853 11.8061 18.1533 11.441 17.4339C11.0803 16.71 10.8999 15.7791 10.8999 14.641C10.8999 13.468 11.0868 12.5022 11.4606 11.7435C11.8344 10.9804 12.3234 10.4157 12.9275 10.0494C13.536 9.68314 14.1902 9.5 14.89 9.5C15.4159 9.5 15.8657 9.59157 16.2395 9.77471C16.6177 9.95349 16.9285 10.1846 17.1719 10.468C17.4153 10.7515 17.6 11.0458 17.726 11.351H17.8173V9.63081H20.9924V19.7166C20.9924 20.5669 20.7794 21.2842 20.3535 21.8685C19.9275 22.4528 19.332 22.8953 18.5671 23.1962C17.8021 23.5014 16.9176 23.6541 15.9136 23.6541ZM16.0113 17.2115C16.3982 17.2115 16.7285 17.109 17.0023 16.9041C17.2762 16.6991 17.487 16.4048 17.6348 16.0211C17.7825 15.6374 17.8564 15.1773 17.8564 14.641C17.8564 14.0959 17.7825 13.6272 17.6348 13.2347C17.4913 12.8379 17.2805 12.5327 17.0023 12.319C16.7285 12.1054 16.3982 11.9985 16.0113 11.9985C15.6158 11.9985 15.2811 12.1076 15.0073 12.3256C14.7335 12.5436 14.5249 12.851 14.3814 13.2478C14.2423 13.6403 14.1728 14.1047 14.1728 14.641C14.1728 15.1773 14.2445 15.6374 14.3879 16.0211C14.5314 16.4048 14.7378 16.6991 15.0073 16.9041C15.2811 17.109 15.6158 17.2115 16.0113 17.2115Z" fill="#F4F4F5"/>
  </svg>;  
  // return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  //   <rect width="32" height="32" rx="16" fill="#E0E7FF"/>
  //   <path d="M15.9136 23.6541C14.9617 23.6541 14.1467 23.5211 13.4687 23.2551C12.7906 22.9891 12.2582 22.625 11.8713 22.1628C11.4845 21.7006 11.252 21.1773 11.1737 20.593L14.1924 20.423C14.2489 20.6279 14.3532 20.8067 14.5053 20.9593C14.6618 21.1119 14.8639 21.2296 15.1116 21.3125C15.3637 21.3953 15.6593 21.4368 15.9983 21.4368C16.5329 21.4368 16.9741 21.306 17.3218 21.0443C17.6739 20.7871 17.8499 20.3358 17.8499 19.6904V17.9244H17.7391C17.613 18.234 17.4218 18.5109 17.1653 18.7551C16.9089 18.9993 16.5894 19.1911 16.2069 19.3307C15.8288 19.4702 15.3941 19.54 14.903 19.54C14.1728 19.54 13.5034 19.3699 12.8949 19.0298C12.2908 18.6853 11.8061 18.1533 11.441 17.4339C11.0803 16.71 10.8999 15.7791 10.8999 14.641C10.8999 13.468 11.0868 12.5022 11.4606 11.7435C11.8344 10.9804 12.3234 10.4157 12.9275 10.0494C13.536 9.68314 14.1902 9.5 14.89 9.5C15.4159 9.5 15.8657 9.59157 16.2395 9.77471C16.6177 9.95349 16.9285 10.1846 17.1719 10.468C17.4153 10.7515 17.6 11.0458 17.726 11.351H17.8173V9.63081H20.9924V19.7166C20.9924 20.5669 20.7794 21.2842 20.3535 21.8685C19.9275 22.4528 19.332 22.8953 18.5671 23.1962C17.8021 23.5015 16.9176 23.6541 15.9136 23.6541ZM16.0113 17.2115C16.3982 17.2115 16.7285 17.109 17.0023 16.9041C17.2762 16.6991 17.487 16.4048 17.6348 16.0211C17.7825 15.6374 17.8564 15.1773 17.8564 14.641C17.8564 14.0959 17.7825 13.6272 17.6348 13.2347C17.4913 12.8379 17.2805 12.5327 17.0023 12.319C16.7285 12.1054 16.3982 11.9985 16.0113 11.9985C15.6158 11.9985 15.2811 12.1076 15.0073 12.3256C14.7335 12.5436 14.5249 12.851 14.3814 13.2478C14.2423 13.6403 14.1728 14.1047 14.1728 14.641C14.1728 15.1773 14.2445 15.6374 14.3879 16.0211C14.5314 16.4048 14.7378 16.6991 15.0073 16.9041C15.2811 17.109 15.6158 17.2115 16.0113 17.2115Z" fill="#27272A"/>
  // </svg>
};


export function ProfileIcon(props) {
  return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="16" fill="#E4E4E7"/>
    <path d="M12.9198 12.5778C12.9198 14.2759 14.3017 15.6578 15.9998 15.6578C17.698 15.6578 19.0798 14.2759 19.0798 12.5778C19.0798 10.8797 17.698 9.49777 15.9998 9.49777C14.3017 9.49777 12.9198 10.8797 12.9198 12.5778ZM21.4754 22.5022H22.1598V21.8178C22.1598 19.1765 20.01 17.0267 17.3687 17.0267H14.631C11.989 17.0267 9.83984 19.1765 9.83984 21.8178V22.5022H10.5243H11.2087H20.791H21.4754Z" fill="#A1A1AA"/>
  </svg>
}
import { PureCallback } from "ufti/src/types";

export interface ThreadResponse {
  // Serializable error
  error?: {
    message: string;
  }

  // Serializable object
  data?: any;
}

// The missing method for quickly scheduling out of flow without 4ms delay
export function setImmediate(handler: PureCallback) {
  Promise.resolve().then(handler);
}
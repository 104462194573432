import { append } from "ufti";
import { Link } from "ufti/src/plugin/router";
import { UILinkButton } from "../../../components/ui/buttons";
import { onEntered } from "../../../lib/extUfti";
import { OutlineIcon } from "../../../lib/icons";
import { wrapAsync } from "../../../lib/logger";
import { setStaticHeaderTags } from "../../../lib/metadata";
import PageFooter from "../components/PageFooter";
import PageTop from "../components/PageTop";
import { applyProductPageStyle } from "../helpers";
import GoalStatus, { StatusMark } from "./GoalStatus";
import SponsorList from "./SponsorList";
import { addCtx } from "ufti/src/plugin/ctx";
import { SponseeCtx } from "../sponsor/lib";
import { getGraffeCollectionId, resolveCollectionId } from "../../../id/helpers";
import { isPromise } from "../../../lib/object";
import CompletionStatus from "./CompletionStatus";
import SessionSponsorTiers from "../sponsor/SessionSponsorTiers";

export default function Insiders() {
  let contentDiv: HTMLDivElement;

  const el = <div class="w-full h-screen max-w-[1280px] mx-auto flex flex-col px-3  md:px-5 text-sm">
    <PageTop />

    {contentDiv = <div class="flex flex-col mx-auto w-full max-w-[960px]">
      
      <h1 class="mt-6 lg:mt-8 mb-6 lg:mb-8 text-center font-display font-semibold text-3xl leading-[40px] lg:text-4xl lg:leading-[48px]">Insiders</h1>

      <h2 class="text-base mb-6 lg:mb-8">
        We're on a mission to build out a trustworthy, privacy-first, efficient and collaborative data system for all of humanity. <br />
        <span class="italic">Free for open data and personal use - forever.</span>
      </h2>

      <div class="text-base mt-2 lg:mt-4 mb-8 lg:mb-10">
        <h2 class="text-lg font-semibold">What are Insiders?</h2>

        <div class="mt-1 lg:mt-2">
          We apply a Sponsorware release strategy to fuel the expensive development of <Link class="hover:underline" href="/insiders#vision">our ambitious vision</Link>. 
        </div>

        <div class="mt-1 lg:mt-2">
          This means new features are first exclusively released to sponsors who are "<span class="font-semibold">Insiders</span>". <br />
          When we achieve funding goals, insider features are unlocked and become available to everybody and are open-sourced.
        </div>
      </div>

      <div class="mb-6 lg:mb-8 text-base">
        <h3 class="text-lg text-zinc-800 font-semibold">What is in it for me or my team?</h3>
        <div class="mt-2">You get immediate access to <span class="font-semibold">all</span> Insider features (everything that is marked <StatusMark status="available" />). Which means you can start using all functionality now. New features are added continuously and made available to sponsors first.</div>
      </div>

      <div class="mb-6 lg:mb-8 text-base">
        <h3 class="text-lg text-zinc-800 font-semibold">What do you achieve by sponsoring Graffe?</h3>
        
        <div class="mt-2">
          You support a more trustworthy data world by sponsoring the development. 
        </div>

        <div class="mt-2">
          As we achieve sponsor goals together, all features of Graffe are unlocked for all users (free of charge!).
        </div>
        
        <div class="mt-2">
          Development of the ambitious vision of graffe.app is time-consuming and very expensive. Years of development have already been invested. I'm working full-time on it. It needs to be sustainable to avoid burning out who is working on it. Sponsorships make this project sustainable, as they buy the maintainer(s) of this project time (and food). Which is spent on the development of new features, bug fixing, stability improvement, issue triage and general support. The biggest bottleneck in Open Source is time. Funds are distributed to the maintainers and contributors.
        </div>
        
        <div class="mt-2">
          If you're unsure if you should sponsor Graffe: the whole application you are using has been developed in the past and has been made available to you free of charge.
        </div>
      </div>

      {/* Here we append more */}
    </div>}

    <div class="mb-[400px]"></div>

    <PageFooter />
  </div>;

  onEntered(el, wrapAsync(async () => {
    const sponseeCtx = addCtx(el, new SponseeCtx());
    const lookup = getGraffeCollectionId();
    sponseeCtx.id.v = (isPromise(lookup) ? await lookup : lookup); // In production there is no network-call for this.
    sponseeCtx.slug.v = 'graffe';

    append(contentDiv, <>
      <div class="text-base">
        <h3 class="text-lg text-zinc-800 font-semibold">How to sponsor and become an Insider?</h3>
        {/* <div class="mt-2"><Link href="/sponsor/graffe/sponsorship" class="text-indigo-700 hover:underline">On the sponsoring page</Link> you can select a sponsoring tier for your personal account or your team.</div>
        <UILinkButton class="w-fit mt-2.5 items-center" size="lg" href="/sponsor/graffe/sponsorship">
          <OutlineIcon icon="heart" class="w-6 h-6" />
          Select sponsor amount and become Insider
        </UILinkButton> */}
        <div class="mt-2">See below. <a href="#sponsor" class="text-indigo-700 hover:underline">Jump there</a></div>
      </div>

      <div class="mt-6 lg:mt-8 ">
        <GoalStatus />
      </div>

      <div class="mt-12 lg:mt-16 mb-14 lg:mb-16 flex flex-col md:flex-row gap-4 md:gap-8">
        <div>
          {/* <h3 class="text-xl mb-2 font-semibold">Sponsoring</h3> */}

          <div class="w-full max-w-[360px]">
            <CompletionStatus />
          </div>

          <div class="mt-4">
            <SponsorList />
          </div>
        </div>

        <div class="w-full max-w-[360px] text-xs" id="sponsor">
          <SessionSponsorTiers />
        </div>
      </div>

      <div class="mb-6 lg:mb-8 text-base">
        <h3 class="text-zinc-800 font-semibold">Tax on sponsorship</h3>
        <div class="mt-2">Sales tax is generally only applicable when a good or service of value is being provided. Goodwill/general support/undying appreciation is not normally taxable.</div>
      </div>
    </>)
  }))

  applyProductPageStyle(el);

  setStaticHeaderTags(
    'Insiders', 
  );

  return el;
}
import { OutlineIcon } from "../../../lib/icons";

export default function GoalStatus() {
  const el = <div class="flex flex-col gap-8">
    <div class="flex flex-col gap-4">
      <h2 class="text-lg font-semibold">Sponsoring Goals</h2>

      <p class="">The following section lists all funding goals. Each goal contains a list of features prefixed with a check mark symbol, denoting whether a feature is <StatusMark status="available" /> already available or <StatusMark status="planned" /> planned, but not yet implemented. When the funding goal is hit, the features are released for general availability to all users.</p>

      {/* <h3 class="text-base font-semibold">$ 1000 - </h3> */}

      {/* <div class="mt-2">
        <h3 class="text-base font-semibold mb-1.5">$ 3,000 - "Yeah buddy"</h3>
        <ul class="list-disc list-inside pl-1">
        </ul>
      </div>

      <div class="mt-2">
        <h3 class="text-base font-semibold mb-1.5">$ 2,500 - "Light weight"</h3>
        <ul class="list-disc list-inside pl-1">
        </ul>
      </div> */}

      <div class="mt-2">
        <h3 class="text-base font-semibold mb-1.5">$ 2,000 - "Nothin' but a peanut"</h3>
        <ul class="list-disc list-inside pl-1">
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> File upload support (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Excel data parser (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> View-specific queries (using DuckDB) (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Transform Scripts for (EngineV1)</div></li>
        </ul>
      </div>

      <div class="mt-2">
        <h3 class="text-base font-semibold mb-1.5">$ 1,500 - "It's an ultra, not a race"</h3>
        <ul class="list-disc list-inside pl-1">
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Strava Table Module (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> SQL (using DuckDb) (EngineV1)</div></li>
        </ul>
      </div>

      <div class="mt-2">
        <h3 class="text-base font-semibold mb-1.5">$ 1,000 - "Slow is smooth, smooth is fast"</h3>
        <ul class="list-disc list-inside pl-1">
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> File upload support (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Customizable Fields (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Size views and dashboards via width/height inputs</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> View titles on dashboards and views</div></li>
        </ul>
      </div>

      <div class="mt-2">
        <h3 class="text-base font-semibold mb-1.5">$ 500 - "Jan/23 we will be live"</h3>
        <ul class="list-disc list-inside pl-1">
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Public Google Sheet table module (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Markdown Data Views (EngineV1)</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> User Variables</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Tree-Duplicate option</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Tree-Undo Changes option</div></li>
        </ul>
      </div>

    </div>

    <div class="flex flex-col gap-4">
      <h2 class="text-lg font-semibold">Goals completed in the past</h2>

      <p class="">This section lists all funding goals that were previously completed, which means that those features were part of Insiders, but are now generally available and can be used by all users.</p>

      <div>
        <h3 class="text-base font-semibold mb-2">Sponsored by the maintainer - "Foundation - Let's Go"</h3>
        <ul class="list-disc list-inside pl-1">
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Graffe.app client-side application</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Identity service with Google auth support</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Universe repository service</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Data Engine V1</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Object change tracking + versioning management</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Data studio for building tables, views and dashboards</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Viewer mode</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Render engine with Views and Dashboards</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Studio object tree</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Query and use objects from the universe</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> HTTP module with data integrity support</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> JSON, CSV, Raw data parsers</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Sponsoring module</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Object Forking</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Share objects publicly</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Modern neutral UI system</div></li>
          <li><div class="inline-flex items-center gap-2"><StatusMark status="available" /> Object metadata system</div></li>
        </ul>
      </div>
    </div>
  </div>;

  return el;
}

const statusColors = {
  'planned': 'bg-zinc-200',
  'ongoing': 'bg-yellow-300',
  'available': 'bg-green-300',
}

export function StatusMark({ status } : { status: 'planned' | 'ongoing' | 'available' }) {
  const color = statusColors[status];

  return <div class={`inline-flex items-center justify-center border-[1px] h-5 w-5 rounded-full ${color}`}>
    <OutlineIcon class="w-3 h-3 inline" icon="check" />
  </div>;
}
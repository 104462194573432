import { ObjectTitle, ObjectTitleCustomizableProps, TitleLogic, TitleUIDimensions } from "graffe-shared/src/models/objects";
import { Signal, signal } from "ufti";

export interface ObjectTitleSignals {
  logic: Signal<TitleLogic>,

  visible: Signal<boolean>,

  text: Signal<string>,

  dim: Signal<TitleUIDimensions>,

  custom: Signal<ObjectTitleCustomizableProps>,
}

export class ObjectTitleHelpers {
  static fromData(data: ObjectTitle) : ObjectTitleSignals {
    return {
      logic: signal(data?.logic),
      visible: signal(data?.visible),
      text: signal(data?.text),
      dim: signal(data?.dim),
      custom: signal(data?.custom),
    }
  }

  static calculateHeight(data: ObjectTitleSignals) : number {
    if(!data.visible.v) {
      return 0;
    }

    return 4 + (data.text.v?.length > 0 ? 20 : 0);
  }
}
// User friendly system redirects are a complex process, while it shouldn't be.
// The use case: a user is routed to @../strava-overview and is not yet enabled. So they see the wait page. Then they get 
// When the user gets enabled, we want to send them back to the strava-overview instead of the profile page.
//
// Then they get the user question.
//
// Don't use the route paths, use location hrefs instead.
//
// Valid arrival pages: studio or objects.
//
// Test: create public strava dash -> signup -> wait -> enabled -> refresh -> flow into strava
//
// Once pages are _actually_ visited, we should burn them. 

import { unexpected } from "graffe-shared/src/lib/devflow";
import { state } from "../state";

const routeKey = 'postAccountRoute';

// Capture the arrival route for users
export function markPostAccountRoute() {
  // If user is invited, we don't track arrival routes, since they won't be blocked.
  // Modules or permission flows themselves take-over here.
  if(state.user.v?.id && state.user.v?.invited == 1) {
    return;
  }
  const path = getCurrentPath();
  
  // We only reroute to remain on user objects
  if(path.indexOf('/@') !== 0 && path.indexOf('/studio') !== 0) {
    return;
  }

  // Track it
  localStorage.setItem(routeKey, path);
}

// Return and burn pending arrival route.
export function getPostAccountRoute() : string {
  return localStorage.getItem(routeKey);
}

// Override function to inject post account routes
// path href should be starting with /
export function setPostAccountRoute(path: string) {
  if(path[0] !== '/') {
    unexpected();
  }
  localStorage.setItem(routeKey, path);
} 

// On the actual visit, which fires 100ms after visiting the page, we burn any pending routes for this.
// Redirect handle pages execute without delay.
export function burnIfArrived(startPath: string) {
  const pathName = window.location.pathname;
  if(startPath.indexOf(pathName) === 0) {
    if(getPostAccountRoute() === startPath) {
      localStorage.removeItem(routeKey);
    }
  }
}

function getCurrentPath() : string {
  return window.location.href.substring(window.location.origin.length)
}
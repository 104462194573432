// CCCCCCCCCCCCCCCCCCCCCCCCCC = community user
export const DEFAULT_COMMUNITY_USER = {
  id: 'CCCCCCCCCCCCCCCCCCCCCCCCCC',
  slug: 'community',
  email: 'community@localhost',
  avatar: 'https://gravatar.com/avatar/1adc583dd45e6eef34cfc1d6d00b4556?s=96&d=identicon',
};


// Grid is 8x8
export const gridSize = 8;
import { SponsorTier } from "graffe-shared/src/identity/types";
import { setChildren, signal, sub } from "ufti";
import { navigate } from "ufti/src/plugin/router";
import { UIButton } from "../../../components/ui/buttons";
import { onEntered } from "../../../lib/extUfti";
import { InputField } from "../../../lib/fields";
import { switchClass } from "../../../lib/ui";
import { SponseeCtx, SponsorCtx } from "./lib";
import { state } from "../../../state";
import { setPostAccountRoute } from "../../../lib/redirectManager";
import { showSignInModal } from "../../../components/session/SignIn";
import { wrapAsync } from "../../../lib/logger";

export default function RenderTiers() {
  let tiersDiv: HTMLDivElement;

  // const el = <div class="flex flex-col border-[1px] border-zinc-200 rounded-sm">
  // const el = <div class="flex flex-col rounded-sm">
  const el = <div class="flex flex-col bg-white rounded-lg">
    <CustomTier />

    {tiersDiv = <div class="flex flex-col" />}
  </div>;

  onEntered(el, SponseeCtx, SponsorCtx, (sponseeCtx, sponsorCtx) => {
    sub(sponsorCtx.tiers, () => {
      setChildren(tiersDiv, sponsorCtx.tiers.v.map(t => <SponsorTierComp tier={t} />));
    }, el);
  });

  return el;
}


function SponsorTierComp({ tier } : { tier: SponsorTier }) {
  let currentMarker;
  let actionDiv;
  const el = <div class="hover:bg-indigo-50 p-4 relative">
    {currentMarker = <div class="ml--1 mb-0.5 w-fit text-xs border-lime-300 bg-lime-100 border-[1px] px-1 py-0.5">Current sponsorship</div>}
    <div class="flex justify-between">
      <div class="text-base font-semibold">${tier.amount} a month</div>
      {actionDiv = <div />}
    </div>
    <div class="text-lg font-semibold">{tier.name}</div>
    <div class="italic my-2">{tier.hint}</div>
    <div class="text-xs my-2">{tier.description}</div>
    <div class="font-semibold mt-2 mb-1">Rewards:</div>
    <ul class="list-disc list-inside pl-1">{tier.rewards.map(reward => <li>{reward}</li>)}</ul>
  </div>;

  onEntered(el, SponseeCtx, SponsorCtx, (sponseeCtx, sponsorCtx) => {
    sub([state.user, sponsorCtx.activeTier, signal(1)], () => {
      const isActive = sponsorCtx.activeTier.v === tier;
      switchClass(isActive, currentMarker, 'block', 'hidden');

      setChildren(
        actionDiv,
        state.user.v?.id ? (
          <UIButton role="sec" size="sm" onclick={() => {
            navigate(`/sponsor/${sponseeCtx.slug.v}/sponsorship/manage?sponsor=${sponsorCtx.id}&amount=${tier.amount}`);
          }}>{isActive ? 'Manage' : 'Select'}</UIButton>
        ) : (
          <UIButton role="ter" onclick={wrapAsync(async () => {
            setPostAccountRoute(window.location.pathname);
            await showSignInModal();
          })}>Sign in to sponsor</UIButton>
        )
      );
    }, el);
  });

  return el;
}

function CustomTier() {
  let rewardsDiv;
  let actionDiv;
  let currentMarker;
  const el = <div class="flex flex-col gap-2 rounded-sm hover:bg-indigo-50 p-4 relative" />;

  onEntered(el, SponseeCtx, SponsorCtx, (sponseeCtx, sponsorCtx) => {
    const customAmount = signal();

    setChildren(el, <>
      {currentMarker = <div class="ml--1 mb-0.5 w-fit text-xs border-lime-300 bg-lime-100  border-[1px] px-1 py-0.5">Current sponsorship</div>}

      <div class="flex justify-between items-center">
        <div class="flex items-center text-base font-semibold gap-1">
          $ <InputField 
            sig={customAmount} 
            type="number" 
            min="9"
            label="" 
            divClass="flex items-center gap-4 justify-between" 
            class="w-20" />
          a month
        </div>

        {actionDiv = <div />}
      </div>

      {rewardsDiv = <div class="italic" />}
    </>);

    sub([customAmount, sponsorCtx.tiers], () => {
      const activeTier = [...sponsorCtx.tiers.v].sort(d => d.amount).reverse().find(d => d.amount <= customAmount.v);

      setChildren(rewardsDiv, <>
        If you want to sponsor a higher/different amount, $9+.<br/>
        You will receive the rewards from the <span class="font-semibold">{activeTier?.name}</span> tier.
      </>);
    }, el);

    sub([state.user, sponsorCtx.activeTier, sponsorCtx.activeAmount, signal(1)], () => {
      const isActive = sponsorCtx.activeAmount.v > 0 && sponsorCtx.activeTier.v == null;
      switchClass(isActive, currentMarker, 'block', 'hidden');

      // Initialize the custom amount only if we have a custom plan active
      if(isActive && customAmount.v == null) {
        customAmount.v = sponsorCtx.activeAmount.v;
      }

      setChildren(
        actionDiv,
        state.user.v?.id != null ? (
          <UIButton role="sec" size="sm" onclick={() => {
            if(customAmount.v < 9) {
              alert('The minimum is $9. Please adjust your amount.');
              return
            };
            navigate(`/sponsor/${sponseeCtx.slug.v}/sponsorship/manage?sponsor=${sponsorCtx.id.v}&amount=${customAmount.v}`);
          }}>{isActive ? 'Manage' : 'Select'}</UIButton>
        ) : (
          // <div class="text-xs">Sign in to sponsor</div>
          <UIButton role="ter" onclick={wrapAsync(async () => {
            setPostAccountRoute(window.location.pathname);
            await showSignInModal();
          })}>Sign in to sponsor</UIButton>
        )
      );
    })
  })

  return el;
}
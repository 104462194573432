import { TableModuleMetadata } from "../../types";

export default function getMetadataFileV1() : TableModuleMetadata {
  return {
    module: 'FileV1', 
    description: 'Upload and work with files (json/csv/xlsx)', 
    displayName: 'File', 
    requiresInsider: true,
    hooks: [
      'beforeCreate', 
      'beforeUploadFiles', 
      'beforeCommit',
    ],
  }
}
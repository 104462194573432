import { StyleObject, UICoordinates, UIDimensions, ViewData, ViewModuleId } from "graffe-shared/src/models/view";
import { CommitHash } from "graffe-shared/src/types/types";
import { getStorageModel } from "graffe-shared/src/universe/utils";
import { Signal, isSignal, signal } from 'ufti';
import { TableModuleId } from "graffe-shared/src/models/table";
import { ObjectInstance } from "../models/data";
import { ObjectTitleHelpers, ObjectTitleSignals } from "../models/objectTitle";
import { reportInfo, reportWarning } from "../types/notifications";
import { ErrorSignal } from "../views/types";
import Table, { fullRefToDefaultAlias } from "./table";

export const viewDefaultStyle: StyleObject = {
  // spacing: {
  //   top: 12,
  //   right: 16,
  //   bottom: 16,
  //   left: 16,
  // },
  // title: {
  //   spacing: {
  //     bottom: 12,
  //   }
  // },
}

export class View {
  module: Signal<ViewModuleId>;
  pos: Signal<UICoordinates>;  
  dim: Signal<UIDimensions>;
  title: ObjectTitleSignals;
  info: Signal<string>;
  accessible: Signal<boolean>;

  // Optional random string set on dependencies. To be able to target individual dependencies deterministically and focus them.
  //
  // This is not an ID and is allowed to change (albeit some editor links out in the wild might break)
  //
  // TODO: add check that this is undefined for universe objects.
  depId: Signal<string>;

  // Typed view specific props
  // We keep them for all types, because when working with the object, it's possible to configure different views and then when switching and comparing, all settings remain and it just works.
  // props: { ChartV1: { ... }, DataGridV1: { rowNumbers: ... }, ... }
  // TODO: do a pre-commit "trim" on the object which removes the non-type props from the object and keeps it clean. But the core structure doesn't change.
  props: Signal<Record<string, any>>;

  // Extra tables created by this view for its execution. Will be primarily SqlV1 tables.
  // These tables get access to the dependency tables context for executing. This allows creating aggregations.
  inlineTables: Signal<Table[]>;

  // Shared data tables that provide the data for the views.
  // These are passed to the views by the view renderer.
  tables: Signal<Table[]>;

  // Can be set to a version number of the last migration done.
  migration: Signal<number>;

  // --- SIGNALS BELOW DO NOT SERIALIZE

  // Contains errors created during rendering or data fetching.
  error: ErrorSignal = signal();

  static fromData(d: ViewData) : View {
    const view = new View();
    let { module, tables, inlineTables, props, dim, pos, title, info, depId, style, migration, ...other } = d;

    // // Migrate if tables is null
    // // TODO: remove after migration completed
    // if(tables == null && table != null) {
    //   reportInfo('Migrating table');
    //   tables = [table];
    // }

    // // Upgrade props if they are not typed.
    // if(props != null && Object.keys(props).length > 0 && props[module] === undefined) {
    //   reportInfo('Upgrading view to 0.9.0 model');
    //   props[type] = { ...props };
    // }

    // // Upgrade DataTableV1 to DataGridV1
    // if(type === 'DataTableV1') {
    //   reportInfo('Upgrading DataTableV1 type to DataGridV1');
    //   type = 'DataGridV1';
      
    //   if(props != null && props['DataTableV1'] != null) {
    //     props['DataGridV1'] = props['DataTableV1'];
    //   }
    // }

    // Silent-upgrade the titles
    if(title != null && typeof title === 'string') {
      title = {
        logic: 'v1',
        dim: { h: 20 },
        text: title,
        visible: title.length > 0,
        custom: {
          text: title.length > 0,
        },
      }
    }

    view.dim = signal(dim);
    // view.style = signal({});
    view.pos = signal(pos);
    view.title = ObjectTitleHelpers.fromData(title);
    view.info = signal(info);
    view.module = signal(module);
    view.depId = signal(depId);
    view.migration = signal(migration);

    view.tables = signal(tables && tables.map(s => Table.fromData(s)) || null);
    view.inlineTables = signal(inlineTables && inlineTables.map(d => Table.fromData(d, view.tables)) || null);

    // View props
    // Migrate datasets
    if(props?.datasets) {
      for(let ds of props.datasets) {
        if(!tables?.find(td => td.props.ref === ds.table)) {
          const t = tables?.find(td => td.props.alias === ds.table);
          if(t) {
            ds.table = t.props.ref;
            reportInfo('Migrated view to new datastructure');
          }
        }
      }
    }
    // View props are special, their content is controlled by the views.
    // We pass null to indicate initialization to the module
    view.props = signal(props); 

    // Validate that we have not missed any keys
    for(let key of Object.keys(d)) {
      if(key !== 'table' && key !== 'id' && key !== 'title' && !isSignal(view[key])) {
        reportWarning(`Key: ${key} should be upgraded.`);
      }
    }

    return view;
  }

  getStorageModel() : ViewData {
    // We drop signals
    const { error, margin, style, inlineTables, ...other } = this;

    // We remove the deps from inline tables (since they are injected, that breaks the model)
    const inlineTablesNoDeps = inlineTables.v?.map(d => {
      const { deps, ...other } = getStorageModel(d);
      return other
    });

    return getStorageModel({
      ...other,
      inlineTables: inlineTablesNoDeps,
    });
  }

  getDeps() : Signal<ObjectInstance[]> {
    return this.tables;
  }

  hasDependency(commitHash: CommitHash, fullRef: string) : boolean {
    return this.tables.v?.findIndex(d => {
      return d.module?.v === TableModuleId.GraffeTableV1 && (d.props.v?.commit === commitHash || d.props.v?.ref === fullRef);
    }) >= 0;
  }

  // Add a dependency and return the alias (automatically generated if missing)
  addDependency(commitHash: CommitHash, fullRef: string, alias?: string) : string {
    if(!alias) {
      alias = fullRefToDefaultAlias(fullRef);
    }

    this.tables.v = [
      ...this.tables.v||[],
      Table.fromData({
        module: TableModuleId.GraffeTableV1,
        props: {
          alias,
          ref: fullRef,
          commit: commitHash,
        },
      })
    ];

    return alias;
  }
}

import { onEnter, onExit } from "ufti";

export function applyProductPageStyle(el: HTMLElement) {
  onEnter(el, () => {
    document.body.classList.remove('bg-white');
    document.body.classList.add('bg-page-body');

    onExit(el, () => {
      document.body.classList.remove('bg-page-body');
      document.body.classList.add('bg-white');
    });
  })
}
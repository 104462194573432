import { JSONObject } from "../types/json.js";

export type GuidanceIssue = 'UniverseSizeLimitReached';

// Guidance error props is agnostic to the implementation and uses simple functions as handlers.
export interface GuidanceErrorProps {
  // Which issue to handle. Frontend needs to implement this
  issue: GuidanceIssue;

  // Any arguments we should pass to this handler.
  args?: JSONObject;
}


// Allows to pass the original source error.
export class StatusError extends Error {
  public status: number;
  public sourceError: Error | any;
  
  constructor(status: number, message: string, sourceError?: Error | any) {
    super(message);
    this.name = 'StatusError';
    this.status = status;
    this.sourceError = sourceError;
  }
}

// Guidance errors require some user action or user flow to resolve the error.
// It's a dynamic type that can be thrown in the universe/id service and is reassembled as GuidanceError in the Application.
// Eg: User should increase their storage.
// The application can choose how to implement these flows, this is quite decoupled.
export class GuidanceError extends StatusError {
  public props: GuidanceErrorProps;
  constructor(status: number, message: string, guidance: GuidanceErrorProps, sourceError?: Error | any) {
    super(status, message, sourceError);
    this.name = 'GuidanceError'
    this.props = guidance;
  }
}

import { ViewModuleMetadata } from "../types";

export default function getMetadataMarkdownV1() : ViewModuleMetadata {
  return {
    module: 'MarkdownV1', 
    description: 'Render rich markdown', 
    displayName: 'Markdown', 
    onlyContent: true,
    attributions: [
      { label: 'Marked', url: 'https://marked.js.org/', spotlight: true },
      { label: 'Mustache', url: 'https://mustache.github.io/', spotlight: true },
    ],
    requiresInsider: true,
  }
}
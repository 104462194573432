import { state } from "../state";

function UnauthContent() {
  return <div class="container mx-auto prose-sm">
    <p>Welcome to the community edition of <a href="https://graffe.app" target="_blank">graffe.app</a>.</p>
  </div>;
  
}

export default function Landing() {
  state.topBar.visible.v = true;
  state.footer.visible.v = false;
  
  if(!state.user.v?.id) {
    return <UnauthContent />;
  }

  return <div class="container mx-auto prose-sm">
    <p>Welcome to the community edition of <a href="https://graffe.app" target="_blank">graffe.app</a>.</p>
    
    <div class="divider">graffe.app site content</div>

    {/* <About /> */}
    
    {/* <ConfigDump class="mt-[600px]"/> */}
  </div>;
}
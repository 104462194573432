import { unexpected } from "graffe-shared/src/lib/devflow";
import { UserProfile } from "graffe-shared/src/types/types";
import { isInsider } from "graffe-shared/src/models/insider";
import { state } from "../state";

export function sessionUserIsInsider() : boolean {
  return isInsider(state.user.v);
}

export function getMyTeamIdBySlug(slug: string) {
  if(slug === state.user.v?.slug) {
    return state.user.v.id;
  }
  const team = state.teams.v.find(t => t.team.slug === slug);
  if(team) {
    return team.team.id;
  }
}
export function formatTimeAgo(time: number) : string {
  const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'short' });
  const currentTime = Date.now();
  const diff = currentTime - time;
  const days = (diff < 0 ? 0 : diff/86400000);
  if(days < 1) {
    const hours = 24*days;
    if(hours < 1) {
      return 'in last hour';
    }
    return rtf1.format(-Math.abs(Math.round(hours)), 'hour');
  } else {
    return rtf1.format(-Math.abs(Math.round(days)), 'day');
  }
}
import { EncryptionType } from 'graffe-shared/src/models/encryption';
import { JSONObject, SettingDefinition, UserSettingData } from "graffe-shared/src/types/types";
import { sub } from 'ufti';
import { state } from "../state";
import { reportFailed } from "../types/notifications";
import { resolveCollectionProps, storeCollectionProps } from "./props";

export const enum SettingProps {
  BEAST_MODE = 'runtime.enableBeastMode',
}

export const settingDefinitions: SettingDefinition[] = [
  { 
    prop: SettingProps.BEAST_MODE, 
    type: 'boolean', 
    default: false, 
    label: 'Beast Mode', 
    description: 'Enable if you want advanced runtime information'
  }
];

export async function refreshUserSettings() {
  state.settings.v = keepOverrides(await resolveCollectionProps(state.user.v.id, 'settings') as UserSettingData[]);
}

export async function initializeUserSettings() {
  await refreshUserSettings();
  await watchSettingChanges();
}

export function getSetting(prop: SettingProps) : any {
  return state.settings.v[prop] ?? settingDefinitions.find(d => d.prop === prop).default ?? null;
}

// This works systemwide and has no cancellation handling
export function watchSettingChanges() {
  sub(state.settings, async settings => {
    if(!state.user.v) return;

    try {
      await storeCollectionProps(state.user.v.id, 'settings', keepOverrides(settings), EncryptionType.none);
    } catch (err) {
      reportFailed('Could not save settings', err);
      throw err;
    }
  },);
}

function keepOverrides(props: JSONObject) : JSONObject {
  const res = {};
  for(let defn of settingDefinitions) {
    const val = props && props[defn.prop];
    if(val != null && val !== defn.default) {
      res[defn.prop] = val;
    }
  }
  return res;
}
import stableStringify from 'fast-json-stable-stringify';
import { EncryptionType } from 'graffe-shared/src/models/encryption';
import { JSONValue } from "graffe-shared/src/types/types";
import { AclRule } from 'graffe-shared/src/universe/sharing';
import { getStorageModel } from "graffe-shared/src/universe/utils";
import store from "../idb/store";
import { appJson, credentialsMode } from "../lib/auth";
import { throwOnBadResponse } from '../lib/fetch';
import { state } from "../state";
import { reportFailed, reportSuccess } from "../types/notifications";

interface CollectionPropsResponse {
  data: string;
  changed: number;
}

export async function fetchCollectionProps(collection: string, prop: string) : CollectionPropsResponse {
  const req = await fetch(`${state.idService.v}/v1/collections/${collection}/props/${prop}`, {
    headers: appJson,
    ...credentialsMode,
  });

  // User has no settings yet
  if(req.status === 404) {
    return null
  }

  await throwOnBadResponse(req);
  
  // User has settings
  return req.json();
}

export async function putCollectionProps(collection: string, prop: string, data: string, enc: EncryptionType) {
  // Store remotely
  const req = await fetch(`${state.idService.v}/v1/collections/${collection}/props/${prop}`, {
    method: 'PATCH',
    headers: appJson,
    ...credentialsMode,
    body: JSON.stringify({ 
      data, 
      enc: 'none'
    }),
  });
}

let lastSavedPropData = {};
export async function resolveCollectionProps(collection: string, prop: string) : JSONValue {
  let remote, data;

  try {
    remote = await fetchCollectionProps(collection, prop);
  } catch(err) {
    reportFailed(err.message);
  }

  const local  = await (await store()).getKey(prop);

  if(remote && local) {
    if(local.time > remote.changed) {
      data = local.val;
    } else {
      await (await store()).putKey(prop, remote.data);
      data = remote.data;
    }
  } else if (remote) {
    await (await store()).putKey(prop, remote.data);
    data = remote.data;
  } else if (local) {
    data = local.val
  } else {
    // No settings data anywhere found, ignore
  }

  if(data) {
    lastSavedPropData[prop] = data;

    return JSON.parse(data);
  }
}

export async function storeCollectionProps(collection: string, prop: string, val: JSONValue, enc: EncryptionType) {
  // Serialize only the overrides
  const data = stableStringify(getStorageModel(val));

  // Compare if anything changed
  if(lastSavedPropData[prop] && lastSavedPropData[prop] === data) {
    return;
  }

  const orig = lastSavedPropData[prop];
  try {
    lastSavedPropData[prop] = data;

    // Cache locally
    await (await store()).putKey(prop, data);
    await putCollectionProps(collection, prop, data, enc);

    reportSuccess(`Saved ${prop}. Might require reload to apply.`);
  } catch(err) {
    lastSavedPropData[prop] = orig;
    throw err;
  }
}

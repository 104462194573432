
// Eg: hardcore motherfucker and rewards: access to codebase, possibility to earn on Graffe, bug prioritization, shape influence roadmap, join weekly planning meetings, sponsor other accounts, ...

import { SponsorTier } from "./types.js";

export const staticTiers: SponsorTier[] = [
  {
    amount: 9,
    name: 'Basic Supporter',
    hint: 'You are using Graffe for your personal data',
    description: "",
    rewards: [
      "Use all Sponsor-Locked features on your objects",
    ]
  },
  {
    amount: 15,
    name: 'Advanced Insider',
    hint: 'You are sharing objects with somebody in your family or friends, or you are a solo founder starting a new company and want to use all features',
    rewards: [
      "Use all Sponsor-Locked features on your objects",
      "Access to the planning calls",
    ]
  },
  {
    amount: 25,
    name: 'Graffe Advocate',
    hint: 'For the individual contributor that wants to see a better open data world and wants to push Graffe foward.',
    rewards: [
      "Use all Sponsor-Locked features on your objects",
      "Access to the planning calls",
    ]
  },
  {
    amount: 50,
    name: 'Bronze Sponsor (startup)',
    hint: 'You use Graffe in a startup with 1-10 employees which is still raising money.',
    rewards: [
      "Use all Sponsor-Locked features on your objects",
      "Bronze sponsor status",
      "Shape the roadmap",
      "Access to the planning calls"
    ]
  },
  {
    amount: 250,
    name: 'Silver Sponsor (small company)',
    hint: 'You use Graffe in a company with 10-50 employees.',
    rewards: [
      "Up to 1 hour of priority support",
      "Silver sponsor status + link",
      "Use all Sponsor-Locked features on your objects",
      "Shape the roadmap",
      "Access to the planning calls"
    ]
  },
  {
    amount: 500,
    name: 'Gold Sponsor (large corp)',
    hint: 'You use Graffe in a company with 50+ employees.',
    rewards: [
      "Bugfix priority",
      "Up to 2 hours of priority support",
      "Gold sponsor status + link",
      "Use all Sponsor-Locked features on your objects",
      "Shape the roadmap",
      "Access to the planning calls"
    ]
  },
]
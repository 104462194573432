import * as Sentry from "@sentry/browser";
import CryptoJS from 'crypto-js';
import { InsiderSponsorship, ProtectedUserProfile, TeamRole } from 'graffe-shared/src/types/types';
import { initializeUserSettings } from '../models/settings';
import { state } from '../state';
import { reportFailed } from '../types/notifications';
import { throwOnBadResponse } from './fetch';


export function makePassHash(password: string, email: string) : string {
  const hash = CryptoJS.PBKDF2(CryptoJS.enc.Utf8.parse(password), CryptoJS.enc.Utf8.parse(email.toLowerCase().trim()), {
    hasher: CryptoJS.algo.SHA256,
    keySize: 256 / 32,
    iterations: 10000,
  });

  return CryptoJS.enc.Base64.stringify(hash);
}

export const appJson = {
  'content-type': 'application/json'
}

export const credentialsMode = {
  credentials: 'include', // otherwise no session cookie..
  mode: 'cors', // (process.env.NODE_ENV === 'production' ? 'same-origin' : 'cors'), // no-cors makes application/json impossible
}

function setSessionState(user: ProtectedUserProfile, slug: string, teams: TeamRole[], insider: InsiderSponsorship) {
  state.user.v = { 
    ...user,
    slug: (slug || null),
    insider,
  }; // state.user is the field to watch to be notified of fully authenticated users
  state.teams.v = teams;
}

export async function doSignOut() {
  // Sign off remote
  const req = await fetch(`${state.idService.v}/v1/logout`, { 
    method: 'POST', 
    headers: appJson, 
    ...credentialsMode
  })
  await throwOnBadResponse(req);

  // Clear local state
  state.user.v = null;
  state.settings.v = null;

  // Kill session cache
  _loadSessionProm = null;
}

export async function doSignIn(email: string, password: string) {
  if(!email || !password) return reportFailed('Fill in email and strong password');

  const passHash = makePassHash(password, email);
  const req = await fetch(`${state.idService.v}/v1/login/local`, {
    method: 'POST',
    headers: appJson,
    body: JSON.stringify({
      email,
      passHash,
    }),
    ...credentialsMode,
  });
  const res = await req.json();

  await reloadSession();
}

async function reloadSessionOnce() {
  const { user, slug, teams, insider } = await getSession();
  if(user) {
    const { id, avatar, invited, email, ...otherProps } = user;
    
    // Set once the google props when we don't have a slug yet to help the user
    if(!slug || slug.length == '') {
      state.onboarding.v = otherProps;
    }

    setSessionState({ id, avatar, invited, email }, slug, teams, insider);
    await postAuthActions();
  }
}

// Singleton promise which, if set, guarantees session has been requested.
let _loadSessionProm;

export async function reloadSession() {
  _loadSessionProm = reloadSessionOnce();
  return _loadSessionProm;
}

export async function ensureSession() {
  if(!_loadSessionProm) {
    reloadSession();
  }

  return _loadSessionProm;
}

export async function postAuthActions() {
  await initializeUserSettings();
  updateLoggingUser();
}

export function updateLoggingUser() {
  // Non-blocking - add user to sentry for logging
  try {
    Sentry.setUser({ 
      id: state.user.v?.id, 
      username: state.user.v?.slug,
    });
  } catch (err) {
    console.warn(err);
  }
}

export async function getSession() {
  const req = await fetch(`${state.idService.v}/v1/session`, {
    headers: appJson,
    ...credentialsMode,
  });
  
  return await req.json();
}

// Super lightweight mobile device detector
export function isMobile() {
  return Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") >= 0;
}

export function getSearchParams() : URLSearchParams {
  const search = window.location.search && window.location.search.substring(1) || undefined;
  return new URLSearchParams(search);
}

// Tip: this url-encodes all values, so the rison structures cannot be set by this.
// nully values indicate removal of the key
// DEPRECATED
export function setSearchParams(props) {
  const curr = getSearchParams();
  for(let [key, val] of Object.entries(props)) {
    if(val != null) {
      curr.set(key, val);
    } else {
      curr.delete(key);
    }
  }
  history.pushState({}, null, toStableParamUrl(curr));
}

// Create a stable start string that matches with our updatedUrl structure (because link annotations from GA might make this unstable)
// Stable param URL which sorts the filters at the front
// DEPRECATED
function toStableParamUrl(params: URLSearchParams) : string {
  const clone = new URLSearchParams(params);
  // Pull out the url filters
  const urlFilters = clone.get('f');
  clone.delete('f');

  // Make the stable URL structure
  const parts = [];
  if(urlFilters) parts.push(`f=${urlFilters}`); // Filters first
  parts.push(clone.toString()); // Then the rest

  return partsToUrl(parts);
}

// DEPRECATED
function partsToUrl(parts: string[]) : string {
  // Make a full URL which can be navigated to
  const queryStr = parts.filter(d => d && d.length > 0).join('&');
  const sign = (queryStr.length > 0 ? '?' : '');
  const url = `${location.pathname}${sign}${queryStr}`;

  return url;
}

export function isIFramedLoad() : boolean {
  return window.top !== window.self;
}
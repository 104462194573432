import { onEnter } from "ufti";
import { head } from "ufti/src/plugin/head";
import { Link } from "ufti/src/plugin/router";
import { state } from "../../state";
import { NotFoundError } from "./errors";
import { noIndexRobots } from "../lib/seo";

export default function NotFound({ err } : { err?: NotFoundError }) {
  const el = <div class="flex flex-col gap-4 items-center text-zinc-600 text-base">
    <h1 class="font-semibold text-xl mt-12">404: Not Found</h1>

    {/* If all goes well, then this object has never existed. */}

    <h2 class="">We're very sorry, this page does not exist.</h2>

    <Link href={state.user.v?.id ? '/about' : '/'} class="hover:underline mt-8">Go to graffe.app</Link>
    
    {/* <p>
      Why not create it?
    </p> */}

  </div>;

  onEnter(el, () => {
    head.meta.v = {
      ...head.meta.v,
      robots: noIndexRobots,
    }
  });

  return el;
}

import { clear, setChildren, signal, sub } from 'ufti';
import { head } from "ufti/src/plugin/head";
import { Action } from '../../lib/button';
import { state } from "../../state";
import { showSignInModal } from '../session/SignIn';
import LogoMenu from "./LogoMenu";
import { ProfileMenuButtonSession } from './ProfileMenu';
import { tm } from '../../lib/tailwind';

export const topBarHeight = 44;

/**
 * Should be toggled to flex to render correctly
 */
export default function TopBar({ class: klass }) {
  // Keep height in sync with topBarHeight!
  // Removed a strange !h-3 class here...
  const el: HTMLDivElement = <header class={`fixed hidden h-[44px] top-0 left-0 right-0 z-[9000] bg-white border-b-[1px] border-b-zinc-200 ${klass||''}`} />;
  
  sub([state.user, signal(1)], () => {
    if(state.user.v?.id != null) {
      setChildren(el, <TopBarSession />);
    } else {
      setChildren(el, <TopBarNoSession />);
    }
  }, el)
  
  return el;
}

function TopBarNoSession() {
  return <>
    <div class="h-[44px] flex flex-row items-center justify-center z-[9001]">
      <LogoMenu />
    </div>

    {/* Middle content (renders potentially absolute) */}
    <BarTitleContent class="flex flex-1 lg:absolute lg:left-0 lg:right-0 lg:min-h-[44px] z-[9000] mr-[58px] lg:mr-0" />
    
    {/* Right side content */}
    <div class="flex absolute top-0 right-0 min-h-[44px] z-[9001] items-center pr-2">
      <ControlsContentTop />

      {/* <Action class="text-xs font-medium text-indigo-800 px-3" onclick={() => showSignInModal()}>Sign in</Action> */}
      <Action class="text-xs font-medium text-indigo-800 px-3" onclick={() => showSignInModal()}>Sign in</Action>
    </div>
  </>;
}

// Top bar is rendered when the user is logged in.
function TopBarSession() {
  // Keep height in sync with topBarHeight!
  // Removed a strange !h-3 class here...
  return <>
    <div class="h-[44px] flex flex-row items-center justify-center z-[9001]">
      <LogoMenu />

      <LeftContent />
    </div>

    {/* Middle content (renders potentially absolute). Right margin kicks in on mobile views to center the content. */}
    <BarTitleContent class="flex flex-1 lg:absolute lg:left-0 lg:right-0 lg:min-h-[44px] z-[9000] mr-[58px] lg:mr-0" />

    {/* Right side content*/}
    <div class="flex absolute top-0 right-0 pr-2 min-h-[44px] z-[9001]">
      <ControlsContentTop />
      
      <ProfileMenuButtonSession />
    </div>
  </>;
}

function BarTitleContent({ class: klass } : { class: string }) {
  const el = <div class={`justify-center items-center ${klass||''}`}></div>;

  sub([state.topBar.centerOutlet, head.title.main], (content, title) => {
    // When no object, we set a title based on the HTML title.
    if(!content) {
      if(!title) return clear(el);
      return setChildren(el, <h1 class="text-sm font-normal">{title}</h1>)
    }

    setChildren(el, content);
  }, el);

  return el;
}

function ControlsContentTop({ class: klass }) {
  const el = <div class={`hidden sm:flex items-center ${klass||''}`}></div>;

  sub(state.topBar.controlsOutletTop, controls => {
    if(controls.length == 0) {
      return clear(el);
    }

    setChildren(el, <>{controls}</>);
  }, el);

  return el;
}

function LeftContent() {
  const el = <div class=""></div>;

  sub(state.topBar.leftOutlet, content => {
    // When no object, we set a title based on the HTML title.
    if(!content) return clear(el);

    setChildren(el, content);
  }, el);

  return el;
}

export function appendTopBarControl(control: HTMLElement) {
  if(state.topBar.controlsOutletTop.v.find(d => d === control)) return;
  state.topBar.controlsOutletTop.v = [
    ...state.topBar.controlsOutletTop.v,
    control,
  ];
}

export function removeTopBarControl(control: HTMLElement) {
  if(!state.topBar.controlsOutletTop.v.find(d => d === control)) return;
  state.topBar.controlsOutletTop.v = state.topBar.controlsOutletTop.v.filter(d => d !== control);
}

// {/* Left side content ABSOLUTE */}
// <div class="absolute top-0 left-0 flex h-[44px] items-center justify-center pl-2">
//   <div class="group" n="mobile-menu">
//     <div tabindex="0" class="btn rounded-none text-white">
//       <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
//     </div>
//     <div class="hidden absolute left-0 top-0 pt-12 group-focus-within:block group-hover:block hover:block cursor-pointer">
//       <ul tabindex="0" class="w-52 bg-slate-800 text-slate-200 z-100 text-sm">
//         {/* <li class="p-1"><Link href='/login'>Sign in</Link></li> */}
//         <li class="px-4 py-2 hover:bg-slate-700"><a class="block" onclick={() => todo('refactored')}>New Dashboard</a></li>
//         {/* <li><a href={`/explorer`}>Explorer</a></li> */}
//         <li class="px-4 py-2 hover:bg-slate-700"><Link class="block" href={'/'}>Graffe</Link></li> 
//         <li class="px-4 py-2 hover:bg-slate-700"><Link class="block" href={`/changelog`}>Changelog</Link></li>
//       </ul>
//     </div>
//   </div>
//   {logo = <Link href='/' class="absolute left-16 top-0 z-[9999] hidden text-zinc-800 font-extrabold font-sans text-[1.728rem] mb-1" tabindex="-1">graffe</Link>}
//
//   <Portal />
// </div>
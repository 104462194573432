import stableStringify from 'fast-json-stable-stringify';
import { primitiveValue } from "graffe-shared/src/universe/utils";
import { sub } from 'ufti';
import { router } from 'ufti/src/plugin/router';
import urlState from "../types/urlState";
import { FilterCtx, getFilterCtxs } from "./filterCtx";
import { ValueFilter } from "./filters";

function applyUrlFilters(el: Element) : FilterCtx[] {
  // Collect all filter contexts, bottom-up
  const ctxs = getFilterCtxs(el);

  // Assign the filters to the top-level filter ctx
  const ahFilters = ctxs.find(c => c.level === 'adhoc');

  // Create the new filters
  const newFilters = urlState.filters.v?.
    map(d => ValueFilter.fromUrlModel(d)) || [];

  // If the filters have changed, we apply.
  if(stableStringify(primitiveValue(ahFilters.filters.v)) !== stableStringify(primitiveValue(newFilters))) {
    ahFilters.filters.v = newFilters;
  }

  return ctxs;
}

// Initalize and sync filters from the URL to the ah filters.
// Sync is done by watching the signals.
function syncFiltersToUrl(el: Element) {
  // Sync once the URL filters
  const ctxs = applyUrlFilters(el);

  // Subscribe to ah filter ctx for changes
  const ahFilters = ctxs.find(c => c.level === 'adhoc');

  sub(ahFilters.filters, () => {
    const risonModel = ahFilters.filters.v.map(f => f.toUrlModel());
    urlState.filters.v = risonModel;
  }, el);
}

// Called at app initialization time. A single watcher manages the URL filter state at load time.
export function setupAdHocFilterSync(el) {
  // This initializes and keeps on syncing filters signal TO the url
  syncFiltersToUrl(el);

  // This keeps on syncing url changes to the filters
  sub(router.search, () => applyUrlFilters(el), el);
}

// setTimeout(() => {
//   const modified = ahFilters.filters.v[0];
//   modified.condition.v = 'neq';
//   ahFilters.filters.v = [modified];
// }, 5000);
// http://localhost:4000/?s=filters:!((f:test,c:eq,v:!(hello)))
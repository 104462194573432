import dayjs from "dayjs";
import { setChildren } from "ufti";
import { Link } from "ufti/src/plugin/router";
import { OutlineIcon } from "../../../lib/icons";
import { formatTimeAgo } from "../../../lib/time";
import { tm } from "../../../lib/tailwind";

const items = [
  { time: dayjs('2023-09-19T14:02:00').valueOf(), title: 'Many fixes have landed for studio, sponsorships, better SEO, ...',  version: '0.648.0' },
  { time: dayjs('2023-09-14T17:58:00').valueOf(), title: 'Refactor with Insider sponsorships and much more.',  version: '0.647.0' },
  { time: dayjs('2023-08-05T23:45:00').valueOf(), title: 'Product pages. Add trending components. Bugfixes.',  version: '0.646.0' },
  { time: dayjs('2023-08-04T13:37:00').valueOf(), title: 'Bugfixes and improvements.',  version: '0.645.7' },
  { time: dayjs('2023-08-03T17:52:00').valueOf(), title: 'Mobile support. Product pages updated. Bugfixes for Android and Windows.',  version: '0.645.6' },
  { time: dayjs('2023-08-02T18:10:00').valueOf(), title: 'New video on About page.',  version: '0.644.0' },
  { time: dayjs('2023-08-02T14:30:00').valueOf(), title: 'Pricing model changed. Bugfixes.',  version: '0.644.0' },
  { time: dayjs('2023-08-02T08:45:00').valueOf(), title: 'Support for NULL filtering added.',  version: '0.643.0' },
  { time: dayjs('2023-08-01T18:09:00').valueOf(), title: 'Cosmetic UX improvements, guidance and banners added.',  version: '0.642.0' },
  { time: dayjs('2023-08-01T12:09:00').valueOf(), title: 'Mobile rendering optimizations.',  version: '0.641.2' },
  { time: dayjs('2023-07-31T18:50:00').valueOf(), title: 'Viewer object/folder/browsing rendering strategy rewritten.',  version: '0.641.0' },
  { time: dayjs('2023-07-30T12:00:00').valueOf(), title: 'Bugfix in data parsing. Improvements to variables.',  version: '0.640.0' },
  { time: dayjs('2023-07-29T13:00:00').valueOf(), title: 'Bugfixes and improvements.',  version: '0.639.1' },
  { time: dayjs('2023-07-28T16:00:00').valueOf(), title: 'GDPR-compliant, privacy protecting app behavior tracking.',  version: '0.639.0' },
  { time: dayjs('2023-07-27T12:26:00').valueOf(), title: 'Bugfixes. Cleanup. Removed default filters.',  version: '0.638.0' },
  { time: dayjs('2023-07-26T17:03:00').valueOf(), title: 'SEO and cosmestic improvements. Bugfixes.',  version: '0.637.1' },
  { time: dayjs('2023-07-26T11:03:00').valueOf(), title: 'Cosmestic and performance improvements.',  version: '0.637.0' },
  { time: dayjs('2023-07-25T14:45:00').valueOf(), title: 'Query using SQL action added for all tables.',  version: '0.636.0' },
  { time: dayjs('2023-07-25T12:30:00').valueOf(), title: 'SQL filter values default functionality.',  version: '0.635.0' },
  { time: dayjs('2023-07-25T11:00:00').valueOf(), title: 'Product pages SEO optimizations.',  version: '0.634.2' },
  { time: dayjs('2023-07-24T23:48:00').valueOf(), title: 'Bugfixes and improvements.',  version: '0.634.1' },
  { time: dayjs('2023-07-24T17:00:00').valueOf(), title: 'Opensource attributions for the modules',  version: '0.634.0' },
  { time: dayjs('2023-07-24T12:44:00').valueOf(), title: 'Wider error capturing.',  version: '0.633.0' },
  { time: dayjs('2023-07-24T10:19:00').valueOf(), title: 'Better UX changes list & tips. Code improvements.',  version: '0.632.2' },
  { time: dayjs('2023-07-24T00:03:00').valueOf(), title: 'Bugfixes. Code cleaning.',  version: '0.632.1' },
  { time: dayjs('2023-07-23T18:29:00').valueOf(), title: 'Embedded view crashing resolved. Bugfixes. Better logging. Permission hints.',  version: '0.632.0' },
  { time: dayjs('2023-07-23T17:00:00').valueOf(), title: 'Bugfixes. Improved filter behavior.',  version: '0.631.2' },
  { time: dayjs('2023-07-23T11:55:00').valueOf(), title: 'Improve transformation flow.',  version: '0.631.1' },
  { time: dayjs('2023-07-22T16:54:00').valueOf(), title: 'Security improvements landing during day',  version: '0.630.0' },
  { time: dayjs('2023-07-21T16:47:00').valueOf(), title: 'User guidance tips are starting to show...',  version: '0.629.0' },
  { time: dayjs('2023-07-21T12:30:00').valueOf(), title: 'Better embed experience for artists. Internal improvement (node provider streams).',  version: '0.628.0' },
  { time: dayjs('2023-07-20T20:14:00').valueOf(), title: 'Object positions are now delegated to the modules. Bugfixes. Code cleaning. Cosmetic improvements.',  version: '0.627.0' },
  { time: dayjs('2023-07-20T13:00:00').valueOf(), title: 'Object dimensions are now a module responsability + fixes',  version: '0.626.0' },
  { time: dayjs('2023-07-19T15:37:00').valueOf(), title: 'Six usability bugfixes',  version: '0.625.6' },
  { time: dayjs('2023-07-18T19:00:00').valueOf(), title: 'App+marketing page flows restructured',  version: '0.625.0' },
  { time: dayjs('2023-07-18T12:00:00').valueOf(), title: 'Users arrival flow via objects routing',  version: '0.624.0' },
  { time: dayjs('2023-07-17T15:00:00').valueOf(), title: 'Viewer rendering safari crash fixed', /* link: '/@graffe/updates/bugfix/safari-crashes-fixed', */ version: '0.623.1' },
]

export default function GraffeChanges({ class: klass }) {
  let itemsDiv;

  const el = <div class={tm('flex flex-col', klass)}>
    <div class="font-semibold">Graffe Changes</div>

    <div class="text-xs text-zinc-500 mt-2">Updates on new features, functionality and bugfixes on graffe.app</div>

    {itemsDiv = <div class="flex-1 mt-4 flex flex-col gap-2 text-xs overflow-y-auto"></div>}
  </div>;

  setChildren(itemsDiv, items.map(i => <Item item={i} />));

  return el;
}

// TODO: resolve user avatars.
function Item({ item } : { item: { title: string, time: number, link?: string, } }) {
  const el = <div class="flex gap-2">
    <div class="w-4 mr-2">
      <OutlineIcon icon="sun" class="w-4 h-4" />
    </div>

    <div class="flex gap-1 flex-col w-full">
      <div class="flex">
        <div class="text-zinc-500 flex-1">
          {formatTimeAgo(item.time)}
        </div>

        <div class="text-xxs text-zinc-600">v{item.version}</div>
      </div>

      {item.link ? (
        <Link href={item.link} class="hover:underline">
          <div class="font-semibold">{item.title}</div>
        </Link>
      ) : (
        <div class="font-semibold">{item.title}</div>
      )}
    </div>
  </div>;

  return el;
}
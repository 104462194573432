.popperArrow,
.popperArrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.popperArrow {
  visibility: hidden;
}

.popperArrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.popperTip[data-popper-placement^='top'] > .popperArrow {
  bottom: -4px;
}

.popperTip[data-popper-placement^='bottom'] > .popperArrow {
  top: -4px;
}

.popperTip[data-popper-placement^='left'] > .popperArrow {
  right: -4px;
}

.popperTip[data-popper-placement^='right'] > .popperArrow {
  left: -4px;
}
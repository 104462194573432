export const missionStatement = 'Our mission is to build an efficient, trustworthy, collaborative data system for all of humanity. Accessible by anyone. Forever.';

export default function Mission({ class: klass, ...props }, content) {
  const el = <div class={`rounded-[24px] bg-white p-[5px] ${klass||''}`} {...props}>
    <div class="rounded-[24px] bg-[#F1F1F4] overflow-hidden h-full py-[51px]">
      <div class="w-full max-w-[880px] mx-auto flex flex-col gap-4 items-center justify-center px-7">
        <div class="text-indigo-400 font-semibold text-xl lg:text-2xl">Mission</div>

        <h2 class="font-display text-zinc-900 font-semibold text-[24px] leading-[32px] lg:text-[30px] lg:leading-[40px] text-center">{content}</h2>
      </div>

      {/* <div class="w-full flex justify-center items-center mt-8">
        <Link href="/invite" class="bg-zinc-900 rounded text-white py-[10px] px-5">Are you on board?</Link>
      </div> */}
    </div>
  </div>; 

  return el;
}
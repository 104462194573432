import { Schema } from "@cfworker/json-schema";

// Logical data types is what we use throughout the system.
// 
// A logical type represents either a low-level type or a higher level type.
// Every logical type has a storage type, which is the value by which it gets serialized in the arrow tables. For this we use mappings.
//
// They can be higher level like "Utf8" or super-detailed "TSMillis".
// The logical type interface is a light layer on top of an arrow data Field. When a data field is missing, type can be set.

// The logical types
export const enum LogicalType {
  // When no type found, if None exists, the is required to type the data
  "None" = "None",

  // Primitive types
  "Int8" = "Int8",
  "Int16" = "Int16",
  "Int32" = "Int32",
  "Int64" = "Int64",
  "UInt8" = "UInt8",
  "UInt16" = "UInt16",
  "UInt32" = "UInt32",
  "UInt64" = "UInt64",
  "Int" = "Int",

  "Decimal" = "Decimal",

  "Float32" = "Float32",
  "Float64" = "Float64",
  "Float" = "Float",

  // TODO: add user-friendly aliases for Utf8?
  "Utf8" = "Utf8",

  "Bool" = "Bool",

  // Logical types
  "JsonArray" = "JsonArray",
  "JsonObject" = "JsonObject",

  // Micro-second precision
  "Timestamp" = "Timestamp",

  // Time since midnight
  "Time" = "Time",

  // Date full days since epoch
  "Date" = "Date",
}

export const LogicalTypeSchema: Schema = {
  $id: "/schemas/LogicalType",

  "enum": [
      "Bool",
      "Decimal",
      "Float",
      "Float32",
      "Float64",
      "Int",
      "Int16",
      "Int32",
      "Int64",
      "Int8",
      "JsonArray",
      "JsonObject",
      "None",
      "Timestamp",
      "UInt16",
      "UInt32",
      "UInt64",
      "UInt8",
      "Utf8"
  ],
  "type": "string"
}

export interface DataFieldData {
  // Data type of the field
  name: string;
  
  // Data type
  type: LogicalType;

  // More info
  info: LogicalType;
}

// Null values here mean that the value _carries over_ from the inferred field
export interface DataFieldOverrideData {
  // Name of the field in the data
  name: string;

  // Data type override
  type?: LogicalType;

  // Renamed field
  alias?: string;

  // Drop indicates we should drop the field in the output
  drop?: boolean;

  // More info on the specific field
  info?: string;
}

export const DataFieldDataSchema: Schema = {
  $id: "/schemas/DataFieldData",

  properties: {
    alias: {
      type: "string",
    },
    drop: {
      type: "boolean",
    },
    horder: {
      type: "number",
    },
    htype: {
      $ref: "/schemas/LogicalType",
    },
    iorder: {
      type: "number",
    },
    itype: {
      $ref: "/schemas/LogicalType",
    },
    name: {
      type: "string",
    },
  },
  type: "object",
  required: ["name", "itype", "iorder"],
};
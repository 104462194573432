import getMetadataChartV1 from './ChartV1/metadata';
import getMetadataDataGridV1 from './DataGridV1/metadata';
import getMetadataGeomapV1 from './GeomapV1/metadata';
import getMetadataMarkdownV1 from './MarkdownV1/metadata';
import getMetadataDashTitleV1 from './inline/DashTitleV1/metadata';
import getMetadataInfoModalButtonV1 from './inline/InfoModalButtonV1/metadata';
import getMetadataInfoV1 from './inline/InfoV1/metadata';
import getMetadataRefreshTimeV1 from './inline/RefreshTimeV1/metadata';
import getMetadataTextV1 from './inline/TextV1/metadata';
import { ViewModuleMetadata } from './types';

// TODO: refactor -> all views should be classes which can be instantiated so they can contain runtime context.
export const viewModules = {
  // -- Internal view type for rendering Graffe views

  GraffeViewV1: _ => import('./GraffeViewV1'),

  // -- The end-user view types

  DataGridV1: _ => import('./DataGridV1/DataGridV1'),
  ChartV1: _ => import('./ChartV1/ChartV1'),
  MarkdownV1: _ => import('./MarkdownV1/MarkdownV1'),

  // LabelV1: _ => import('./LabelV1.tsx'),
  DebugV1: _ => import('./DebugV1'),
  GeomapV1: _ => import('./GeomapV1/GeomapV1'),

  // -- Inline views

  DashTitleV1: _ => import('./inline/DashTitleV1/DashTitleV1'),
  InfoV1: _ => import('./inline/InfoV1/InfoV1'),
  InfoModalButtonV1: _ => import('./inline/InfoModalButtonV1/InfoModalButtonV1'),
  TextV1: _ => import('./inline/TextV1/TextV1'),
  RefreshTimeV1: _ => import('./inline/RefreshTimeV1/RefreshTimeV1'),
}

// Simple views to 
export const basicViewLibrary: ViewModuleMetadata[] = [
  getMetadataChartV1(),
  getMetadataDataGridV1(),
  getMetadataMarkdownV1(),
  // getMetadataGeomapV1(), // Temporary disabled.
]

// The inline views in dropdown order
export const inlineViews: ViewModuleMetadata[] = [
  getMetadataDashTitleV1(),
  getMetadataTextV1(),
  getMetadataInfoV1(),
  getMetadataInfoModalButtonV1(),
  getMetadataRefreshTimeV1(),
]


// interface ViewLibraryItem {
//   module: ViewModuleId;

//   description: string;

//   // Optional override
//   displayName?: string;

//   // True when we don't need to render the title.
//   // TODO: think this is now deprecated and controllable.
//   onlyContent: boolean;

//   // True when view is phased out
//   deprecated?: boolean;

//   // Used for rendering the PRO labels, to avoid loading the modules.
//   requiresInsider?: boolean;

//   // User-flow hooks where customized (blocking) logic can execute.
//   hooks?: ModuleFlowHook[];
// }

// { module: 'ChartV1', description: 'Make bar/line/pie/... charts (using chartjs)', displayName: 'Chart' },
  // { module: 'DataGridV1', description: 'A simple table grid which remains performant with larger data sets', displayName: 'Data grid' },
  // // { module: 'DebugV1', description: 'Debug view to render JSON data', displayName: 'Debug JSON' }, 
  // // { module: 'LabelV1', description: 'A stylable label', displayName: 'Label', onlyContent: true }, 
  // { module: 'MarkdownV1', description: 'Render rich markdown', displayName: 'Markdown', onlyContent: true },
  // { module: 'GeomapV1', description: 'Geomap (using mapbox)', displayName: 'Geomap (dev)' }, 

// // Modules which allow importing other views
// export const universeViewLibrary: ViewLibraryItem[] = [
//   { module: 'GraffeViewV1', description: 'A single view', displayName: 'View' },
// ]

// export const viewLibrary: ViewLibraryItem[] = [
//   ...basicViewLibrary,
//   ...universeViewLibrary,
// ];
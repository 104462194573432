import { GuidanceError, GuidanceIssue } from "graffe-shared/src/lib/error";
import { trimEnd } from "lodash-es";
import { navigate } from "ufti/src/plugin/router";

function guideToUpgrade(err: GuidanceError) {
  if(confirm(`${trimEnd(err.message, '. ')}. Shall we go to the pricing page?`)) {
    navigate('/pricing');
  }
}

const issues: Record<GuidanceIssue, { handler: (err: GuidanceError) => void }> = {
  'UniverseSizeLimitReached': {
    handler: guideToUpgrade,
  }
}

// It's up to the caller to throw errors, handlers only sugar on top.
export function handleGuidanceError(err: GuidanceError) {
  const config = issues[err.props.issue];
  if(config) {
    // Delegate to handler
    config.handler(err);
  }
}
import { onEnter, replace } from 'ufti';
import store from '../../idb/store';
import { ensureSession } from '../../lib/auth';
import { logError, wrapAsync } from '../../lib/logger';
import { validateEnvironment } from '../../lib/validation';
import AppGraffe from './AppGraffe';

export default function AppLoader() {
  // Initialize application
  validateEnvironment();

  // Warn: loading placeholder - !!gets replaced with the appContent!!
  const el: HTMLDivElement = <div />;

  onEnter(el, wrapAsync(async () => {
    // Wait for fonts to be loaded
    if(document.fonts.ready) {
      await document.fonts.ready;
    }

    // Swap body bg color
    document.body.classList.remove('bg-zinc-100');
    document.body.classList.add('bg-white');

    // Swap in the application when everything is ready
    replace(el, <AppGraffe />);

    // Remove the preloader
    document.getElementById('preLoader')?.parentElement?.removeChild(document.getElementById('preLoader'));

    // Force session creation
    wrapAsync(store)();

    // We want to handle getSession gracefully
    ensureSession()
      .catch(err => {
        if(err?.message.indexOf('Failed to fetch') >= 0) {
          return console.error(err);
        }
        logError(err);
      })
  }));

  return el;
}
// The gateway orbits the local storage and has access to the outer universe.
// Through it, it can resolve any object in the universe.
// 
// Resolving = try hard and search local or fallback to remote.
// Get = get from a specific source

import { unexpected, unsupported } from "graffe-shared/src/lib/devflow";
import { GuidanceError } from "graffe-shared/src/lib/error";
import { Thing } from "graffe-shared/src/models/nodes";
import { CommitHash, RefHash } from "graffe-shared/src/types/types";
import { Commit } from "graffe-shared/src/universe/commit";
import { UniverseCommitData, UniverseCommitLogResponse } from "graffe-shared/src/universe/remote";
import { getStorageModel } from "graffe-shared/src/universe/utils";
import { last, sortBy } from 'lodash-es';
import { sub } from "ufti";
import { getAuthReqProps } from "../id/helpers";
import { getUniverseToken } from "../id/universeTokens";
import store from "../idb/store";
import { appJson } from "../lib/auth";
import { isProduction } from "../lib/dev";
import { throwOnBadResponse } from "../lib/fetch";
import { logError } from "../lib/logger";
import { state } from "../state";
import { reportSuccess, reportWarning } from "../types/notifications";
import { handleGuidanceError } from "./guidance";

// All storage happens at the commit level.
export async function resolveCommit(hash: CommitHash) : Promise<Commit> {
  let commit: Commit;

  // See if we have the commit locally
  commit = await (await store()).getCommit(hash);
  if(commit) return commit;

  // See if we can find it remotely
  commit = await getCommitFromUniverse(hash);
  if(commit) {
    await (await store()).cacheCommit(commit);
    return commit;
  };

  // -- DEV FLOW
  if(!isProduction()) {
    commit = await getFromDevStore(hash);
    if(commit) {
      await (await store()).putCommit(commit);
      return commit;
    }
  }
  // -- DEV FLOW

  return null;
}

export function lastCommit(rows: Commit[]) {
  const sorted = sortBy(rows, d => d.commit.time[0]);

  return last(sorted);
}

export async function resolveHeadCommitByRefHash(thing: Thing, refHash: RefHash, universeShouldSucceed?: boolean) : Promise<Commit> {
  const commits: Commit[] = [];

  // Fetch from universe - which is allowed to fail
  try {
    const remoteCommit = await getHeadCommitFromUniverseByRefHash(thing, refHash);
    if(remoteCommit) {
      commits.push(remoteCommit);
      if(!(await (await store()).getCommitRow(remoteCommit.hash))) {
        await (await store()).cacheCommit(remoteCommit); // Cache it, if we don't already have it
      }
    }
  } catch(err) {
    logError(err);
    if(universeShouldSucceed) {
      throw err;
    }
  }

  // Fetch from local commits
  const localCommit = await (await store()).getHead(thing, refHash);
  if(localCommit) {
    commits.push(localCommit);
  }

  // --- DEV FLOW
  if(!isProduction()) {
    if(commits.length == 0 && thing === Thing.Object) {
      const devCommit = await getFromDevStore(refHash);
      if(devCommit) {
        await (await store()).putCommit(devCommit);
        commits.push(devCommit);
      }
    }
  }
  // --- DEV FLOW END

  if(commits.length > 0) {
    return lastCommit(commits);
  }

  return null;
}

export async function postCommit(commit: Commit) {
  // TODO: get short lived token from session
  if(!commit) {
    unexpected('commit missing');
  }

  const req = await fetch(`${state.universe.gateway.v}/v1/commits`, {
    method: 'POST',
    headers: {
      ...appJson,
      authorization: await getUniverseToken(),
    },
    body: JSON.stringify(
      getStorageModel(commit)
    ),
  });
  
  await throwOnBadResponse(req, { onError: () => console.error(commit) });
  
  // TODO: track proofs etc...
  
  return req.json();
}

export async function getCommitFromUniverse(hash: CommitHash) : Promise<Commit> {
  const req = await fetch(`${state.universe.gateway.v}/v1/commitlog?hash=${hash}`, {
    method: 'GET',
    headers: await getAuthReqProps(),
  });
  await throwOnBadResponse(req);
  
  const res = await req.json() as UniverseCommitData;
  if(res && res.items?.[0]?.commit) {
    return Commit.fromData(res.items[0].commit);
  }
}

export async function getHeadCommitFromUniverseByRefHash(thing: Thing, refHash: RefHash) : Promise<Commit> {
  const params = [
    'thing='+thing,
    'refHash='+refHash,
    'isHead=1',
  ];
  const req = await fetch(`${state.universe.gateway.v}/v1/commitlog?${params.join('&')}`, {
    method: 'POST', // We post to bust any possible cache
    headers: await getAuthReqProps(),
  });
  await throwOnBadResponse(req);

  const res = await req.json() as UniverseCommitLogResponse;
  if(res.items?.length == 1) {
    return Commit.fromData(res.items[0].commit);
  }
}

// export async function getHeadCommitHashForFullRef(thing: Thing, fullRef: string) : Promise<CommitHash> {
//   const { colId, colRef } = await fullRefToRefHashProps(fullRef);
//   const refHash = toRefHash(colRef, colId);
//   const commit = await resolveHeadCommitByRefHash(Thing.Object, refHash);
  
//   return commit.hash;
// }

async function flushWalOnce() {
  let queue = await (await store()).getSyncQueue();
  // Loop over queue in write order
  queue = sortBy(queue, d => d.id);
  while(queue.length > 0) {
    const row = queue.shift();
    if(row.commit != null) {
      const commit = await (await store()).getCommit(row.commit);
      await postCommit(commit);
    } else {
      unsupported();
    }

    // Drop row from sync queue
    await (await store()).delFromSyncQueue(row.id);

    // Signal we've done a commit, so any components can refresh.
    state.editor.commit.sent.v++;
  }

  // TODO connections
}

let isFlushing = false;
let racedRequests: number;
export async function flushWal(isInternal?: boolean) {
  if(isFlushing) {
    if(!isInternal) racedRequests++;
    return;
  }
  isFlushing = true;
  racedRequests = 0;
  try {
    await flushWalOnce();
  } catch (err) {
    if(err instanceof GuidanceError) {
      handleGuidanceError(err);
    }
    throw err; // Block flow (TODO: support for stopping syncing on issues to avoid UI showing repeatedly)
  } finally {
    isFlushing = false;
    // Flush more if there is more work pending
    if(racedRequests > 0) {
      setTimeout(() => flushWal(true), 0);
    }
  }
}

export async function tryFlushWalWithReporting() {
  // Try flushing
  try {
    await flushWal();
    reportSuccess('Changes pushed');
  } catch(err) {
    reportWarning('Could not sync repo', err);
  }
}

// -- Dev support

export async function getFromDevStore(hash: string) : Promise<Commit> {
  const val = localStorage.getItem(hash);
  if(val) {
    return Commit.fromData(JSON.parse(val));
  }
}

export function initAutomatedWalSyncing(exitEl?: HTMLElement) {
  // Hook up wal log sync
  sub(state.universe.actions.flushWal, d => d && flushWal(), exitEl);
}
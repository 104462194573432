import CryptoJS from 'crypto-js';
import stableStringify from 'fast-json-stable-stringify';
import { isSignal } from "ufti";
import { KeyService } from '../keyService.js';
import { unexpected, unsupported } from '../lib/devflow.js';
import { b32ToWordArr, wordArrToBase32 } from "../lib/encoding.js";
import { isFunc, isObject } from '../lib/js.js';
import { EncryptionType } from "../models/encryption.js";
import { JSONValue } from '../types/json.js';
import { CollectionId, PrimitiveValue, RefHash, RefSeed } from '../types/types.js';

export async function seal(keyService: KeyService, enc: EncryptionType, data: PrimitiveValue) : Promise<string> {
  const sealedData = stableStringify(data);
  switch(enc) {
    case EncryptionType.none:
      // No action required
      break;
    // case EncryptionType.key: 
    //   // TODO salt data blob with collection ID
    //   throw new Error('TODO implement encryption');
    //   break;
    default:
      throw new Error('unknown encryption type: '+enc);
  }

  return sealedData;
}

export async function unseal(keyService: KeyService, enc: EncryptionType, sealedData: string) : Promise<PrimitiveValue> {
  switch(enc) {
    case EncryptionType.none: 
      return JSON.parse(sealedData);
      break;
    // case EncryptionType.key:
      // TODO unsalt data blob with collection ID
      // throw new Error('implement encryption');
      // break;
    default:
      throw new Error('unknown encryption: '+enc);
  }
}

// Simple deep clone function with JSON validation
// TODO: replace with that deep copy thing
export function clone<PrimitiveValue>(input: PrimitiveValue) : PrimitiveValue {
  if(input == null) return input;
  return JSON.parse(JSON.stringify(input));
}

// This removes null and undefined keys in the whole subtree, including arrays.
export function removeNullKeys(input: PrimitiveValue) : PrimitiveValue {
  // Ignore inputs which are nullable
  if(input == null) return null;

  if(typeof input === 'bigint' || 
    typeof input === 'number' || 
    typeof input === 'string' || 
    typeof input === 'boolean') {

    return input;
  }
  
  if(Array.isArray(input)) {
    const res = input.map(v => removeNullKeys(v));
    
    // Remove empty arrays
    if(res.length == 0) return null;

    return res;
  }

  if(isObject(input)) {
    const cleaned = {};
    for(let [key, value] of Object.entries(input)) {
      // Ignore keys which have null/undefined values
      if(value == null) continue;

      if(isObject(value) || Array.isArray(value)) {
        value = removeNullKeys(value);
      }
      if(value == null) continue;
      cleaned[key] = value;
    }

    // Remove objects without content
    if(Object.keys(cleaned).length == 0) {
      return null;
    }
  
    return cleaned;
  }

  unsupported();
}

function saltHash(base: CryptoJS.lib.WordArray, salt: CryptoJS.lib.WordArray) : CryptoJS.lib.WordArray {
  // Make the hash
  const hasher = CryptoJS.algo.SHA256.create();

  // Deterministic hash of the ref
  hasher.update(base);

  // Salt with collection id
  // This makes it impossible to reveal the knowledge that some collections might have the "same" refs.
  hasher.update(salt); 

  return hasher.finalize();
}

export function makeRefHash(refSeed: RefSeed, collectionId: CollectionId) : RefHash {
  const hashBuf = saltHash(
    b32ToWordArr(refSeed),
    b32ToWordArr(collectionId),
  );

  return wordArrToBase32(hashBuf);
}

// Provide ref without leading collection.
// Eg: provide argument 'env-dashboard/pollution' for URL 'roelb/env-dashboard/pollution' with LKSJDF... id as collection id.
export function makeRefSeed(ref: string, collectionId: CollectionId) : RefSeed {
  if(ref[0] === '/') {
    throw new Error('leading slash');
  }

  // TODO validate ref structure (load into URL?)
  if(!collectionId) throw new Error('missing');

  const hashBuf = saltHash(
    CryptoJS.enc.Utf8.parse(ref),
    b32ToWordArr(collectionId),
  );

  return wordArrToBase32(hashBuf);
}

export function toRefHash(ref: string, colId: CollectionId) : RefHash {
  return makeRefHash(
    makeRefSeed(ref, colId),
    colId,
  );
}

// This only supports a single level of signals.
export function primitiveValue(input: any) : PrimitiveValue {
  if(isSignal(input)) {
    input = input.v;
  }
  if(isSignal(input)) {
    // This should never happen, there is a datamodel active with too many levels of signals. 
    // Look for fromData functions which have signals in the input data.
    debugger
    unexpected('structure bug');
  }

  if(typeof input === 'boolean' || typeof input === 'bigint' || typeof input === 'number' || typeof input === 'string' || input == null) {
    return input;
  }

  if(Array.isArray(input)) {
    return input.map(v => primitiveValue(v));
  }

  if(isObject(input)) {
    // If the object implements "toPrimitiveValue", it gets called
    if(isFunc(input?.toPrimitiveValue)) {
      return input.toPrimitiveValue();
    }

    // If the object implements "getStorageModel", it gets called
    if(isFunc(input?.getStorageModel)) {
      return input.getStorageModel();
    }

    // Else we serialize and walk forward
    const serialized = {};
    for(let [key, value] of Object.entries(input)) {
      serialized[key] = primitiveValue(value);
    }
    return serialized;
  }

  debugger
  unsupported();
}

export function getStorageModel(d: any) : JSONValue {
  return removeNullKeys(primitiveValue(d)) as JSONValue; // TODO: remove bigint from primitiveValue definition, we're not using it and then serializing is transparent
}


export function getUrlModel(input: any) : JSONValue {
  // Ignore the signal and work with it's value
  if(isSignal(input) === true) {
    input = input.v;
  }

  if(typeof input === 'bigint') unsupported();

  if(typeof input === 'boolean' || typeof input === 'number' || typeof input === 'string' || input == null) {
    return input;
  }

  if(Array.isArray(input)) {
    return input.map(v => getUrlModel(v));
  }

  if(isObject(input)) {
    // If the object implements "toUrlModel", it gets called
    if(isFunc(input?.toUrlModel)) {
      return input.toUrlModel();
    }

    // Else we serialize and walk forward
    const serialized = {};
    for(let [key, value] of Object.entries(input)) {
      const val = getUrlModel(value);
      if(val == null) continue; // We ignore nully values
      serialized[key] = val;
    }

    return serialized;
  }

  unsupported();
}
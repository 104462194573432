import { onEnter, setChildren } from "ufti";
import { findCtx } from "ufti/src/plugin/ctx";
import urlState from "../../types/urlState";
import { TeamCtx } from "../Teams/TeamCtx";
import { UIButton } from "../ui/buttons";
import ConnectionList from "./ConnectionList";
import CreateConnectionFlowRouter, { CreateConnectionFlow, CreateConnectionStep } from "./CreateConnectionFlowRouter";

// Shows full page for connection management
export default function SettingsConnections() {
  const el = <div class="w-full max-w-[960px] mx-auto"></div>;

  onEnter(el, () => {
    const teamCtx = findCtx(el, TeamCtx);

    setChildren(el, <>
      <div>
        
        <div class="flex justify-between py-1">
          <div class="font-semibold">Your connections</div>
          <UIButton onclick={() => createConnection(teamCtx)}>Create connection</UIButton>
        </div>

        <ConnectionList />

        <CreateConnectionFlowRouter />
      </div>
    </>);
  });

  function createConnection(ctx: TeamCtx) {
    urlState.subState.v = { 
      flow: CreateConnectionFlow.createConnection, 
      step: CreateConnectionStep.selectModule,
    };
  }

  return el;
}

function ListConnections() {
  const el = <div></div>;
  onEnter(el, () => {
    const teamCtx = findCtx(el, TeamCtx);

    // TODO
  });

  return el;
}


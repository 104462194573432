import { TableModuleMetadata } from "../../types";

export default function getMetadataStravaV1() : TableModuleMetadata {
  return {
    module: 'StravaV1',
    description: 'Analyze your activities on Strava', 
    displayName: 'Strava',
    experimental: true,
    attributions: [
      { label: 'Strava API', url: 'https://www.strava.com/', spotlight: true },
    ],
    requiresInsider: true,
  }
}
import { ValidationResult, Validator } from "@cfworker/json-schema";
import { KeyService } from "../keyService.js";
// import { DashNodeDataSchema } from "../deprecated/dashNodeV1.js";
import { DataFieldDataSchema, LogicalTypeSchema } from "../models/dataField.js";
import { NodeType } from "../models/nodes.js";
import { TableDataSchema, TableModuleTypeSchema } from "../models/table.js";
// import { TableNodeDataSchema } from "../models/tableNodeV1.js";
import { UICoordinatesSchema, UIDimensionsSchema, ViewDataSchema } from "../models/view.js";
// import { ViewNodeDataSchema } from "../models/viewNodeV1.js";
import { JSONArraySchema, JSONObjectSchema, JSONSimpleValueSchema, JSONValueSchema } from "../types/json.js";
import { Commit } from "../universe/commit.js";

let _validators: Record<NodeType, Validator>;
function getValidators() : Record<NodeType, Validator> {
  if(!_validators) {
    const validators = {
      // [NodeType.DashV1]: new Validator(DashNodeDataSchema),
      // [NodeType.TableV1]: new Validator(TableNodeDataSchema),
      // [NodeType.ViewV1]: new Validator(ViewNodeDataSchema),
    };
    
    for(let v of Object.values(validators)) {
      v.addSchema(ViewDataSchema);
      v.addSchema(UICoordinatesSchema);
      v.addSchema(UIDimensionsSchema);
      v.addSchema(JSONObjectSchema);
      v.addSchema(JSONValueSchema);
      v.addSchema(JSONSimpleValueSchema);
      v.addSchema(JSONArraySchema);
      v.addSchema(LogicalTypeSchema);
      v.addSchema(TableModuleTypeSchema);
      v.addSchema(TableDataSchema);
      v.addSchema(DataFieldDataSchema);
    }

    _validators = validators;
  }

  return _validators;
}

export async function validateCommitData(obj: any, ks: KeyService): Promise<ValidationResult> {
  // TODO: we need to validate the commit structure (commit props, externalized fields, ...)

  // See if can unseal, to validate the contents (client will be able to unseal all - universe will be able to unseal those not encrypted)
  try {
    // Unseal
    const unsealed = await Commit.fromData(obj).unseal(ks);
    const result = await validateUnsealedCommitData(unsealed);
    if(!result.valid) {
      return result;
    }
  } catch (err) {
    // Throw all errors, except if it's unknown encryption (the error thrown when not having the keys)
    if(err.toString().indexOf('unknown encryption') < 0) {
      throw err;
    }
  }

  // TODO: replace with validator at commit level
  return {
    valid: true,
    errors: [],
  }
}

export async function validateUnsealedCommitData(uc: any) : Promise<ValidationResult> {
  // TODO: validate the commit structure, externalized fields, ...

  const v = getValidators()[uc?.ext?.type];
  if(!v) {
    throw new Error(`Unknown type: ${uc?.ext?.type}`);
  }

  return v.validate(uc.data);
}

export class ValidationError extends Error {
  res: ValidationResult;
  constructor(msg: string, res: ValidationResult) {
    super(msg);
    this.res = res;
  }
}
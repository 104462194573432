import store from "../idb/store";

export interface GuidanceKeyState {
  // Times we've shown this.
  timesShown: number,

  // Time we've last shown this.
  lastShown?: number,

  // Time when the user burned this property "I GOT THIS! SHUT UP!"
  burnTime?: number,
}

function toKey(prefix: string) : string {
  return `guidance.${prefix}`;
}

export async function getGuidanceState(prefix: string) : GuidanceKeyState {
  const d = await (await store()).getKey(toKey(prefix));
  if(d) {
    return JSON.parse(d);
  }

  // Initialize
  return {
    timesShown: 0,
  }
}

async function setGuidanceState(prefix: string, val: GuidanceKeyState) {
  await (await store()).putKey(toKey(prefix), JSON.stringify(val));
}

export async function markGuidanceShown(prefix: string) {
  const val = await getGuidanceState(prefix);
  val.timesShown++;
  val.lastShown = Date.now();
  await setGuidanceState(prefix, val);
}

export async function markGuidanceBurned(prefix: string) {
  const val = await getGuidanceState(prefix);
  val.burnTime = Date.now();
  await setGuidanceState(prefix, val);
}

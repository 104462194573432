// The browser repository is an incomplete repository. 
// It only tracks local commits and allows removing commits.
// It's always activated and it's used to track local commits.
//
// Local changes can have one or or more "upstreams".

import { Thing } from 'graffe-shared/src/models/nodes';
import { CollectionId, CommitHash, RefHash } from 'graffe-shared/src/types/types';
import { Commit } from 'graffe-shared/src/universe/commit';
import { getStorageModel } from 'graffe-shared/src/universe/utils';
import { IDBPDatabase, openDB } from 'idb';
import { Signal, signal } from 'ufti';
import { Connection } from '../connections/types';
import { logError } from '../lib/logger';
import { canWrite } from '../models/nodes';
import { state } from '../state';
import { ChangeStoreRow, CommitStoreRow, ConnectionRowStatus, KeyVal, syncLogEntry } from './types';

// Safari: use privacy -> manage data -> remove to reset indexeddb hell

// Version of the code: bump on migrations
const currVersion = 3;

const enum Tables {
  synclog = 'synclog',
  commits = 'commits',
  keyv = 'keyv',
  changes = 'changes',
  connections = 'connections',
}

const commitsRefHash = 'commitsRefHash';
const commitsThingRefHash = 'commitsThingRefHash';
const commitsThingIsHead = 'commitsThingIsHead';
const connCollIdx = 'collectionId';

const commitsWalRefHash = 'commitsWalRefHash';
const changesRefHash = 'changesRefHash';
const refsWalIdx = 'refHash'; // DEPRECATED

// BrowserStore is not a repository, but it's a local storage for storing ongoing changes and not interrupting workflow.
export class BrowserStore {
  public db: IDBPDatabase;
  private syncCounter: number;

  // Signal which gets fired when change actions are written.
  // Entries are written as complete as possible
  public changes: Signal<{ 
    action: 'change' | 'delete' | 'clear', 
    thing?: Thing, 
    refHash?: RefHash, 
    change?: Commit,
  }> = signal();

  // Signal which gets fired when connections are changed.
  // Entries are written as complete as possible
  public connections: Signal<{ 
    action: 'change' | 'delete' | 'clear', 
    refHash?: RefHash, 
    connection?: Connection,
  }> = signal();


  static async createAndInit() : Promise<BrowserStore> {
    const store = new BrowserStore();
    await store.init();

    return store;
  }

  private async init() {
    this.db = await openDB('graffe', currVersion, {
      upgrade(db, oldVersion, newVersion, tx, event) {
        console.log({ oldVersion, newVersion, currVersion });
        if(oldVersion == null) {
          oldVersion = 0;
        }
        if(oldVersion < 1) {
          console.log('creating: '+Tables.keyv);
          const keyv = db.createObjectStore(Tables.keyv, { keyPath: 'key' });

          // Create new tables
          console.log('creating: '+Tables.commits);
          const commits = db.createObjectStore(Tables.commits, { keyPath: 'commit.hash' }); 

          console.log('creating: '+Tables.synclog);
          const synclog = db.createObjectStore(Tables.synclog, { keyPath: 'id', autoIncrement: true }); 

          // Create commit index on refHash
          commits.createIndex(commitsThingRefHash, ['commit.ext.thing', 'commit.ext.refHash']);

          // Create commit index on head ref
          commits.createIndex(commitsThingIsHead, ['commit.ext.thing', 'isHead']); // isHead contains the refHash value for easy searches
        }
        if(oldVersion < 2) {
          // Re-create changes
          console.log('creating: '+Tables.changes);
          const changes = db.createObjectStore(Tables.changes, { keyPath: ['change.ext.thing', 'change.ext.refHash'] });
        }
        if(oldVersion < 3) {
          // Connections
          // Data model: { conn: Connection, created, accessed, status: 'synced' | 'modified' | 'removed' }
          console.log('creating: '+Tables.connections);
          const connections = db.createObjectStore(Tables.connections, { keyPath: 'conn.ext.refHash' });
          connections.createIndex(connCollIdx, 'conn.ext.collection.id');
        }
      },
      blocked(currentVersion, blockedVersion, event) {
        console.log('blocked', { currentVersion, blockedVersion, event });
        debugger;
      },
      blocking(currentVersion, blockedVersion, event) {
        console.log('blocking', { currentVersion, blockedVersion, event });
        debugger;
      },
      terminated() {
        console.log('terminated');
        // debugger;
      },
    });
  }

  // -- CHANGES
  //
  // We've changed this storage to session storage so they become volatile.
  // Leaving a editor also whacks the full state.

  async putChange(change: Commit) {
    // Full row
    const row: ChangeStoreRow = {
      change: getStorageModel(change),
      modified: Date.now(),
    }

    await this.db.put(Tables.changes, row);

    // Trigger listeners
    this.changes.v = { action: 'change', change, refHash: change.ext.refHash, thing: change.ext.thing };
  }

  async getChangeRowForRefHash(thing: Thing, refHash: RefHash) : Promise<ChangeStoreRow> {
    const row = await this.db.get(Tables.changes, [thing, refHash]);
    return row;
  }

  async getChangesAsCommits() : Promise<Commit[]> {
    const rows = await this.getChangeRows();

    return rows.map(d => Commit.fromData(d.change));
  }

  async getChangeCommitForRefHash(thing: Thing, refHash: RefHash) : Promise<Commit> {
    const row = await this.getChangeRowForRefHash(thing, refHash);
    if(row) return Commit.fromData(row.change);
  }

  async getChangeRows() : Promise<ChangeStoreRow[]> {
    return this.db.getAll(Tables.changes) as ChangeStoreRow[];
  }

  async delChangeForRefHash(thing: Thing, refHash: RefHash) : Promise<void> {
    await this.db.delete(Tables.changes, [thing, refHash]);

    this.changes.v = { action: 'delete', thing, refHash };
  }

  // // Called by onExit of the editor component
  // async clearChanges() : Promise<void> {
  //   // for (let i = 0; i < sessionStorage.length; i++) {
  //   //   const key = sessionStorage.key(i);
  //   //   if(key.indexOf('change:') === 0) {
  //   //     sessionStorage.removeItem(key);
  //   //   }
  //   // }
  //   this.changes.v = { action: 'clear' };
  // }

  // -- COMMITS

  async getHeadRow(thing: Thing, refHash: string) : Promise<CommitStoreRow> {
    return this.db.getFromIndex(Tables.commits, commitsThingRefHash, [thing, refHash]);
  }

  async getHead(thing: Thing, refHash: string) : Promise<Commit> {
    const row = await this.getHeadRow(thing, refHash);
    if(row) return Commit.fromData(row.commit);
  }

  async getCommitRow(hash: CommitHash) : Promise<CommitStoreRow> {
    return this.db.get(Tables.commits, hash); 
  }

  async getCommit(hash: CommitHash) : Promise<CommitStoreRow> {
    const row = await this.getCommitRow(hash);
    if(row) return Commit.fromData(row.commit);
  }

  async cacheCommit(commit: Commit) {
    if(canWrite(commit)) {
      return this.writeCommit(commit);
    }
  }

  // WARN: this function works under the assumption the commits has a sparse set with minimally the parent row loaded.
  //       it requires the store to be synced up or might make mistakes. 
  //       The remote sync can correct any mistakes normally.
  private async writeCommit(commit: Commit) {
    // Get current commit row
    const existing = await this.db.get(Tables.commits, commit.hash);
    if(existing) return;

    // Check if this commit is the new head
    const headRow = await this.getHeadRow(commit.ext.thing, commit.ext.refHash);
    const head = (headRow && Commit.fromData(headRow.commit) || null);
    let isNewHead = false;
    if(!head || commit.commit.parents?.indexOf(head.hash) >= 0) {
      isNewHead = true;
    }

    // Storage model
    const cd = getStorageModel(commit);

    // Complete row
    const row: CommitStoreRow = {
      commit: cd,
      isHead: (isNewHead ? commit.ext.refHash : null),
      modified: Date.now(),
    };

    // If it already exists, we ignore it
    await this.db.put(Tables.commits, row);

    // Mark the parent commit off head
    if(isNewHead && head) {
      await this.db.put(Tables.commits, {
        ...headRow,
        isHead: null,
        modified: Date.now(),
      });
    }
  }

  // WARN: this function works under the assumption the commits has a sparse set with minimally the parent row loaded.
  //       it requires the store to be synced up or might make mistakes. 
  //       The remote sync can correct any mistakes normally.
  async putCommit(commit: Commit) {
    await this.writeCommit(commit, false);

    console.log('stored commit');

    // Mark the row to be synced
    await this.syncQueueCommit(commit.hash);
  }

  async delCommit(hash: CommitHash) : Promise<void> {
    return this.db.delete(Tables.commits, hash);
  }

  async getCommitRows() : Promise<CommitStoreRow[]> {
    const rows = await this.db.getAll(Tables.commits);

    return rows as CommitStoreRow[];
  }

  // -- SYNC
  async syncQueueCommit(hash: CommitHash) : Promise<IDBValidKey> {
    console.log('syncQueueCommit', hash);
    const res = await this.db.put(Tables.synclog, { commit: hash, created: Date.now() });
    state.universe.actions.flushWal.v = true;
    
    return res;
  }

  async getSyncQueue() : Promise<syncLogEntry[]> {
    const rows = await this.db.getAll(Tables.synclog);
    return rows as syncLogEntry[];
  }

  async delFromSyncQueue(id: number) : Promise<void> {
    return this.db.delete(Tables.synclog, id);
  }

  // -- KEYV

  async getKey(key: string) : Promise<KeyVal> {
    const row = await this.db.get(Tables.keyv, key);
    if(row?.key) {
      return row.val;
    }
    return null
  }

  async putKey(key: string, val: string) : Promise<void> {
    await this.db.put(Tables.keyv, { key, val, time: Date.now() });
  }

  // Fucking pile of shit idb - this just hangs - we're not waiting for it
  async clearTables() {
    try {
      this.db.clear(Tables.changes);
      this.db.clear(Tables.commits);
      this.db.clear(Tables.synclog);
      this.db.clear(Tables.keyv);
      this.db.clear(Tables.connections);
    } catch (err) {
      // TODO: whack the whole table store?
      logError(err);
    }
  }

  async getKeyValRows() : Promise<KeyVal[]> {
    return this.db.getAll(Tables.keyv);
  }

  async delKey(key: string) {
    await this.db.delete(Tables.keyv, key);
  }

  // -- CONNECTIONS

  // TODO: trigger wal sync after write
  async putConnection(c: Connection, status: ConnectionRowStatus = 'modified') {
    // See if there is a more recent entry which might superseed or need to be removed
    const row = await this.getConnection(c.ext.refHash);
    if(row?.ext.time[0] > c.ext.time[0]) {
      console.warn('ignoring ref save, since more recent version is available');
      return;
    }

    // Store the ref
    await this.db.put(Tables.connections, {
      conn: getStorageModel(c),
      modified: Date.now(),
      accessed: (row ? row.accessed : null), // Keep last access time
      status,
    });

    this.connections.v = { action: 'change', refHash: c.ext.refHash };
  }

  async markConnectionSynced(conn: Connection) {
    const row = await this.db.get(Tables.connections, conn.ext.refHash);
    if(row) {
      const old = Connection.fromData(row.conn);
      if(old.contentHash() === conn.contentHash()) {
        await this.db.put(Tables.connections, { ...row, status: 'synced' });
      }
    }
  }

  async getConnection(refHash: RefHash) : Promise<Connection> {
    const row = await this.db.get(Tables.connections, refHash);
    if(row && row.status !== 'removed') {
      return Connection.fromData(row.conn);
      // TODO: update the row and mark accessed time now.
    }
  }

  // Get local connections which are not removed
  async getConnections(collection: CollectionId) : Promise<Connection[]> {
    const rows = await this.db.getAllFromIndex(Tables.connections, connCollIdx, collection);

    return rows
      .filter(r => r.status !== 'removed')
      .map(row => Connection.fromData(row.conn));
  }

  // Returns a list of connections which either have been modified or removed
  async getWalConnections() : Promise<{ status: ConnectionRowStatus, conn: Connection }[]> {
    const rows = await this.db.getAll(Tables.connections);
    return rows
      .filter(row => row.status === 'modified' || row.status === 'removed')
      .map(({ status, conn }) => ({ status, conn: Connection.fromData(conn) }));
  }

  // Remove from the local table
  async delConnection(conn: Connection) {
    // LATER: in tx validate if still same conn in DB as requested (not a race condition)
    await this.db.delete(Tables.connections, conn.ext.refHash);

    this.connections.v = { action: 'delete', refHash: c.ext.refHash };
  }

}
import b32decode from 'base32-decode';
import b32encode from 'base32-encode';
import { decode as b64arrbufdecode, encode as b64arrbufencode } from 'base64-arraybuffer';
import CryptoJS from 'crypto-js';


const variant = 'RFC4648';

export function encodeBufferToB32(buf: ArrayBuffer) : string {
  return b32encode(buf, variant, { padding :false });
}

export function wordArrToBase32(arr: CryptoJS.lib.WordArray) : string {
  return encodeBufferToB32(b64arrbufdecode(arr.toString(CryptoJS.enc.Base64)));
}

export function b32ToWordArr(b32Str: string) : CryptoJS.lib.WordArray {
  return CryptoJS.enc.Base64.parse(b64arrbufencode(b32decode(b32Str, variant)));
}

export function randomId(nBytes: number) : string {
  return wordArrToBase32(CryptoJS.lib.WordArray.random(nBytes));
}
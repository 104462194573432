// import "@fontsource/inter/variable.css";
import { initBundledCrashReporting, initExternalCrashReporting } from './reporting/reporting';
initBundledCrashReporting();
initExternalCrashReporting();

import { append } from 'ufti';
import { startRouter } from "ufti/src/plugin/router";
import { showErrors } from "ufti/src/plugin/showErrors";
import AppLoader from './components/App/AppLoader';
import { isProduction } from './lib/dev';
import './lib/importPreloads';
import { state } from './state';
import './types/urlState';

if(!process.env.GRF_REPO) throw new Error('GRF_REPO missing');
if(!process.env.GRF_APP) throw new Error('GRF_APP missing');

function getUniverseGateway() : string {
  if(!isProduction()) {
    if(window.location.origin.endsWith(':4000')) {
      return window.location.origin.replace(':4000', ':4001');
    }
  }
  
  return process.env.GRF_REPO;
}

state.universe.gateway.v = getUniverseGateway();

// Load up flags
if(localStorage.getItem('dev.wsUsageIntent')) {
  state.dev.wsUsageIntent.v = true;
}
if(localStorage.getItem('dev.mem')) {
  state.dev.mem.v = true;
}
if(localStorage.getItem('dev.copyJson')) {
  state.dev.copyJson.v = true;
}

if(!isProduction()) {
  showErrors();
}
startRouter();

// Store the referrer on arrival for usage within current tab
sessionStorage.setItem('referrer', document.referrer);

append(document.body, <AppLoader />);
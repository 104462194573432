import { clear, onEnter, setChildren, signal, sub } from "ufti";
import { Link, NavLink, router } from "ufti/src/plugin/router";
import { profileMenuWidth, toggleProfileMenuDropdown } from "../../../components/TopBar/ProfileMenu";
import { showSignInModal } from "../../../components/session/SignIn";
import { ProfileIcon } from "../../../lib/assets";
import { Action } from "../../../lib/button";
import { getPageLeft } from "../../../lib/ui";
import { state } from "../../../state";

function PageTopSessionControls({ class: klass }) {
  const el = <div class={`flex gap-2 py-3 ${klass||''}`} />;
  
  sub([state.user, router.location, signal(1)], () => {
    if(state.user.v?.id) {
      setChildren(el, <>
        {/* {state.user.v.invited == 1 && <Link 
          href={`/@${state.user.v.slug}`} 
          class="py-2 px-4 text-sm font-medium text-zinc-800 hidden md:block">Profile</Link>} */}

        {state.user.v?.avatar != null ? (
          <div 
            onclick={() => toggleMenu()}
            class="w-[42px] h-[42px] rounded-full border-[2px] border-white bg-white overflow-hidden cursor-pointer">
            <div class="w-10 h-10">
              <img class="w-full h-auto" src={state.user.v?.avatar} />
            </div>
          </div>
        ) : <ProfileIcon 
          onclick={() => toggleMenu()}
          class="inline cursor-pointer" />}
      </>);
    } else if(router.location.v?.pathname !== '/invite') {
      setChildren(el, <Action class="py-2 px-4" onclick={() => showSignInModal()}>Sign in</Action>);
    } else {
      clear(el);
    }
  }, el);

  function toggleMenu() {
    const pageX = (getPageLeft(el) + 40)-profileMenuWidth;
    const pageY = 50;
    toggleProfileMenuDropdown(el, { pageX, pageY });
  }

  return el;
}

export default function PageTop() {
  let navDiv: HTMLDivElement;
  const el = <header class="flex flex-col">
    <div class="relative flex justify-center items-center text-zinc-900 text-sm font-medium">
      <div class="absolute top-0 bottom-0 left-2 flex items-center md:inline-block">
        <Link href="/"><div class="py-5">{logo}</div></Link>
      </div>

      {navDiv = <nav class="gap-3 md:gap-8 flex items-center">
        {/* <NavLink href="/pricing" activeClasses="border-lime-400 border-b-2"><div class="my-2 md:my-3 py-1">Pricing</div></NavLink>
        <NavLink href="/insiders" activeClasses="border-lime-400 border-b-2"><div class="my-2 md:my-3 py-1">Insiders</div></NavLink> */}
      </nav>}

      <PageTopSessionControls class="absolute right-0 top-0 bottom-0 py-3" />
    </div>
  </header>;

  // Dynamically set the about link content
  onEnter(el, () => {
    sub([state.user, signal(1)], () => {
      setChildren(navDiv, <>
        <NavLink href={`/${state.user.v?.id != null ? 'about' : '' }`} activeClasses="border-lime-400 border-b-2"><div class="my-2 md:my-3 py-1">About</div></NavLink>
        <NavLink href="/pricing" activeClasses="border-lime-400 border-b-2"><div class="my-2 md:my-3 py-1">Pricing</div></NavLink>
        <NavLink href="/insiders" activeClasses="border-lime-400 border-b-2"><div class="my-2 md:my-3 py-1">Insiders</div></NavLink>
        {/* <NavLink href="/community" activeClasses="border-lime-400 border-b-2"><div class="md:my-3 md:py-2">Community</div></NavLink> */}
      </>)
    })
  })

  return el;
}


const logo = <svg width="54" height="18" viewBox="0 0 54 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.01365 18C4.06177 18 3.24681 17.867 2.56876 17.601C1.89071 17.335 1.35827 16.9709 0.971435 16.5087C0.5846 16.0465 0.352064 15.5233 0.273827 14.939L3.29245 14.7689C3.34895 14.9738 3.45327 15.1526 3.60539 15.3052C3.76187 15.4578 3.96398 15.5756 4.21173 15.6584C4.46382 15.7413 4.75938 15.7827 5.09841 15.7827C5.63302 15.7827 6.07419 15.6519 6.42191 15.3903C6.77397 15.133 6.95 14.6817 6.95 14.0363V12.2703H6.83917C6.71312 12.5799 6.52187 12.8568 6.26543 13.101C6.00899 13.3452 5.68953 13.5371 5.30704 13.6766C4.92889 13.8161 4.49425 13.8859 4.0031 13.8859C3.27289 13.8859 2.60353 13.7158 1.99503 13.3757C1.39087 13.0312 0.906238 12.4993 0.541135 11.7798C0.180378 11.056 0 10.125 0 8.98692C0 7.81395 0.186898 6.84811 0.560694 6.08939C0.934491 5.32631 1.42347 4.76163 2.02763 4.39535C2.63613 4.02907 3.29028 3.84593 3.99006 3.84593C4.51598 3.84593 4.96584 3.9375 5.33964 4.12064C5.71778 4.29942 6.02855 4.53052 6.27195 4.81395C6.51535 5.09738 6.70008 5.39172 6.82613 5.69695H6.9174V3.97674H10.0925V14.0625C10.0925 14.9128 9.87952 15.6301 9.45357 16.2144C9.02761 16.7987 8.43215 17.2413 7.66717 17.5421C6.90219 17.8474 6.01768 18 5.01365 18ZM5.11145 11.5574C5.49828 11.5574 5.82861 11.4549 6.10244 11.25C6.37627 11.0451 6.58707 10.7507 6.73485 10.367C6.88263 9.98328 6.95652 9.52326 6.95652 8.98692C6.95652 8.44186 6.88263 7.97311 6.73485 7.58067C6.59142 7.18387 6.38061 6.87863 6.10244 6.66497C5.82861 6.45131 5.49828 6.34448 5.11145 6.34448C4.71592 6.34448 4.38124 6.45349 4.10741 6.67151C3.83358 6.88954 3.62495 7.19695 3.48152 7.59375C3.34243 7.98619 3.27289 8.45058 3.27289 8.98692C3.27289 9.52326 3.34461 9.98328 3.48804 10.367C3.63147 10.7507 3.83793 11.0451 4.10741 11.25C4.38124 11.4549 4.71592 11.5574 5.11145 11.5574Z" fill="#27272A"/>
<path d="M12.125 14.0233V3.97674H15.2219V5.80814H15.3262C15.5087 5.14535 15.8065 4.65262 16.2194 4.32994C16.6323 4.00291 17.1126 3.83939 17.6602 3.83939C17.808 3.83939 17.9601 3.85029 18.1166 3.87209C18.2731 3.88954 18.4187 3.91788 18.5534 3.95712V6.73692C18.4013 6.68459 18.2014 6.64317 17.9536 6.61265C17.7102 6.58212 17.4929 6.56686 17.3017 6.56686C16.9235 6.56686 16.5823 6.65189 16.2781 6.82195C15.9782 6.98765 15.7413 7.22093 15.5674 7.5218C15.3979 7.81831 15.3131 8.16715 15.3131 8.56831V14.0233H12.125Z" fill="#27272A"/>
<path d="M22.299 14.1933C21.6601 14.1933 21.0929 14.0865 20.5974 13.8728C20.1062 13.6548 19.7172 13.3278 19.4303 12.8917C19.1478 12.4513 19.0066 11.8997 19.0066 11.2369C19.0066 10.6788 19.1044 10.2078 19.2999 9.82413C19.4955 9.44041 19.765 9.12863 20.1084 8.88881C20.4518 8.64898 20.8473 8.46802 21.295 8.34593C21.7427 8.21948 22.2208 8.13445 22.7293 8.09084C23.2987 8.03852 23.7572 7.98401 24.105 7.92733C24.4527 7.86628 24.7048 7.78125 24.8612 7.67224C25.0221 7.55887 25.1025 7.39971 25.1025 7.19477V7.16206C25.1025 6.82631 24.9873 6.56686 24.7569 6.38372C24.5266 6.20058 24.2158 6.10901 23.8246 6.10901C23.403 6.10901 23.064 6.20058 22.8075 6.38372C22.5511 6.56686 22.3881 6.81977 22.3186 7.14244L19.3782 7.03779C19.4651 6.42733 19.689 5.88227 20.0497 5.40262C20.4148 4.91861 20.9147 4.53925 21.5492 4.26454C22.1882 3.98547 22.9553 3.84593 23.8507 3.84593C24.4896 3.84593 25.0786 3.92224 25.6175 4.07485C26.1565 4.22311 26.6259 4.44113 27.0258 4.72892C27.4257 5.01236 27.7343 5.36119 27.9516 5.77544C28.1733 6.18968 28.2841 6.66279 28.2841 7.19477V14.0233H25.285V12.6235H25.2068C25.0286 12.9637 24.8004 13.2515 24.5222 13.4869C24.2484 13.7224 23.9246 13.899 23.5508 14.0167C23.1813 14.1344 22.7641 14.1933 22.299 14.1933ZM23.2835 12.1003C23.6269 12.1003 23.9355 12.0305 24.2093 11.891C24.4875 11.7515 24.7091 11.5596 24.8743 11.3154C25.0395 11.0669 25.122 10.7791 25.122 10.452V9.49709C25.0308 9.54506 24.9199 9.58866 24.7895 9.62791C24.6635 9.66715 24.5244 9.70422 24.3723 9.7391C24.2201 9.77398 24.0637 9.80451 23.9029 9.83067C23.742 9.85683 23.5877 9.88081 23.44 9.90262C23.14 9.95058 22.8836 10.0247 22.6706 10.125C22.462 10.2253 22.3012 10.3561 22.1882 10.5174C22.0795 10.6744 22.0252 10.8619 22.0252 11.0799C22.0252 11.4113 22.1425 11.6642 22.3772 11.8387C22.6163 12.0131 22.9184 12.1003 23.2835 12.1003Z" fill="#27272A"/>
<path d="M35.9252 3.97674V6.3314H29.4837V3.97674H35.9252ZM30.8268 14.0233V3.46657C30.8268 2.69041 30.9724 2.04724 31.2636 1.53706C31.5548 1.02253 31.959 0.638809 32.4763 0.385902C32.9935 0.128634 33.5911 0 34.2692 0C34.7125 0 35.1298 0.0348839 35.521 0.104652C35.9165 0.170059 36.2099 0.228925 36.4011 0.281251L35.8926 2.62282C35.7752 2.58358 35.6318 2.55087 35.4623 2.52471C35.2971 2.49855 35.1406 2.48547 34.9929 2.48547C34.6191 2.48547 34.3626 2.56831 34.2235 2.73401C34.0845 2.89971 34.0149 3.12645 34.0149 3.41424V14.0233H30.8268Z" fill="#27272A"/>
<path d="M43.1506 3.97674V6.3314H36.7092V3.97674H43.1506ZM38.0522 14.0233V3.46657C38.0522 2.69041 38.1978 2.04724 38.4891 1.53706C38.7803 1.02253 39.1845 0.638809 39.7017 0.385902C40.2189 0.128634 40.8166 0 41.4946 0C41.938 0 42.3552 0.0348839 42.7464 0.104652C43.1419 0.170059 43.4353 0.228925 43.6266 0.281251L43.118 2.62282C43.0007 2.58358 42.8573 2.55087 42.6877 2.52471C42.5226 2.49855 42.3661 2.48547 42.2183 2.48547C41.8445 2.48547 41.5881 2.56831 41.449 2.73401C41.3099 2.89971 41.2404 3.12645 41.2404 3.41424V14.0233H38.0522Z" fill="#27272A"/>
<path d="M49.0428 14.2129C47.9953 14.2129 47.0913 14.0058 46.3306 13.5916C45.5743 13.173 44.9919 12.5778 44.5833 11.806C44.1791 11.0298 43.977 10.1076 43.977 9.03924C43.977 8.00145 44.1813 7.09448 44.5899 6.31831C44.9984 5.53779 45.5743 4.93169 46.3176 4.5C47.0608 4.06395 47.9366 3.84593 48.945 3.84593C49.6578 3.84593 50.3098 3.95712 50.9009 4.17951C51.4921 4.40189 52.0028 4.73111 52.4331 5.16715C52.8634 5.6032 53.198 6.14172 53.4371 6.7827C53.6762 7.41933 53.7957 8.14971 53.7957 8.97384V9.7718H45.0919V7.91424H50.8292C50.8249 7.57413 50.7445 7.27108 50.588 7.00509C50.4315 6.7391 50.2164 6.53198 49.9425 6.38372C49.6731 6.2311 49.3623 6.1548 49.0102 6.1548C48.6538 6.1548 48.3343 6.23547 48.0518 6.3968C47.7693 6.55378 47.5455 6.76962 47.3803 7.04433C47.2151 7.31468 47.1282 7.62209 47.1195 7.96657V9.85683C47.1195 10.2667 47.1999 10.6265 47.3607 10.936C47.5216 11.2413 47.7497 11.4789 48.0453 11.649C48.3409 11.819 48.6929 11.9041 49.1015 11.9041C49.384 11.9041 49.6405 11.8648 49.8708 11.7863C50.1012 11.7078 50.299 11.5923 50.4641 11.4397C50.6293 11.2871 50.7532 11.0996 50.8357 10.8772L53.7631 10.9622C53.6414 11.6206 53.3741 12.194 52.9612 12.6824C52.5526 13.1664 52.0158 13.5436 51.3508 13.814C50.6858 14.0799 49.9165 14.2129 49.0428 14.2129Z" fill="#27272A"/>
</svg>;
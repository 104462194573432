import { TypeConnectionModuleId } from 'graffe-shared/src/types/connections';
import { ModuleFlowHook } from 'graffe-shared/src/types/types';

interface ConnectionLibraryItem {
  module: TypeConnectionModuleId;

  description: string;

  displayName?: string;

  // Used for rendering the PRO labels, to avoid loading the modules.
  requiresInsider?: boolean;

  // User-flow hooks where customized (blocking) logic can execute.
  hooks?: ModuleFlowHook[];
}

// Simple views to 
export const basicConnectionLibrary: ConnectionLibraryItem[] = [
  { module: 'StravaV1', description: 'Analyze your activities from Strava', displayName: 'Strava API (personal)' },
]

// TODO: refactor -> all views should be classes which can be instantiated so they can contain runtime context.
export const connectionModules = {
  StravaV1: _ => import('./modules/StravaV1/StravaV1'),
}
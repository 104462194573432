import { onEnter, onExit, signal, sub } from "ufti";
import { Action } from "../../../lib/button";
import { switchClass, toggleClass } from "../../../lib/ui";

const items = [
  {
    title: 'Companies, Teams', 
    content: 'Work together on dashboards, charts and tables in your team. Define all your data clearly and get oversight. Avoid complex data integrations for simple dashboards. Share results with clients or embed in other applications',
  },
  {
    title: 'Connected Athletes', 
    content: 'Connect with Strava and keep an eye on your progress with dashboards created by others, or start building dashboards that explore your data in ways that were previously impossible. Then share your work with other athletes without sharing your data.',
  },
  {
    title: 'Governments',
    content: 'Data discovery and access of all the current open data systems is horrific. Use Graffe and make it accessible. When data is accessible, data gets used and the investment in it will start to pay off. Exposing data in Graffe is a minimal-cost effort.',
  },
  {
    title: 'Journalists, Mass Media, Fact Checkers', 
    content: 'Increase trust in your reporting by enriching your stories with charts, tables which people can inspect and trust all the way down to the data source origin. Prove where your data comes from. Accept improvements.',
  },
  {
    title: 'Data Analysts & Scientists',
    content: 'Quality data analysis is art. Build customized dashboards, charts and tables for clients bringing together all data sources and deploy fast analysis that is cost-efficient. Join the community and work in it. ',
  },
  {
    title: 'Data Engineers',
    content: 'Share quality data sources, improve data sources and work together in teams or assist clients freelance.',
  },
  {
    title: 'Developers', 
    content: 'Easily create and embed dashboards and charts. Define clear data sources within companies. Define data pipelines without the overhead.',
  },
  {
    title: 'Researchers', 
    content: 'Make an analysis and have cryptographic proofs you can share or include in papers that prove that research existed and originated from you.',
  },
];

function BenefitItem({ item, selected }) {
  let contentDiv;
  let borderDiv;
  const el = <article class="pl-6 relative flex flex-col">
    <Action class="flex justify-start items-start font-display font-bold text-2xl text-left" onclick={() => selected.v = item}><h3>{item.title}</h3></Action>
    {contentDiv = <p class="text-zinc-600 text-sm">{item.content}</p>}
    {borderDiv = <div class={`absolute left-0 top-0 bottom-0 border-l-8 radius-[1px]`} />}
  </article>;

  onEnter(el, () => {
    let enterTime = Date.now();

    // We initialize the menu after 200ms to give search engines a chance to index all the content
    const to = setTimeout(() => {
      sub(selected, () => {
        toggleClass(selected.v !== item, contentDiv, 'hidden');
        switchClass(selected.v === item, borderDiv, 'border-lime-400', 'border-zinc-200');
      }, el);
    }, 200);

    onExit(el, () => to && clearTimeout(to))
  })

  return el;
}

export default function Benefitters({ class: klass }) {
  const selected = signal(items[0]);
  const el = <div class={`flex flex-col lg:flex-row gap-x-12 gap-y-8 ${klass||''}`}>
    <div class="flex-1 max-w-[604px] flex justify-center items-center mx-auto md:mx-none">
      <div class="w-full px-12 lg:px-0 lg:max-w-[498px] h-auto">
        <img src="/_assets/graffe-app/about/green-balls.png" />
      </div>
    </div>

    <div class="flex-1 max-w-[604px] flex flex-col gap-8 px-4 lg:px-[53px] justify-center">
      {items.map(item => {
        return <BenefitItem item={item} selected={selected} />
      })}
    </div>
  </div>;

  return el;
}
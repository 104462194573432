import { unexpected } from 'graffe-shared/src/lib/devflow';
import { setChildren, sub } from 'ufti';
import { setCtxParent } from 'ufti/src/plugin/ctx';
import { Link, NavLink, navigate } from "ufti/src/plugin/router";
import { ProfileIcon } from "../../lib/assets";
import { Action } from '../../lib/button';
import { OutlineIcon } from "../../lib/icons";
import { sessionUserIsInsider } from '../../lib/user';
import { state } from "../../state";
import { fixedState, showContentFixed } from '../fixed';
import Avatar from "./Avatar";

export function ProfileMenuButtonSession() : HTMLDivElement {
  // TODO: ACTIONS
  const el: HTMLDivElement = <div class="flex items-center justify-center">
    <div class="cursor-pointer flex items-center" onclick={toggleMenu}>
      {state.user.v?.avatar != null ? (
        <div class="w-[33px] h-[33px] rounded-full border-[1px] bg-zinc-200 border-zinc-200 overflow-hidden">
          <div class="w-8 h-8">
            <img class="w-full h-auto" src={state.user.v?.avatar} />
          </div>
        </div>
      ) : <ProfileIcon class="inline" />}
      {/* <OutlineIcon icon="chevronDown" class="ml-0.5 w-4 h-4" /> */}
    </div>
  </div>;

  function toggleMenu(e: MouseEvent) {
    const pageX = document.body.clientWidth-288-12;
    const pageY = 36;
    toggleProfileMenuDropdown(el, { pageX, pageY })
  }

  return el;
}

export function toggleProfileMenuDropdown(el: HTMLElement, opts: {
  pageX: number,
  pageY: number,
}) {
  if(fixedState.outlet.querySelector('.gProfileMenu')) {
    fixedState.ctx.close();
    return
  }
  const menu = setCtxParent(<ProfileMenuDropdown />, el);
  const { pageX, pageY } = opts;
  showContentFixed(menu, { pageX, pageY }, { 
    closeOnInside: true,
    addCloseControl: false,
    exitEl: el,
  });
}

export const profileMenuWidth = 288;

export function ProfileMenuDropdown() : HTMLDivElement {
  // This object expects to be called in a session ctx.
  if(!state.user.v?.id) {
    unexpected();
  }

  // Keep max-w-288 in sync with profileMenuWidth
  const el = <nav class={`flex flex-col py-3 px-2 bg-white text-zinc-600 min-w-[288px] shadow-md z-[9999] text-xs font-normal gProfileMenu`}>

    <div class="px-2 flex flex-row justify-center items-center gap-2 hover:cursor-pointer" onclick={() => navigate(`/@${state.user.v?.slug}`)}>
      <Avatar class="h-10 w-10" />
      <div class="flex-grow">
        <div class="flex gap-1 items-center">
          <div>@{state.user.v?.slug}</div>
          {sessionUserIsInsider() && <div class="text-xxs font-semibold text-zinc-700 uppercase px-1 rounded-sm bg-lime-300">{state.user.v?.insider.activatedTier?.name}</div>}
        </div>
        <div class="text-zinc-400 mt-0.5">{state.user.v?.email}</div>
      </div>
    </div>

    {/* <Link class="block text-white bg-indigo-600 py-1.5 mx-2 text-center my-2" href="/editor">Editor</Link> */}

    {/* <Link class="block text-white bg-indigo-600 w-full p-1.5 mx-2 text-center my-2 font-semibold" href="/editor"><OutlineIcon icon="plus" class="inline mr-1 w-4 h-4" />Create new object</Link> */}
    
    {state.user.v?.insider == null && <div class="mt-2">
      <NavLink 
        href={`/insiders`} 
        class="flex gap-1.5 items-center px-2 py-1.5 hover:bg-zinc-100 hover:text-zinc-800">
        <OutlineIcon icon="sun" class="w-4 h-4 text-zinc-500" /><div class="flex-grow">Become Insider</div>
      </NavLink>
    </div>}

    <hr class="mx-2 my-2 border-t-[1px] border-zinc-200" />
    
    <div class="mt-2">
      {/* <NavLink class="flex gap-1.5 items-center px-2 py-1.5" href={`/editor`} activeClasses="bg-zinc-100 text-zinc-800">
        <OutlineIcon icon="pencilSquare" class="w-4 h-4 text-zinc-500"></OutlineIcon><div class="flex-grow">Editor</div>
      </NavLink> */}

      <NavLink class="flex gap-1.5 items-center px-2 py-1.5 hover:bg-zinc-100 hover:text-zinc-800" href={`/`}>
        <OutlineIcon icon="user" class="w-4 h-4 text-zinc-500" /><div class="flex-grow">Your Overview</div>
      </NavLink>

      <NavLink 
        href={`/settings`} 
        class="flex gap-1.5 items-center px-2 py-1.5 hover:bg-zinc-100 hover:text-zinc-800">
        <OutlineIcon icon="cog6Tooth" class="w-4 h-4 text-zinc-500" /><div class="flex-grow">Your Settings</div>
      </NavLink>

      <NavLink 
        class="flex gap-1.5 items-center px-2 py-1.5 hover:bg-zinc-100 hover:text-zinc-800" 
        href={`/@${state.user.v.slug}`}>
        <OutlineIcon icon="user" class="w-4 h-4 text-zinc-500" /><div class="flex-grow">Your Profile</div>
      </NavLink>
      
      {/* <Action class="flex gap-1.5 py-1.5"><OutlineIcon icon="clock" class="w-4 h-4 inline text-zinc-500" /><div class="flex-grow">Activity log</div></Action> */}
      {/* <Action class="flex gap-1.5 py-1.5">
        <OutlineIcon icon="moon" class="w-4 h-4 inline text-zinc-500" /><div class="flex-grow">Dark mode</div> */}
        {/* TODO TOGGLE */}
      {/* </Action> */}
    </div>

    <hr class="mx-2 my-2 border-t-[1px] border-zinc-200" />
    
    <NavLink 
      href="/studio" 
      class="px-2 py-1.5 flex items-center hover:bg-zinc-100 hover:text-zinc-800" 
      // activeClasses="bg-zinc-100 text-zinc-800"
      >Studio</NavLink>

    <hr class="mx-2 my-2 border-t-[1px] border-zinc-200" />

    <div class="px-2">
      <div class="flex gap-1.5 py-1.5 select-none">
        <OutlineIcon icon="userGroup" class="w-4 h-4 inline text-zinc-500"></OutlineIcon>
        <div class="flex-grow">Teams</div>
      </div>
      
      <TeamItems />

      <div class="pl-4 hover:bg-zinc-100">
        <Action class="flex gap-1.5 py-1.5">
          <OutlineIcon icon="plus" class="w-4 h-4 inline text-zinc-500"></OutlineIcon>
          <Action onclick={() => {
            navigate('/pricing');
          }} class="flex-grow">Create new team</Action>
        </Action>
      </div>
    </div>

    <hr class="mx-2 my-2 border-t-[1px] border-zinc-200" />

    <NavLink 
      href="/about" 
      class="px-2 py-1.5 flex items-center hover:bg-zinc-100 hover:text-zinc-800">About</NavLink>

    <NavLink 
      href="/pricing" 
      class="px-2 py-1.5 flex items-center hover:bg-zinc-100 hover:text-zinc-800">Pricing</NavLink>

    <NavLink 
      href="/insiders" 
      class="px-2 py-1.5 flex items-center hover:bg-zinc-100 hover:text-zinc-800">Insiders</NavLink>

    <hr class="mx-2 my-2 border-t-[1px] border-zinc-200" />

    {/* <FeedbackButton class="flex gap-1.5 py-1.5 mx-2">
      <OutlineIcon icon="chatBubbleLeftEllipsis" class="w-4 h-4 inline text-zinc-500" />
      <div class="flex-grow">Send feedback</div>
    </FeedbackButton> */}

    <Link href="/signout" class="flex gap-1.5 py-1.5 px-2 items-center hover:bg-zinc-100 hover:text-zinc-800">
      <OutlineIcon icon="arrowRightOnRectangle" class="w-4 h-4 text-zinc-500" />
      Sign out
    </Link>
  </nav>;

  return el;
}

function TeamItems() : HTMLDivElement {
  const el = <div></div>;

  sub(state.teams, () => {
    setChildren(el, state.teams.v?.map(t => {
      return <div class="pl-4 hover:bg-zinc-100">
        <Action onclick={() => {
          navigate(`/teams/${t.team.id}/settings`);
        }} class="flex gap-1.5 py-1.5">
          <div class="flex-grow">{t.team.name} (@{t.team.slug})</div>
        </Action>
      </div>
    }));
  }, el);

  return el;
}

// export function SessionRight() : HTMLDivElement {
//   const el = <div class="flex items-center justify-center"></div>;
 
//   function update() {
//     if(state.user.v?.id != null) {
//       setChildren(el, <ProfileMenu />);
//     } else {
//       setChildren(el, <Action 
//         class="text-xs font-medium text-indigo-800 py-6 px-3" 
//         onclick={() => showSignInModal()}>Sign in</Action>
//       );
//     }
//   }
//   sub(state.user, update, el);
//   update();

//   return el;
// }
import { Link } from 'ufti/src/plugin/router';
import { Action } from '../../lib/button';
import { handleDisabledCtx } from '../../lib/fieldsClean';
import { tm } from '../../lib/tailwind';

// This file implements the button from the design system.

export const btnSizeStyles = {
  'xs': 'px-2 py-0.5 gap-1 rounded-sm text-xs',
  'sm': 'px-3 py-1.5 gap-1 rounded-sm text-xs',
  'md': 'px-4 py-2 gap-1.5 rounded-sm text-sm',
  'lg': 'px-5 py-3 gap-2 rounded text-base',
}

export const btnRoleStyles = {
  'pri': 'bg-indigo-600 text-white hover:bg-indigo-700 active:bg-indigo-800 disabled:bg-zinc-300 disabled:text-zinc-50 outline-yellow-300',
  'sec': 'bg-zinc-100 text-indigo-700 hover:bg-zinc-200 active:bg-zinc-300 disabled:bg-zinc-300 disabled:text-zinc-50 outline-zinc-600 -outline-offset-2',
  'ter': 'text-indigo-700 hover:bg-zinc-100 active:bg-zinc-200 disabled:text-zinc-400 focus:bg-none outline-zinc-600 -outline-offset-2',
}

export const btnBaseStyle = 'flex justify-center items-center font-semibold outline-2 focus-visible:outline';

type btnSize = 'xs' | 'sm' | 'md' | 'lg';

type btnRole = 'pri' | 'sec' | 'ter';

function toBtnStyle(size: btnSize, role: btnRole, overrideClass?: string) : string {
  // Default we render sm btns
  if(!btnSizeStyles[size]) {
    throw new Error('Unknown size: '+size);
  }
  if(!btnRoleStyles[role]) {
    throw new Error('Unknown role: '+role);
  }
  const sizeStyle = btnSizeStyles[size];
  const roleStyle = btnRoleStyles[role];
  const baseStyle = `${btnBaseStyle} ${roleStyle} ${sizeStyle}`;
  const fullStyle = (overrideClass != null ? tm(baseStyle, overrideClass) : baseStyle);

  return fullStyle;
}

type UIButtonProps = { 
  size?: btnSize,
  role?: btnRole,
  class?: string,
} & HTMLButtonElement;

export function UIButton({ size = 'sm', role = 'pri', class: klass, ...others } : UIButtonProps, children) : HTMLButtonElement {
  const fullStyle = toBtnStyle(size, role, klass);
  
  const el = <Action class={fullStyle} {...others}>{children}</Action>;

  handleDisabledCtx(el);

  return el;
}

type UILinkButtonProps = { 
  size?: btnSize,
  role?: btnRole,
  class?: string,
} & HTMLAnchorElement;
export function UILinkButton({ size = 'sm', role = 'pri', class: klass, ...others } : UILinkButtonProps, children) : HTMLAnchorElement {
  const fullStyle = toBtnStyle(size, role, klass);

  return <Link class={fullStyle} {...others}>{children}</Link>;
}
// A generic status component for long-running processes. Simple for code. Generic UI.

import { unexpected } from "graffe-shared/src/lib/devflow";
import { Signal, prepend, remove, signal, sub } from "ufti";
import { toggleClass } from "../../lib/ui";
import { reportFailed } from "../../types/notifications";
import { DefaultProcessReporterUI } from "./DefaultProcessReporterUI";

const processReporterState : {
  wrapper: HTMLDivElement,
  outlet: HTMLDivElement,
  ongoing: Signal<Set<ProcessReporter>>,
} = {
  outlet: null,
  wrapper: null,
  ongoing: signal(new Set()),
}

// Container to mount mouse-following or single instance containers like context menu's, column stats, ...
export function ProcessReporterOutletSingleton() : HTMLDivElement {
  if(processReporterState.wrapper) {
    unexpected();
  }
  processReporterState.wrapper = <div class="fixed hidden inset-x-0 bg-transparent bottom-1.5 z-[9999]">
    {processReporterState.outlet = <div class="mx-auto text-xs flex flex-col gap-2 w-[320px]" />}
  </div>;

  return processReporterState.wrapper;
}

function updateVisibility() {
  toggleClass(
    processReporterState.outlet.children.length == 0, 
    processReporterState.wrapper, 
    'hidden', 
  );
}

// Lightweight utility class which shows after 300ms of ongoing a status msg to the user.
//
// TODO: implement progressbar when a value is provided between 0-100.
export class ProcessReporter {
  // The message to show
  message: Signal<string> = signal(''); 

  // 0-100 progress, can be used by a status bar
  progress: Signal<number> = signal();

  status: Signal<'started' | 'done'> = signal('started');

  // The timeout before the UI shows, if not completed or cancelled.
  private showTimeout: number; 

  // The mounted UI element
  private el: HTMLDivElement;

  // The UI element which is triggered
  public ui = DefaultProcessReporterUI;

  constructor(message: string, opts?: { showAfterMs: number }) {
    const { showAfterMs = 300 } = opts ?? {};
    opts = { showAfterMs };
    this.message.v = message;
    this.showTimeout = setTimeout(() => this.show(), opts.showAfterMs);
  }

  // Keep buttons disabled for the duration of the process
  disableBtn(btn: HTMLButtonElement) {
    if(this.status.v === 'done') return;
    btn.disabled = true;
    sub(this.status, () => {
      if(this.status.v === 'done') {
        btn.disabled = false;
      }
    }, this.el);
  }

  show() {
    // Mount component
    if(this.status.v === 'started') {
      this.el = this.ui({ process: this });
      prepend(processReporterState.outlet, this.el);
      updateVisibility();
    }
  }

  done(err?: Error) {
    // Showing an error more is better than swallowing it
    if(err) {
      console.error(err);
      reportFailed(err);
    }
    if(this.status.v === 'done') return; // Already ended.
    this.status.v = 'done';
    clearTimeout(this.showTimeout);

    // If already mounted, we remove the element
    if(this.el && this.el.parentElement != null) {
      remove(this.el);
      updateVisibility();
    }
  }
}

import { onEnter, remove } from "ufti";
import { missionStatement } from "../../graffe.app/components/About/Mission";
import { getGuidanceState, markGuidanceBurned, markGuidanceShown } from "../../lib/guidance";
import { OutlineIcon } from "../../lib/icons";
import { wrapAsync } from "../../lib/logger";
import { ClickTip } from "../../lib/tooltips/tooltips";
import { switchClass } from "../../lib/ui";
import { Link } from "ufti/src/plugin/router";

export default function AlphaBanner() : HTMLDivElement {
  const prefix = 'alpha.banner.v2'
  const el = <div class="hidden fixed bottom-0 left-0 right-0 bg-indigo-600 text-white px-2 items-center gap-2">
    <div class="text-xs py-1">Graffe is in its early, alpha stage, with the foundation live. The tables layer is pretty stable, but we're still improving it. As we shape Graffe to fulfill <ClickTip tip={missionStatement} class="inline">the mission</ClickTip>, anticipate occasional changes and improvements. Thanks for being part of our journey. <Link href="/pricing" class="hover:underline">Become a sponsor</Link>.</div>

    <div class="m-0.5 p-2 rounded-sm hover:bg-zinc-100 hover:text-zinc-800 cursor-pointer">
      <OutlineIcon onclick={wrapAsync(burn)} class="w-5 h-5" icon="xMark" />
    </div>
  </div>;

  onEnter(el, wrapAsync(async () => {
    const s = await getGuidanceState(prefix);
    if(!s.burnTime) {
      switchClass(true, el, 'flex', 'hidden');
      markGuidanceShown(prefix);
    }
  }));

  async function burn() {
    await markGuidanceBurned(prefix);
    remove(el);
  }

  return el;
}

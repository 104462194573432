import { onEnter, setChildren } from "ufti";
import { Connection, UnsealedConnection } from "../../connections/types";
import store from "../../idb/store";
import { state } from "../../state";

export default function ConnectionList() {
  const el = <div class="flex flex-col gap-2"></div>;
  
  onEnter(el, async () => {
    const connections = await (await store()).getConnections(state.user.v.id);
    const unsealed = await Promise.all(connections.map(c => c.unseal()));
    setChildren(el, unsealed.map(c => {
      return <ConnectionItem conn={c} />
    }))
  });

  return el;
}

function ConnectionItem({ conn } : { conn: UnsealedConnection }) {
  const el = <div class="flex gap-2 justify-between">
    <div>{conn.data.module}</div>
    <div>{conn.ext.collection.slug.v}/{conn.ext.ref.v}</div>
    <div>Edit controls in development</div>
  </div>;

  return el;
}
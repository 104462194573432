import { compact } from "lodash-es";
import { head } from "ufti/src/plugin/head";
import { unsupported } from "graffe-shared/src/lib/devflow";
import { DataType } from "graffe-shared/src/models/nodes";
import { ObjectInstance } from "../models/data";
import { ObjectCtx } from "../nodeCtxs/objectCtx";
import { basicTableModuleLibrary } from "../tables/libraryUI";
import { basicViewLibrary } from "../views/library";

function prettyNameFor(dataType: DataType, module: string) : string {
  if(dataType === DataType.ViewV1) {
    return basicViewLibrary.find(d => d.module === module).displayName;
  } else if (dataType === DataType.TableV1) {
    return basicTableModuleLibrary.find(d => d.module === module).displayName;
  } else if (dataType === DataType.DashV1) {
    return 'Dashboard';
  } else {
    unsupported();
  }

  return `${dataType}.${module}`;
}


export function setHtmlHeadMetaProps(ctx: ObjectCtx) {
  const instance = ctx.open.v?.data as ObjectInstance;

  // Set HTML title
  // If the title is set, this creates a "The title - Chart by @username - graffe.app" title.
  const parts = [
    instance.title?.text.v?.length > 0 ? instance.title?.text.v : prettyNameFor(ctx.open.v.ext.dataType.v, instance.module?.v),
    `by @${ctx.open.v.ext.collection.slug.v}`
  ]
  const title = compact(parts).join(' ');

  // TODO: process the page and grab this from the description content.
  // TODO: markdown and strip tags.
  const description = instance.info.v || '';

  // TODO: generate screenshots and set og:image

  // TODO: lookup <meta name="robots" content="index, follow"> config from other social data pages.

  setStaticHeaderTags(title, description);
}

export function setStaticHeaderTags(title: string, description?: string) {
  if(description == null) {
    description = 'A collaborative and trustworthy data network that allows you to discover and work with all your data. Share with the world, your team or keep it private.';
  }
  head.title.main.v = title;
  head.meta.v = {
    ...head.meta.v,
    
    'og:title': { name: 'og:title', content: title },

    description: { name: 'description', content: description },
    'og:description': { name: 'og:description', content: description },
  }
}

import { Options, createPopper } from "@popperjs/core";
import { append, onEnter, remove } from "ufti";
import { UIButton } from "../../components/ui/buttons";
import { getGuidanceState, markGuidanceBurned, markGuidanceShown } from "../guidance";
import { OutlineIcon } from "../icons";
import { wrapAsync } from "../logger";
import { tm } from "../tailwind";
import * as style from './styles.module.css';
import { addCtx, findCtx } from "ufti/src/plugin/ctx";

export class TooltipCtx {
  public tip: HTMLElement;
  constructor(tip: HTMLElement) {
    this.tip = tip;
  }
}

export function ToolTip({ class: klass, prefix, btnGotIt } : { class: string, prefix: string | null, btnGotIt?: boolean }, content) : HTMLDivElement {
  const el = <div role="tooltip" class={`${style.popperTip} text-zinc-100 bg-zinc-800 rounded-sm px-3 py-2 text-xs font-normal flex flex-col z-[9999]`}>
    <div class={klass||''}>
      {content}
    </div>

    {btnGotIt && <div class="mt-4 w-full flex justify-end">
      <UIButton onclick={e => {
        e.stopPropagation(); // Don't focus on this element.
        remove(el);
        prefix && markGuidanceBurned(prefix);
      }}>I got it</UIButton>
    </div>}

    <div class={style.popperArrow} data-popper-arrow></div>
  </div>;

  addCtx(el, new TooltipCtx(el));

  onEnter(el, () => prefix && markGuidanceShown(prefix));

  return el;
}

interface tooltipHelperOptions {
  btnGotIt?: boolean,
  tip?: Options,
  delay?: number,
}

// Integrated helper for easily showing and burning tooltips using the prefix system.
// Just pass a prefix with a content and only when not burned it will be shown.
// Later we can add more complex burn logic and burning whole groups, eg: "stop showing me tips".
// Good prefixes form an "action"-based structure which follows the component hierarchy a bit, eg: tips.studio.sidebar.commitChanges
export async function tooltipInSelf(prefix: string | null, parent: HTMLElement, opts: tooltipHelperOptions, content: HTMLElement) {
  // If already shown, burn it.
  if(prefix) {
    const s = await getGuidanceState(prefix);
    if(s.burnTime) {
      return;
    }
  }
  
  // Show the tooltip with optional delay
  setTimeout(() => {
    const tip = <ToolTip prefix={prefix} btnGotIt={opts.btnGotIt}>{content}</ToolTip>
    onEnter(parent, () => {
      append(parent, tip);
      createPopper(parent, tip, opts.tip);
    });
  }, opts.delay ?? 0);
}

export function ClickTip({ tip, class: klass, ...props }, content) : HTMLDivElement {
  const el = <div 
    class={tm('hover:underline cursor-pointer', klass)} 
    onclick={wrapAsync(() => tooltipInSelf(
      null, 
      el, 
      {},
      (
        <div class="flex items-center">
          <div class="flex-1">{tip}</div>
          <div class="p-0.5 m-0.5 hover:cursor-pointer" onclick={e => {
            e.stopPropagation();
            const ctx = findCtx(e.target, TooltipCtx);
            remove(ctx.tip);
          }}><OutlineIcon icon="xMark" class="w-4 h-4" /></div>
        </div>
      )
    ))}
    {...props}>{content}</div>;

  return el;
}
import { round } from 'lodash-es';
import { setChildren } from "ufti";
import { onEntered } from "../../../lib/extUfti";
import { wrapAsync } from "../../../lib/logger";
import { state } from "../../../state";
import { tm } from '../../../lib/tailwind';
import { SponseeCtx, resolveSponsorshipsFor } from '../sponsor/lib';

export default function CompletionStatus({ class: klass }) {
  const el = <div class={tm(klass, '')} />

  onEntered(el, SponseeCtx, wrapAsync(async (sponseeCtx) => {
    const formatter = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'USD' });

    // Collect current sponsorships
    const sponsorships = await resolveSponsorshipsFor(sponseeCtx.id.v);
    const totalAmount = sponsorships.reduce((acc, row) => {
      return acc += row.amount;
    }, 0);
    const hasMe = sponsorships.find(s => s.sponsor === state.user.v?.id);

    const goal = 500;
    const pct = (100/1000)*(totalAmount/100);
    const cnt = sponsorships.length - (hasMe ? 1 : 0);

    setChildren(el, <>
      <div class="text-base font-semibold text-indigo-800">{round(pct, 1)}% towards next goal of {formatter.format(goal).replace('US','').replace('.00','')} a month</div>
      
      <div class="mt-0.5 w-full rounded-full border-[1px] border-zinc-200 overflow-hidden">
        <div class="h-2 bg-indigo-600" style={{ width: round(pct)+'%' }}></div>
      </div>
      
      <div class="text-zinc-600 mt-1">{hasMe ? 'You and ' : ''}{cnt} others sponsor the Graffe mission.</div>
    </>)
  }));

  return el;
}

// @ts-ignore
import "urlpattern-polyfill"; // Always import it to avoid the mess with the build.
import { createElement } from '../createElement.js';
import { Signal, signal, sub } from '../signal.js';
import { PureCallback } from '../types.js';

const resetDupes = signal();

// Reactive state of the router
export const router: {
  location: Signal<Location>,
  active: Signal<string[]>,

  // Set to the search params when a new href loads.
  search: Signal<string>,

  // Configure a function which has to yield true or navigation is cancelled.
  leaveRouteValidator?: () => Promise<boolean>,
} = {
  location: signal<Location>(),
  active: signal<string[]>([]),
  search: signal<string>(),

  leaveRouteValidator: null,
}

// Tracks the remaining paths per router group, as defined in route definition.
const groupPaths: Record<string, string> = {};

// always on load we parse the route
let lastHref;
sub(resetDupes, () => lastHref = null, null, { blocking: true });
function urlToState() {
  const href = window.location.href;

  // Check to ensure we only execute once per href change
  if(lastHref === href) return;

  // Set properties
  lastHref = href;
  
  // Reset the remaining paths for every group, so they all evaluate the route fresh.
  for(let key of Object.keys(groupPaths)) {
    groupPaths[key] = window.location.pathname;
  }

  // Trigger the updates
  router.location.v = window.location;
  router.active.v = [];
  router.search.v = (window.location.search ? window.location.search.substring(1) : null);
}

interface HistoryWithNotifier extends History {
  onpushstate: (data: any, unused: string, url?: string | URL | null) => PureCallback | Promise<void>;
  onreplacestate: (data: any, unused: string, url?: string | URL | null) => PureCallback | Promise<void>;
}

function watchUrlChange() {
  (history as HistoryWithNotifier).onpushstate = (state, unused, url) => (async () => urlToState())();
  (history as HistoryWithNotifier).onreplacestate = (state, unused, url) => (async () => urlToState())();
  window.onpopstate = event => (async () => urlToState())();
  window.onhashchange = event => (async () => urlToState())();
}

// function isRoutePattern(match: string) : any {
//   const pattern = new UrlPattern(match);
//   return pattern.match(router.location().pathname);
// }

export interface Route {
  // Path-pattern to match the path
  // Follows URL semantics, / is the index route.
  // * is a fallback catch-all route.
  path: string;

  // Handler to call when this route is matched
  // This can be anything
  content: any; 
}

// Very simplistic router with child router support.
// Logic is simple: start with full path and consume from the path what is not yet consumed.
// Always define your leading '/route' for all routes. '/' is root.
//
// Provide a group if you have child routers active. Then it will consume from the route according to what routers use.
//
// If the router is relative, it consumes hierarchically from what is not yet consumed by other route definitions.
// This way components can embed their own routers and still deep match on everything.
export function onRoutes(routes: Route[], handler: (route: Route, match: any) => void, exitEl: Element, group?: string) : PureCallback {
  routes = [...routes];

  // Initialize the group if needed
  if(group != null && groupPaths[group] == null) {
    groupPaths[group] = window.location.pathname;
  }

  // Pull fallback route out of the routes
  const fallbackIdx = routes.findIndex(r => r.path === '*');
  const fallback = (fallbackIdx >= 0 ? routes.splice(fallbackIdx, 1)[0] : null);

  // Pull root route out of the routes
  const rootIdx = routes.findIndex(r => r.path === '');
  const root = (rootIdx >= 0 ? routes.splice(rootIdx, 1)[0] : null);

  // Make patterns once
  const patterns = routes.map(r => new URLPattern({ pathname: r.path }));

  // The last path evaluated for this router group.
  let lastPath: string;

  // Reset last path if its requested
  sub(resetDupes, () => lastPath = null, exitEl, { blocking: true });

  return sub(router.location, location => {
    (() => {
      const remainingPath = (group != null ? groupPaths[group] : location.pathname);
      
      // Load root if the path is consumed and we have a root element
      if(remainingPath === '' && root) {
        router.active.v = [...router.active.v, ''];
        handler(root, {});
        return;
      }

      // See if we can load a route which matches the consumable path
      if(remainingPath.length > 0) {
        let match;
        
        // Search route patterns for a match on the remaining path
        const idx = patterns.findIndex(p => match = p.exec(self.origin+remainingPath));
        if(idx >= 0) {
          const pattern = patterns[idx];

          // Get a str representation of the matching path, to be able to consume it.
          const fullPath = match.pathname.input;
          const consumedPath = (group != null && match.pathname.groups[0] != null ? fullPath.replace(match.pathname.groups[0], '') : fullPath);
          
          // Development check to ensure we don't miss cases
          if(remainingPath.substring(0, consumedPath.length) !== consumedPath) {
            console.warn('route string is different: inspect', { remainingPath, consumedPath });
            debugger;
          }
          
          // If a group is active, set the remaining path for later consumers
          if(group != null) {
            groupPaths[group] = remainingPath.substring(consumedPath.length);
          }

          // Set the new active route
          router.active.v = [...router.active.v, consumedPath, routes[idx].path];

          // Execute the callback with the matched path, only if the path changed.
          if(lastPath !== location.pathname) {
            handler(routes[idx], match.pathname.groups); 
          }

          return;
        }
      }

      // No match, fallback to the fallback route, if any.
      if(fallback != null) {
        router.active.v = [...router.active.v, fallback.path];
        handler(fallback, {});
        return;
      }
    })();

    // Burn the path, so components don't re-render without a route change.
    lastPath = location.pathname;
  }, exitEl);
}

interface NavigateOptions {
  // Replace the state, don't push a new history entry
  replaceState?: boolean;

  // If we are already on this page when navigate is called, reload the page.
  // This clears the duplicate check to allow one pass.
  reloadIfSame?: boolean;
}

export function navigate(path: string, opts?: NavigateOptions) {
  const startRef = window.location.href;
  
  // Remove the dupe check to allow reloading the page
  if(opts?.reloadIfSame && window.location.href === lastHref && window.location.pathname === path) {
    resetDupes.v = true;
  }

  setTimeout(async () => {
    try {
      if(router.leaveRouteValidator != null) {
        if(!(await router.leaveRouteValidator())) {
          return;
        }
      }
      if(startRef !== window.location.href) {
        return; // race
      }
      // TODO support for external URLs?
      router.leaveRouteValidator = null;
      if(opts?.replaceState) {
        history.replaceState({}, '', path);
      } else {
        history.pushState({}, '', path);
      }
    } catch (err) {
      throw err;
    }
  }, 0);
}

// TODO test class/style/extension support
export function Link({ href, replaceState, ...otherProps }, children) : HTMLAnchorElement {
  return createElement('a', {
    ...otherProps,
    href,
    onclick: (e) => {
      // TODO support external URLs
      if(otherProps.onclick) otherProps.onclick(e);
      e.preventDefault();
      const opts = {
        replaceState: !!replaceState,
      }
      navigate(href, opts);
    },
  }, ...children) as HTMLAnchorElement;
}

// Utility function to create navigation menus
export function subscribeActive(route, handler: (isActive: boolean) => void) {
  router.active.sub(v => handler(v.indexOf(route) >= 0));
}

export function NavLink(props, children) : HTMLAnchorElement {
  const el = Link(props, children);
  let { activeClasses } = props;
  activeClasses = (typeof activeClasses === 'string' ? activeClasses.split(' ') : activeClasses);
  subscribeActive(props.href, isActive => (isActive ? el.classList.add : el.classList.remove).bind(el.classList)(...(activeClasses||['active'])));
  return el;
}

let started = 0;
export function startRouter() {
  if(started) return;
  started = 1;

  // Monkey patch pushState & replaceState to capture state changes
  (function(history) {
    // The original
    const pushState = history.pushState;
    history.pushState = function(...args) {
      // This might throw first
      const res = pushState.apply(history, args);
      // Call our callback
      if (typeof (history as HistoryWithNotifier).onpushstate === 'function') {
            (history as HistoryWithNotifier).onpushstate(...args);
      }
      // Return original result
      return res;
    }

    // The original
    const replaceState = history.replaceState;
    history.replaceState = function(...args) {
      // This might throw first
      const res = replaceState.apply(history, args);
      // Call our callback
      if (typeof (history as HistoryWithNotifier).onreplacestate === 'function') {
            (history as HistoryWithNotifier).onreplacestate(...args);
      }
      // Return original result
      return res;
    }
  })(window.history);

  // Run once on load
  watchUrlChange();
  urlToState();
}

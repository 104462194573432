import { onEnter } from "ufti";
import AlphaBanner from "../../../components/boring/AlphaBanner";
import { setStaticHeaderTags } from "../../../lib/metadata";
import PageFooter from "../components/PageFooter";
import PageTop from "../components/PageTop";
import { applyProductPageStyle } from "../helpers";
import Benefitters from "./Benefitters";
import Callout from "./Callout";
import Examples from "./Examples";
import FeatureBlocks from "./FeatureBlocks";
import Hero from "./Hero";
import Mission, { missionStatement } from "./Mission";
import VideoIntro from "./VideoIntro";

export default function About() {
  const el = <div class="w-full max-w-[1280px] mx-auto px-3 md:px-5">
    <PageTop />

    <Hero class="mt-6" />

    <Callout class="mt-[56px] lg:mt-[72px]" label="Features">Imagine a collaborative data network that allows you to discover and play with all your data. Share with the world, your team or keep it private.</Callout>
    {/* Some short text to describe in general what graffe is about, its core value and features */}

    <FeatureBlocks class="mt-12 lg:mt-16" />

    <Callout class="mt-[72px] lg:mt-[120px]" label="Showcase" isFigure={true}>
      A quick intro how to use Graffe
    </Callout>

    <VideoIntro class="mt-5 lg:mt-10 mx-auto" />

    <Mission id="mission" class="mt-[80px] mb-[40px] lg:mt-[160px] lg:mb-[80px]">{missionStatement}</Mission> 

    <div class={`w-full max-w-[880px] mx-auto flex flex-col items-center gap-4 justify-between`}>
      <div class="font-semibold text-xl lg:text-2xl text-indigo-400 text-center">What we aim for</div>
      <h2 class="text-zinc-900 text-lg text-center">
        <div class="flex flex-col gap-4 rounded-xl bg-[#F1F1F4] p-4">
          {/* <div>When the process of doing analysis on a topic changes from hours to seconds thanks to quickly accessible data, then curiosity is triggered and the whole workflow changes.</div>

          <div>When collaboration on data happens, with trust at the core of it all, then overall quality of the data and reporting increases, which leads to more trustworthy reporting being shared.</div>

          <div>Although a grandiose vision, when we achieve this, this will lead to: better decisions, better research, higher quality reporting, and in case of open data: more positive discourse.</div> */}

          <div>When the time taken to analyze a topic shrinks from hours to seconds due to readily available data combined with efficient tooling, curiosity is sparked, transforming the entire workflow.</div>

          <div>When data collaboration takes place, with transparency and trust as fundamental elements, the quality of both the data and subsequent reporting improves, resulting in more reliable reports being disseminated.</div>

          <div>Although ambitious, achieving this will result in improved decision-making, enhanced research, higher quality of reports, and in the case of open data, a more constructive discourse.</div>
        </div>
      </h2>
    </div>

    <Callout class="mt-[72px] lg:mt-[120px]" label="Value">Who will benefit?</Callout>

    <Benefitters class="mt-12 lg:mt-16" />

    <Examples class="mt-[72px] lg:mt-[120px]" />

    <PageFooter class="mt-16" />

    <AlphaBanner />

  </div>;

  applyProductPageStyle(el);

  onEnter(el, () => {
    setStaticHeaderTags(
      'Your trustworthy data universe', 
    );
  });

  return el;
}

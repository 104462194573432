import { ConnectionModuleId } from "graffe-shared/src/types/connections";
import { JSONObject } from "graffe-shared/src/types/json";
import { onEnter, onExit, remove, setChildren, signal, sub } from "ufti";
import { addCtx, findCtx, setCtxParent } from "ufti/src/plugin/ctx";
import { basicConnectionLibrary, connectionModules } from "../../connections/library";
import urlState from "../../types/urlState";
import { showInDialog } from "../ProcessReporter/Dialogs";
import { UIButton } from "../ui/buttons";
import { PostCreateCallback } from "./types";


export const enum CreateConnectionFlow {
  createConnection = "createConnection",
}

export const enum CreateConnectionStep {
  selectModule = 'selectModule',
  configureModule = 'configureModule',
}

interface mySubState {
  flow: CreateConnectionFlow;
  step: CreateConnectionStep;
  bag: JSONObject;
}

// A component which activates in a dialog based on url state and can be injected and appear in multiple flows.
export default function CreateConnectionFlowRouter() {
  const el = <div></div>;

  let dialog;

  onEnter(el, () => {
    sub([urlState.subState, signal(1)], () => {
      if(urlState.subState.v?.flow === CreateConnectionFlow.createConnection) {
        if(!dialog) {
          // Activate the dialog
          const outlet = <CreateConnectionOutlet />;
          dialog = showInDialog(outlet, el);
          onExit(dialog, () => urlState.subState.v = null);
        }
      } else {
        // Destroy dialog
        if(dialog) {
          remove(dialog);
          dialog = null;
        }
      }
    }, el);
  });

  return el;
}

function CreateConnectionOutlet() {
  const el = <div></div>;

  onEnter(el, () => {
    sub(urlState.subState, () => {
      const s = urlState.subState.v as mySubState;
      if(s.step === CreateConnectionStep.selectModule) {
        setChildren(el, <ConnectionModuleSelector />);
      } else if(s.step === CreateConnectionStep.configureModule) {
        setChildren(el, <ConnectionConfigure />);
      }
    }, el)
  });

  return el;
}

function ConnectionModuleSelector() {
  const el = <div>
    {basicConnectionLibrary.map(item => {
      return <div>
        <UIButton onclick={() => addConnection(item.module)}>{item.displayName}</UIButton>
      </div>
    })}
  </div>;

  function addConnection(module: ConnectionModuleId) {
    urlState.subState.v = { 
      ...urlState.subState.v, 
      module, 
      step: CreateConnectionStep.configureModule,
    };
  }

  return el;
}

function ConnectionConfigure() {
  const el = <div></div>;

  onEnter(el, async () => {
    // TODO: add process reporter
    const connectionModule = await connectionModules[urlState.subState.v?.module]();
    if(connectionModule?.PostCreate) {
      setChildren(el, connectionModule?.PostCreate())
    }

    // NEXT: 
    // Configure the URL by loading strava module, save the connection, then redirect to strava, then save the session on the connection, then broadcast to reload the session, then use the session in the strava module and define an activities table and POOF we are ready.
    // Re-auth we do later.
  });

  return el;
}
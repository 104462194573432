import { round } from 'lodash-es';
import { onEnter, onExit, Signal } from 'ufti';
import { PureCallback } from "ufti/src/types";

export function toggleClass(outcome: any, el: HTMLElement, ...classes: string[]) {
  if(outcome) {
    el.classList.add(...classes);
  } else {
    el.classList.remove(...classes);
  }
}

// export function switchStyles(outcome: any, el: HTMLElement, onStyles: Record<string, string>, offStyles: Record<string, string>) {
//   if(outcome) {
//     Object.assign(el.style, {...onStyles});
//   } else {
//     Object.assign(el.style, {...offStyles})
//   }
// }

export function switchClass(outcome: any, el: HTMLElement, onClasses: string, offClasses: string) {
  const onClassList = onClasses.split(' ');
  const offClassList = offClasses.split(' ');
  if(outcome) {
    for(let cls of offClassList) if(el.classList.contains(cls)) el.classList.remove(cls);
    for(let cls of onClassList) if(!el.classList.contains(cls)) el.classList.add(cls);
  } else {
    for(let cls of onClassList) if(el.classList.contains(cls)) el.classList.remove(cls);
    for(let cls of offClassList) if(!el.classList.contains(cls)) el.classList.add(cls);
  }
}

export function setValueUntilExit(el: Element, sig: Signal<any>, value: any) {
  onEnter(el, () => {
    let prev = sig.v;
    sig.v = value;
    onExit(el, () => sig.v = prev);
  });
}

export function nearestGrid(v: number, snapSize: number) : number {
  return round(v / snapSize) * snapSize;
}

// Expand the height since h-screen class doesn't work well on some browsers
export function keepHeightScreen(el: HTMLElement, heightUpdate: (inner: number) => number) : PureCallback {
  const updateHeight = () => {
    el.style.height = `${heightUpdate(window.innerHeight)}px`;
    // el.style.maxHeight = `${heightUpdate(window.innerHeight)}px`;
  }
  window.addEventListener('resize', updateHeight);
  onExit(el, () => window.removeEventListener('resize', updateHeight));
  updateHeight();

  return updateHeight;
}

export function getPageLeft(element: HTMLElement): number {
  let pageLeft = 0;
  while (element) {
    pageLeft += (element.offsetLeft - element.scrollLeft + element.clientLeft);
    element = element.offsetParent as HTMLElement;
  }

  return pageLeft;
}

export function getPageTop(element: HTMLElement): number {
  let pageTop = 0;
  while (element) {
    pageTop += (element.offsetTop - element.scrollTop + element.clientTop);
    element = element.offsetParent as HTMLElement;
  }
  
  return pageTop;
}

import { onEnter, setChildren, sub } from "ufti";
import { OutlineIcon } from "../../lib/icons";
import { ProcessReporter } from "./ProcessReporter";

// Appears in the bottom and has a status bar
export function DefaultProcessReporterUI({ process } : { process: ProcessReporter }) {
  let contentDiv: HTMLDivElement;
  const el = <div class="px-2 py-1.5 rounded bg-indigo-600 text-white flex flex-row gap-1.5 items-center">
    <OutlineIcon icon="arrowPath" class="animate-spin h-4 2-4" />
    {contentDiv = <div />}
  </div>;
  
  onEnter(el, () => {
    sub([process.message, process.progress], () => {
      setChildren(contentDiv, <>
        {process.message.v}
      </>);
    }, el);
  });

  return el;
}
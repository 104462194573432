
// TODO: merge previous callout with this into a wrapped structure for SEO
// <Callout class="mt-[72px] lg:mt-[120px]" label="Showcase" isFigure={true}>
//       A minimal example of using tables to create charts and dashboards.
//     </Callout>

// <figure>
//     <figcaption>
//         A quick introduction to creating tables, charts and dashboards in Graffe.
//     </figcaption>
//     VIDEO HERE
// </figure>

// TODO: poster html5
// TODO: Add full article section with transcript. 
export default function VideoIntro({ class: klass }) {
  const el = <div class={`rounded-[24px] bg-white p-[5px] h-fit max-w-[1288px] ${klass||''}`}>
    <div class="relative rounded-[24px] bg-zinc-600 overflow-hidden h-full">
      <video controls style="height: auto; width: 1278px">
        {/* <source src="/_assets/graffe-app/about/showcase.mp4" type="video/mp4" /> */}
        <source src="https://pub-182c943669ee4c7296fd0f26c6374fd3.r2.dev/ECXCWBW255CRHHZOURUBLKT2WI/graffe-app/showcase2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>; 

  return el;
}
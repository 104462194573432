import { isNaN, snakeCase } from 'lodash-es';
import { unsupported } from "graffe-shared/src/lib/devflow";
import { TableData, TableModuleId, TypeTableModuleId } from "graffe-shared/src/models/table";
import { JSONObject, JSONValue } from "graffe-shared/src/types/json";
import { CommitHash } from "graffe-shared/src/types/types";
import { getStorageModel } from "graffe-shared/src/universe/utils";
import { Signal, computed, signal } from "ufti";
import { DataField, DataFieldOverride, mergeFields } from "../lib/dataField";
import { ObjectInstance } from "../models/data";
import { reportFailed, reportInfo } from "../types/notifications";

export const defaultSqlQuery = `select 'hello universe' as placeholder`;

const isValidNumber = (value) => {
  return !isNaN(parseInt(value));
};

interface TransformConfig {
  // The runtime which executes the code. 
  // When null or empty string, then no transformation is done.
  runtime: Signal<'js'>;

  // The script string
  script: Signal<string>;
}

interface ParseConfig {
  // The parser which is called. If null, no parsing is done.
  parser: Signal<'json' | 'csv'>;

  // Parser configuration. This should be a details config where no library defaults are active.
  props: Signal<JSONObject>;
}

// Table definition.
// It is opaque to the implementation but holds all the Table configuration.
//
// Tables internally [FETCH | PARSE | TRANSFORM] and emit DataSlices after this.
// Parse and transform are common actions which can both be complex, and can be reused by many modules.
//
// Under the hood, for efficiency, the parsing and transforming is handled by the module, which emits a single data slice stream after.
export default class Table {
  info: Signal<string> = signal();
  
  // NEW: LATER
  // defaultAlias: Signal<string> = signal();

  module: Signal<TypeTableModuleId> = signal();
  
  // Props controlled by the module
  props: Signal<JSONValue> = signal();

  deps: Signal<Table[]> = signal();

  // Fields are the "processed" fields. 
  // It's computed by merging inferred and field overrides. Might contain either inferred fields, override fields, or something more which we add later.
  // Computed on the fly, not stored.
  fields: Signal<DataField[]>;

  // Inferred fields by the system after transforming the data.
  // Think of this as the input fields before we pass and output the table.
  iFields: Signal<DataField[]> = signal();

  // Optional override field definitions to correct system-inferred properties. 
  // When set, it superseeds the inferred fields in the output to the next reap.
  // Think of this as the output fields before we pass and output the table.
  oFields: Signal<DataFieldOverride[]> = signal();

  // Parse config defines how to evaluate the module data stream and convert it into data slices (None, JSON, CSV, Parquet, Avro, PgSQL, ...).
  // It can customized
  parse: ParseConfig;

  // Transform configuration, which can be configured on any table.
  // Transforms execute on the output of any table.
  transform: TransformConfig;

  // Can be set to a version number of the last migration done.
  migration: Signal<number> = signal();

  static fromData(data: TableData, deps?: Signal<Table[]>) : Table {
    const tab = new Table();
    tab.loadFromData(data);

    // If deps are passed, we load from them
    if(deps) {
      tab.deps = deps;
    }

    return tab;
  }

  loadFromData(data: TableData) {
    // Run data migrations
    data = migrateTableData(data);

    // Initialize object
    this.module.v = data.module;
    this.info.v = data.info;
    this.props.v = data.props || {};
    this.deps.v = data.deps?.map(d => Table.fromData(d));
    this.iFields.v = data.iFields?.map(d => DataField.fromData(d));
    this.oFields.v = data.oFields?.map(d => DataFieldOverride.fromData(d));
    this.migration.v = data.migration;

    // Create the parse configuration
    this.parse = {
      parser: signal(data.parse?.parser ?? null),
      props: signal(data.parse?.props ?? null),
    }

    // Create the transform, if any.
    this.transform = {
      runtime: signal(data?.transform?.runtime ?? null),
      script: signal(data?.transform?.script ?? null),
    };

    // Create fields signal
    this.fields = computed(
      [this.iFields, this.oFields], 
      () => {
        try {
          return mergeFields(this.iFields.v, this.oFields.v)
        } catch (err) {
          reportFailed(err.message);
          return [];
        }
      },
      null,
      { blocking: true },
    );
  }

  getStorageModel() : TableData {
    // We drop fields
    const { fields, ...other } = this;

    return getStorageModel(other);
  }

  getDeps() : Signal<ObjectInstance[]> {
    return this.deps;
  }

  hasDependency(commitHash: CommitHash, fullRef: string) : boolean {
    return this.deps.v?.findIndex(d => {
      return d.module?.v === TableModuleId.GraffeTableV1 && ((commitHash != null && d.props.v?.commit === commitHash) || d.props.v?.ref === fullRef);
    }) >= 0;
  }

  // Add a dependency and return the alias (automatically generated if missing)
  addDependency(commitHash: CommitHash, fullRef: string, alias?: string) : string {
    if(this.module.v !== 'SqlV1') unsupported();

    if(!alias) {
      alias = fullRefToDefaultAlias(fullRef);
    }

    this.deps.v = [
      ...this.deps.v||[],
      Table.fromData({
        module: TableModuleId.GraffeTableV1,
        props: {
          alias,
          ref: fullRef,
          commit: commitHash,
        },
      })
    ];

    // If we don't have a SQL yet - we initiate a statement for showing this alias
    if(this.props.v?.query == null || this.props.v?.query?.trim().length == 0) {
      this.props.v = {
        ...this.props.v||{},
        query: `select * from ${sqlWrapTableName(alias)}`,
      }
    }

    return alias;
  }
 
}

export function fullRefToDefaultAlias(str: string) : string {
  const parts = str.split('/');
  return snakeCase(parts[parts.length-1]);
}

export function sqlWrapTableName(alias: string) : string {
  return isValidNumber(alias) ? '"'+alias+'"' : alias;
}

function migrateTableData(data: TableData) : TableData {
  // The old migrations
  if(!data.migration) {
    if(data.module === 'FileTableV1') {
      data.module = 'FileV1';
      reportInfo('Migrated data type');
    }

    // Migrate the parsing
    if(data.props?.format?.length > 0 || data.props?.selector?.length > 0) {
      data.parse = {};
    }
    if(data.props?.format?.length > 0) {
      data.parse.parser = data.props.format;
      delete data.props.format;
    }

    if(data.props?.selector?.length > 0) {
      if(data.props?.format === 'xlsx') {
        data.parse.props = { sheet: data.props.selector };
      } else {
        data.parse.props = { selector: data.props.selector };
      }
      delete data.props.selector;
    }

    // Migrate the transform
    if(data.props?.transform?.script) {
      data.transform = {
        runtime: 'js',
        script: data.props?.transform?.script,
      }
      delete data.props.transform;
      reportInfo('transform migrated');
    }
  }

  // Migrate the buggy module/runtime mess
  // This is where we start with migrations
  if(!data.migration || data.migration < 1) {
    if(data.transform?.script?.trim().length > 0 && data.transform?.runtime == null) {
      data.transform.runtime = 'js';
      data.migration = 1;
    }
  }
  
  return data;
}
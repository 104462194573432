import { append } from './render.js';
import { Signal, sub } from './signal.js';
import { UftiFragment } from './types.js';

// Here we should capture the render tree instead of initializing the tree.
// Rendering then is a process of calling the different renderers later.
// This would allow sub-based/signal-based rendering.
// When we have signal based rendering, we effectively can implement templates with subs, which is the holy grail.

// interface treeNode {
//   comp: string | Function | UftiFragment;
//   attrs: Record<string, string>;
//   content: treeNode[];
// }

// export function convertNode(tagNameNodeComponentFragment, attrs, ...content) : treeNode {
//   debugger;
//   if(tagNameNodeComponentFragment === UftiFragment) {
//     return {
//       comp: tagNameNodeComponentFragment,
//       content,
//     }
//   }

//   if(typeof tagNameNodeComponentFragment === 'function') {
//     const 
//   }
// }

// Create ufti elements (JSX/JS helper).
// Here we instantly create elements, where we should create element signatures that can be intercepted.
export function createElement(tagNameNodeComponentFragment, attrs, ...content) : Element | { type: Symbol, v: any } {
  // Clear content which shouldn't show in the UI (undefined, null & true/false values)
  if(content && content.length > 0) {
    for(let i = 0; i < content.length; i++) {
      // Clear content values which don't belong here
      if(content[i] == null || content[i] === false || content[i] === true) {
        content[i] = '';
      }
    }
  }

  // Return fragments as fragment type to be flattened by append
  if(tagNameNodeComponentFragment === UftiFragment) {
    return {
      type: UftiFragment,
      v: content,
    }
  }
  
  // Here we convert array members into fragments.
  // Since we don't have parent here, we cannot flatten deeper children here.
  // We pass this responsibility to the render function.
  for(let i = 0; i < content.length; i++) {
    if(Array.isArray(content[i])) {
      content[i] = createElement(UftiFragment, {}, ...content[i]);
    }
  }

  // Expand signals into span elements and subscribe for updates
  // TODO: this should be removed and use a signal rendering instead
  for(let i = 0; i < content.length; i++) {
    const c = content[i];
    if(c && c.uftiSignal == true) {
      const span = document.createElement('span');
      sub(c as Signal<any>, (v, removed) => {
        if(!removed) {
          span.innerHTML = v && v.toString();
        }
      }, span);
      content[i] = span;
    }
  }

  // Create components as needed
  if(typeof tagNameNodeComponentFragment === 'function') {
    // TODO: this should be removed, very odd.
    // If the top-level element is a proxy, subscribe for updates.
    if(tagNameNodeComponentFragment.uftiSignal == true) {
      const span = document.createElement('span');
      sub(tagNameNodeComponentFragment as Signal<any>, (v, removed) => {
        if(!removed) {
          span.innerHTML = v && v.toString();
        }
      }, span);

      return span;
    }

    // Create the component
    return tagNameNodeComponentFragment(attrs, content);
  }

  if(typeof tagNameNodeComponentFragment !== 'string') {
    // Inspect, we're not expecting this
    throw new Error('unhandled');
  }

  // TODO: support for comments
  // TODO: support for text nodes
  // Else we create a new tag
  let elem: HTMLElement | SVGElement;
  if(isSVGElement(tagNameNodeComponentFragment)) {
    elem = document.createElementNS(attrs.xmlns || 'http://www.w3.org/2000/svg', tagNameNodeComponentFragment);
  } else {
    elem = document.createElement(tagNameNodeComponentFragment);
  }
  
  // Set HTML args
  if(attrs != null) {
    const { class: klass, style, ...remaining } = attrs;

    // Add any passed classes
    if(klass) {
      elem.classList.add(...klass.split(' ').filter(c => c));
    }

    // Assign any style
    if(style) {
      if(typeof style === 'string') {
        remaining['style'] = style;
      } else {
        Object.assign(elem.style, style);
      }
    }

    if(remaining != null) {
      // Pull out all non-string/non-number values and set them directly.
      // TODO: improve this more to make it production worthy. It's just a quick hack to keep it small. Should be tested well.
      const toAssign = {};
      let hasValues = false;
      for(let [key, val] of Object.entries(remaining)) {
        if(typeof val === 'string' || typeof val === 'number' || typeof val === 'bigint') {
          elem.setAttribute(key, val.toString());
        }  else {
          hasValues = true;
          toAssign[key] = val;
        }
      }
      
      if(hasValues) {
        Object.assign(elem, toAssign);
      }
    }
  }

  // Append any content
  if(content && content.length > 0) {
    append(elem, content);
  }

  return elem;
}
export const h = createElement;

// API for JSX
export default {
  h,
  createElement,
}


// TODO: cleanup
const isSVGElement = (() => {
  const svgRe = /^(t(ext$|s)|s[vwy]|g)|^set|tad|ker|p(at|s)|s(to|c$|ca|k)|r(ec|cl)|ew|us|f($|e|s)|cu|n[ei]|l[ty]|[GOP]/; //URL: https://regex101.com/r/Ck4kFp/1
  const svgCache = {};

  return ( element: string ): boolean => {
    return ( element in svgCache ) ? svgCache[element] : ( svgCache[element] = svgRe.test ( element ) && element.indexOf ( '-' ) === -1 );
  };
})();
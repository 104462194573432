import { updateInNextTick } from "./nextTick.js";
import { $ } from "./objects.js";
import { onExit } from "./render.js";
import { PureCallback } from "./types.js";

// // Execution tracking context to collect effects
// let ctx: Signal<any>[] = null;

// Execution tracking context to collect signals which are set in batch
let batchCallbacks: PureCallback[] = null;

export type SubscribeCallback<T> = (v: T, prev: T, sig: Signal<T>) => void;
export type SubscribeManyCallback<T> = (...values: T[]) => void;
export type OnSubscriberCallback<T> = (sig: Signal<T>) => void;
export type ComputeFunc<R,T> = (v: T, prev: T, sig: Signal<T>) => R;
export type ComputeManyFunc<R,T> = (...values: T[]) => R;

interface SubscribeOptions {
  // Set to true to always receive a callback.
  // Set to false to not receive an initial callback.
  // By default (null), then it only emits if a value is defined
  initial?: boolean;

  // Bypass the batching and force a callback to be evaluated instantly (mainly interesting for computed signals)
  blocking?: boolean;

  // Set to true to only call the callback once with a value
  once?: boolean;

// TODO IMPLEMENT
//   // Force initial callback, even if undefined
//   trigger?: boolean;
}

export type SurfaceOnValueCallback<T> = (s: Signal<T>, val: T | null, prev: T) => void;

// TODO: since Set retains insert order, we can replace the arrays with sets for cleaner .has/.delete flows. Changes actions from O(N) to O(1).
export class Signal<T> {
  // The latest value the signal tracks
  // 'a' because it's short and renders in debuggers on top
  private a: T;

  // Blocking subscribers who get notified of changes
  private b: SubscribeCallback<T>[] = [];

  // Delayed subscribers who get notified of changes via batch strategies
  private s: SubscribeCallback<T>[] = [];

  // Subscriber subscribers, who get notified of new value subscribers
  // This allows lazy state setters, which activate only when the value is used.
  private w: OnSubscriberCallback<T>[] = [];

  // End callbacks, who get notified when the signal has ended.
  private e: PureCallback[] = [];

  // Top-level surfaces to bubble up changes to and to keep in sync
  private t: SurfaceOnValueCallback<T>[] = [];

  // Subs which are marked once and need to be burned after usage.
  private o: Set<SubscribeCallback<T>> = new Set<SubscribeCallback<T>>();

  // True when ended
  public isEnded: boolean = false;

  // Check to know if an object is a signal
  public readonly uftiSignal = true;

  /**
   * Create a signal
   * 
   * @param value optional initial value
   */
  constructor(value?: T) {
    if(value != null) {
      this.a = value;
    }
  }

  /**
   * Shorthand for getting value
   */
   get v() : T | null {
    return this.getVal();
  }

  /**
   * Shorthand for setting value
   */
  set v(value: T | null) {
    this.setVal(value);
  }
  
  /**
   * Get the value of the signal
   */
  get value() : T | null {
    return this.getVal();
  }

  /**
   * Set the value of the signal
   */
  set value(value: T | null) {
    this.setVal(value);
  }

  private getVal() : T | null {
    // // Track the effect if the ctx requested it
    // if(ctx != null && ctx.indexOf(this) < 0) ctx.push(this);

    // Return the value
    return this.a;
  }

  private setVal(value: T | null) {
    const prev = this.a;
    this.a = value;
    const val = value;
    
    // Notify any subscribers
    const bsubs = [...this.b];
    const subs = [...this.s];
    const tops = [...this.t];

    // Blocking subscriptions
    const burns = [];
    for(let bsub of bsubs) {
      bsub(val, prev, this);

      // Track callbacks to burn
      if(this.o.has(bsub)) {
        burns.push(bsub);
      }
    }

    // Burn used callbacks
    for(let b of burns) {
      const idx = this.b.indexOf(b);
      if(idx >= 0) {
        this.b.splice(idx, 1);
        this.o.delete(b);
      }
    }

    // Ensure surfaces are notified down the tree
    // WARN: this has a performance impact: it works well for sane data structures, not for unbounded lists!
    for(const top of this.t) {
      traverseSurface(this.a, top, false);
    }

    if(batchCallbacks != null) {
      batchCallbacks.push(() => this.setValEffects(prev, val, subs, tops));
    } else {
      this.setValEffects(prev, val, subs, tops)
    }
  }

  private setValEffects(prev: T | null, val: T | null, subs: SubscribeCallback<T>[], tops: SurfaceOnValueCallback<T>[]) {
    // Queue subscriber notification of the update
    updateInNextTick(() => {
      const burns = [];
      // Notify subscribers
      for(const sub of subs) {
        sub(val, prev, this);
        if(this.o.has(sub)) {
          burns.push(sub);
        }
      }

      for(let b of burns) {
        const idx = this.s.indexOf(b);
        if(idx >= 0) {
          this.s.splice(idx, 1);
          this.o.delete(b);
        }
      }
    }); 

    // Queue surface notification
    updateInNextTick(() => {
      // Notify any surfaces
      for(const top of tops) {
        top(this, val, prev);
      }
    }); 
  }

  /**
   * Subscribe to done to this signal changes. 
   * 
   * @param handler called in the next UI cycle when value is set. If the value is immutable, then the callback will be called as many times as the value is set, with all intermediate values, nicely ordered. If the value is not immutable, then the code in the callback might already see a more recent version than the calling order. It's up to the user to provide correct values for their flow.
   * @returns callback to stop watching
   */
  public sub(handler: SubscribeCallback<T>, opts?: SubscribeOptions) : PureCallback {
    // Add callback to the correct subscriber list
    let cancel: PureCallback;
    if(opts?.blocking) {
      this.b.push(handler);
      cancel = () => removeFromArr(this.b, handler);
    } else {
      this.s.push(handler);
      cancel = () => removeFromArr(this.s, handler);
    }

    // Call once if a value exists
    let called = false;
    if(opts?.initial !== false) {
      if(opts?.initial === true || this.a !== undefined) {
        called = true;
        handler(this.a, this.a, this);
      }
    }
    
    // Burn the callback after the next call
    if(opts?.once == true) {
      if(called) {
        cancel();
      } else {
        this.o.add(handler);
      }
    }


    return cancel;
  }

  /**
   * Called when a listener is added on this signal
   * 
   * @param handler called when a signal value change listener is added 
   * @returns callback to stop watching
   */
  public onSubscriber(handler: OnSubscriberCallback<T>) : PureCallback {
    this.w.push(handler);

    // If any watchers for subscribers, notify them
    for(let sub of this.w) {
      sub(this);
    }

    return () => removeFromArr(this.w, handler);
  }

  // Helper to execute new value handlers
  public bump() {
    this.v = this.v;
  }

  public toString() : string {
    return "" + this.a;
  }

  /**
   * End a signal it's utility.
   * This will unsubscribe everything.
   * Can be called repeatedly
   */
  public end() {
    this.isEnded = true;

    // Ref to the subscriptions so it can be cleared before cancelling them.
    const endSubs = this.e;

    // Empty node to guarantee nothing fires anymore
    this.s = [];
    this.w = [];
    this.e = [];
    this.t = [];
    this.a = undefined;

    // Remove self
    for(const cb of endSubs) cb();
  }

  /**
   * Get notified when signal ends
   * 
   * @param handler gets notified when ended
   */
  public onEnd(handler: PureCallback) {
    this.e.push(handler);
  }
  
  public toJSON() : string {
    return JSON.stringify(this.a);
  }

  /**
   * End a Signal, array or object of Signals.
   * 
   * @param values the signals to end
   */
  static end(values: Signal<any> | any) {
    if(values && values.uftiSignal) {
      (values as Signal<any>).end();
      return;
    }

    if(values instanceof Array || values instanceof Set) {
      for(let val of values) Signal.end(val);
      return;
    }
    
    if(values instanceof Map) {
      for(let [k, v] of values) Signal.end(v);
      return;
    }

    if(values instanceof Set) {
      for(let v of values) Signal.end(v);
      return;
    }

    if(typeof values === 'object') {
      for(let [key, value] of Object.entries(values)) Signal.end(value);
      return;
    }

    // Else it's ignored...
  }

  /**
   * Track a surface listener for one callback surface. 
   * Surfaces are uniquely tracked by unique reference.
   * 
   * @param node parent node in storage trees
   * @param top top-level surface which triggered the watch
   * @returns cancellation callback
   */
  public regSurface(top: SurfaceOnValueCallback<T>) : PureCallback {
    const idx = this.t.indexOf(top);
    if(idx >= 0) return; // Break the traverse loop

    // Add the surface watch to this signal.
    this.t.push(top);

    // Trigger a deep-watch, that the surface is added.
    traverseSurface(this.a, top, false);

    // Cancellation callback
    return () => this.unregSurface(top);
  }

  public unregSurface(top: SurfaceOnValueCallback<T>) {
    // Remove listener downtree
    traverseSurface(this.a, top, true);

    // Remove any local listeners
    const idx = this.t.indexOf(top);
    if(idx >= 0) this.t.splice(idx, 1);
  }

  public subscriberCount() : number { 
    return this.b.length + this.s.length;
  }
}

// Check the value:
// If the value is a signal, then subscribe and stop walking (since the signal will take over)
// If the value is list, then loop over the list
// If the value is an object, then walk over the object
// Everything else, ignore.
//
// If we have a surface, verify on all value sets that we are still surface
function traverseSurface<T>(node: any, top: SurfaceOnValueCallback<T>, unreg: boolean) {
  if(!node) return;
  if(node?.uftiSignal) {
    if(unreg) {
      (node as Signal<T>).unregSurface(top);
    } else {
      (node as Signal<T>).regSurface(top);
    }
  } 
  else if(node instanceof Array || node instanceof Set) {
    for(const member of node) {
      traverseSurface(member, top, unreg);
    }
  }
  else if(node instanceof Map) {
    for(const [k,member] of node) {
      traverseSurface(member, top, unreg);
    }
  }
  else if(typeof node === 'object') {
    for(const member of Object.values(node)) {
      traverseSurface(member, top, unreg);
    }
    return;
  }

  // Everything else is ignored.
}

// // Create an "effect" which executes whenever _any_ of the effects used within the initial call are used.
// // Don't set signal values in effects, use computed instead.
// // If exitObj is passed, it will dispose of the effect on exit of the element
// // TODO: throw error if an effect sets signals, it's bad practice. We could use a view int for cheap tracking.
// // TODO: these should get passed the list of effects used. This has side-effects.
// //       or replace with something sub-based, which allows hooking cancellation.
// export function effect(fn: PureCallback, exitObj?: object) : PureCallback {
//   // Capture consumed signals
//   const startScope = ctx;
//   ctx = [];
//   fn();
//   const signals = [...ctx];
//   ctx = startScope;

//   // Subscribe all read signals in a batch signal
//   // Since the function doesn't change, it will only be called once.
//   const cancels = signals.map(s => s.sub(fn, { initial: false }));

//   // Return cancellation callback
//   const cancel = () => cancels.forEach(c => c());
//   if(exitObj) {
//     onExit(exitObj, cancel);
//   }
//   return cancel;
// }

// // DEPRECATED
// // Return a signal which gets updated every time any of it's consumed signals change.
// // If exitObj is passed, it will dispose of the effect on exit of the element.
// // TODO: these should get passed the list of effects used. This has side-effects.
// export function computed<T>(fn: () => T, exitObj?: object) : Signal<T> {
//   const startScope = ctx;
//   ctx = [];
//   const val = fn();
//   const signals = [...ctx];
//   ctx = startScope;

//   const sig = signal(val);
//   const updater = () => {
//     sig.v = fn();
//   };

//   const cancels = signals.map(s => s.sub(updater, { initial: false }));
//   sig.onEnd(() => cancels.forEach(c => c()));

//   if(exitObj) {
//     onExit(exitObj, () => sig.end());
//   }

//   return sig;
// }

// Return a signal which gets updated every time any of it's subscribed signals change.
// If exitObj is passed, it will dispose of the effect on exit of the element.
// The function executes as the subscription callback.
export function computed<R,T>(sigs: Signal<T>, func: ComputeFunc<R,T>, exitObj?: object, opts?: SubscribeOptions) : Signal<R>;
export function computed<R,T>(sigs: Signal<T>[], func: ComputeManyFunc<R,T>, exitObj?: object, opts?: SubscribeOptions) : Signal<R>;
export function computed<R,T>(sigs: Signal<T> | Signal<T>[], func: ComputeFunc<R,T> | ComputeManyFunc<R,T>, exitObj?: object, opts?: SubscribeOptions) : Signal<R> {
// export function computed<T>(sigs: Signal<any>[], fn: () => T, exitObj?: object) : Signal<T> {
  const sig = signal<R>();
  sub(sigs, (...params) => sig.v = func(...params), exitObj, opts);
  
  return sig;
}

export function signal<T>(initialValue?: T) : Signal<T> {
  return new Signal<T>(initialValue);
}

// Convenience method helper for subscribing to one or more signals in a dom workflow.
// If exitObj is passed, it will unsubscribe on exit of the element
//
// TODO: allow handler to return cancellation to be cancelled if a new value arrives?
// TODO: pass cancel to the handler?
// TODO: allow to request a trigger, even if no value!!
// TODO: always trigger? Even if no value? Might be better.
export function sub<T>(sig: Signal<T>, handler: SubscribeCallback<T>, exitObj?: object, opts?: SubscribeOptions) : PureCallback;
export function sub<T>(sig: Signal<T>[], handler: SubscribeManyCallback<T>, exitObj?: object, opts?: SubscribeOptions) : PureCallback;
export function sub<T>(sig: Signal<T> | Signal<T>[], handler: SubscribeCallback<T> | SubscribeManyCallback<T>, exitObj?: object, opts?: SubscribeOptions) : PureCallback {
  let cancel: PureCallback;

  // Support for one or more signals
  if(Array.isArray(sig)) {
    const groupHandler = () => {
      (handler as SubscribeManyCallback<T>)(...sig.map(s => s.v));
    }
    const initials = sig.map(s => s.v);
    const cancels = sig.map(s => s.sub(groupHandler, { ...opts||{}, initial: false }));

    if(!(opts?.initial === false)) {
      // Call once on create if one has a value (debounce effect)
      if(initials.findIndex(d => d !== undefined) >= 0) {
        groupHandler()
      }
    }

    cancel = () => cancels.forEach(c => c());
  } else {
    // Normal flow
    cancel = sig.sub(handler as SubscribeCallback<T>, opts||{});
  }

  // Cancel when the object exits
  if(exitObj) {
    onExit(exitObj, () => cancel());
  }

  return cancel;
}

// Promise helper to easily wait for a value
// WARN: no rejection support
export function firstValue(sig: Signal<T>, exitObj?: object) : Promise<T> {
  return new Promise(resolve => {
    sub(sig, v => resolve(v), exitObj, { once: true });
  });
}

export function batch(fn: PureCallback) {
  const batchBefore = batchCallbacks;
  if(batchBefore == null) batchCallbacks = [];
  fn();
  if(batchBefore == null) {
    const collected = batchCallbacks;
    batchCallbacks = null;
    for(let cb of collected) cb();
  }
}

function removeFromArr(arr: any[], item: any) {
  const idx = arr.indexOf(item);
  if(idx >= 0) arr.splice(idx, 1);
}

export function isSignal(d: any) : boolean {
  if(d instanceof Signal || d?.uftiSignal === true) return true;

  return false;
}

export function regSurface<T>(sig: Signal<T>, top: SurfaceOnValueCallback<T>, exitObj?: object) : PureCallback {
  const cancel = sig.regSurface(top);
  if(exitObj) {
    onExit(exitObj, () => cancel());
  }
  return cancel;
}

// Objects which can be be passed around as exitObj to sub/effect/computed.
export class SyntheticObject {
  constructor(cancelObj?: object) {
    if(cancelObj) this.addCancelObj(cancelObj);
  }

  addCancelObj(obj: object) {
    onExit(obj, () => this.cancel());
  }

  cancel() {
    if(!$.get(this) || $.get(this)?.exit != null) return;
    $.get(this).exit = Infinity;
    
    // Fire all exit jobs
    for(let d of $.get(this)?.evt?.['exit']) d();
  }
}
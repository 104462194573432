import isbot from 'isbot';
import { setChildren, signal, sub } from 'ufti';
import { addCtx } from 'ufti/src/plugin/ctx';
import { head } from 'ufti/src/plugin/head';
import { navigate, onRoutes } from 'ufti/src/plugin/router';
import { PureCallback } from 'ufti/src/types';
import { FilterCtx } from '../../filters/filterCtx';
import { setupAdHocFilterSync } from '../../filters/urlFilters';
import About from '../../graffe.app/components/About/About';
import Insiders from '../../graffe.app/components/Insiders/Insiders';
import Pricing from '../../graffe.app/components/Pricing/Pricing';
import { ensureSession } from '../../lib/auth';
import { getSearchParams, isIFramedLoad } from '../../lib/browser';
import { isCE, isGraffeSite } from '../../lib/environment';
import { isDOMNode, isPromise } from '../../lib/object';
import { burnIfArrived, getPostAccountRoute, markPostAccountRoute } from '../../lib/redirectManager';
import { switchClass, toggleClass } from '../../lib/ui';
import { notifyRouteChange } from '../../reporting/reporting';
import { state } from '../../state';
import { LocalFilesCtx } from '../../tables/modules/FileV1/FileProvider/LocalFilesCtx';
import { reportFailed, reportInfo } from '../../types/notifications';
import { initAutomatedWalSyncing } from '../../universe/gateway';
import SettingsConnections from '../Connections/SettingsConnections';
import DebugBar from '../DebugBar';
import { default as HomeNoSession, default as HomePage } from '../Home/HomeNoSession';
import HomeSession from '../Home/HomeSession';
import Landing from '../Landing';
import { DialogContainerSingleton } from '../ProcessReporter/Dialogs';
import { ProcessReporter, ProcessReporterOutletSingleton } from '../ProcessReporter/ProcessReporter';
import Toaster from '../Toaster';
import TopBar from '../TopBar/TopBar';
import AccountRequired from '../boring/AccountRequired';
import { FixedContainerSingleton } from '../fixed';
import { RouterCtx } from '../lib/RouterCtx';
import { FrameCtx } from '../lib/frame';
import NotFound from '../notFound/NotFound';

interface LazyCallback extends PureCallback {
  isAsync: boolean;
}

function load(cb: LazyCallback) : LazyCallback {
  cb.isAsync = true;
  return cb;
}

// This is initialized when the session is loaded
export default function AppGraffe() {
  let topBarEl: HTMLDivElement;
  let topBarPlaceHolderEl: HTMLDivElement;
  let contentDiv: HTMLDivElement;

  const el: HTMLDivElement = <div class="relative w-full flex flex-col gApp">
    {/* Topbar is fixed */}
    {topBarEl = <TopBar class="hidden" />}

    {/* Placeholder to push down the content and keep on using simple flex without all the hacks */}
    {topBarPlaceHolderEl = <div class="min-h-[44px] h-[44px] w-full" />}

    {/* 
      This creates an app rendering bounding box for all content, pages in here can scroll themselves.
      A subscription is toggling the top padding class.
    */}
    {contentDiv = <div class="gContent">
      {/* {content = <div class="gContent" />}  */}
    </div>}

    <Toaster />
    <DebugBar />

    {/* A container to be used by context-less fixed components */}
    <FixedContainerSingleton />

    {/* Container to be used by progress status components */}
    <ProcessReporterOutletSingleton />

    {/* A container to be used by context-less dialogs */}
    <DialogContainerSingleton />
  </div>;

  // Toggle padding based on top bar visibility to achieve stable behavior of the view rendering
  // const topBarPadding = 'pt-[44px]';
  sub([state.topBar.visible, signal(1)], () => {
    toggleClass(!state.topBar.visible.v, topBarPlaceHolderEl, 'hidden');
  }, el);

  // Add the application-level and adhoc-filter contexts
  const appFilters = addCtx(el, new FilterCtx('app'));
  const ahFilters = addCtx(el, new FilterCtx('adhoc')); // Keep this one last ! FilterCtxs walk through these order dependant.

  // Top-level we put the local files ctx to ensure we keep these file refs.
  state.localFiles.v = addCtx(el, new LocalFilesCtx());

  const routerCtx = addCtx(el, new RouterCtx());
  state.routerCtx.v = routerCtx;

  // Set frame ctx how we are loaded
  // TODO: support to detect app and desktop apps
  const isIFramed = (isIFramedLoad() || getSearchParams().has('embed'));
  const renderFrameCtx = addCtx(el, new FrameCtx(isIFramed ? 'iframe' : 'primary'));

  initAutomatedWalSyncing(el);

  // Setup ah
  setupAdHocFilterSync(el);

  // Runtime specific pages
  let landing;

  head.title.separator.v = ' | ';

  // Load the default session if we are running
  if(isCE()) {
    landing = () => <Landing />;
    head.title.suffix.v = 'graffe community edition';
  }

  // // Set Enterprise edition overrides
  // if(isEE()) {
  //   landing = () => <EeLanding />;
  //   head.title.suffix.v = 'graffe enterprise edition';
  // }

  // Set website overrides
  if(isGraffeSite()) {
    landing = () => <HomePage />;
    head.title.suffix.v = 'graffe';
  }

  const routes = [
    // -- User friendly routes
    {
      path: '/', 
      reporting: false,
      isOpen: true, 
      noTopBar: true, 
      unprotected: true, 
      content: () => {
        // / page is special: About-LIKE page for logged out users and a logged in useful page for session users.

        // For bots we shortcut the session wait and inject the landings page inline.
        if(isbot(navigator.userAgent)) {
          return <HomeNoSession />;
        }

        // For real visitors, we check our session
        return new Promise(async (resolve, reject) => {
          try {
            await ensureSession();
            resolve(state.user.v?.id != null ? <HomeSession /> : <HomeNoSession />);
          } catch (err) {
            reject(err);
          }
        });
      }
    },

    // About page
    isGraffeSite() && { 
      path: '/about', 
      reporting: false,
      isOpen: true, 
      unprotected: true, 
      noTopBar: true, 
      noFooter: true,
      content: () => {
        // About page is also special, we don't want this one to be indexed (which should be handled at prerender level).
        if(isbot(navigator.userAgent)) {
          // We don't even redirect, to avoid seo confusion.
          return <div>-</div>; // TODO: block indexing here.
        }

        return new Promise(async (resolve, reject) => {
          try {
            await ensureSession();
            // If we don't have a user, then we redirect to the / page
            if(state.user.v?.id == null) {
              navigate('/', { replaceState: true });
            }
            resolve(state.user.v?.id == null ? <div>Redirecting to /...</div> : <About />);
          } catch (err) {
            reject(err);
          }
        });
      },
    },

    // Temporary invite router
    { path: '/invite', reporting: false, isOpen: true, noTopBar: true, unprotected: true, content: load(() => import('../../graffe.app/components/Invite/InviteFlowRouter')) },

    
    { path: '/signout', isOpen: true, noTopBar: true, noFooter: true, unprotected: true, content: load(() => import('../session/SignOut')) },
    
    // Only to be used manually
    // We support both /signout and /logout, /logout for user to type it in case of problems.
    { path: '/logout', isOpen: true, noTopBar: true, noFooter: true, unprotected: true, content: load(() => import('../session/SignOut')) }, 

    isGraffeSite() && {
      path: '/pricing',
      reporting: false,
      isOpen: true,
      unprotected: true,
      noTopBar: true,
      noFooter: true,
      noSession: true,
      content: () => <Pricing />,
    },

    // INSIDERS
    isGraffeSite() && {
      path: '/insiders',
      reporting: false,
      isOpen: true,
      unprotected: true,
      noTopBar: true,
      noFooter: true,
      noSession: true,
      content: () => <Insiders />,
    },

    { path: '/settings/connections', content: (m) => <SettingsConnections /> }, 
    // { path: '/settings/connections/:refHash', content: (m) => <EditConnection refHash={m.refHash} /> }, 
    // { path: '/settings/vars', content: (m) => <SettingsVars /> }, 
    // { path: '/settings/profile', content: (m) => <SettingsProfile /> }, 
    { path: '/required/username', content: load(() => import('../session/ConfigureUsername')) },
    { path: '/settings', content: load(() => import('../Settings/Settings')) },

    // Connection flows
    { path: '/handle/connection/:module/*', content: load(() => import('../Connections/FlowHandler')) },

    // // -- UPGRADES
    // { path: '/handle/upgrade/:product', content: load(() => import('../Upgrade/HandleUpgrade')) }, 
    // { path: '/upgrade-cancel', content: load(() => import('../Upgrade/UpgradeCancel')) },

    // -- SPONSORSHIPS
    // Temporarily disabled
    // { path: '/sponsor/:sponseeSlug/sponsorship', unprotected: true, isOpen: true, content: load(() => import('../../graffe.app/components/sponsor/Sponsorship')) },
    { path: '/sponsor/:sponseeSlug/sponsorship/manage', content: load(() => import('../../graffe.app/components/sponsor/SponsorshipManage')) },

    // -- TEAMS
    { path: '/handle/create-team/:product', content: load(() => import('../Teams/HandleCreateTeam')) }, 
    { path: '/teams/:teamId/settings', content: load(() => import('../Teams/TeamSettings')) },
    { path: '/create-team/cancel', content: load(() => import('../Teams/CreateTeamCancel')) },

    // { path: '/activity/:collection', content: (m) => <CollectionActivity collection={m.collection} /> }, 
    
    // { path: '/pricing', content: (m) => <Pricing /> }, 
    // { path: '/privacy', reporting: false, isOpen: true, unprotected: true, noTopBar: true, noFooter: true, content: () => <Privacy /> }, 
    // { path: '/terms', reporting: false, isOpen: true, unprotected: true, noTopBar: true, noFooter: true, content: () => <TermsOfService /> }, 
    { path: '/privacy', reporting: false, isOpen: true, unprotected: true, noTopBar: true, noFooter: true, content: load(() => import('../boring/Privacy')) }, 
    { path: '/terms', reporting: false, isOpen: true, unprotected: true, noTopBar: true, noFooter: true, content: load(() => import('../boring/TermsOfService')) }, 
    // { path: '/security', content: load(() => import('../boring/Security')) }, 
    // { path: '/investors', content: (m) => <Investors /> }, 
    // { path: '/changelog', content: (m) => <ChangelogViewer /> }, 
    // { path: '/vision', content: (m) => <Vision /> }, 
    // { path: '/universe', content: (m) => <UniverseExplorer /> }, 
    // { path: '/support/:article', content: (m) => <Support article={m.article} /> }, 

    // -- Editor
    // { path: '/editor', noFooter: true, content: (m) => <Editor /> },

    // Universe editor
    { path: '/studio', noFooter: true, content: load(() => import('../Studio/Studio')) },

    // -- Dev page
    { path: '/devtest', noFooter: true, content: load(() => import('../dev/Tester')) },

    // -- Detached load path to inspect any object, at any time, at any version.
    { path: '/commit/:commitHash', isOpen: true, unprotected: true, noFooter: true, content: load(() => import('../Viewer/Object/LoadCommit')) },

    // -- User/Organization collections

    // Collections are @prefixed
    { path: '/@:slug', isOpen: true, unprotected: true, noFooter: true, content: load(() => import('../Viewer/Collection/CollectionProfile')) },

    // Render folders (-> note the trailing slash which triggers path browsing)
    // When the folder doesn't have any items, then this will replace itself with the NotFound component.
    { path: '/@:slug/', isOpen: true, unprotected: true, noFooter: true, content: load(() => import('../Viewer/Folder/LoadFolder')) },
    { path: '/@:slug/*/', isOpen: true, unprotected: true, noFooter: true, content: load(() => import('../Viewer/Folder/LoadFolder')) },
    
    // Render latest version of an object
    // When the object doesn't have a latest version, then this will replace itself with the NotFound component.
    { path: '/@:slug/*', isOpen: true, unprotected: true, noFooter: true,  content: load(() => import('../Viewer/Object/LoadLatest')) },

    // -- Everything else

    // Fallback (this only hits on ///* patterns)
    { path: '*', isOpen: true, content: () => <NotFound /> },
    
  ].filter(d => d);

  function showComponent(route: Route, match: any, component: HTMLElement | SVGElement) {
    // Load route content
    setChildren(contentDiv, component);

    // Log route change
    notifyRouteChange(route, match, state.user.v, Date.now());
  }

  // Router
  onRoutes(routes, async (route, match) => {
    // We clear the main title main before routing, such components don't need to clean up. It's up to the pages to set these on load.
    if(head.title.main.v != null) head.title.main.v = null;

    // Reset the meta tags as well. It's up to the pages to set these on load.
    if(Object.keys(head.meta.v||[]).length > 0) head.meta.v = {};

    // Clear the top bar before routing, such components fon't need to clean up
    if(state.topBar.centerOutlet.v != null) state.topBar.centerOutlet.v = null;

    // Set the url context
    routerCtx.prevRoute.v = routerCtx.route.v; // Cycle route
    routerCtx.prevMatch.v = routerCtx.match.v; // Cycle route
    routerCtx.route.v = route;
    routerCtx.match.v = match;

    // Track the arrival route if needed.
    markPostAccountRoute();

    // If the route _requires_ session to make loading decisions, 
    // then it waits for a session.
    if(!route.noSession) {
      await ensureSession();
    }
    if(route !== routerCtx.route.v) {
      return; // async race protection
    }

    // INVITE stuff - remain stealth
    if(!state.user.v?.id) {
      state.topBar.visible.v = false;
      state.footer.visible.v = false;
    }

    // INVITE stuff - remain stealth
    if(!state.user.v?.id && !route.isOpen ) {
      setChildren(contentDiv, <AccountRequired />);
      return;
    }

    // INVITE STUFF - block until activated
    if(!state.user.v?.invited && !route.unprotected) {
      reportFailed('Not yet invited, try again at a later time');
      navigate('/invite');
      return
    }

    // Set correct bar on top (if not iframes)
    if(route.noTopBar === true) {
      state.topBar.visible.v = false;
    } else if(!state.topBar.visible.v && !isIFramed) {
      state.topBar.visible.v = true;
    }

    // Set correct bar on top
    if(route.noFooter === true) {
      state.footer.visible.v = false;
    } else if(!state.footer.visible.v) {
      state.footer.visible.v = true;
    }

    // If no username, redirect user to configure their username
    if(state.user.v?.id != null && state.user.v?.slug == null) {
      if(route.path !== '/required/username' && ((!route.isOpen && !route.isProtected) || route.path.indexOf('/@') == 0 || route.path === '/')) {
        reportInfo('Please configure your profile username');
        navigate('/required/username');
        return;
      }
    }

    // If we get here, we have a username and the user is ready.
    // Here we check if there are any burned routes we should follow.
    const pendingRoute = getPostAccountRoute();
    if(pendingRoute && state.user.v?.id && state.user.v?.slug && state.user.v.invited == 1) {
      // Redirect handlers clean instantly
      if(window.location.pathname.indexOf('/handle/') == 0) {
        burnIfArrived(pendingRoute);
      } else {
        setTimeout(() => burnIfArrived(pendingRoute), 200);
      }

      const pendingPath = new URL(window.location.origin + pendingRoute).pathname;
      if(pendingPath !== window.location.pathname) {
        // Redirect user to the requested path if not already on the path
        navigate(pendingRoute);
        return;
      }
    }

    // If the route is an import: resolve the import and render, unless cancelled.
    // This saves the lazy object intermediate.
    const componentOrProm = route.content();
    if(route.content?.isAsync || isPromise(componentOrProm)) {
      const startRoute = route;
      const p = new ProcessReporter(`Loading module for ${route.path}`);
      (async () => {
        try {
          const content = await componentOrProm;
          p.done();
          if(startRoute !== routerCtx.route.v) return; // Race protection
          // If content contains a function
          let component: HTMLElement;
          // Handle normal promises which return dom elements
          if(isDOMNode(content)) {
            component = content;
          } else {
            // Support for split bundle import() loading with default exports
            const creator = (typeof content === 'function' ? content : content.default);
            component = creator();
          }

          showComponent(route, match, component);
        } catch (err) {
          p.done(err);  
        }
      })();
    } else {
      showComponent(route, match, componentOrProm);
    }
  },
  el);

  // Render the topbar correctly
  sub([state.topBar.visible, signal(1)], () => {
    switchClass(state.topBar.visible.v, topBarEl, 'flex', 'hidden');
  }, el)
  
  return el;
}
export type PureCallback = () => void;

export interface Plugs {
  error: (err: Error) => void;
}

export const UftiFragment = Symbol.for('UFTI_FRAGMENT');

// Data props which get set on the DOM objects
export interface UftiDomProps {
  evt?: Record<string|symbol, PureCallback[]>;
  enter?: number;
  exit?: number;
}

export type ComponentFunc = (props?: Record<string, any>, content?: HTMLElement | SVGElement | (HTMLElement | SVGElement)[]) => HTMLElement | SVGElement;
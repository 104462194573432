import { OutlineIcon } from "../../../lib/icons";

function FeatureBlock({ class: klass, label, icon }, content) {
  return <div class={`p-6 rounded-lg bg-white flex flex-col ${klass||''}`}>
    <div class="bg-[#EDEDF7] rounded-lg mr-auto overflow-hidden p-2">
      {icon}
    </div>

    <h3 class="mt-4 text-xl font-bold">{label}</h3>
    <p class="mt-2 text-sm font-normal text-zinc-600">{content}</p>
  </div>
}

export default function FeatureBlocks({ class: klass }) {
  const el = <div class={`grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-10 ${klass||''}`}>
    <FeatureBlock 
      icon={iconSquare()}
      label="Query and join all data">You can join data from your API's with data from your databases, Excel files, Google Sheets, CSV or JSON files and so on. We're trying to simplify the whole operational data engineering hassle, to be able to just start exploring data. Run simple, reusable scripts to clean your data when SQL is not the right tool for the job. We are adding data connectors at a rapid pace now that the foundation is live, get a team subscription to prioritize yours.</FeatureBlock>

    <FeatureBlock 
      icon={iconSquare()}
      label="Accessible discoverable data">Discover and explore easily what datasets are available to work with, reuse quality data tables, no matter where they are in the organization, <span class="italic">or in the world</span>. Having easily discoverable, accessible data increases the utility of the data, reduces complexity, avoids double work, increases reporting quality & data management across your whole data stack.</FeatureBlock>

    <FeatureBlock 
      icon={iconSquare()}
      label="Universal repository">The Graffe Universe has been designed as a universal metadata repository to support the vision of Graffe, where we see a world of trustworthy sharing high quality datasets at different access levels. Every change is a unique version in Graffe. Objects depend on specific versions and can evolve without impacting other usages. Private, encrypted or public objects are all proven in time by the universe.</FeatureBlock>

    <FeatureBlock 
      icon={iconSquare()}
      label="No servers or jobs to operate">All data logic executes on your device. That includes SQL and transformation scripts. No database management, while you can hook up any supported data source without having to run a server. Data flows directly from the source into the browser. That also means minimal to no ETL, we're developing this tool with realtime as a default, no server-side data pipelines to operate.</FeatureBlock>

    <FeatureBlock 
      icon={iconSquare()}
      label="Transparency and Privacy">Viewers can inspect what they are looking at, where the data comes from, how it is transformed and how the visualizations are created. Bit for bit, total transparency. You can even open the devtools to see that the data flows from your source directly to your browsers, that means true data privacy. No party in the middle.</FeatureBlock>

    <FeatureBlock 
      icon={iconSquare()}
      label="Cryptographic integrity">Every view/dashboard will be rendered <span class="italic">how the artist intended it</span>. When your tables in an analysis have data integrity activated, then this means a tamper-proof correctness guarantee from data all the way to visualization. Integrity end-to-end. Without any intermediate third-party that can change the results. News organizations that use Graffe expose full transparency, which increases trust in their reporting.</FeatureBlock>

    {/* <FeatureBlock 
      icon={iconSquare()}
      label="Universal repository">An ecosystem of metadata about data, visualizations, transformations. Every object is a building block. Once shared, you can build on eachothers work.</FeatureBlock> */}

    <FeatureBlock 
      icon={iconSquare()}
      label="A network of data objects">An ecosystem of data tables, visualizations, transformations. Every object is a building block. Once shared, you can build on each other's work. A modular reporting/business-intelligence studio which allows building on composable objects. Compose dashboards from private and public charts. Build quality.</FeatureBlock>
    
    <FeatureBlock 
      icon={iconSquare()}
      label="Embeddable">All objects created, can be embedded into your site, mobile application or desktop application easily. Build trust by letting users inspect the visualization, where you can show the whole data path. Powered by Graffe.</FeatureBlock>

    <FeatureBlock 
      icon={iconSquare()}
      label="Low cost">A universal data studio that promotes data discovery and sharing should be accessible by anyone. That means for free for public good, educational and personal data. Achieving this for the public good is only possible with a strong business use case, which works via paying subscriptions for Pro and Team users.</FeatureBlock>

    {/* We hope you sit down, since our architecture is so radically different than any competitors, you'll have a better deal. Get on board soon, early bird prices. */}

    {/* <FeatureBlock   
      icon={iconSquare()}
      label="Personizable dashboards without storing personal data">Users can view their own personalized version of dashboards, e.g., a reusable dashboard on banking stats that render directly from the connection. Without duplicating the data into a database for the analysis tool.</FeatureBlock> */}

    {/* <FeatureBlock 
      icon={iconSquare()}
      label="Object versioning without breaking collaboration">Thanks to the object-level version control, every change is unique. Object can evolve without automatically changing their usages. Artists can upgrade their dashboards to sync up with improvments in the dependencies.</FeatureBlock> */}
  
    {/* <FeatureBlock 
      icon={iconSquare()}
      label="Propose changes to existing data visualizations by forking them">Data reporting in the news is broken. Data sources are not shared. Skewed axises in visualizations, questionable results in the news. If these charts are shared in Graffe In graffe you can just propose changes. Any data object can be forked. </FeatureBlock> */}
    
    {/* <FeatureBlock 
      icon={iconSquare()}
      label="Sustainable open sourced">When the company is funded and can sustainably serve the public good use case forever, that is the point to opensource the codebase.</FeatureBlock> */}
  </div>;

  return el;
}

const iconSquare = props => <OutlineIcon icon="sparkles" class="w-8 h-8 text-indigo-800" {...props} />
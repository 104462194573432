import { getTimeTzOffset } from "graffe-shared/src/lib/time";
import { EncryptionType } from "graffe-shared/src/models/encryption";
import { DataType, Thing } from "graffe-shared/src/models/nodes";
import { TableModuleId } from "graffe-shared/src/models/table";
import { ViewModuleId } from "graffe-shared/src/models/view";
import { CollectionId, CommitHash, ContentHash, RefHash, RefSeed } from "graffe-shared/src/types/types";
import { Commit, CommitCommitProps, FileData, UnsealedCommit, UnsealedCommitData, makeCommitContentHash, makeCommitHash, sealedDataFromUnsealed } from "graffe-shared/src/universe/commit";
import { LicenseDataSignals, LicensePropsSignals } from "graffe-shared/src/universe/licenses";
import { CollectionMetadataSignals, TimestampWithTzInfo, TrackingRefExtended, TrackingRefSignals } from "graffe-shared/src/universe/types";
import { clone, getStorageModel, makeRefHash, makeRefSeed } from "graffe-shared/src/universe/utils";
import { Signal, computed, signal } from 'ufti';
import { ValueFilter } from "../filters/filters";
import { state } from "../state";
import { reportInfo } from "../types/notifications";
import { DataInstance, getDataInstance } from "./data";

export interface CommitExternalizedPropsSignals {
  // Encryption type
  enc: Signal<EncryptionType>;

  // Repository node type
  thing: Signal<Thing>;

  // Repository node type
  dataType: Signal<DataType>;

  // Collection metadata at time of commit
  collection: CollectionMetadataSignals; 

  // collectionId salted hash of the refSeed
  refHash: Signal<RefHash>;

  // The reference of this object (optionally empty for encrypted objects)
  // When empty, refSeed is required and props.ref should be set client-side.
  ref: Signal<string>;

  // Optional collectionId salted hash of the ref
  // Required when the ref field is null or length 0
  // When the ref is provided, it's uniqueness properties are rolled into the refHash
  refSeed: Signal<RefSeed>;

  // If missing, the license is inferred NONE and sharing permissions can be revoked.
  // When an object is committed with an open-source license, the sharing permissions cannot be revoked anymore and are final.
  license?: LicenseDataSignals;

  // Graffe File references used and their hashes at the time of commit.
  files: Signal<FileData[]>;

  // Tombstoned refs should name their successor path forward ref/commit to indicate tracking upstreams. This makes forward synchronization explicit.
  //
  // When an object is to be cleared and to be replaced with another object and all objects depending on it should sync to the successor, then refs can be re-used without messing up histories.
  //
  // Security-wise, when we have this information then we can leverage the target policy on the Tombstone, allowing to discover the tombstone according to the new target's permissions (which can form a chain and allow access to this specific info). Universe should implement this.
  // 
  // Tombstones or archived=true object should/can provide info where their legacy continues.
  //
  // This allows passing around tracking to other maintainers and allows all dependencies to sync forward.
  //
  // We could allow to send multiple Tombstones to add missing/changed successor info.
  successor?: Signal<TrackingRefExtended>;
}

export interface CommitInternalPropsSignals {
  // Used by fully encrypted objects, contains the reference that point(ed) to this commit
  // Empty when the reference is unsealed.
  ref: Signal<string>;

  // Used by fully encrypted objects, contains the reference this commit was forked from
  // Empty when the fork is unsealed.
  forkRef: Signal<string>;

  // Used by fully encrypted objects, contains the pointer to the successor of this Tombstone.
  // Empty when the commit is unencrypted.
  successorRef: Signal<string>;

  // Any object-level filters
  filters: Signal<ValueFilter[]>;

  // Additional license props for UI.
  license: LicensePropsSignals;
}

export interface CommitCommitPropsSignals {
  // Hash of the object tree
  content: Signal<ContentHash>;

  // Which account did the commit
  committer: Signal<CollectionId>;

  // At what time the commit was done
  time: Signal<TimestampWithTzInfo>;

  // If empty, it indicates an object without parent
  parents: Signal<CommitHash[]>;

  // In case of a fork, this hints what object was forked from.
  fork: TrackingRefSignals;
}

// Graffe works with 3 commit classes:
// Commit (sealed, storage) <-> UnsealedCommit (validation) <-> Change (an object which can be created from a commit, but can have changes).
//
// Change is a commit-aware representation of a universe object. Changes can be initialized for a specific commit, or from a latest hash. Once initialized, they manage their own lifecycle. Changers wrap the graffe object.
//
// Changers are serialized to commits and commits are deserialized to gobs, since commits cannot be changed anymore.
//
// TODO: Commit actions (clicking commit) require to write new values to these props (like time), which should be atomic actions, that on failure of committing, they revert to the original.
// 
// TODO: rename to Change after we refactored.
export class Change {
  public ext: CommitExternalizedPropsSignals;

  public commit: CommitCommitPropsSignals;

  public props: CommitInternalPropsSignals;

  public data: DataInstance;

  // Change hash is either the loaded or the last committed commit hash
  public hash: Signal<CommitHash>;

  // Weak ref to self - which can be passed into signals and will garbage collect.
  // public weak: WeakRef<Change> = new WeakRef(this);

  ref() : string {
    return this.ext.ref.v ?? this.props.ref.v;
  }

  fullRef() : string {
    return [this.ext.collection.slug.v, this.ref()].join('/');
  }

  refHash() : RefHash {
    return this.ext.refHash.v;
  }

  static fromData({ ext, props, data, commit, hash } : UnsealedCommitData) : Change {
    const c = new Change();

    // Migrate bubble prop
    if(props?.license?.bubble) {
      props!.license!.attribution = props?.license?.bubble;
      delete props!.license!.bubble;
      reportInfo('Migrated change attribution');
    }

    c.ext = {
      collection: {
        id: signal(ext.collection.id),
        slug: signal(ext.collection.slug),
      },
      // refHash: signal(ext.refHash), // Computed signals below overwrite this!
      // refSeed: signal(ext.refSeed), // Computed signals below overwrite this!
      ref: signal(ext.ref),
      enc: signal(ext.enc),
      thing: signal(ext.thing),
      dataType: signal(ext.dataType),
      license: {
        spdxId: signal(ext.license?.spdxId),
      },
      files: signal(ext.files ?? []),
      successor: signal(ext.successor),
    };

    // Properties
    c.props = {
      forkRef: signal(props?.forkRef),
      ref: signal(props?.ref),
      filters: signal(props?.filters?.map(f => ValueFilter.fromData(f)) || []),
      license: {
        attribution: signal(props?.license?.attribution),
      },
    };

    // Load the commit
    c.commit = {
      committer: signal(commit?.committer),
      content: signal(commit?.content), // Updated only on commit of the live commit.
      parents: signal(commit?.parents),
      time: signal(commit?.time),
      fork: {
        ref: signal(commit?.fork?.ref),
        commit: signal(commit?.fork?.commit),
        collection: {
          id: signal(commit?.fork?.collection?.id),
          slug: signal(commit?.fork?.collection?.slug),
        }
      }
    };

    // Hook up the refSeed and the refHash computed signals.
    c.ext.refSeed = makeComputedRefSeed(c.ext.collection.id, c.props.ref);

    // If the ref is public, we calculate inline the refSeed without setting it (to keep object stable).
    c.ext.refHash = makeComputedRefHash(c.ext.collection.id, c.ext.refSeed, c.ext.ref);

    // c.ext.refSeed = computed(
    //   [c.ext.collection.id, c.props.ref], 
    //   () => (c.props.ref.v?.length > 0 ? makeRefSeed(c.props.ref.v, c.ext.collection.id.v) : null),
    //   null,
    //   { blocking: true }
    // );

    // c.ext.refHash = computed(
    //   [c.ext.collection.id, c.ext.refSeed, c.ext.ref],
    //   () => {
    //     console.debug('recalc');
    //     let refSeed = c.ext.refSeed.v;
    //     if(refSeed == null) {
    //       refSeed = makeRefSeed(c.ext.ref.v, c.ext.collection.id.v);
    //     }
    //     return makeRefHash(refSeed, c.ext.collection.id.v);
    //   }, 
    //   null, 
    //   { blocking: true }
    // );

    // Set the hash.
    // This hash and the content hash are only changed at commit time.
    // This allows diffing the object easily, if the commit != then changes happened (and thus we can remove stable string compare).
    c.hash = signal(hash);
    
    // Load the thing
    if(c.ext.dataType.v !== DataType.Tombstone) {
      c.data = getDataInstance(c.ext.dataType.v, clone(data));
    }

    return c;
  }

  static fromUnsealedCommit(uCommit: UnsealedCommit) : Change {
    return Change.fromData(uCommit);
  }

  private validateCommittable() {
    if(this.ext.dataType.v === DataType.TableV1) {
      if(this.data?.module.v === TableModuleId.GraffeTableV1) {
        throw new Error('Invalid module for committable');
      }
    }
    if(this.ext.dataType.v === DataType.ViewV1) {
      if(this.data?.module.v === ViewModuleId.GraffeViewV1) {
        throw new Error('Invalid module for committable');
      }
    }
  }

  // Finalized the commit structure and sets the signals to latest state.
  async finalize(committer: CollectionId, parents: CommitHash[], fork: TrackingRef) : Promise<Commit> {
    const preHash = this.hash.v;
    const preContent = this.commit.content.v;
    const preTime = this.commit.time.v;
    
    // Validate some core props
    this.validateCommittable();

    const c = await this.seal();
    
    let { ext, data } = c;
    ext = getStorageModel(ext);
    data = getStorageModel(data);
    const contentHash = makeCommitContentHash({ ext, data });

    const commit = (getStorageModel(this.commit) || {}) as CommitCommitProps;
    commit.committer = committer;
    if(parents) commit.parents = parents;
    if(fork) commit.fork = fork;
    commit.content = contentHash;
    commit.time = getTimeTzOffset();
    
    const hash = makeCommitHash(commit);

    // Success; atomic apply all values
    try {
      applyCommitProps(this.commit, commit);
      this.hash.v = hash;

      return Commit.fromData({ ext, data, commit, hash });
    } catch (err) {
      this.commit.content.v = preContent;
      this.commit.time.v = preTime;
      this.hash.v = preHash;

      throw err;
    }
  }

  // Diffing function which re-calculated the content hash based on active props. This allows to diff if anything changed on the object.
  async calcContentHash() : Promise<CommitHash> {
    this.validateCommittable();

    const c = await this.seal();
    
    let { ext, data } = c;
    ext = getStorageModel(ext);
    data = getStorageModel(data);

    return makeCommitContentHash({ ext, data });
  }

  toUnsealedCommit() : UnsealedCommit {
    const { ext, props, data, commit, hash } = Change.getDiffModel(this);
    
    return UnsealedCommit.fromData({ ext, props, data, commit, hash });
  }

  // Seal without committing (for storage in changes)
  async seal() : Promise<Commit> {
    const { ext, props, data, commit, hash } = Change.getDiffModel(this);

    const sealedData = await sealedDataFromUnsealed(state.keyService.v, ext, props, data);

    return Commit.fromData({ ext, data: sealedData, commit, hash });
  }

  static getDiffModel(uCommit: UnsealedCommit) : UnsealedCommitData {
    const { ext, props, data, commit, hash } = uCommit;

    return getStorageModel({ hash, ext, props, data, commit });
  }
}

export function applyCommitProps(commit: CommitCommitPropsSignals, props: CommitCommitProps) {
  commit.committer.v = props.committer;
  commit.content.v = props.content;
  commit.time.v = props.time;
  if(props.parents) {
    commit.parents.v = props.parents;
  }
  if(props.fork) {
    commit.fork.ref.v = props.fork.ref;
    commit.fork.commit.v = props.fork.commit;
    commit.fork.collection.id.v = props.fork.collection.id;
    commit.fork.collection.slug.v = props.fork.collection.slug;
  }
}

export function makeComputedRefSeed(colId: Signal<CollectionId>, ref: Signal<string>) : Signal<string> {
  // Hook up the refSeed and the refHash computed signals.
  return computed(
    [colId, ref], 
    () => (ref.v?.length > 0 ? makeRefSeed(ref.v, colId.v) : null),
    null,
    { blocking: true }
  );
}

export function makeComputedRefHash(colId: Signal<CollectionId>, refSeed: Signal<string>, extRef: Signal<string>) : Signal<string> {
  return computed(
    [colId, refSeed, extRef],
    () => {
      console.debug('recalc');
      let derivedRefSeed = refSeed.v;
      if(derivedRefSeed == null) {
        derivedRefSeed = makeRefSeed(extRef.v, colId.v);
      }
      return makeRefHash(derivedRefSeed, colId.v);
    }, 
    null, 
    { blocking: true }
  );
}
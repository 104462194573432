import { unexpected } from "graffe-shared/src/lib/devflow";
import { uniqueId } from 'lodash-es';
import { append, clear, onEnter, setChildren, signal, Signal, sub } from 'ufti';
import { Action } from "./button";
import { handleDisabledCtx, InputCheckbox } from "./fieldsClean";
import { OutlineIcon } from "./icons";
import { switchClass, toggleClass } from "./ui";

export function InputElement({
  sig,
  placeholder,
  type,
  step,
  min,
  class: klass,
  changeSig,
  fromVal,
  toVal,
  onclick,
  instant,
  autocomplete,
  disabled,
  id,
  onblur,
  onKeyEscape,
  onKeyEnter,
  noSelfCheck,
  autoFocusIfEmpty,
  autoFocus,
  autoSelectAll,
  readOnly,
  readOnlyClass,
} : {
  sig: Signal<any>,
  placeholder?: string,
  type?: string,
  step?: string,
  min?: string,
  class?: string,
  changeSig?: Signal<any>,
  fromVal?: ((d:string) => any),
  toVal?: ((d: any) => string),
  onclick?: (e: MouseEvent) => void,
  instant?: boolean,
  autocomplete?: string,
  disabled?: boolean,
  id?: string,
  onblur?: (e: MouseEvent) => void,
  onKeyEscape?: (e: KeyboardEvent) => void,
  onKeyEnter?: (e: KeyboardEvent) => void,
  noSelfCheck?: boolean,
  autoFocusIfEmpty?: boolean,
  autoFocus?: boolean,
  autoSelectAll?: boolean,
  readOnly?: boolean,
  readOnlyClass?: string,
}) : HTMLInputElement {
  if(!fromVal) fromVal = d => d;
  if(!toVal) toVal = d => d;
  
  const input: HTMLInputElement = <input
    id={id}
    name={id}
    type={type||'text'}
    step={step}
    min={min}
    placeholder={placeholder||''}
    class={`field ${klass||''}`}
    value={sig.v != null ? toVal(sig.v) : ''}
    autocomplete={autocomplete||''}
    disabled={disabled??false}
    readOnly={!!readOnly}
  />;

  if(readOnly && readOnlyClass) {
    input.classList.add(...readOnlyClass.split(' '));
  }

  // Update the field
  const updater = (e: KeyboardEvent) => {
    const val = fromVal(
      type === 'number' 
        ? parseInt(e.target.value) 
        : e.target.value
    );
    const s$ = (changeSig ?? sig);
    if(s$.v !== val) {
      s$.v = val;
    }

    return;
  };
  
  // Set signal instantly or when Enter is pressed
  // TODO: custom onkeydown implementation which instantly sets the value can make this feel faster.
  input.addEventListener('keyup', e => {
    if(instant || e.code === 'Enter') {
      updater(e);
    }

    // Trigger other behaviors
    if(e.code === 'Enter') {
      onKeyEnter && onKeyEnter(e);
    }

    if(e.code === 'Escape') {
      onKeyEscape && onKeyEscape(e);
    }
  }, { passive: false }); // capture not required here

  // Set signal when unfocusing
  input.addEventListener('blur', e => {
    updater(e); // First set the value
    if(onblur) onblur(e); 
  });

  // Proxy onclick handler
  if(onclick) {
    input.onclick = onclick;
  }

  // Apply signal updates ("two-way" binding)
  sub(sig, () => {
    if(!noSelfCheck) {
      if(document.activeElement === input) return;
    }
    if(input.value !== toVal(sig.v)) {
      input.value = toVal(sig.v);
    }
  }, input);

  // Autofocus if signal is null
  if(autoFocusIfEmpty || autoFocus) {
    onEnter(input, () => {
      if(sig.v == null || sig.v?.length == 0) {
        input.focus();
      }
      if(autoSelectAll) {
        input.select();
      }
    });
  }

  // Disable if in a disabled ctx (eg: only insiders)
  handleDisabledCtx(input);

  return input;
}

export function FieldLabel({
  class: klass,
  label,
  alt,
  icon,
  for: vor
} : {
  class?: string,
  label?: string,
  alt?: string,
  icon?: any,
  for?: string
}, children) : HTMLLabelElement {
  const el = <label class={`flex justify-between cursor-pointer ${klass||''}`} for={vor||''}>
    {/* REFACTOR: remove this icon */}
    {label && <span class="mr-auto select-none">{icon?(<div class="w-4 h-4 mr-1">{icon}</div>):''}{label}</span>}
    {alt && <span>{alt}</span>}
    {children}
  </label>;

  return el;
}

export type InputFieldProps = {
  sig: Signal<any>,
  placeholder?: string,
  type?: string,
  step?: string,
  min?: string,
  class?: string,
  prefixLabel?: string,
  prefixClass?: string,
  label?: string,
  labelAlt?: string,
  label2?: string,
  label2Alt?: string,
  divClass?: string,class?: string,
  changeSig?: Signal<any>,
  fromVal?: ((d:string) => any),
  toVal?: ((d: any) => string),
  onclick?: (e: MouseEvent) => void,
  instant?: boolean,
  autocomplete?: string,
  withToggle?: boolean,
  labelClass?: string,
  label2Class?: string,
  disabled?: boolean,
  icon?: any,
  onblur?: (e: MouseEvent) => void,

  // Dev test to disable current element checks - evaluate behavior...
  noSelfCheck?: boolean,

  // Autofocuses entering the dom if the signal length = 0 or null
  autoFocusIfEmpty?: boolean,

  // Read only
  readOnly?: boolean,

  // Read only class
  readOnlyClass?: string,
};

export function InputField({ 
    sig,
    placeholder,
    type,
    step,
    min,
    label,
    labelAlt,
    label2,
    label2Alt,
    divClass,
    class: klass,
    prefixLabel,
    prefixClass,
    changeSig,
    fromVal,
    toVal,
    onclick,
    instant,
    autocomplete,
    withToggle,
    labelClass,
    label2Class,
    disabled,
    icon,
    onblur,
    noSelfCheck,
    autoFocusIfEmpty,
    readOnly,
    readOnlyClass,
  } : InputFieldProps) {

  if(type === 'checkbox') unexpected();

  const uid = uniqueId('input-');

  const input = <InputElement 
    sig={sig}
    placeholder={placeholder}
    type={type}
    step={step}
    min={min}
    class={klass}
    changeSig={changeSig}
    toVal={toVal}
    fromVal={fromVal}
    onclick={onclick}
    instant={instant}
    autocomplete={autocomplete}
    disabled={disabled}
    id={uid}
    onblur={onblur}
    noSelfCheck={noSelfCheck}
    autoFocusIfEmpty={autoFocusIfEmpty}
    readOnly={readOnly}
    readOnlyClass={readOnlyClass}
  />;

  const el = <fieldset class={`${divClass||''}`}>
      {(label || labelAlt) && (
        <FieldLabel class={`mb-1 text-zinc-600 ${labelClass||''}`} label={label} alt={labelAlt} icon={icon} for={uid} />
      )}
      
      {prefixLabel ? (
        <label class="flex flex-row" for={uid}>
          <span class={`${prefixClass||''}`}>{prefixLabel}</span>
          {input}
        </label>
      ) : input}
      
      {withToggle && <button type="button" class="hover:underline mt-1" onclick={() => input.type = (input.type === 'password' ? 'text' : 'password')}>show/hide value</button>}

      {(label2 || label2Alt) && (
        <FieldLabel class={`mt-1 text-zinc-400 ${label2Class||''}`} label={label2} alt={label2Alt} />
      )}
  </fieldset>;

  return el;
}

const invalidOption = '___...INVALID...___';

function selectMarkSelected(sig: Signal<string>, select: HTMLSelectElement) {
  const options = select.children as HTMLOptionElement[];
  let found = false;
  for(let child of options) {
    if(child.value === sig.v) {
      child.selected = true;
      found = true;
    } else if(child.selected) {
      child.selected = false;
    }
  }

  // Append a special option which indicates the value is missing
  if(!found) {
    append(select, <option value={invalidOption} selected={true}>INVALID: {sig.v}</option>);
  }
}

export function SelectField({ sig, options, class: klass } : { sig: Signal<string>, options: Signal<HTMLOptionElement[]>, class?: string }) : HTMLSelectElement {
  const select: HTMLSelectElement = <select 
    class={`field ${klass||''}`}
    onchange={e => {
      if(e.target.value === invalidOption) return;
      sig.v = e.target.value;
    }}
  />;

  // Update options
  sub(options, opts => {
    setChildren(select, opts);
    selectMarkSelected(sig, select);
  })

  // Two-way binding
  sub(sig, v => selectMarkSelected(sig, select), select);

  return select;
}

export interface TextareaFieldProps { 
  sig: Signal<string>,
  autoSelectAll?: boolean,
  divClass?: string,
  labelClass?: string,
  class?: string,
  label?: string,
  labelAlt?: string,
  placeholder?: string,
  readOnly?: boolean
  readOnlyClass?: string,
};

export function TextareaField({ 
  sig,
  autoSelectAll,
  divClass,
  labelClass,
  class: klass,
  label,
  labelAlt,
  placeholder,
  readOnly,
  readOnlyClass,
} : TextareaFieldProps) {
  let tarea: HTMLTextAreaElement;
  const uid = uniqueId('input-');

  const el = <div class={`${divClass||''}`}>
      {label && <FieldLabel 
        class={`mb-1 text-zinc-600 ${labelClass||''}`} 
        label={label}
        alt={labelAlt} 
        for={uid} />}
      {tarea = <textarea 
        id={uid}
        name={uid}
        readOnly={!!readOnly}
        class={`field ${klass||''}`}>{sig.v ?? ''}</textarea>}
    </div>;
  
  tarea.onblur = e => sig.v = e.target.value;

  if(readOnly && readOnlyClass) {
    tarea.classList.add(...readOnlyClass.split(' '));
  }

  // Autofocus if signal is null
  if(autoSelectAll) {
    onEnter(tarea, () => {
      setTimeout(() => {
        tarea.select()
      }, 0);
    });
  }

  return el;
}

export function CheckboxField({ sig, label, divClass, class: klass }) : HTMLInputElement {
  let input: HTMLInputElement;
  
  const el = <div class={`form-control ${divClass||''}`}>
    <label class="label">
      {label && <span class="label-text">{label}</span>}
      {input = <InputCheckbox class={klass} sig={sig} />}
    </label>
  </div>;

  return el;
}

export interface SelectCustomProps { 
  sig: Signal<any>,
  opts: Signal<({ value: string, label: string, disabled?: boolean } | string)[]>,
  class?: string,
  divClass?: string,
  placeholder?: string,
  label?: any,
  labelAlt?: any,
  labelClass?: string,
  readOnly?: boolean,
  readOnlyClass?: string,
};

// Custom select which we will enrich with more functionality.
// Renders options below.
// TODO: react to external state changes.
export function SelectCustom({ 
  sig,
  opts,
  class: klass,
  divClass,
  placeholder,
  label,
  labelAlt,
  labelClass,
  readOnly,
  readOnlyClass,
} : SelectCustomProps ) : HTMLFieldSetElement {
  const uid = uniqueId('input-');

  let selectionDiv: HTMLDivElement;
  let clickDiv: HTMLDivElement;
  let drawerDiv: HTMLDivElement;
  let optsDiv: HTMLDivElement;
  let labelDiv: HTMLDivElement = ((label || labelAlt) ? <FieldLabel class={`mb-1 text-zinc-600 ${labelClass||''}`} label={label} alt={labelAlt} /> : null); 

  const mySig = signal(sig.v);

  const el = <fieldset class={`relative group flex ${klass||''}`}>
      {(label || labelAlt) && labelDiv}
      
      {clickDiv = <div class={`relative ${!readOnly ? 'select-none hover:cursor-pointer' : ''}`}>
        {selectionDiv = <div class={`field pr-8 ${divClass||''} ${readOnly ? readOnlyClass : ''}`}></div>}

        {!readOnly && <div class="absolute right-2 top-1">
          <OutlineIcon icon="chevronDown" class="w-4 h-4" />
        </div>}
      </div>}
      
      {drawerDiv = <div class="hidden absolute top-full mt-0.5 left-0 right-0 bg-white new-shadow-light z-50 text-xs font-normal flex-col">
        {/* {searchDiv = <InputField sig={searc}} */}
        {optsDiv = <div class="flex flex-col" />}
      </div>}
  </fieldset>;

  function optsToPairs() : { value: string, label: string, disabled?: boolean }[] {
    return (opts.v ?? []).map(d => {
      if(d == null || typeof d !== 'object') {
        return {
          value: d,
          label: d,
        };
      }
      return d;
    });
  }

  function showDrawer() {
    setChildren(optsDiv, optsToPairs().map(opt => {
      return <Action
        onclick={() => {
          if(opt.disabled) {
            return hide();
          }
          mySig.v = opt.value;
          hide();
        }}
        class={`px-2 py-1.5 select-none text-left ${opt.disabled ? 'text-zinc-500' : 'hover:bg-zinc-100'}`}>{opt.label ?? '(none)'}{opt.disabled ? ' (disabled)' : ''}</Action>
    }));
  }


  function removeClickOutside(e: MouseEvent) {
    if(e.target !== clickDiv && !clickDiv.contains(e.target) && !labelDiv?.contains(e.target)) {
      hide();
      document.body.removeEventListener('click', removeClickOutside);
    }
  }

  function onFocus() {
    if(!!readOnly) return;

    switchClass(true, drawerDiv, 'flex', 'hidden');
    showDrawer();
    document.body.addEventListener('click', removeClickOutside);
  }

  clickDiv.addEventListener('click', onFocus);
  if(labelDiv) {
    labelDiv.addEventListener('click', onFocus);
  }

  function hide() {
    clear(optsDiv);
    switchClass(false, drawerDiv, 'flex', 'hidden');
  }

  sub([mySig, opts, signal(1)], () => {
    const match = optsToPairs().find(d => d.value == mySig.v);
    selectionDiv.innerText = match?.label || '(none)';

    // Sync external sig to our state (should be in sync)
    if(mySig.v !== sig.v) {
      sig.v = mySig.v;
    }
  }, el);

  // Apply external changes
  sub(sig, () => {
    if(sig.v !== mySig.v) {
      mySig.v = sig.v;
    }
  }, el);

  return el;
}

export function InputSwitchSliding({ sig, class: klass, uid, readOnly }) : HTMLInputElement {
  let input;
  const el = <div class="flex justify-center items-center">
    <label class="relative inline-flex items-center cursor-pointer">
      {input = <input type="checkbox" value="" class="sr-only peer" onchange={function() {
        if(sig.v !== this.checked) {
          sig.v = this.checked;
        }
      }} />}
      <div class="w-9 h-5 bg-zinc-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600"></div>
    </label>
  </div>;

  sub(sig, () => {
    if(sig.v !== input.checked) {
      input.checked = sig.v;
    }
  }, el);

  handleDisabledCtx(input);

  return el;
}

export function SwitchField({ sig, label, inputClass, class: klass }) : HTMLDivElement {
  let input: HTMLInputElement;
  const uid = uniqueId('input-');
  
  const el = <div class={`${klass||''}`}>
    {label && (
      <FieldLabel label={label} for={uid} />
    )}
    {input = <InputCheckbox sig={sig} class={inputClass} id={uid} />}
  </div>;

  return el;
}

export type FieldSwitchHorizontalProps = Partial<{ 
  readOnly?: boolean | Signal<boolean>,
  readOnlyClass?: string
}>;

export function FieldSwitchHorizontal({ 
  sig, 
  label, 
  inputClass, 
  class: 
  klass, 
  onclick, 
  labelClass, 
  readOnly,
  readOnlyClass,
} : FieldSwitchHorizontalProps) : HTMLDivElement {
  let input: HTMLInputElement;
  const uid = uniqueId('input-');
  
  const el = <div class={`flex flex-row gap-1 ${klass||''}`} onclick={onclick}>
    {input = <InputCheckbox sig={sig} class={`${inputClass||''}`} id={uid} readOnly={readOnly} />}
    {label && (
      <FieldLabel label={label} for={uid} class={`${labelClass||''}`} />
    )}
  </div>;

  return el;
}

function splitQuotedString(str) {
  const regex = /"[^"]*"|[^,]+/g;
  const columns = str.match(regex);
  
  // Remove surrounding quotes from columns
  return columns.map(column => {
    if (column.startsWith('"') && column.endsWith('"')) {
      return column.slice(1, -1);
    }
    return column;
  });
}

// Text-based multi-value selector
export function InputFieldSelector({ sig, opts, label, class: klass, inputClass, placeholder, readOnly, readOnlyClass } : {
  sig: Signal<any>,
  opts: Signal<string[]>,
  class?: string,
  inputClass?: string,
  placeholder?: string,
  label?: any,
  readOnly?: boolean,
  readOnlyClass?: string,
  // labelAlt?: any,
  // labelClass?: string,
}) : HTMLFieldSetElement {
  const uid = uniqueId('input-');

  let input: HTMLDivElement;
  let drawerDiv: HTMLDivElement;
  let optsDiv: HTMLDivElement;
  const el = <fieldset class={`relative group ${klass||''}`}>
      {input = <InputField 
         sig={sig}
         label={label} 
         class={`rounded-sm outline-1 outline-indigo-600 -outline-offset-1 ${inputClass}`}
         placeholder={placeholder}
         noSelfCheck={true}
         fromVal={d => (d && splitQuotedString(d) || [])}
         toVal={d => d.map(d => (d.indexOf(',') >= 0 ? `"${d}"` : d)).join(',')} 
         readOnly={readOnly}
         readOnlyClass={readOnlyClass}
        />}
      {drawerDiv = <div class="hidden absolute top-full mt-0.5 left-0 right-0 bg-white new-shadow-light z-50 text-xs font-normal flex-col px-2 py-1" />}
  </fieldset>;

  function removeClickOutside(e: MouseEvent) {
    if(!el.contains(e.target)) {
      hide();
      document.body.removeEventListener('click', removeClickOutside);
    }
  }

  const inputEl = el.querySelector('input');

  function onFocus() {
    if(!!readOnly) return;
    switchClass(true, drawerDiv, 'flex', 'hidden');
    toggleClass(true, inputEl, 'outline');
    setChildren(drawerDiv, <>
      {/* {searchDiv = <InputField sig={searc}} */}
      <SelectTagOptions   
        sig={sig} 
        opts={opts} 
        class="flex flex-wrap gap-1.5 text-zinc-600" />
    </>);
    document.body.addEventListener('click', removeClickOutside);
  }

  inputEl.addEventListener('focusin', onFocus);

  function hide() {
    clear(drawerDiv);
    toggleClass(false, inputEl, 'outline');
    switchClass(false, drawerDiv, 'flex', 'hidden');
  }

  return el;
}

function SelectTagOptions({ sig, opts, class: klass } : {
  sig: Signal<string[]>,
  opts: Signal<string[]>,
  class: string,
}) : HTMLDivElement {
  const el = <div class={`${klass||''}`}></div>;

  // Adjust dropdown when sig changes
  sub([sig, signal(1)], () => {
    setChildren(el, opts.v.filter(opt => {
      if(sig.v == null) return true;
      return sig.v.indexOf(opt) < 0;
    }).map(opt => {
      return <Action 
        onmousedown={e => {
          e.preventDefault(); // We capture this to make sure the input field remains focused
          sig.v = [...sig.v??[], opt].filter(d => d);
        }}
        class="bg-zinc-100 px-2 py-0.5 select-none text-left">{opt}</Action>
    }));
  }, el);

  return el;
}
import CryptoJS from 'crypto-js';
import stableStringify from 'fast-json-stable-stringify';
import { wordArrToBase32 } from '../lib/encoding.js';
import { EncryptionType } from '../models/encryption.js';
import { CollectionId, CommitHash, ContentHash, PrimitiveValue, RefHash, RefSeed, SealedData } from '../types/types.js';
import { CollectionMetadata, TrackingRef, TimestampWithTzInfo, TrackingRefExtended } from '../universe/types.js';
import { clone, makeRefHash, makeRefSeed, removeNullKeys, seal, unseal } from "../universe/utils.js";
// import { Change } from '../../../app/src/repo/types/change.js';
import { KeyService } from '../keyService.js';
import { getTimeTzOffset } from '../lib/time.js';
import { ValueFilterData } from '../models/filters.js';
import { DataType, Thing } from '../models/nodes.js';
import { LicenseData, LicensePropsData } from './licenses.js';

// sha256 base64 encoded filehash without the prefix.
type FileHash = string;

export interface FileData {
  // Remote path of the file.
  // This can be an encrypted path.
  path: string;

  // sha-256 file hash.
  integrity: FileHash;
}

// Should be JSON-seriazable, where null === undefined.
export interface CommitExternalizedProps {
  // Encryption type
  enc: EncryptionType;

  // Commit group
  thing: Thing;

  // Commit data type
  dataType: DataType;

  // Collection metadata at time of commit
  collection: CollectionMetadata; 

  // collectionId salted hash of the refSeed
  refHash: RefHash;

  // The reference of this object (optionally empty for encrypted objects)
  // When empty, refSeed is required and props.ref should be set client-side.
  ref?: string;

  // Optional collectionId salted hash of the ref
  // Required when the ref field is null or length 0
  // When the ref is provided, it's uniqueness properties are rolled into the refHash
  refSeed?: RefSeed;

  // If missing, the license is inferred NONE and sharing permissions can be revoked.
  // When an object is committed with an open-source license, the sharing permissions cannot be revoked anymore and are final.
  license?: LicenseData;

  // A list of Graffe Files and their integrity hash at time of commit.
  files?: FileData[];

  // Tombstones or archived=true object can provide info where their legacy continues.
  //
  // This allows passing around tracking to other maintainers and allows all dependencies to sync forward.
  successor?: TrackingRefExtended;
}

// // Versioning information for repository objects
// interface Changelog {
//   // Hint how impactful a change is.
//   // Data is part of versioning mindset.
//   //
//   // Examples of breaking changes:
//   // -  Changing filter dimension values.
//   // -  Changing data structure would be major, this would break integrations. 
//   // Bugfixing an aggregation improves the quality, this would be minor.
//   // However changing a dimension value might break integrations.
//   level: 'major' | 'minor' | 'patch';

//   // User message about the change
//   message: string;
// }

export interface CommitInternalProps {
  // The reference to this object
  ref?: string;

  // Any object-level filters
  filters?: ValueFilterData[];

  // Tracks the reference text this commit was forked from (if encrypted!!!)
  // PLACEHOLDER - NOT YET USED
  forkRef?: string;

  license?: LicensePropsData;

  // Versioning is a tricky thing in a data world.
  // - There is data quality. Eg: usually you want to include changes which improve data quality "automatically".
  // - There is aggregation. Eg: when a deeper level of granularity is added, but aggregates are still correct, then it's ok to add this
  // To make this simple we define changes unde
  // This doesn't mean a table cannot add a deeper level of 
  // Versioning is not at code level, but at "meaning" level.
  // Changes which improve data quality should be usually accepted without change.
  // Changes which break the interf
  // Version info for this object.
  // Audit trailing is part of the repository, so part of the objects.
  // It's hidden however for encrypted objects.
  // Version is a parseable field which follows
  // Breaking changes are not allowed.
  // change: Changelog;
}

// Should be JSON-seriazable, where null===undefined.
export interface CommitCommitProps {
  // Hash of the object tree
  content: ContentHash;

  // Which account did the commit
  committer: CollectionId;

  // At what time the commit was done
  time: TimestampWithTzInfo;

  // If empty, it indicates an object without parent
  parents?: CommitHash[];

  // In case of a fork, this hints what objet was forked from.
  fork?: TrackingRef;

  // // If the author is different from committer, or in case of multiple authors (of a shared session)
  // authors?: CollectionId[];

  // // Sig proof of signing the above by the committer
  // sig?: string;
}

export interface CommitData { 
  ext: CommitExternalizedProps;

  commit: CommitCommitProps;

  data: SealedData;

  hash: CommitHash;
}

// Commits deploy new versions of addressable things into the universe.
// A Commit is always sealed, since the contentHash is calculated on the sealed content. This way the contentHash doesn't leak information and can be verified by repository.
// A commit is an immutable object.
export class Commit {
  public ext: CommitExternalizedProps;

  public commit: CommitCommitProps;

  public data: SealedData;

  // Verifiable, calculated hash of this commit
  public hash: CommitHash;

  getStorageModel() : CommitData {
    const { ext, commit, data, hash } = this;
    return { ext, commit, data, hash };
  }

  static fromData(props: CommitData) : Commit {
    const { ext, commit, hash, data } = props;
    const sealed = new Commit();
    sealed.ext = clone(ext);
    sealed.data = data;
    sealed.hash = hash;
    sealed.commit = clone(commit);

    return sealed;
  }

  // static async commitFromChange(change: Change, ks: KeyService, committer: string) : Promise<Commit> {
  //   const commit = new Commit();

  //   const changeData = change.getStorageModel();

  //   // Externalized props
  //   // TODO: remove acl/dcl
  //   const { enc, type, collection, acl, dcl, license } = clone(changeData.ext);
  //   commit.ext = removeNullKeys({ enc, type, collection, acl, dcl, license });

  //   // Create refHash
  //   const refSeed = makeRefSeed(change.props.ref, collection.id);
  //   const refHash = makeRefHash(refSeed, collection.id);

  //   // Sealed props
  //   const props = removeNullKeys({
  //     ref: changeData.props?.forkRef,
  //     forkRef: changeData.props?.forkRef,
  //     filters: changeData.props?.filters,
  //   });

  //   // The actual data object
  //   const data = removeNullKeys(clone(changeData.data));

  //   // Seal the data of the commit
  //   commit.data = await seal(ks, enc, { p: props, d: data });

  //   // Calculate content verifiable hash of the above
  //   const contentHash = makeCommitContentHash(commit);

  //   // Create the commit structure
  //   commit.commit = removeNullKeys({
  //     content: contentHash,
  //     committer,
  //     time: getTimeTzOffset(),
  //     parents: changeData.ext.parents,
  //     fork: changeData.commit?.fork || null,
  //   });
  //   // TODO: track authors
  //   // TODO: sign structure if a key exists

  //   // Calculate content addressable verifiable commit hash
  //   commit.hash = makeCommitHash(commit.commit);

  //   // TODO: re-enable validation checks
  //   // const check = await validateCommitData(commit.getStorageModel());
  //   // if(!check.valid) {
  //   //   throw new ValidationError('invalid commit', check);
  //   // }

  //   return commit;
  // }

  async unseal(ks: KeyService) : Promise<UnsealedCommit> {
    const { ext, commit, hash } = this;
    const { p: props, d: data } = await unseal(ks, this.ext.enc, this.data) as any;

    return UnsealedCommit.fromData({ ext, props, data, commit, hash });
  }
}

export interface UnsealedCommitData {
  ext: CommitExternalizedProps;
  commit: CommitCommitProps;
  props: CommitInternalProps;
  data: PrimitiveValue;
  hash: CommitHash;
}

// Intermediate type used for implementing data validation
export class UnsealedCommit implements UnsealedCommitData {
  public ext: CommitExternalizedProps;

  public commit: CommitCommitProps;

  public props: CommitInternalProps;

  // The dash/view/table
  public data: PrimitiveValue;

  public hash: CommitHash;

  static fromData(d: UnsealedCommitData) : UnsealedCommit {
    const { ext, props, data, commit, hash } = d;
    const c = new UnsealedCommit();
    c.ext = clone(ext);
    c.props = clone(props);
    c.data = clone(data);
    c.commit = clone(commit);

    // TODO: calculate the hash and verify against the passed hash if any
    c.hash = hash;

    return c;
  }

  ref() : string {
    return this.ext.ref ?? this.props.ref;
  }

  fullRef() : string {
    return [this.ext.collection.slug, this.ref()].join('/');
  }
}

export async function sealedDataFromUnsealed(ks: KeyService, ext: CommitExternalizedProps, props: CommitInternalProps, data: PrimitiveValue) : Promise<SealedData> {
  // Externalized props
  const { enc, type, collection, license, refHash, refSeed } = clone(ext);
  ext = removeNullKeys({ enc, type, collection, license, refHash, refSeed });

  // Sealed props
  const { ref, forkRef, filters, license: sealedLicense } = clone((props || {}));
  props = removeNullKeys({ ref, forkRef, filters, license: sealedLicense });

  // The actual data object
  data = removeNullKeys(clone(data));

  // Seal the data of the commit
  return seal(ks, enc, { p: props, d: data });
}

export function makeCommitContentHash(props: { ext: CommitExternalizedProps, data: SealedData }) : ContentHash {
  const { ext, data } = props;

  const sha = CryptoJS.algo.SHA256.create(); 

  // First write the content, so we have controls to model later on
  sha.update(CryptoJS.enc.Utf8.parse(data));

  // Then add the content
  const serializedExt = stableStringify(removeNullKeys(ext as any));
  sha.update(CryptoJS.enc.Utf8.parse(serializedExt));

  // Finalize the content hash
  const hashBuf = sha.finalize();
  const hash = wordArrToBase32(hashBuf);

  return hash;
}

export function makeCommitHash(props: CommitCommitProps) : CommitHash {
  // Hasher
  const sha = CryptoJS.algo.SHA256.create(); 

  // Hash the commit object (which contains the content hash and sigs)
  const serializedCommit = stableStringify(removeNullKeys(props as any));
  sha.update(CryptoJS.enc.Utf8.parse(serializedCommit));

  // Finalize the commit hash
  const hashBuf = sha.finalize();
  const hash = wordArrToBase32(hashBuf);

  return hash;
}

// Compute the size cost of a commit.
// This function is not managed stable over time and should only be used for counters, not for validation.
export function commitDataToByteSize(commit: CommitData) : number {
  // We just count the storage cost of all separate fields we store
  let total = new TextEncoder().encode(
    (commit.hash) +
    (commit.ext.thing) +
    (commit.ext.dataType) +
    (commit.ext.ref||'') +
    (commit.ext.refSeed||'') +
    (commit.ext.enc) +
    (commit.ext.collection.id) +
    (commit.ext.collection.slug) +
    (commit.ext.files ? JSON.stringify(commit.ext.files) : '') +
    (commit.ext.successor?.ref||'') +
    (commit.ext.successor?.refHash||'') +
    (commit.ext.successor?.commit) +
    (commit.ext.successor?.collection.id) +
    (commit.ext.successor?.collection.slug) +
    (commit.data) +
    (commit.commit.committer) +
    (commit.commit.content) +
    (commit.commit.parents ? JSON.stringify(commit.commit.parents) : '') +
    (commit.commit.fork?.ref) +
    (commit.commit.fork?.commit) +
    (commit.commit.fork?.collection.id) +
    (commit.commit.fork?.collection.slug) +
    (commit.ext.collection.id)
  ).byteLength;

  total += 12; // time
  total += 4; // head flag
  total += 8; // created time

  return total;
}
import { setChildren } from "ufti";
import { Link } from "ufti/src/plugin/router";

const items = [
  { slug: 'roel', ref: 'strava/activity-overview', title: 'Your weekly activity overview (Strava connection)', avatar: 'https://gravatar.com/avatar/c8425c7e9862a06f00edcfd50e307d34?s=96&d=identicon' },
  { slug: 'roel', ref: 'belgium/trains/performance-monitor-yesterday', title: 'Belgian trains daily departure delay performance - per station - yesterday', avatar: 'https://gravatar.com/avatar/c8425c7e9862a06f00edcfd50e307d34?s=96&d=identicon' },
]

export default function TrendingDashboards({ class: klass }) {
  let itemsDiv;
  const el = <div class={klass||''}>
    <div class="font-semibold">Trending Dashboards</div>

    <div class="text-xs text-zinc-500 mt-1.5">Shared dashboards that are worth checking out.</div>

    {itemsDiv = <div class="mt-4 flex flex-col gap-2 text-xs"></div>}
  </div>;

  setChildren(itemsDiv, items.map(i => <Item item={i} />));

  return el;
}

function Item({ item } : { item: { slug: string, ref: string, title: string, avatar?: string } }) {
  const el = <div class="flex gap-2 items-start">
    <div class="mr-2">
      <div class="rounded-full overflow-hidden">
        <div class="w-8 h-8">
          <img class="w-full h-auto" src={item.avatar} />
        </div>
      </div>
    </div>

    <div class="flex gap-1 flex-col">
      <Link href={`/@${item.slug}/${item.ref}`} class="hover:underline font-semibold">
        <div>@{item.slug}/{item.ref}</div>
      </Link>

      <div class="text-zinc-500">{item.title}</div>
    </div>
  </div>;

  return el;
}
import { unexpected } from "graffe-shared/src/lib/devflow";
import { append, clear, onEnter, onExit, setChildren } from "ufti";
import { addCtx } from 'ufti/src/plugin/ctx';
import { PureCallback } from 'ufti/src/types';
import { whenClickedOutside } from "../lib/body";
import { OutlineIcon } from "../lib/icons";
import { pushKeyStack } from '../lib/keyStack';
import { tm } from '../lib/tailwind';

export class FixedCtx {
  close: PureCallback;
}

export const fixedState : {
  outlet: HTMLDivElement,
  ctx: FixedCtx,
} = {
  outlet: null,
}

// Container to mount mouse-following or single instance containers like context menu's, column stats, ...
export function FixedContainerSingleton() : HTMLDivElement {
  if(fixedState.outlet) {
    unexpected();
  }
  fixedState.outlet = <div class="fixed hidden z-[9999]"></div>;
  
  fixedState.ctx = new FixedCtx();
  
  // Create the close function
  fixedState.ctx.close = function() {
    clear(fixedState.outlet);
    fixedState.outlet.classList.add('hidden');
  }

  addCtx(fixedState.outlet, fixedState.ctx);

  return fixedState.outlet;
}

// Helper function which will render content in the fixed modal (which closes other instances).
// By default when a user clicks outside the children, the fixed container will cleanup itself.
//
// TODO: technically we could skip the exitEl/setCtxParent overhead if we track all instances and close others when a new one is created.
export function showContentFixed(el: HTMLDivElement, postn: { pageX: number, pageY: number }, opts: {
  closeOnOutside?: boolean,
  closeOnInside?: boolean,
  addCloseControl?: boolean,
  closeOnEscape?: boolean,
  xClasses?: string,
  exitEl?: HTMLElement,
}) {
  const { closeOnOutside = true, closeOnInside = false, addCloseControl = true, closeOnEscape = true } = opts || {};
  fixedState.outlet.style.left = `${postn.pageX}px`;
  fixedState.outlet.style.top = `${postn.pageY}px`;
  setChildren(fixedState.outlet, el);
  el.classList.add('overflow-y-auto'); // Make sure overlays scroll when too big
  el.style.maxHeight = `${window.innerHeight-postn.pageY-16}px`;
  fixedState.outlet.classList.remove('hidden');

  // Close when clicked outside
  if(closeOnOutside) {
    whenClickedOutside(el, fixedState.ctx.close);
  }

  // Close when clicked inside
  if(closeOnInside) {
    // This will be automatically wiped via clear, we don't need to unsubscribe.
    el.addEventListener('click', e => {
      if(e.target === el || el.contains(e.target)) {
        fixedState.ctx.close();
      }
    });
  }

  if(addCloseControl) {
    append(fixedState.outlet, <div 
      onclick={fixedState.ctx.close}
      class={tm('absolute right-0 top-0 p-1 rounded-sm hover:bg-zinc-100 hover:cursor-pointer', opts?.xClasses)}>
        <OutlineIcon icon="xMark" class="w-5 h-5" />
      </div>)
  }

  // TODO: we should rewrite this logic such we register "subscribe to escape event" and then, the lowest member which _has focus_ gets closed, falling back to current stack order after.
  if(closeOnEscape) {
    onEnter(el, () => {
      const cancel = pushKeyStack('escape', fixedState.ctx.close);
      onExit(el, cancel);
    })
  }

  // TODO: adjust view if falls outside (can be predictive).

  // Allows to hook to a parent element
  if(opts?.exitEl) {
    onExit(opts.exitEl, fixedState.ctx.close);
  }
}
// Simple module which allows stacking certain hotkey combinations, such the latest caller gets the callback
import hotkeys, { HotkeysEvent, KeyHandler } from 'hotkeys-js';
import { last } from 'lodash-es';

// All calls to this callback have now to be routed through this stack.
const stacks: Record<string, KeyHandler[]> = {};

function onKeys(key: string, keyboardEvent: KeyboardEvent, hotkeysEvent: HotkeysEvent) {
  const latest = last(stacks[key]);
  latest && latest(keyboardEvent, hotkeysEvent);
}

type CancelCallback = () => void;

export function pushKeyStack(key: string, handler: KeyHandler) : CancelCallback {
  if(!stacks[key]) stacks[key] = [];
  stacks[key].push(handler);
  if(stacks[key].length == 1) {
    hotkeys(key, (keyboardEvent, hotkeysEvent) => onKeys(key, keyboardEvent, hotkeysEvent));
  }

  return () => {
    const idx = stacks[key].indexOf(handler);
    if(idx >= 0) stacks[key].splice(idx, 1);
    if(stacks[key].length == 0) {
      hotkeys.unbind(key);
    }
  }
}
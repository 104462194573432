import { Link } from "ufti/src/plugin/router";
import { CustomIcon, SolidIcon } from "../../../lib/icons";

export default function PageFooter({ class: klass }) : HTMLDivElement {
  const el = <footer class={`flex flex-col gap-3 sm:gap-0 items-center sm:flex-row text-zinc-500 ${klass||''}`}>
    <div class="sm:mr-auto sm:mb-4 flex items-center gap-5">
      <a href="https://twitter.com/graffeapp" target="_blank"><CustomIcon icon="twitter" class="w-5 h-5" /></a>
      <a href="https://discord.gg/wHxEkMZe3w" target="_blank"><CustomIcon icon="discord" class="inline w-5 h-5" /></a>
      <div onclick={e => { e.currentTarget.innerHTML = 'info@graffe.app'}}><SolidIcon icon="envelope" class="h-5 w-5 hover:cursor-pointer" /></div>
    </div>

    <div class="text-xs font-normal mb-4 flex items-center gap-2 sm:mr-4">
      <Link href="/privacy" class="hover:underline">Privacy</Link>
      <Link href="/terms" class="hover:underline">Terms</Link>
    </div>

    <div class="text-xs font-normal mb-4 flex items-center">Copyright © 2023 - All rights reserved by Graffe.app</div>
  </footer>;

  return el;
}
// Helpers for creating Tombstones correctly.

import { trimStart } from "lodash-es";
import { unexpected } from "graffe-shared/src/lib/devflow";
import { UnsealedCommit } from "graffe-shared/src/universe/commit";
import { TrackingRefExtended } from "graffe-shared/src/universe/types";
import { toRefHash } from "graffe-shared/src/universe/utils";
import { onEnter, onExit, setChildren, signal, sub } from "ufti";
import { showInDialog } from "../components/ProcessReporter/Dialogs";
import { closeParentDialog } from "../components/ProcessReporter/helpers";
import { UIButton } from "../components/ui/buttons";
import { fullRefToRefHashProps } from "../id/helpers";
import { InputField, SelectCustom } from "../lib/fields";
import { toFullRef } from "../lib/ref";
import { state } from "../state";
import { reportFailed } from "../types/notifications";
import { resolveHeadCommitByRefHash } from "./gateway";

export async function resolveSuccessor(killed: UnsealedCommit) : Promise<TrackingRefExtended> {
  return new Promise((resolve, reject) => {
    const successor: Signal<TrackingRefExtended> = signal();
  
    showInDialog(
      <SuccessorSelector 
        killed={killed} 
        successor={successor} 
        resolve={resolve}
        reject={reject}
      />
    );
  })
}

function SuccessorSelector({ killed, successor, resolve, reject } : { 
  killed: UnsealedCommit, 
  successor: TrackingRefExtended,
  resolve: (d: TrackingRefExtended) => void,
  reject: (err: any) => void,
}) {
  const el: HTMLDivElement = <div></div>;

  const fullRef = signal();
  const strategy = signal('url');

  const hintData = localStorage.getItem('successor.'+killed.ext.refHash);
  if(hintData) {
    const { slug, ref } = JSON.parse(hintData);
    fullRef.v = `@${toFullRef(slug, ref)}`;
  }

  let resolved = false;
  let inputDiv: HTMLInputElement;
  onEnter(el, () => {
    setChildren(el, <>
      <div class="flex flex-col">
        <div>
          You're removing a previously committed object: <span class="font-semibold">@{killed.fullRef()}</span>
        </div>

        <div class="mt-2 text-zinc-500">
          When removing an object, it's required to indicate if there is a next version, so other objects know where to look for the next version.
        </div>

        <div class="mt-4">
          <SelectCustom 
            opts={signal([
              { value: 'url', label: 'Provide successor' },
              { value: 'delete', label: `No successor. The URL @${killed.fullRef()} is to be "removed"` }
            ])}
            sig={strategy}
          />
        </div>

        {inputDiv = <div class="mt-2" />}
      </div>

      <div class="mt-4 flex justify-between">
        <UIButton onclick={() => apply()}>Apply</UIButton>
      </div>
    </>);

    sub(strategy, () => {
      if(strategy.v === 'delete') {
        setChildren(inputDiv, <div class="text-zinc-500 italic py-1">No next version. This was the last version. Usages will not discover a next version.</div>);
      } else {
        setChildren(inputDiv, <>
          <InputField divClass="flex items-center gap-2" sig={fullRef} label="Successor object" placeholder={`@${state.user.v?.slug}/object-url/...`} />
        </>);
      }
    }, el);
  });

  async function apply() {
    try {
      let successor: TrackingRefExtended; 
      if(strategy.v === 'url') {
        const { colId, colRef, slug } = await fullRefToRefHashProps(trimStart(fullRef.v??'', ' /@'));
        const refHash = toRefHash(colRef, colId);
        const commit = await resolveHeadCommitByRefHash(killed.ext.thing, refHash);
        if(commit) {
          const { ref, collection } = commit.ext;
          successor = {
            commit: commit.hash,
            ref,
            refHash, 
            collection,
          }
        } else {
          throw new Error('Could not find object for '+fullRef.v);
        }
      } else if (strategy.v === 'delete') {
        // There is no successor and thus no forward branch.
        // Serialization using getStorageModel() cleans this to a null value in the eventual commit.
        successor = null;
      } else {
        unexpected();
      }
      
      resolved = true;
      resolve(successor);
      closeParentDialog(el);
    } catch (err) {
      reportFailed(err)
    }
  }

  onExit(el, () => {
    if(!resolved) {
      reject();
    }
  })

  return el;
}
import { findCtx } from "ufti/src/plugin/ctx";
import { unexpected } from "graffe-shared/src/lib/devflow";
import { CollectionId } from "graffe-shared/src/types/types";
import { Signal } from "ufti";
import { isDOMNode } from "../lib/object";
import { AccessCtx } from "../nodeCtxs/accessCtx";
import { ObjectCtx } from "../nodeCtxs/objectCtx";
import { state } from "../state";

interface CollectionObjectLike {
  ext: {
    collection: {
      id: Signal<string> | string;
    }
  }
}

export function canWrite(obj: Element) : boolean;
export function canWrite(obj: CollectionObjectLike) : boolean;
export function canWrite(obj: ObjectCtx) : boolean;
export function canWrite(obj: AccessCtx) : boolean;
export function canWrite(obj: any) : boolean {
  if(!state.user.v?.id) return false;

  // Find col id across different objects
  let objColId: CollectionId;
  if(obj instanceof ObjectCtx || obj instanceof AccessCtx) {
    objColId = obj.open.v?.ext.collection.id.v;
  } else if (isDOMNode(obj)) {
    const ctx = findCtx(obj, ObjectCtx);
    if(!ctx) {
      unexpected('object ctx not found');
    }
    objColId = ctx.open.v?.ext.collection.id.v;
  } else {
    objColId = obj.ext.collection.id?.v ?? obj.ext.collection.id;
  }
  if(!objColId) {
    unexpected();
  }

  return canWriteCollection(objColId);
}

export function canWriteCollection(colId: CollectionId) : boolean {
  if(!state.user.v?.id) return false;
  
  if(state.user.v?.id === colId) {
    return true;
  }

  const team = state.teams.v?.find(v => v.team.id === colId);
  if(team && ['owner', 'member'].indexOf(team.role) >= 0) {
    return true;
  }

  return false;
}
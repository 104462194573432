// Helpers for showing consistent dialogs, we fully hook into the html5 dialog element.

import { unexpected } from "graffe-shared/src/lib/devflow";
import { append, remove } from "ufti";
import { Action } from "../../lib/button";
import { OutlineIcon } from "../../lib/icons";
import { tm } from '../../lib/tailwind';

const dialogState : {
  wrapper: HTMLDivElement,
  outlet: HTMLDivElement,
} = {
  wrapper: null,
  outlet: null,
}

// After creation, the element should be added to a parent and showModal() should be called on it.
export function DialogOverlay({ class: klass }, content) : HTMLDialogElement {
  const dialog: HTMLDialogElement = <dialog
    class={tm('relative bg-white p-5 text-xs', klass)}
    onclick={e => {
      // When the click is on the outside area, we close.
      // Escape presses are automatically handled with the dialog element and 
      if(e.target === dialog) {
        terminate();
      }
    }}>
    <div>{content}</div>

    <Action class="absolute right-1 top-1" onclick={() => terminate()}>
      <OutlineIcon icon="xMark" class="w-5 h-5" />
    </Action>
  </dialog>;

  function terminate() {
    dialog.close();
    remove(dialog);
  }

  return dialog;
}

export function showInDialog(content: HTMLElement | HTMLElement[], mountEl?: HTMLElement) : HTMLDialogElement {
  if(!mountEl) {
    mountEl = dialogState.outlet;
  }
  const dialog = <DialogOverlay>{content}</DialogOverlay>;
  append(mountEl, dialog);
  dialog.showModal();

  return dialog;
}

export function DialogContainerSingleton() : HTMLDivElement {
  if(dialogState.wrapper) {
    unexpected();
  }
  dialogState.wrapper = <div class="text-xs">
    {dialogState.outlet = <div />}
  </div>
  
  return dialogState.wrapper;
}
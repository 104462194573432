
import { append, onEnter, onExit, remove, signal, sub } from 'ufti';
import { navigate, router } from 'ufti/src/plugin/router';
import { PureCallback } from "ufti/src/types";
import { DEFAULT_COMMUNITY_USER } from '../../constants';
import { inviteFlowTimeKey } from '../../graffe.app/components/Invite/InvitePage';
import { doSignIn } from '../../lib/auth';
import { Action } from "../../lib/button";
import { isProduction } from '../../lib/dev';
import { hasRemoteRepo, isCE } from '../../lib/environment';
import { InputField } from '../../lib/fields';
import { blockFormSubmit } from '../../lib/form';
import { CustomIcon, OutlineIcon } from "../../lib/icons";
import { pushKeyStack } from "../../lib/keyStack";
import { wrapAsync } from '../../lib/logger';
import { state } from "../../state";

function DeveloperSignInForm() {
  const email = signal(process.env.NODE_ENV === 'development'
    && localStorage.getItem('devmail') || '');
  const pass = signal(process.env.NODE_ENV === 'development'
    && localStorage.getItem('devpass') || '');

  async function submit() {
    const mail = email.v;
    await doSignIn(email.v, pass.v);

    // Backend routes also to / page
    navigate(`/`, { reloadIfSame: true });
  }

  return <div>
    <form onsubmit={() => blockFormSubmit(wrapAsync(submit))} class="mt-8">
      <hr />
      
      <p>
        <InputField type="email" class="w-full" placeholder='Your email' instant={true} sig={email} autocomplete="username" />
      </p>
      
      <p>
        <InputField type="password" class="w-full" placeholder='A strong passphrase' instant={true} sig={pass} autocomplete="current-password" />
      </p>

      <div class="flex">
        {/* Allow user to create the above account */}
        <Action class="link flex-1" onclick={wrapAsync(async () => {
            const { createUserLocal } = await import('../../lib/authHeavy');
            await createUserLocal(email.v, pass.v);
        })}>Sign up</Action>
        
        <Action class="btn flex-1" type="submit">Sign in</Action>
      </div>
    </form>
  </div>
}

function SignInContent() {
  return <div class="text-center">

    <h2 class="text-2xl font-semibold">Sign in</h2>

    {/* <p class="text-sm font-normal text-zinc-600 mt-4">TODO: some new relevant text here to make it a visually more friendly box.</p> */}

    <div class="flex flex-col md:flex-row mt-8">
      <div class="md:flex-1 md:ml-4 mb-4 md:mb-0">
        <a href={`${state.idService.v}/v1/login/google`} class="btn px-4 py-2 gap-2 text-indigo-800 text-sm font-medium">
          <CustomIcon icon="google" class="inline w-6 h-6 mr-1" />
          Sign in with Google
        </a>
      </div>
      {/* <div class="md:flex-1">
        <a href={`${state.idService.v}/v1/login/github`} class="btn gap-2"><IconFa prefix="fab" iconName='github' class="w-6 h-6" />Sign in with GitHub</a>
        <div class="text-xs text-slate-500 mt-2">needs email visible on <a class="text-slate-500 no-underline" target="_blank" href="https://github.com/settings/emails">your profile</a></div>
      </div> */}
    </div>

    {/* Developer login form */}
    {!isProduction() && <DeveloperSignInForm />}
  </div>;
}

function CommunitySession() {
  function ceSignIn() {
    // When community edition is loaded, we load the community user by default
    if(isCE()) {
      if(!hasRemoteRepo()) {
        state.user.v = clone(DEFAULT_COMMUNITY_USER);
      }
    }
  }

  return <div class="text-center mt-4">
    <button class="ml-2 btn" onclick={() => ceSignIn()} >Sign in as community user</button>
  </div>
}

function SignInModal({ resolve, reject, persist } : {
  resolve: PureCallback,
  reject: PureCallback,
  persist?: boolean,
}) {
  //
  const el = <div class="modal" onclick={e => {
    if(e.target === el && !persist) {
      close();
    }
  }}>
    <div class="modal-content p-10 w-[420px] mx-2 md:mx-0">
      <OutlineIcon icon="xMark" class="absolute right-4 top-4 w-6 h-6 text-zinc-600 hover:cursor-pointer" onclick={() => close()}/>

      <SignInContent />
      
      {/* <Show
        when={() => isCE()}
        t={() => <CommunitySession />}
      /> */}
    </div>
  </div>;

  function close() {
    resolve();
    remove(el);
  }

  onEnter(el, () => {
    onExit(el, pushKeyStack('escape', () => {
      if(!persist) close();
    })); // Close on ESC

    sub(state.user, user => {
      if(user != null) close();
    }, el);
  });

  return el;
}

// Contextless component which can be called on an element as a promise.
// When the user selects, it returns the promise to the caller.
export async function showSignInModal(persist: boolean) : Promise<void> {
  // TODO: if user is already signed in, redirect them to logsignoutout

  // If logged in, go to home page
  if(state.user.v?.id && state.user.v?.slug && state.user.v?.invited == 1) {
    return navigate(`/`);
  }

  // INVITE FLOW - enable this for the onboarding
  // if(localStorage.getItem(inviteFlowTimeKey) == null && router.location.v?.pathname !== '/invite') {
  //   navigate('/invite');
  //   return
  // }

  // Default: load the signin modal
  return new Promise((resolve, reject) => {
    append(document.body, <SignInModal resolve={s => resolve(s)} reject={e => reject(e)} persist={persist} />);
  });
}

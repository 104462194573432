import { Link } from "ufti/src/plugin/router";
import { tm } from "../../../lib/tailwind";

export default function Examples({ class: klass }) {
  const el = <div class={tm('flex flex-col gap-8', klass)}>
    <div class="text-center flex flex-col gap-2">
      <h3 class="font-semibold text-xl lg:text-2xl text-indigo-400">Example Dashboards</h3>
      <h4 class="font-display font-semibold text-zinc-900 text-base lg:text-xl">Made by Data Artists in Graffe</h4>
    </div>

    <DashboardList />

    <div class="mt-[48px] lg:mt-[60px] text-center flex flex-col gap-2">
      <h3 class="font-semibold text-xl lg:text-2xl text-indigo-400">Trending Tables defined in Graffe</h3>
      <h4 class="font-display font-semibold text-zinc-900 text-base lg:text-xl">Everybody can query and build on these tables</h4>
    </div>

    <TableList />
  </div>;

  return el;
}

function ExampleDashboard({ artist, href, title, img }) {
  const objectUrl = `/@${artist}/${href}`;
  return <Link href={objectUrl}>
    <div class="bg-white hover:outline focus:outline outline-2 outline-indigo-600 p-[5px] rounded-xl w-full sm:w-auto max-w-[440px] mx-auto">
      <div class="flex flex-col bg-[#F1F1F4] flex-1 flex-grow h-96 p-4 rounded-xl max-w-full overflow-hidden break-words">
        <div>@{artist}/{href}</div>

        {/* <div class="flex"> */}
          {/* <div class="text-zinc-600 hover:underline text-right">by <Link href={`/@${artist}`} class="font-semibold">@{artist}</Link></div> */}
        {/* </div> */}

        <div class="mt-1.5 text-xs text-zinc-600">{title}</div>

        <div class="mt-3 flex justify-center overflow-hidden rounded">
          <div class="flex justify-center overflow-hidden rounded">
            <img 
              src={img}
              class="outline-2 outline outline-[#F1F1F4] w-80 h-72 object-cover" />
          </div>
        </div>
      </div>
    </div>
  </Link>;
}

function DashboardList() {
  return <div class="flex gap-8 lg:gap-12 justify-around flex-col md:flex-row">
    <ExampleDashboard 
      artist="roel" 
      href="strava/activity-overview"
      title="Your weekly activities using live Strava API"
      img="/_assets/examples/roel_strava_activity_overview.png"
    />

    <ExampleDashboard 
      artist="roel" 
      href="belgium/trains/performance-monitor-yesterday"
      title="Belgian trains daily departure delay performance using live open data"
      img="/_assets/examples/roel_belgium_trains_performance_monitor_yesterday.png"
    />
  </div>;
}

function ExampleTable({ artist, href, title }) {
  const objectUrl = `/@${artist}/${href}`;

  return <div class="flex flex-col">
    <Link href={objectUrl} class="font-semibold hover:underline">@{artist}/{href}</Link>
    <div class="text-xs text-zinc-600">{title}</div>
  </div>;
}

function TableList() {
  return <div class="flex-1 flex justify-center bg-white p-[5px] rounded-xl w-full sm:w-auto max-w-[768px] mx-auto">
    <div class="flex-1 flex flex-col bg-[#F1F1F4] p-4 rounded-xl gap-4 max-w-full overflow-hidden break-words">
      <ExampleTable
        artist="roel"
        href="climatedata.imf.org/climate-change/annual-surface-temp-change"
        title="Annual estimates of mean surface temperature change are measured with respect to a baseline climatology. From the IMF."
      />

      <ExampleTable
        artist="roel"
        href="opendata.infrabel.be/datasets/ruwe-gegevens-van-stiptheid/day-1/data-sql"
        title="The raw data about arrival and departure of Belgian passenger trains (domestic and international) at their stopping points over a day and by train number. The relations and lines are also given."
      />

      <ExampleTable
        artist="roel"
        href="strava/data/activities-sql"
        title="Table wrapping Strava API with SQL, filtering support and some field extensions."
      />
    </div>
  </div>
}
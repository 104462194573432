import stableStringify from 'fast-json-stable-stringify';
import { DataType, Thing } from 'graffe-shared/src/models/nodes';
import { CommitData, UnsealedCommit, UnsealedCommitData } from 'graffe-shared/src/universe/commit';
import { getStorageModel, primitiveValue } from "graffe-shared/src/universe/utils";
import { ValidationError } from 'graffe-shared/src/validator/commit';
import { Signal, signal, sub } from 'ufti';
import { unexpected } from '../../../graffe-shared/src/lib/devflow';
import { TableModuleId } from '../../../graffe-shared/src/models/table';
import { ViewModuleId } from '../../../graffe-shared/src/models/view';
import { PureCallback } from 'ufti/src/types';
import { FilterCtx } from '../filters/filterCtx';
import store from "../idb/store";
import { Change, applyCommitProps } from '../models/change';
import { Dash } from '../nodes/dash';
import Table from '../nodes/table';
import { View } from '../nodes/view';
import { state } from '../state';
import { RefreshCtx } from '../tables/refreshCtx';
import { reportFailed, reportInfo, reportSuccess, reportWarning } from '../types/notifications';
import { resolveHeadCommitByRefHash } from '../universe/gateway';
import { resolveSuccessor } from '../universe/tombstone';
import { enableCtxAutoSaving, unsealedCommitsEq } from './helpers';
import { InstanceCtx } from './instanceCtx';
import { UsageCtx, UsageIntent } from './usageIntent';

// In charge of tracking the change (and loading it)
// Tries to be as lazy as possible.
// Every rendered projection has an object render ctx exposed.
// Every rendered projection can search from self for the render ctx.
export class ObjectCtx {
  // Snapshot of the last commit done on this object.
  // For fast stable diffing of changes.
  initData: Signal<UnsealedCommitData> = signal<UnsealedCommitData>();

  // The live open change, signalling.
  // Gets debounced saved periodically.
  //
  // TODO: this should expose this into a separate change context to be composable and work without objects
  open: Signal<Change> = signal<Change>();

  // Savepoint state of the auto-saved commit (to avoid re-writes)
  // Initialized with the last stored change on load
  lastSave: Signal<UnsealedCommitData> = signal<UnsealedCommitData>();

  // Object filter context.
  filterCtx: Signal<FilterCtx>;

  // Loaded instance ctx of the active object instance
  // Warning: if ever implement reverting and forwarding with the .open signal, then this one should be kept in sync.
  // We could also just pass the .open signal into the instanceCtx and just sync to that..
  instanceCtx: InstanceCtx;

  // Loaded usage ctx
  usageCtx: UsageCtx;

  // If set, autosaving is active and can be cancelled
  autoSaveCancel?: PureCallback;

  // Refresh ctx of this object
  refreshCtx: RefreshCtx = new RefreshCtx();

  static create(usage: UsageIntent, lastCommit?: UnsealedCommit, lastSave?: UnsealedCommit) : Promise<ObjectCtx> {
    const ctx = new ObjectCtx();

    // Set filterctx
    ctx.filterCtx = signal(new FilterCtx());
    ctx.instanceCtx = new InstanceCtx();
    ctx.usageCtx = new UsageCtx(usage);
    ctx.filterCtx.v.id = usage.commitHash ?? usage.refHash;
    
    if(!lastCommit && !lastSave) {
      debugger
      throw new Error('invalid object');
    }

    if(lastCommit) {
      ctx.initData.v = getStorageModel(lastCommit);
    }
    if(lastSave) {
      ctx.lastSave.v = getStorageModel(lastSave);
    }

    // Load the change from either last save point or last version.
    const change = Change.fromUnsealedCommit(lastSave ?? lastCommit);

    // Set the object filters on the filter context
    if(change.props.filters.v && change.props.filters.v.length > 0) {
      ctx.filterCtx.v.filters.v = [...change.props.filters.v];
    }

    // Enable auto saving in editor
    if(usage.appRole === 'editor') {
      // TODO: normally this is automatically garbage collected... might not be. Keep an eye on.
       ctx.autoSaveCancel = enableCtxAutoSaving(ctx);
    }

    // Set the instance ctx
    ctx.instanceCtx.instance.v = change.data;

    // Trigger the context, it's now "open for business".
    ctx.open.v = change;

    return ctx;
  }

  // To be called once by the parent renderer during existance of the ctx.
  subFilterCtx(el: Element) {
    // Propagate filter changes to the object (if different)
    sub(this.filterCtx.v.filters, filters => {
      const ctxFilters = stableStringify(primitiveValue(filters));
      const changeFilters = stableStringify(primitiveValue(this.open.v!.props.filters.v));
      if(ctxFilters !== changeFilters) {
        this.open.v!.props.filters.v = [...filters];
      } else {
        // Hit it because these are already in sync
        // TODO: feels like we should approach this with a surface
        this.open.v!.props.filters.v = this.open.v!.props.filters.v;
      }
    }, el);
  }

  isModified() : boolean {
    return this.lastSave.v != null;
  }

  // Remove the unsaved change for this ctx from the stage
  async dropOpenChange() : Promise<void> {
    await (await store()).delChangeForRefHash(Thing.Object, this.lastSave.v.ext.refHash);
    this.lastSave.v = null;
    console.log('deleted last save');
  }
}

// This commits a single object ctx
export async function commitCtxObject(ctx: ObjectCtx, access?: CommitData) {
  console.log('commitCtxObject', ctx.open.v?.ext.ref.v);

  // Create a stable copy to work with
  const change = Change.fromUnsealedCommit(ctx.open.v.toUnsealedCommit());

  if((await change.calcContentHash()) === change.commit.content.v) {
    await ctx.dropOpenChange();
    return reportInfo('Nothing changed');
  }

  // Create a commit from the change
  try {
    // Validate if we have all info to commit.
    // For Tombstones we require a successfor.
    if(ctx.open.v?.ext.dataType.v === DataType.Tombstone) {
      if(!ctx.initData.v) {
        unexpected('Previous commit expected for tombstones')
      }
      
      // First clear the successor if we're working on the original version
      const prev = stableStringify(getStorageModel(ctx.initData.v?.ext.successor));
      const next = stableStringify(getStorageModel(change.ext.successor.v));
      if(prev === next) {
        change.ext.successor!.v = null;
      }

      // Now lookup the successor path and ask for confirmation
      const successor = await resolveSuccessor(change.toUnsealedCommit());
      change.ext.successor!.v = successor;
    }

    // Collect the parents for branch mapping
    const parents = [];
    
    // If the open change had a previous version, we tag it.
    // This means we are aware of the ancestry (from a previous commit operation).
    if(ctx.open.v.hash.v != null) {
      parents.push(ctx.open.v.hash.v);
    } else {
      // TODO: this doesn't belong here in a hidden flow. NO HIDDEN FLOWS.
      //
      //       this should be an explicit flow which runs before committing and updates objects. When something changes we inform something has changed and allow inspection/reloading objects.
      // 
      // If it's not a tombstone, we require a network connection here.
      const oldHead = await resolveHeadCommitByRefHash(Thing.Object, ctx.open.v?.ext.refHash.v, true);
      if(oldHead) {
        // reportInfo('Found an old parent on this commit, adding')
        parents.push(oldHead.hash);
      }
    }
    
    // Create the commit and queue it.
    const commit = await change.finalize(state.user.v.id, parents, null);

    // Store it
    await (await store()).putCommit(commit);

    // Re-initialize the context with the new commit
    const unsealed = await commit.unseal();
    const commitData = getStorageModel(unsealed);

    // If we have a change which was loaded from commit, we update the commit hash to keep it in the same state, but evolved, without changing behavior.
    // TODO: questionable if we want to do this, technically, if you push forward... you are on the head. Hmmm. We need simple head-detection logic here.
    ctx.initData.v = commitData;
    ctx.open.v.hash.v = commit.hash;
    applyCommitProps(ctx.open.v.commit, commit.commit);
    ctx.open.v.ext.successor.v = change.ext.successor.v;

    // Delete the auto save that matches the latest commit, this value might have changed in meantime, but that's OK. We remove the unstable fields (hashes, commit, successor, ...).
    if(ctx.lastSave.v) {
      const { hash: hash1, commit: commit1, ext: { successor: suc1, ...extLast }, ...lastSaveWithoutHash } = ctx.lastSave.v;
      const { hash: hash2, commit: commit2, ext: { successor: suc2, ...extChange }, ...changeWithoutHash } = change.toUnsealedCommit();
      lastSaveWithoutHash.ext = extLast;
      changeWithoutHash.ext = extChange;
      if(unsealedCommitsEq(lastSaveWithoutHash, changeWithoutHash)) {
        await (await store()).delChangeForRefHash(Thing.Object, ctx.lastSave.v.ext.refHash);
        ctx.lastSave.v = null;
        console.log('deleted last save');
      }
    }

    // Notify commit
    reportSuccess('Object committed');

    // Apply change of commit hash to the required nodes
    // Sync editor to the new unsealed commit
    for(let [hash, objectCtxLoadingProm] of state.nodeProvider.v?.objects) {
      console.debug(`syncing ${hash} to ${unsealed.fullRef()}`);
      let resolvedCtx: ObjectCtx;
      try {
        resolvedCtx = await objectCtxLoadingProm;
      } catch (err) {
        reportWarning('Could not update object for '+hash);
        continue; // CONTINUE
      }
      await syncObjectCtxToCommit(resolvedCtx, unsealed);
    }
    reportSuccess('Usages in editor synced to commit');
  } catch(err) {
    if(err instanceof ValidationError) {
      for(let err of err.res.errors) reportFailed(err.error, err);
      return;
    }

    throw err;
  }
}

async function syncObjectCtxToCommit(ctx: ObjectCtx, unsealed: UnsealedCommit) {
  if(unsealed.ext.refHash === ctx.open.v?.ext.refHash.v) return; // Skip self, that one is in sync

  const change = ctx.open.v;

  // If object is a sql table, loop over deps
  if(change.ext.dataType.v === DataType.TableV1 && change?.data?.module === 'SqlV1') {
    const table = change.data as Table;

    // Update hash for table dependencies
    for(let dep of table.deps.v||[]) {
      if(dep.module.v !== TableModuleId.GraffeTableV1) continue;
      if(dep.props.v?.ref === unsealed.fullRef() && (!dep.props.v?.commit || unsealed.commit.parents?.indexOf(dep.props.v?.commit) >= 0)) {
        console.debug(`syncing table dep to ${unsealed.fullRef()}`);
        dep.props.v = { ...dep.props.v, commit: unsealed.hash };
      }
    }
  }
    
  // If object is a dashboard, then loop and update view dependencies
  if(change?.ext.dataType.v === DataType.DashV1) {
    const dash = change.data as Dash;

    for(let view of dash.views.v||[]) {
      if(view.module.v !== ViewModuleId.GraffeViewV1) continue;
      if(view.props.v?.ref === unsealed.fullRef() && (!view.props.v?.commit || unsealed.commit.parents?.indexOf(view.props.v?.commit) >= 0)) {
        console.debug(`syncing dash dep to ${unsealed.fullRef()}`);
        view.props.v = { ...view.props.v, commit: unsealed.hash };
      }
    }
  }

  // If object is a view, then loop and update table dependencies
  if(change?.ext.dataType.v === DataType.ViewV1) {
    const view = change.data as View;

    for(let table of view.tables.v||[]) {
      if(table.module.v !== TableModuleId.GraffeTableV1) continue;
      if(table.props.v?.ref === unsealed.fullRef() && (!table.props.v?.commit || unsealed.commit.parents?.indexOf(table.props.v?.commit) >= 0)) {
        console.debug(`syncing view dep to ${unsealed.fullRef()}`);
        table.props.v = { ...table.props.v, commit: unsealed.hash };
      }
    }
  }
}

// Editor works by loading _specific_ versions, once an object is on the editor, it's evolution is managed by the user.
// We only allow one open editing version on the editor of an object. This is the editing change object for a reference.
//
// Commits of trees require to commit the full tree before the parent can be committed with the new versions.
//
// Commit-specific loads are a pro behavior. Most people assume ref-based loads. Commits are the edgecase.
// 
// Objects on the editor need an identifier for uniqueness, when LATEST object is active, this point to the refHash.
// This makes the space work as well. Then if you want to link to specific commits, they are loaded pinned.
// 
// RefHash is the identifier for latest loads.

// Loading of changes has following patterns:
//
// -  Adding from portal: (here we know the version)
// -  Revise: open from inspector/viewer with specific version
// -  Add from link: (short url - lookup version)
//
// Multiple objects on the editor of the same ref:
// -  Parallel objects can exist (objects work forward from their load context - user then pulls them forward via actions)
// -  
//
// Commonly you want the latest version to be added (which has a commit hash).
// It's up to the user to pull forward commits to later versions.
//
// So we result on an add strategy which uses a hash or a tag always.
// When an @ version is missing, we can just add @latest to the end, which resolved to the latest version.
//
// This means that every object gets loaded from a specific commit ! FUCK YEAH !
// 
// -  Load an object onto the editor. _If we have edit permissions_ on the object and it's not a commit load, then we load the changes.
//    So users enter by ref
//
// Users load a latest change - we resolve a specific commit on add - 
//

// Clicking Revise will always render the latest version of references. Since you are working on those commits.
// The commit import string is informative.

// Flows:
// -  Click Revise on a LATEST link: open a new editor, pass the exact version of the object, if the version is latest, latest handling kicks in.
// -  Click Revise on a DATED link: open a new editor, pass the exact hash, since outdated, latest handling requires user to sync up objects. No difference between forks and owning objects.
// -  Load pinned versions on the editor: paste a link, portal shows "import latest REF X and import version X of REF". This detaches them.
// -  Rendering of latest is by refHash.
// -  Rendering of detached is by hash.

// // The init seed controls the object loader and also contains intent how to render a ctx on the editor/inspector.
// // 
// // -  For URL loads / portal loads or latest objects (default) the resolver sets the latest commit hash. 
// // -  If latest is the same at the time of commit, then pull forward is done. When not the same, commit should fail for now.
// // -  For specific version loads, Graffe indicates outdated and renders the same.
// // -  For outdated loads, Graffe indicates outdated.
// // -  For detached loads, Graffe indicates outdated.
// //
// export interface ObjectInitCtxSeed {
//   // Full reference to the object, when loading, without the @-prefix.
//   // This loads strings like roel/some-dash/some-table and resolves the collection ID from the slug.
//   //
//   // Loading without reference indicates a detached load.
//   // True when the object is loaded with intent to only render and allow no changes.
//   // This causes the object to render in the space by commit hash and not by refHash.
//   fullRef?: string;

//   // The most recent known load commit version is always passed, except for changes which have not yet been committed.
//   // When this is missing, then we've loaded from a fresh change.
//   commitHash?: string;
// }

// // This blows away and re-initializes the ctx from seed + storage.
  // // If the change is already resolved, it can be seeded, this will avoid loading it remotely. Mostly used for quickly resetting/cloning ctxs.
  // async reInitialize(seedInitData?: UnsealedCommitData) {
  //   // We require an initialized context. Loaders take care of this, eg: see RenderInstance.
  //   if(!this.init.v) unhandled();
  //   if(!this.filterCtx.v) unexpected();

  //   // Set seed commit data
  //   if(seedInitData != null) {
  //     this.init.v.initData = seedInitData;
  //   } else if(this.init.v.seed.commitHash) {
  //     // If a commit hash is provided we load the data.
  //     const commit = await resolveCommit(this.init.v.seed.commitHash);
  //     if(!commit) {
  //       throw new Error('Could not find commit');
  //     }
  //     const unsealed = await commit.unseal();
  //     this.init.v.initData = getStorageModel(unsealed);

  //     // // If this is a ref-based load, then we load the ref + any open changes on top of it.
  //     // const { colId, colRef } = await fullRefToRefHashProps(this.init.v.seed.fullRef);
  //     // const refHash = toRefHash(colRef, colId);
  //     // const commit = await resolveHeadCommitByRefHash(Thing.Object, refHash);
  //     // if(commit) {
  //     //   const unsealed = await commit.unseal();
  //     //   this.init.v.initData = getStorageModel(unsealed);
  //     // }

  //     // // Layer on top any open change we have for this commit
  //     // const openChange = await (await store()).getChangeCommitForRefHash(Thing.Object, refHash);
  //     // if(openChange) {
  //     //   const unsealed = await openChange.unseal();
  //     //   this.lastSave.v = getStorageModel(unsealed);
  //     // }
  //   // }
  //   }

  //   // Load any open changes to layer on top (allowed for any object right now)
  //   if(this.init.v.seed.fullRef) {
  //     const { colId, colRef } = await fullRefToRefHashProps(this.init.v.seed.fullRef);
  //     const refHash = toRefHash(colRef, colId);
  //     // Layer on top any open change we have for this commit
  //     const openChange = await (await store()).getChangeCommitForRefHash(Thing.Object, refHash);
  //     if(openChange) {
  //       const unsealed = await openChange.unseal();
  //       this.lastSave.v = getStorageModel(unsealed);
  //     }
  //   }

  //   // Load the change
  //   const change = Change.fromUnsealedCommit(
  //     UnsealedCommit.fromData(this.lastSave.v ?? this.init.v.initData) // We load from the last change, if one is provided.
  //   );

  //   // Assert
  //   if(!change) unexpected('no change object');

  //   // Load the object filters on the filter context
  //   if(change.props.filters.v && change.props.filters.v.length > 0) {
  //     this.filterCtx.v.filters.v = [...change.props.filters.v];
  //   }
    
  //   // Trigger the context "open for business".
  //   this.open.v = change;
  // }

// export async function wrapCommitChangeInCtx(change: Change) : Promise<ObjectCtx> {
//   // Create an object ctx
//   const ctx = new ObjectCtx();
//   ctx.filterCtx.v = new FilterCtx();
  
//   // We fetch the current slug for this collection id, which might have changed.
//   const slug = await resolveCollectionSlugById(change.ext.collection.id);
//   ctx.init.v = {
//     seed: {
//       fullRef: toFullRef(slug, change.ext.ref || change.props.ref),
//       commitHash: change.hash,
//     }
//   };
  
//   await ctx.reInitialize(getStorageModel(change.toUnsealedCommit()));

//   return ctx;
// }

// export async function delLastSave(ctx: ObjectCtx) {
//   if(ctx.lastSave.v != null) {
//     await (await store()).delChangeForRefHash(Thing.Object, ctx.lastSave.v?.ext.refHash);
    
//     // Clear the save on storage
//     ctx.lastSave.v = null;

//     // Reset the ctx to the last commit point
//     if(ctx.init.v.initData != null) {
//       await ctx.reInitialize(ctx.init.v.initData);
//     } else {
//       unhandled();
//     }
//   }
// }
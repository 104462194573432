import { findParent } from "./dom.js";

declare global {
  interface Element {
    // Ufti contexts
    ctx?: any[];
  }
}

const z = new WeakMap<object, any[]>();

// Injected ctx parents
const p = new WeakMap<object, object>();

export function addCtx<T>(el: Element, ctx: T) : T {
  if(!z.has(el)) z.set(el, []);
  z.get(el)!.push(ctx);

  return ctx;
}

export function copyCtxs(source: Element, target: Element) {
  if(z.has(source)) {
    const ctxs = z.get(source);
    for(let ctx of ctxs) {
      addCtx(target, ctx);
    }
  }
}

// This tracks a disconnected parent to use for ctx resolution instead of its actual parents.
// And returns the original element
export function setCtxParent(el: Element, parent: Element) : Element {
  p.set(el, parent);
  return el;
}

// Ctx resolver which doesn't throw, allows finding a ctx from self and allows skipping initial levels
export function findCtx<T>(start: Element, ctxClass: { new(): T }, skipLevels: number = 0) : T {
  let node = start;
  while(node != null) {
    if(skipLevels <= 0) {
      const ctx: T = getCtxOn(node, ctxClass);
      if(ctx != null) return ctx;
    }
    skipLevels--;
    if(p.has(node)) {
      node = p.get(node);
    } else {
      node = node.parentElement;
    }
  }
}

// /**
//  * DEPRECATED
//  * More advanced version of getCtx version, mainly for building hierarchical ctx resolvers.
//  * Doesn't throw.
//  * 
//  * @param el element to start searching from for context
//  * @param ctxClass the context class
//  * @returns the found { ctx, node } combo
//  */
//  export function getCtxEl<T>(el: Element, ctxClass: { new(): T }) : { ctx: T, el: Element } {
//   console.warn('getCtxEl is deprecated, use findCtx(el, ctx, 1) instead');
//   let ctx: T;
//   const node = findParent(el, p => !!(ctx = getCtxOn(p, ctxClass)));
//   if(node) {
//     return { ctx, el: node }
//   }
// }

/**
 * Get direct context on a single element
 * 
 * @param el element to check for ctxClass
 * @param ctxClass the context class
 * @returns any found ctx instance or undefined
 */
export function getCtxOn<T>(el: Element, ctxClass: { new(): T }) : T {
  return z.get(el)?.find(c => c instanceof (ctxClass as any));
}

/**
 * Get direct contexts on a single element
 * 
 * @param el element to check for ctxClass
 * @param ctxClass the context class
 * @returns any found ctx instance or undefined
 */
export function getCtxsOn<T>(el: Element, ctxClass: { new(): T }) : T[] {
  return z.get(el)?.filter(c => c instanceof (ctxClass as any));
}


/**
 * DEPRECATED
 * Returns the first parent context found which is an instance of ctxClass.
 * Throws when no context can be found.
 * 
 * @param el element to start searching from for context
 * @param ctxClass the context class type
 * @returns the found ctx
 */
export function getCtx<T>(el: Element, ctxClass: { new(): T }) : T {
  console.warn('getCtx is deprecated, use findCtx(el, ctx, 1) instead and add a throw check');
  const res = getCtxEl(el, ctxClass);
  if(res?.ctx != null) {
    return res.ctx;
  }
  
  throw new Error('ctx not found');
}

import { createElement } from 'ufti';

export function Action(props : HTMLButtonElement, children) : HTMLButtonElement {
  return createElement(
    'button', 
    {
      type: 'button',
      ...props, // can be overridden to submit
    }, 
    ...children,
  );
}
// A thing is a uniquely addressable thing in the universe, it has a last version.
// Things can share the same address, if they address the same address.
export enum Thing {
  Object = "Object",
  Access = "Access",
}

// Things have data in their last version commits and that data has a data type to hint the parsers.
// Data types are shared across things and ideally should not overlap in naming. Only Tombstone key is shared to indicate dead objects.
export enum DataType {
  // -- SHARED

  // Indicates a reference has ended.
  // Needs to be seperately committed for each Thing.
  // The data in the commit is recommended to be empty.
  Tombstone = "Tombstone",

  // -- OBJECTS

  // Tables
  TableV1 = "TableV1",

  // Views
  ViewV1 = "ViewV1",

  // Dashboards
  DashV1 = "DashV1",

  // -- ACCESS

  // Direct object access policy
  ObjectPolicy = "ObjectPolicy",
}

export const dataTypeList = [
  'TableV1',
  'ViewV1',
  'DashV1',
  'Tombstone',
  'ObjectPolicy',
]

// -  Table Modules are code (an abstract service is a connector).
// -  Connections are session-specific instances of services.
// -  Tables are queryable data definitions which provide a columnar structure.
// -  Dashboards are collections of apps and views
// -  Apps are the most advanced render type with high customizatin and high interactivity. Complex to build.
// -  Views are a simple render type. Easy to build. 


// Repo consists of references and objects.
// Nodes are reusable objects and can be found in the repository, every node exists as a commit, or as a reference.
// Technically a Dashboard is also a reusable module, since it's views are importable.
// As codebase devs, a Table is the storage model of the actual table information.